(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File !== 'undefined' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[36m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.cM.ar === region.d0.ar)
	{
		return 'on line ' + region.cM.ar;
	}
	return 'on lines ' + region.cM.ar + ' through ' + region.d0.ar;
}



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return !isNaN(word)
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.hZ,
		impl.iZ,
		impl.iK,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	var initPair = init(result.a);
	var model = initPair.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		var pair = A2(update, msg, model);
		stepper(model = pair.a, viewMetadata);
		_Platform_enqueueEffects(managers, pair.b, subscriptions(model));
	}

	_Platform_enqueueEffects(managers, initPair.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS
//
// Effects must be queued!
//
// Say your init contains a synchronous command, like Time.now or Time.here
//
//   - This will produce a batch of effects (FX_1)
//   - The synchronous task triggers the subsequent `update` call
//   - This will produce a batch of effects (FX_2)
//
// If we just start dispatching FX_2, subscriptions from FX_2 can be processed
// before subscriptions from FX_1. No good! Earlier versions of this code had
// this problem, leading to these reports:
//
//   https://github.com/elm/core/issues/980
//   https://github.com/elm/core/pull/981
//   https://github.com/elm/compiler/issues/1776
//
// The queue is necessary to avoid ordering issues for synchronous commands.


// Why use true/false here? Why not just check the length of the queue?
// The goal is to detect "are we currently dispatching effects?" If we
// are, we need to bail and let the ongoing while loop handle things.
//
// Now say the queue has 1 element. When we dequeue the final element,
// the queue will be empty, but we are still actively dispatching effects.
// So you could get queue jumping in a really tricky category of cases.
//
var _Platform_effectsQueue = [];
var _Platform_effectsActive = false;


function _Platform_enqueueEffects(managers, cmdBag, subBag)
{
	_Platform_effectsQueue.push({ p: managers, q: cmdBag, r: subBag });

	if (_Platform_effectsActive) return;

	_Platform_effectsActive = true;
	for (var fx; fx = _Platform_effectsQueue.shift(); )
	{
		_Platform_dispatchEffects(fx.p, fx.q, fx.r);
	}
	_Platform_effectsActive = false;
}


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				s: bag.n,
				t: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.t)
		{
			x = temp.s(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		u: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		u: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS
//
// For some reason, tabs can appear in href protocols and it still works.
// So '\tjava\tSCRIPT:alert("!!!")' and 'javascript:alert("!!!")' are the same
// in practice. That is why _VirtualDom_RE_js and _VirtualDom_RE_js_html look
// so freaky.
//
// Pulling the regular expressions out to the top level gives a slight speed
// boost in small benchmarks (4-10%) but hoisting values to reduce allocation
// can be unpredictable in large programs where JIT may have a harder time with
// functions are not fully self-contained. The benefit is more that the js and
// js_html ones are so weird that I prefer to see them near each other.


var _VirtualDom_RE_script = /^script$/i;
var _VirtualDom_RE_on_formAction = /^(on|formAction$)/i;
var _VirtualDom_RE_js = /^\s*j\s*a\s*v\s*a\s*s\s*c\s*r\s*i\s*p\s*t\s*:/i;
var _VirtualDom_RE_js_html = /^\s*(j\s*a\s*v\s*a\s*s\s*c\s*r\s*i\s*p\s*t\s*:|d\s*a\s*t\s*a\s*:\s*t\s*e\s*x\s*t\s*\/\s*h\s*t\s*m\s*l\s*(,|;))/i;


function _VirtualDom_noScript(tag)
{
	return _VirtualDom_RE_script.test(tag) ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return _VirtualDom_RE_on_formAction.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return _VirtualDom_RE_js.test(value)
		? /**/''//*//**_UNUSED/'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'//*/
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return _VirtualDom_RE_js_html.test(value)
		? /**/''//*//**_UNUSED/'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'//*/
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlJson(value)
{
	return (typeof _Json_unwrap(value) === 'string' && _VirtualDom_RE_js_html.test(_Json_unwrap(value)))
		? _Json_wrap(
			/**/''//*//**_UNUSED/'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'//*/
		) : value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		G: func(record.G),
		cO: record.cO,
		cG: record.cG
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.G;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.cO;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.cG) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.hZ,
		impl.iZ,
		impl.iK,
		function(sendToApp, initialModel) {
			var view = impl.i1;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.hZ,
		impl.iZ,
		impl.iK,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.cL && impl.cL(sendToApp)
			var view = impl.i1;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.hl);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.iS) && (_VirtualDom_doc.title = title = doc.iS);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.iq;
	var onUrlRequest = impl.ir;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		cL: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.fI === next.fI
							&& curr.ea === next.ea
							&& curr.fD.a === next.fD.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		hZ: function(flags)
		{
			return A3(impl.hZ, flags, _Browser_getUrl(), key);
		},
		i1: impl.i1,
		iZ: impl.iZ,
		iK: impl.iK
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { hV: 'hidden', ho: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { hV: 'mozHidden', ho: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { hV: 'msHidden', ho: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { hV: 'webkitHidden', ho: 'webkitvisibilitychange' }
		: { hV: 'hidden', ho: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		f3: _Browser_getScene(),
		g6: {
			ha: _Browser_window.pageXOffset,
			hb: _Browser_window.pageYOffset,
			g7: _Browser_doc.documentElement.clientWidth,
			d9: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		g7: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		d9: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			f3: {
				g7: node.scrollWidth,
				d9: node.scrollHeight
			},
			g6: {
				ha: node.scrollLeft,
				hb: node.scrollTop,
				g7: node.clientWidth,
				d9: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			f3: _Browser_getScene(),
			g6: {
				ha: x,
				hb: y,
				g7: _Browser_doc.documentElement.clientWidth,
				d9: _Browser_doc.documentElement.clientHeight
			},
			hJ: {
				ha: x + rect.left,
				hb: y + rect.top,
				g7: rect.width,
				d9: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}



var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});


function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return $elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return $elm$core$Maybe$Nothing;
	}
}


function _Time_now(millisToPosix)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(millisToPosix(Date.now())));
	});
}

var _Time_setInterval = F2(function(interval, task)
{
	return _Scheduler_binding(function(callback)
	{
		var id = setInterval(function() { _Scheduler_rawSpawn(task); }, interval);
		return function() { clearInterval(id); };
	});
});

function _Time_here()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(
			A2($elm$time$Time$customZone, -(new Date().getTimezoneOffset()), _List_Nil)
		));
	});
}


function _Time_getZoneName()
{
	return _Scheduler_binding(function(callback)
	{
		try
		{
			var name = $elm$time$Time$Name(Intl.DateTimeFormat().resolvedOptions().timeZone);
		}
		catch (e)
		{
			var name = $elm$time$Time$Offset(new Date().getTimezoneOffset());
		}
		callback(_Scheduler_succeed(name));
	});
}



// SEND REQUEST

var _Http_toTask = F3(function(router, toTask, request)
{
	return _Scheduler_binding(function(callback)
	{
		function done(response) {
			callback(toTask(request.hK.a(response)));
		}

		var xhr = new XMLHttpRequest();
		xhr.addEventListener('error', function() { done($elm$http$Http$NetworkError_); });
		xhr.addEventListener('timeout', function() { done($elm$http$Http$Timeout_); });
		xhr.addEventListener('load', function() { done(_Http_toResponse(request.hK.b, xhr)); });
		$elm$core$Maybe$isJust(request.iV) && _Http_track(router, xhr, request.iV.a);

		try {
			xhr.open(request.h7, request.i_, true);
		} catch (e) {
			return done($elm$http$Http$BadUrl_(request.i_));
		}

		_Http_configureRequest(xhr, request);

		request.hl.a && xhr.setRequestHeader('Content-Type', request.hl.a);
		xhr.send(request.hl.b);

		return function() { xhr.c = true; xhr.abort(); };
	});
});


// CONFIGURE

function _Http_configureRequest(xhr, request)
{
	for (var headers = request.hT; headers.b; headers = headers.b) // WHILE_CONS
	{
		xhr.setRequestHeader(headers.a.a, headers.a.b);
	}
	xhr.timeout = request.iR.a || 0;
	xhr.responseType = request.hK.d;
	xhr.withCredentials = request.he;
}


// RESPONSES

function _Http_toResponse(toBody, xhr)
{
	return A2(
		200 <= xhr.status && xhr.status < 300 ? $elm$http$Http$GoodStatus_ : $elm$http$Http$BadStatus_,
		_Http_toMetadata(xhr),
		toBody(xhr.response)
	);
}


// METADATA

function _Http_toMetadata(xhr)
{
	return {
		i_: xhr.responseURL,
		iH: xhr.status,
		iI: xhr.statusText,
		hT: _Http_parseHeaders(xhr.getAllResponseHeaders())
	};
}


// HEADERS

function _Http_parseHeaders(rawHeaders)
{
	if (!rawHeaders)
	{
		return $elm$core$Dict$empty;
	}

	var headers = $elm$core$Dict$empty;
	var headerPairs = rawHeaders.split('\r\n');
	for (var i = headerPairs.length; i--; )
	{
		var headerPair = headerPairs[i];
		var index = headerPair.indexOf(': ');
		if (index > 0)
		{
			var key = headerPair.substring(0, index);
			var value = headerPair.substring(index + 2);

			headers = A3($elm$core$Dict$update, key, function(oldValue) {
				return $elm$core$Maybe$Just($elm$core$Maybe$isJust(oldValue)
					? value + ', ' + oldValue.a
					: value
				);
			}, headers);
		}
	}
	return headers;
}


// EXPECT

var _Http_expect = F3(function(type, toBody, toValue)
{
	return {
		$: 0,
		d: type,
		b: toBody,
		a: toValue
	};
});

var _Http_mapExpect = F2(function(func, expect)
{
	return {
		$: 0,
		d: expect.d,
		b: expect.b,
		a: function(x) { return func(expect.a(x)); }
	};
});

function _Http_toDataView(arrayBuffer)
{
	return new DataView(arrayBuffer);
}


// BODY and PARTS

var _Http_emptyBody = { $: 0 };
var _Http_pair = F2(function(a, b) { return { $: 0, a: a, b: b }; });

function _Http_toFormData(parts)
{
	for (var formData = new FormData(); parts.b; parts = parts.b) // WHILE_CONS
	{
		var part = parts.a;
		formData.append(part.a, part.b);
	}
	return formData;
}

var _Http_bytesToBlob = F2(function(mime, bytes)
{
	return new Blob([bytes], { type: mime });
});


// PROGRESS

function _Http_track(router, xhr, tracker)
{
	// TODO check out lengthComputable on loadstart event

	xhr.upload.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Sending({
			iD: event.loaded,
			ge: event.total
		}))));
	});
	xhr.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Receiving({
			iy: event.loaded,
			ge: event.lengthComputable ? $elm$core$Maybe$Just(event.total) : $elm$core$Maybe$Nothing
		}))));
	});
}


// DECODER

var _File_decoder = _Json_decodePrim(function(value) {
	// NOTE: checks if `File` exists in case this is run on node
	return (typeof File !== 'undefined' && value instanceof File)
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FILE', value);
});


// METADATA

function _File_name(file) { return file.name; }
function _File_mime(file) { return file.type; }
function _File_size(file) { return file.size; }

function _File_lastModified(file)
{
	return $elm$time$Time$millisToPosix(file.lastModified);
}


// DOWNLOAD

var _File_downloadNode;

function _File_getDownloadNode()
{
	return _File_downloadNode || (_File_downloadNode = document.createElement('a'));
}

var _File_download = F3(function(name, mime, content)
{
	return _Scheduler_binding(function(callback)
	{
		var blob = new Blob([content], {type: mime});

		// for IE10+
		if (navigator.msSaveOrOpenBlob)
		{
			navigator.msSaveOrOpenBlob(blob, name);
			return;
		}

		// for HTML5
		var node = _File_getDownloadNode();
		var objectUrl = URL.createObjectURL(blob);
		node.href = objectUrl;
		node.download = name;
		_File_click(node);
		URL.revokeObjectURL(objectUrl);
	});
});

function _File_downloadUrl(href)
{
	return _Scheduler_binding(function(callback)
	{
		var node = _File_getDownloadNode();
		node.href = href;
		node.download = '';
		node.origin === location.origin || (node.target = '_blank');
		_File_click(node);
	});
}


// IE COMPATIBILITY

function _File_makeBytesSafeForInternetExplorer(bytes)
{
	// only needed by IE10 and IE11 to fix https://github.com/elm/file/issues/10
	// all other browsers can just run `new Blob([bytes])` directly with no problem
	//
	return new Uint8Array(bytes.buffer, bytes.byteOffset, bytes.byteLength);
}

function _File_click(node)
{
	// only needed by IE10 and IE11 to fix https://github.com/elm/file/issues/11
	// all other browsers have MouseEvent and do not need this conditional stuff
	//
	if (typeof MouseEvent === 'function')
	{
		node.dispatchEvent(new MouseEvent('click'));
	}
	else
	{
		var event = document.createEvent('MouseEvents');
		event.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
		document.body.appendChild(node);
		node.dispatchEvent(event);
		document.body.removeChild(node);
	}
}


// UPLOAD

var _File_node;

function _File_uploadOne(mimes)
{
	return _Scheduler_binding(function(callback)
	{
		_File_node = document.createElement('input');
		_File_node.type = 'file';
		_File_node.accept = A2($elm$core$String$join, ',', mimes);
		_File_node.addEventListener('change', function(event)
		{
			callback(_Scheduler_succeed(event.target.files[0]));
		});
		_File_click(_File_node);
	});
}

function _File_uploadOneOrMore(mimes)
{
	return _Scheduler_binding(function(callback)
	{
		_File_node = document.createElement('input');
		_File_node.type = 'file';
		_File_node.multiple = true;
		_File_node.accept = A2($elm$core$String$join, ',', mimes);
		_File_node.addEventListener('change', function(event)
		{
			var elmFiles = _List_fromArray(event.target.files);
			callback(_Scheduler_succeed(_Utils_Tuple2(elmFiles.a, elmFiles.b)));
		});
		_File_click(_File_node);
	});
}


// CONTENT

function _File_toString(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(reader.result));
		});
		reader.readAsText(blob);
		return function() { reader.abort(); };
	});
}

function _File_toBytes(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(new DataView(reader.result)));
		});
		reader.readAsArrayBuffer(blob);
		return function() { reader.abort(); };
	});
}

function _File_toUrl(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(reader.result));
		});
		reader.readAsDataURL(blob);
		return function() { reader.abort(); };
	});
}



// CREATE

var _Regex_never = /.^/;

var _Regex_fromStringWith = F2(function(options, string)
{
	var flags = 'g';
	if (options.ia) { flags += 'm'; }
	if (options.hm) { flags += 'i'; }

	try
	{
		return $elm$core$Maybe$Just(new RegExp(string, flags));
	}
	catch(error)
	{
		return $elm$core$Maybe$Nothing;
	}
});


// USE

var _Regex_contains = F2(function(re, string)
{
	return string.match(re) !== null;
});


var _Regex_findAtMost = F3(function(n, re, str)
{
	var out = [];
	var number = 0;
	var string = str;
	var lastIndex = re.lastIndex;
	var prevLastIndex = -1;
	var result;
	while (number++ < n && (result = re.exec(string)))
	{
		if (prevLastIndex == re.lastIndex) break;
		var i = result.length - 1;
		var subs = new Array(i);
		while (i > 0)
		{
			var submatch = result[i];
			subs[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		out.push(A4($elm$regex$Regex$Match, result[0], result.index, number, _List_fromArray(subs)));
		prevLastIndex = re.lastIndex;
	}
	re.lastIndex = lastIndex;
	return _List_fromArray(out);
});


var _Regex_replaceAtMost = F4(function(n, re, replacer, string)
{
	var count = 0;
	function jsReplacer(match)
	{
		if (count++ >= n)
		{
			return match;
		}
		var i = arguments.length - 3;
		var submatches = new Array(i);
		while (i > 0)
		{
			var submatch = arguments[i];
			submatches[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		return replacer(A4($elm$regex$Regex$Match, match, arguments[arguments.length - 2], count, _List_fromArray(submatches)));
	}
	return string.replace(re, jsReplacer);
});

var _Regex_splitAtMost = F3(function(n, re, str)
{
	var string = str;
	var out = [];
	var start = re.lastIndex;
	var restoreLastIndex = re.lastIndex;
	while (n--)
	{
		var result = re.exec(string);
		if (!result) break;
		out.push(string.slice(start, result.index));
		start = re.lastIndex;
	}
	out.push(string.slice(start));
	re.lastIndex = restoreLastIndex;
	return _List_fromArray(out);
});

var _Regex_infinity = Infinity;




// STRINGS


var _Parser_isSubString = F5(function(smallString, offset, row, col, bigString)
{
	var smallLength = smallString.length;
	var isGood = offset + smallLength <= bigString.length;

	for (var i = 0; isGood && i < smallLength; )
	{
		var code = bigString.charCodeAt(offset);
		isGood =
			smallString[i++] === bigString[offset++]
			&& (
				code === 0x000A /* \n */
					? ( row++, col=1 )
					: ( col++, (code & 0xF800) === 0xD800 ? smallString[i++] === bigString[offset++] : 1 )
			)
	}

	return _Utils_Tuple3(isGood ? offset : -1, row, col);
});



// CHARS


var _Parser_isSubChar = F3(function(predicate, offset, string)
{
	return (
		string.length <= offset
			? -1
			:
		(string.charCodeAt(offset) & 0xF800) === 0xD800
			? (predicate(_Utils_chr(string.substr(offset, 2))) ? offset + 2 : -1)
			:
		(predicate(_Utils_chr(string[offset]))
			? ((string[offset] === '\n') ? -2 : (offset + 1))
			: -1
		)
	);
});


var _Parser_isAsciiCode = F3(function(code, offset, string)
{
	return string.charCodeAt(offset) === code;
});



// NUMBERS


var _Parser_chompBase10 = F2(function(offset, string)
{
	for (; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (code < 0x30 || 0x39 < code)
		{
			return offset;
		}
	}
	return offset;
});


var _Parser_consumeBase = F3(function(base, offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var digit = string.charCodeAt(offset) - 0x30;
		if (digit < 0 || base <= digit) break;
		total = base * total + digit;
	}
	return _Utils_Tuple2(offset, total);
});


var _Parser_consumeBase16 = F2(function(offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (0x30 <= code && code <= 0x39)
		{
			total = 16 * total + code - 0x30;
		}
		else if (0x41 <= code && code <= 0x46)
		{
			total = 16 * total + code - 55;
		}
		else if (0x61 <= code && code <= 0x66)
		{
			total = 16 * total + code - 87;
		}
		else
		{
			break;
		}
	}
	return _Utils_Tuple2(offset, total);
});



// FIND STRING


var _Parser_findSubString = F5(function(smallString, offset, row, col, bigString)
{
	var newOffset = bigString.indexOf(smallString, offset);
	var target = newOffset < 0 ? bigString.length : newOffset + smallString.length;

	while (offset < target)
	{
		var code = bigString.charCodeAt(offset++);
		code === 0x000A /* \n */
			? ( col=1, row++ )
			: ( col++, (code & 0xF800) === 0xD800 && offset++ )
	}

	return _Utils_Tuple3(newOffset, row, col);
});
var $author$project$Msg$ClickedLink = function (a) {
	return {$: 1, a: a};
};
var $author$project$Msg$UrlChange = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $elm$core$List$cons = _List_cons;
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.g) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.i),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.i);
		} else {
			var treeLen = builder.g * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.j) : builder.j;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.g);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.i) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.i);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{j: nodeList, g: (len / $elm$core$Array$branchFactor) | 0, i: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {d8: fragment, ea: host, fB: path, fD: port_, fI: protocol, fJ: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$application = _Browser_application;
var $author$project$Msg$NavMsg = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $author$project$Model$Model$AuthStateSignInPage = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $author$project$Model$Model$AuthUiStateLogin = 0;
var $author$project$TypesPage$PageAllN80s = {$: 1};
var $author$project$BigO$GeneralOptions$TimeFormatIncludeSecondsFalse = 1;
var $author$project$Api$UserEmail$UserEmail = function (unUserEmail) {
	return {gR: unUserEmail};
};
var $author$project$Consts$defaultEmail = $author$project$Api$UserEmail$UserEmail('mike@flexisense.io');
var $author$project$Api$UserPassword$UserPassword = function (unUserPassword) {
	return {gW: unUserPassword};
};
var $author$project$Consts$defaultPassword = $author$project$Api$UserPassword$UserPassword('hello123');
var $turboMaCk$any_dict$Dict$Any$AnyDict = $elm$core$Basics$identity;
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $turboMaCk$any_dict$Dict$Any$empty = function (toKey) {
	return {c: $elm$core$Dict$empty, t: toKey};
};
var $rundis$elm_bootstrap$Bootstrap$Modal$Hide = 3;
var $rundis$elm_bootstrap$Bootstrap$Modal$hidden = 3;
var $rundis$elm_bootstrap$Bootstrap$Tab$Showing = 2;
var $rundis$elm_bootstrap$Bootstrap$Tab$State = $elm$core$Basics$identity;
var $rundis$elm_bootstrap$Bootstrap$Tab$initialState = {aL: $elm$core$Maybe$Nothing, m: 2};
var $elm$time$Time$Posix = $elm$core$Basics$identity;
var $elm$time$Time$millisToPosix = $elm$core$Basics$identity;
var $elm$core$String$cons = _String_cons;
var $elm$core$String$fromChar = function (_char) {
	return A2($elm$core$String$cons, _char, '');
};
var $elm$core$Bitwise$and = _Bitwise_and;
var $elm$core$Bitwise$shiftRightBy = _Bitwise_shiftRightBy;
var $elm$core$String$repeatHelp = F3(
	function (n, chunk, result) {
		return (n <= 0) ? result : A3(
			$elm$core$String$repeatHelp,
			n >> 1,
			_Utils_ap(chunk, chunk),
			(!(n & 1)) ? result : _Utils_ap(result, chunk));
	});
var $elm$core$String$repeat = F2(
	function (n, chunk) {
		return A3($elm$core$String$repeatHelp, n, chunk, '');
	});
var $elm$core$String$padLeft = F3(
	function (n, _char, string) {
		return _Utils_ap(
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)),
			string);
	});
var $cmditch$elm_bigint$BigInt$Magnitude = $elm$core$Basics$identity;
var $cmditch$elm_bigint$BigInt$Pos = function (a) {
	return {$: 0, a: a};
};
var $cmditch$elm_bigint$BigInt$BigIntNotNormalised = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $cmditch$elm_bigint$BigInt$MagnitudeNotNormalised = $elm$core$Basics$identity;
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $elm$core$Basics$abs = function (n) {
	return (n < 0) ? (-n) : n;
};
var $elm_community$list_extra$List$Extra$last = function (items) {
	last:
	while (true) {
		if (!items.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			if (!items.b.b) {
				var x = items.a;
				return $elm$core$Maybe$Just(x);
			} else {
				var rest = items.b;
				var $temp$items = rest;
				items = $temp$items;
				continue last;
			}
		}
	}
};
var $cmditch$elm_bigint$BigInt$isNegativeMagnitude = function (digits) {
	var _v0 = $elm_community$list_extra$List$Extra$last(digits);
	if (_v0.$ === 1) {
		return false;
	} else {
		var x = _v0.a;
		return x < 0;
	}
};
var $cmditch$elm_bigint$BigInt$Neg = function (a) {
	return {$: 1, a: a};
};
var $cmditch$elm_bigint$BigInt$Zer = {$: 2};
var $elm$core$List$isEmpty = function (xs) {
	if (!xs.b) {
		return true;
	} else {
		return false;
	}
};
var $cmditch$elm_bigint$BigInt$mkBigInt = F2(
	function (s, mag) {
		var digits = mag;
		if ($elm$core$List$isEmpty(digits)) {
			return $cmditch$elm_bigint$BigInt$Zer;
		} else {
			switch (s) {
				case 2:
					return $cmditch$elm_bigint$BigInt$Zer;
				case 0:
					return $cmditch$elm_bigint$BigInt$Pos(mag);
				default:
					return $cmditch$elm_bigint$BigInt$Neg(mag);
			}
		}
	});
var $cmditch$elm_bigint$BigInt$mkBigIntNotNormalised = F2(
	function (s, digits) {
		return A2($cmditch$elm_bigint$BigInt$BigIntNotNormalised, s, digits);
	});
var $elm_community$list_extra$List$Extra$dropWhileRight = function (p) {
	return A2(
		$elm$core$List$foldr,
		F2(
			function (x, xs) {
				return (p(x) && $elm$core$List$isEmpty(xs)) ? _List_Nil : A2($elm$core$List$cons, x, xs);
			}),
		_List_Nil);
};
var $cmditch$elm_bigint$BigInt$dropZeroes = $elm_community$list_extra$List$Extra$dropWhileRight(
	$elm$core$Basics$eq(0));
var $cmditch$elm_bigint$Constants$maxDigitMagnitude = 7;
var $elm$core$Basics$pow = _Basics_pow;
var $cmditch$elm_bigint$Constants$maxDigitValue = (-1) + A2($elm$core$Basics$pow, 10, $cmditch$elm_bigint$Constants$maxDigitMagnitude);
var $cmditch$elm_bigint$BigInt$baseDigit = $cmditch$elm_bigint$Constants$maxDigitValue + 1;
var $elm$core$Tuple$mapFirst = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			func(x),
			y);
	});
var $cmditch$elm_bigint$BigInt$normaliseDigit = function (x) {
	return (x < 0) ? A2(
		$elm$core$Tuple$mapFirst,
		$elm$core$Basics$add(-1),
		$cmditch$elm_bigint$BigInt$normaliseDigit(x + $cmditch$elm_bigint$BigInt$baseDigit)) : _Utils_Tuple2((x / $cmditch$elm_bigint$BigInt$baseDigit) | 0, x % $cmditch$elm_bigint$BigInt$baseDigit);
};
var $cmditch$elm_bigint$BigInt$normaliseDigitList = F2(
	function (carry, xs) {
		normaliseDigitList:
		while (true) {
			if (!xs.b) {
				if (_Utils_cmp(carry, $cmditch$elm_bigint$BigInt$baseDigit) > 0) {
					var $temp$carry = 0,
						$temp$xs = _List_fromArray(
						[carry]);
					carry = $temp$carry;
					xs = $temp$xs;
					continue normaliseDigitList;
				} else {
					return _List_fromArray(
						[carry]);
				}
			} else {
				var x = xs.a;
				var xs_ = xs.b;
				var _v1 = $cmditch$elm_bigint$BigInt$normaliseDigit(x + carry);
				var newCarry = _v1.a;
				var x_ = _v1.b;
				return A2(
					$elm$core$List$cons,
					x_,
					A2($cmditch$elm_bigint$BigInt$normaliseDigitList, newCarry, xs_));
			}
		}
	});
var $cmditch$elm_bigint$BigInt$normaliseMagnitude = function (_v0) {
	var xs = _v0;
	return $cmditch$elm_bigint$BigInt$dropZeroes(
		A2($cmditch$elm_bigint$BigInt$normaliseDigitList, 0, xs));
};
var $cmditch$elm_bigint$BigInt$reverseMagnitude = $elm$core$List$map($elm$core$Basics$negate);
var $cmditch$elm_bigint$BigInt$Negative = 1;
var $cmditch$elm_bigint$BigInt$Positive = 0;
var $cmditch$elm_bigint$BigInt$Zero = 2;
var $cmditch$elm_bigint$BigInt$signNegate = function (sign_) {
	switch (sign_) {
		case 0:
			return 1;
		case 1:
			return 0;
		default:
			return 2;
	}
};
var $cmditch$elm_bigint$BigInt$normalise = function (_v0) {
	normalise:
	while (true) {
		var s = _v0.a;
		var digits = _v0.b;
		var _v1 = $cmditch$elm_bigint$BigInt$normaliseMagnitude(digits);
		var normalisedMag = _v1;
		if ($cmditch$elm_bigint$BigInt$isNegativeMagnitude(normalisedMag)) {
			var $temp$_v0 = A2(
				$cmditch$elm_bigint$BigInt$mkBigIntNotNormalised,
				$cmditch$elm_bigint$BigInt$signNegate(s),
				$cmditch$elm_bigint$BigInt$reverseMagnitude(normalisedMag));
			_v0 = $temp$_v0;
			continue normalise;
		} else {
			return A2($cmditch$elm_bigint$BigInt$mkBigInt, s, normalisedMag);
		}
	}
};
var $elm$core$Basics$compare = _Utils_compare;
var $cmditch$elm_bigint$BigInt$signFromInt = function (x) {
	var _v0 = A2($elm$core$Basics$compare, x, 0);
	switch (_v0) {
		case 0:
			return 1;
		case 2:
			return 0;
		default:
			return 2;
	}
};
var $cmditch$elm_bigint$BigInt$fromInt = function (x) {
	return $cmditch$elm_bigint$BigInt$normalise(
		A2(
			$cmditch$elm_bigint$BigInt$BigIntNotNormalised,
			$cmditch$elm_bigint$BigInt$signFromInt(x),
			_List_fromArray(
				[
					$elm$core$Basics$abs(x)
				])));
};
var $cmditch$elm_bigint$BigInt$bigIntToInt_ = function (bigInt) {
	_v0$3:
	while (true) {
		switch (bigInt.$) {
			case 2:
				return 0;
			case 0:
				if (bigInt.a.b) {
					if (!bigInt.a.b.b) {
						var _v1 = bigInt.a;
						var a = _v1.a;
						return a;
					} else {
						if (!bigInt.a.b.b.b) {
							var _v2 = bigInt.a;
							var a = _v2.a;
							var _v3 = _v2.b;
							var b = _v3.a;
							return (b * A2($elm$core$Basics$pow, 10, $cmditch$elm_bigint$Constants$maxDigitMagnitude)) + a;
						} else {
							break _v0$3;
						}
					}
				} else {
					break _v0$3;
				}
			default:
				break _v0$3;
		}
	}
	return 42;
};
var $cmditch$elm_bigint$BigInt$abs = function (bigInt) {
	switch (bigInt.$) {
		case 2:
			return $cmditch$elm_bigint$BigInt$Zer;
		case 1:
			var mag = bigInt.a;
			return $cmditch$elm_bigint$BigInt$Pos(mag);
		default:
			var i = bigInt;
			return i;
	}
};
var $cmditch$elm_bigint$BigInt$MagnitudePair = $elm$core$Basics$identity;
var $cmditch$elm_bigint$BigInt$sameSizeRaw = F2(
	function (xs, ys) {
		var _v0 = _Utils_Tuple2(xs, ys);
		if (!_v0.a.b) {
			if (!_v0.b.b) {
				return _List_Nil;
			} else {
				var _v2 = _v0.b;
				var y = _v2.a;
				var ys_ = _v2.b;
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(0, y),
					A2($cmditch$elm_bigint$BigInt$sameSizeRaw, _List_Nil, ys_));
			}
		} else {
			if (!_v0.b.b) {
				var _v1 = _v0.a;
				var x = _v1.a;
				var xs_ = _v1.b;
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(x, 0),
					A2($cmditch$elm_bigint$BigInt$sameSizeRaw, xs_, _List_Nil));
			} else {
				var _v3 = _v0.a;
				var x = _v3.a;
				var xs_ = _v3.b;
				var _v4 = _v0.b;
				var y = _v4.a;
				var ys_ = _v4.b;
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(x, y),
					A2($cmditch$elm_bigint$BigInt$sameSizeRaw, xs_, ys_));
			}
		}
	});
var $cmditch$elm_bigint$BigInt$sameSizeNotNormalized = F2(
	function (_v0, _v1) {
		var xs = _v0;
		var ys = _v1;
		return A2($cmditch$elm_bigint$BigInt$sameSizeRaw, xs, ys);
	});
var $cmditch$elm_bigint$BigInt$toPositiveSign = function (bigInt) {
	switch (bigInt.$) {
		case 2:
			return A2($cmditch$elm_bigint$BigInt$mkBigIntNotNormalised, 2, _List_Nil);
		case 1:
			var digits = bigInt.a;
			return A2(
				$cmditch$elm_bigint$BigInt$mkBigIntNotNormalised,
				0,
				$cmditch$elm_bigint$BigInt$reverseMagnitude(digits));
		default:
			var digits = bigInt.a;
			return A2($cmditch$elm_bigint$BigInt$mkBigIntNotNormalised, 0, digits);
	}
};
var $cmditch$elm_bigint$BigInt$add = F2(
	function (a, b) {
		var _v0 = $cmditch$elm_bigint$BigInt$toPositiveSign(b);
		var mb = _v0.b;
		var _v1 = $cmditch$elm_bigint$BigInt$toPositiveSign(a);
		var ma = _v1.b;
		var _v2 = A2($cmditch$elm_bigint$BigInt$sameSizeNotNormalized, ma, mb);
		var pairs = _v2;
		var added = A2(
			$elm$core$List$map,
			function (_v3) {
				var a_ = _v3.a;
				var b_ = _v3.b;
				return a_ + b_;
			},
			pairs);
		return $cmditch$elm_bigint$BigInt$normalise(
			A2($cmditch$elm_bigint$BigInt$BigIntNotNormalised, 0, added));
	});
var $cmditch$elm_bigint$BigInt$compareMagnitude = F4(
	function (x, y, xs, ys) {
		compareMagnitude:
		while (true) {
			var _v0 = _Utils_Tuple2(xs, ys);
			if (!_v0.a.b) {
				if (!_v0.b.b) {
					return A2($elm$core$Basics$compare, x, y);
				} else {
					return 0;
				}
			} else {
				if (!_v0.b.b) {
					return 2;
				} else {
					var _v1 = _v0.a;
					var x_ = _v1.a;
					var xss = _v1.b;
					var _v2 = _v0.b;
					var y_ = _v2.a;
					var yss = _v2.b;
					if (_Utils_eq(x_, y_)) {
						var $temp$x = x,
							$temp$y = y,
							$temp$xs = xss,
							$temp$ys = yss;
						x = $temp$x;
						y = $temp$y;
						xs = $temp$xs;
						ys = $temp$ys;
						continue compareMagnitude;
					} else {
						var $temp$x = x_,
							$temp$y = y_,
							$temp$xs = xss,
							$temp$ys = yss;
						x = $temp$x;
						y = $temp$y;
						xs = $temp$xs;
						ys = $temp$ys;
						continue compareMagnitude;
					}
				}
			}
		}
	});
var $cmditch$elm_bigint$BigInt$orderNegate = function (x) {
	switch (x) {
		case 0:
			return 2;
		case 1:
			return 1;
		default:
			return 0;
	}
};
var $cmditch$elm_bigint$BigInt$compare = F2(
	function (int1, int2) {
		var _v0 = _Utils_Tuple2(int1, int2);
		switch (_v0.a.$) {
			case 0:
				if (!_v0.b.$) {
					var mag1 = _v0.a.a;
					var mag2 = _v0.b.a;
					return A4($cmditch$elm_bigint$BigInt$compareMagnitude, 0, 0, mag1, mag2);
				} else {
					return 2;
				}
			case 1:
				if (_v0.b.$ === 1) {
					var mag1 = _v0.a.a;
					var mag2 = _v0.b.a;
					return $cmditch$elm_bigint$BigInt$orderNegate(
						A4($cmditch$elm_bigint$BigInt$compareMagnitude, 0, 0, mag1, mag2));
				} else {
					return 0;
				}
			default:
				switch (_v0.b.$) {
					case 0:
						var _v1 = _v0.a;
						return 0;
					case 2:
						var _v2 = _v0.a;
						var _v3 = _v0.b;
						return 1;
					default:
						var _v4 = _v0.a;
						return 2;
				}
		}
	});
var $cmditch$elm_bigint$BigInt$gt = F2(
	function (x, y) {
		return A2($cmditch$elm_bigint$BigInt$compare, x, y) === 2;
	});
var $elm$core$Basics$not = _Basics_not;
var $cmditch$elm_bigint$BigInt$lte = F2(
	function (x, y) {
		return !A2($cmditch$elm_bigint$BigInt$gt, x, y);
	});
var $cmditch$elm_bigint$BigInt$magnitude = function (bigInt) {
	switch (bigInt.$) {
		case 2:
			return _List_Nil;
		case 0:
			var mag = bigInt.a;
			return mag;
		default:
			var mag = bigInt.a;
			return mag;
	}
};
var $cmditch$elm_bigint$BigInt$mulSingleDigit = F2(
	function (_v0, d) {
		var xs = _v0;
		return $cmditch$elm_bigint$BigInt$normaliseMagnitude(
			A2(
				$elm$core$List$map,
				$elm$core$Basics$mul(d),
				xs));
	});
var $cmditch$elm_bigint$BigInt$mulMagnitudes = F2(
	function (_v0, _v1) {
		var mag1 = _v0;
		var mag2 = _v1;
		if (!mag1.b) {
			return _List_Nil;
		} else {
			if (!mag1.b.b) {
				var m = mag1.a;
				return A2($cmditch$elm_bigint$BigInt$mulSingleDigit, mag2, m);
			} else {
				var m = mag1.a;
				var mx = mag1.b;
				var accum = A2($cmditch$elm_bigint$BigInt$mulSingleDigit, mag2, m);
				var _v3 = A2($cmditch$elm_bigint$BigInt$mulMagnitudes, mx, mag2);
				var rest = _v3;
				var bigInt = A2(
					$cmditch$elm_bigint$BigInt$add,
					A2($cmditch$elm_bigint$BigInt$mkBigInt, 0, accum),
					A2(
						$cmditch$elm_bigint$BigInt$mkBigInt,
						0,
						A2($elm$core$List$cons, 0, rest)));
				return $cmditch$elm_bigint$BigInt$magnitude(bigInt);
			}
		}
	});
var $cmditch$elm_bigint$BigInt$sign = function (bigInt) {
	switch (bigInt.$) {
		case 2:
			return 2;
		case 0:
			return 0;
		default:
			return 1;
	}
};
var $cmditch$elm_bigint$BigInt$signProduct = F2(
	function (x, y) {
		return ((x === 2) || (y === 2)) ? 2 : (_Utils_eq(x, y) ? 0 : 1);
	});
var $cmditch$elm_bigint$BigInt$mul = F2(
	function (int1, int2) {
		return A2(
			$cmditch$elm_bigint$BigInt$mkBigInt,
			A2(
				$cmditch$elm_bigint$BigInt$signProduct,
				$cmditch$elm_bigint$BigInt$sign(int1),
				$cmditch$elm_bigint$BigInt$sign(int2)),
			A2(
				$cmditch$elm_bigint$BigInt$mulMagnitudes,
				$cmditch$elm_bigint$BigInt$magnitude(int1),
				$cmditch$elm_bigint$BigInt$magnitude(int2)));
	});
var $cmditch$elm_bigint$BigInt$negate = function (bigInt) {
	switch (bigInt.$) {
		case 2:
			return $cmditch$elm_bigint$BigInt$Zer;
		case 0:
			var mag = bigInt.a;
			return $cmditch$elm_bigint$BigInt$Neg(mag);
		default:
			var mag = bigInt.a;
			return $cmditch$elm_bigint$BigInt$Pos(mag);
	}
};
var $cmditch$elm_bigint$BigInt$sub = F2(
	function (a, b) {
		return A2(
			$cmditch$elm_bigint$BigInt$add,
			a,
			$cmditch$elm_bigint$BigInt$negate(b));
	});
var $cmditch$elm_bigint$BigInt$zero = $cmditch$elm_bigint$BigInt$fromInt(0);
var $cmditch$elm_bigint$BigInt$divmodDigit_ = F4(
	function (to_test, padding, num, den) {
		if (!to_test) {
			return _Utils_Tuple2($cmditch$elm_bigint$BigInt$zero, num);
		} else {
			var x = $cmditch$elm_bigint$BigInt$fromInt(to_test);
			var candidate = A2(
				$cmditch$elm_bigint$BigInt$mul,
				A2($cmditch$elm_bigint$BigInt$mul, x, den),
				padding);
			var _v0 = A2($cmditch$elm_bigint$BigInt$lte, candidate, num) ? _Utils_Tuple2(
				A2($cmditch$elm_bigint$BigInt$mul, x, padding),
				A2($cmditch$elm_bigint$BigInt$sub, num, candidate)) : _Utils_Tuple2($cmditch$elm_bigint$BigInt$zero, num);
			var newdiv = _v0.a;
			var newmod = _v0.b;
			var _v1 = A4($cmditch$elm_bigint$BigInt$divmodDigit_, (to_test / 2) | 0, padding, newmod, den);
			var restdiv = _v1.a;
			var restmod = _v1.b;
			return _Utils_Tuple2(
				A2($cmditch$elm_bigint$BigInt$add, newdiv, restdiv),
				restmod);
		}
	});
var $cmditch$elm_bigint$BigInt$maxDigitBits = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $cmditch$elm_bigint$Constants$maxDigitValue));
var $cmditch$elm_bigint$BigInt$divmodDigit = F3(
	function (padding, x, y) {
		return A4(
			$cmditch$elm_bigint$BigInt$divmodDigit_,
			A2($elm$core$Basics$pow, 2, $cmditch$elm_bigint$BigInt$maxDigitBits),
			padding,
			x,
			y);
	});
var $cmditch$elm_bigint$BigInt$one = $cmditch$elm_bigint$BigInt$fromInt(1);
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $cmditch$elm_bigint$BigInt$repeatedly = F3(
	function (f, x, n) {
		return A3(
			$elm$core$List$foldl,
			$elm$core$Basics$always(f),
			x,
			A2($elm$core$List$range, 1, n));
	});
var $cmditch$elm_bigint$BigInt$padDigits = function (n) {
	return A3(
		$cmditch$elm_bigint$BigInt$repeatedly,
		$cmditch$elm_bigint$BigInt$mul(
			$cmditch$elm_bigint$BigInt$fromInt($cmditch$elm_bigint$BigInt$baseDigit)),
		$cmditch$elm_bigint$BigInt$one,
		n);
};
var $cmditch$elm_bigint$BigInt$divMod_ = F3(
	function (n, num, den) {
		if (!n) {
			return A3(
				$cmditch$elm_bigint$BigInt$divmodDigit,
				$cmditch$elm_bigint$BigInt$padDigits(n),
				num,
				den);
		} else {
			var _v0 = A3(
				$cmditch$elm_bigint$BigInt$divmodDigit,
				$cmditch$elm_bigint$BigInt$padDigits(n),
				num,
				den);
			var cdiv = _v0.a;
			var cmod = _v0.b;
			var _v1 = A3($cmditch$elm_bigint$BigInt$divMod_, n - 1, cmod, den);
			var rdiv = _v1.a;
			var rmod = _v1.b;
			return _Utils_Tuple2(
				A2($cmditch$elm_bigint$BigInt$add, cdiv, rdiv),
				rmod);
		}
	});
var $cmditch$elm_bigint$BigInt$toDigits = function (bigInt) {
	switch (bigInt.$) {
		case 2:
			return _List_Nil;
		case 0:
			var ds = bigInt.a;
			return ds;
		default:
			var ds = bigInt.a;
			return ds;
	}
};
var $cmditch$elm_bigint$BigInt$divmod = F2(
	function (num, den) {
		if (_Utils_eq(den, $cmditch$elm_bigint$BigInt$zero)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var cand_l = ($elm$core$List$length(
				$cmditch$elm_bigint$BigInt$toDigits(num)) - $elm$core$List$length(
				$cmditch$elm_bigint$BigInt$toDigits(den))) + 1;
			var _v0 = A3(
				$cmditch$elm_bigint$BigInt$divMod_,
				A2($elm$core$Basics$max, 0, cand_l),
				$cmditch$elm_bigint$BigInt$abs(num),
				$cmditch$elm_bigint$BigInt$abs(den));
			var d = _v0.a;
			var m = _v0.b;
			return $elm$core$Maybe$Just(
				_Utils_Tuple2(
					A2(
						$cmditch$elm_bigint$BigInt$mkBigInt,
						A2(
							$cmditch$elm_bigint$BigInt$signProduct,
							$cmditch$elm_bigint$BigInt$sign(num),
							$cmditch$elm_bigint$BigInt$sign(den)),
						$cmditch$elm_bigint$BigInt$magnitude(d)),
					A2(
						$cmditch$elm_bigint$BigInt$mkBigInt,
						$cmditch$elm_bigint$BigInt$sign(num),
						$cmditch$elm_bigint$BigInt$magnitude(m))));
		}
	});
var $cmditch$elm_bigint$BigInt$eightHexDigits = A2(
	$cmditch$elm_bigint$BigInt$mul,
	$cmditch$elm_bigint$BigInt$fromInt(2),
	$cmditch$elm_bigint$BigInt$fromInt(2147483648));
var $elm$core$String$fromList = _String_fromList;
var $elm$core$Basics$modBy = _Basics_modBy;
var $rtfeldman$elm_hex$Hex$unsafeToDigit = function (num) {
	unsafeToDigit:
	while (true) {
		switch (num) {
			case 0:
				return '0';
			case 1:
				return '1';
			case 2:
				return '2';
			case 3:
				return '3';
			case 4:
				return '4';
			case 5:
				return '5';
			case 6:
				return '6';
			case 7:
				return '7';
			case 8:
				return '8';
			case 9:
				return '9';
			case 10:
				return 'a';
			case 11:
				return 'b';
			case 12:
				return 'c';
			case 13:
				return 'd';
			case 14:
				return 'e';
			case 15:
				return 'f';
			default:
				var $temp$num = num;
				num = $temp$num;
				continue unsafeToDigit;
		}
	}
};
var $rtfeldman$elm_hex$Hex$unsafePositiveToDigits = F2(
	function (digits, num) {
		unsafePositiveToDigits:
		while (true) {
			if (num < 16) {
				return A2(
					$elm$core$List$cons,
					$rtfeldman$elm_hex$Hex$unsafeToDigit(num),
					digits);
			} else {
				var $temp$digits = A2(
					$elm$core$List$cons,
					$rtfeldman$elm_hex$Hex$unsafeToDigit(
						A2($elm$core$Basics$modBy, 16, num)),
					digits),
					$temp$num = (num / 16) | 0;
				digits = $temp$digits;
				num = $temp$num;
				continue unsafePositiveToDigits;
			}
		}
	});
var $rtfeldman$elm_hex$Hex$toString = function (num) {
	return $elm$core$String$fromList(
		(num < 0) ? A2(
			$elm$core$List$cons,
			'-',
			A2($rtfeldman$elm_hex$Hex$unsafePositiveToDigits, _List_Nil, -num)) : A2($rtfeldman$elm_hex$Hex$unsafePositiveToDigits, _List_Nil, num));
};
var $cmditch$elm_bigint$BigInt$hexMagnitudeToString = function (bigInt) {
	var _v0 = A2($cmditch$elm_bigint$BigInt$divmod, bigInt, $cmditch$elm_bigint$BigInt$eightHexDigits);
	if (_v0.$ === 1) {
		return 'Failure converting BigInt to hex string. Should be impossible. Open up issue on the elm-bigint repo.';
	} else {
		var _v1 = _v0.a;
		var d = _v1.a;
		var r = _v1.b;
		var rString = $rtfeldman$elm_hex$Hex$toString(
			$cmditch$elm_bigint$BigInt$bigIntToInt_(r));
		return _Utils_eq(
			d,
			$cmditch$elm_bigint$BigInt$fromInt(0)) ? rString : _Utils_ap(
			$cmditch$elm_bigint$BigInt$hexMagnitudeToString(d),
			A3($elm$core$String$padLeft, 8, '0', rString));
	}
};
var $cmditch$elm_bigint$BigInt$toHexString = function (bigInt) {
	switch (bigInt.$) {
		case 2:
			return '0';
		case 0:
			var mag = bigInt.a;
			return _Utils_eq(mag, _List_Nil) ? '0' : $cmditch$elm_bigint$BigInt$hexMagnitudeToString(
				$cmditch$elm_bigint$BigInt$Pos(mag));
		default:
			var mag = bigInt.a;
			return '-' + $cmditch$elm_bigint$BigInt$toHexString(
				A2(
					$cmditch$elm_bigint$BigInt$mul,
					$cmditch$elm_bigint$BigInt$fromInt(-1),
					bigInt));
	}
};
var $author$project$BigO$TypesAsStrings$toHexStrN80 = function (_v0) {
	var n80 = _v0;
	return A3(
		$elm$core$String$padLeft,
		20,
		'0',
		$cmditch$elm_bigint$BigInt$toHexString(n80));
};
var $author$project$BigO$TypesAsStrings$showN80 = function (n80) {
	return $author$project$BigO$TypesAsStrings$toHexStrN80(n80);
};
var $author$project$Model$Model$emptyModel = F3(
	function (flags, key, navState) {
		return {
			c0: $elm$core$Maybe$Nothing,
			c2: $elm$core$Maybe$Nothing,
			c3: $elm$core$Maybe$Nothing,
			dI: _List_Nil,
			dL: $elm$core$Maybe$Nothing,
			dM: $elm$core$Maybe$Nothing,
			dN: $elm$core$Maybe$Nothing,
			dO: $elm$core$Maybe$Nothing,
			dP: $elm$core$Maybe$Nothing,
			dQ: $elm$core$Maybe$Nothing,
			hC: $elm$core$Maybe$Nothing,
			d2: _List_Nil,
			cj: flags,
			eb: $elm$core$Maybe$Nothing,
			ek: $elm$core$Maybe$Nothing,
			h3: {
				c$: A3($author$project$Model$Model$AuthStateSignInPage, $author$project$Consts$defaultEmail, $author$project$Consts$defaultPassword, $elm$core$Maybe$Nothing),
				d5: false,
				iM: $rundis$elm_bootstrap$Bootstrap$Tab$initialState,
				gs: 0
			},
			eA: $rundis$elm_bootstrap$Bootstrap$Modal$hidden,
			cA: key,
			io: navState,
			fm: {
				da: $elm$core$Maybe$Nothing,
				eZ: $turboMaCk$any_dict$Dict$Any$empty($author$project$BigO$TypesAsStrings$showN80),
				fn: _List_Nil,
				gd: $elm$core$Maybe$Nothing
			},
			it: {hz: true, iF: false, gl: 1},
			fq: $author$project$TypesPage$PageAllN80s,
			gm: $elm$time$Time$millisToPosix(0),
			g8: '',
			g9: 0
		};
	});
var $rundis$elm_bootstrap$Bootstrap$Navbar$Hidden = 0;
var $rundis$elm_bootstrap$Bootstrap$Navbar$State = $elm$core$Basics$identity;
var $elm$browser$Browser$Dom$getViewport = _Browser_withWindow(_Browser_getViewport);
var $rundis$elm_bootstrap$Bootstrap$Navbar$mapState = F2(
	function (mapper, _v0) {
		var state = _v0;
		return mapper(state);
	});
var $rundis$elm_bootstrap$Bootstrap$Navbar$initWindowSize = F2(
	function (toMsg, state) {
		return A2(
			$elm$core$Task$perform,
			function (vp) {
				return toMsg(
					A2(
						$rundis$elm_bootstrap$Bootstrap$Navbar$mapState,
						function (s) {
							return _Utils_update(
								s,
								{
									aI: $elm$core$Maybe$Just(vp.g6.g7)
								});
						},
						state));
			},
			$elm$browser$Browser$Dom$getViewport);
	});
var $rundis$elm_bootstrap$Bootstrap$Navbar$initialState = function (toMsg) {
	var state = {M: $elm$core$Dict$empty, d9: $elm$core$Maybe$Nothing, m: 0, aI: $elm$core$Maybe$Nothing};
	return _Utils_Tuple2(
		state,
		A2($rundis$elm_bootstrap$Bootstrap$Navbar$initWindowSize, toMsg, state));
};
var $author$project$TypesPage$NotFound = function (a) {
	return {$: 13, a: a};
};
var $author$project$Api$AccessGrantedForUser$AccessGrantedForUser = function (unAccessGrantedForUser) {
	return {gt: unAccessGrantedForUser};
};
var $author$project$Api$AccessLevelGrantedForN80Group$AccessLevelGrantedForN80Group = function (unAccessLevelGrantedForN80Group) {
	return {gw: unAccessLevelGrantedForN80Group};
};
var $author$project$Api$AccessLevel$AccessLevelNone = 0;
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$fromList = function (assocs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, dict) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($elm$core$Dict$insert, key, value, dict);
			}),
		$elm$core$Dict$empty,
		assocs);
};
var $turboMaCk$any_dict$Dict$Any$fromList = F2(
	function (f, xs) {
		return {
			c: $elm$core$Dict$fromList(
				A2(
					$elm$core$List$map,
					function (_v0) {
						var k = _v0.a;
						var v = _v0.b;
						return _Utils_Tuple2(
							f(k),
							_Utils_Tuple2(k, v));
					},
					xs)),
			t: f
		};
	});
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $turboMaCk$any_dict$Dict$Any$get = F2(
	function (k, _v0) {
		var dict = _v0.c;
		var toKey = _v0.t;
		return A2(
			$elm$core$Maybe$map,
			$elm$core$Tuple$second,
			A2(
				$elm$core$Dict$get,
				toKey(k),
				dict));
	});
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $author$project$PageCommonInfo$buildPageCommonInfo = function (mdl) {
	var res = function () {
		var _v0 = _Utils_Tuple2(mdl.c2, mdl.c3);
		if ((!_v0.a.$) && (!_v0.b.$)) {
			var user = _v0.a.a;
			var groups = _v0.b.a;
			var usergroup_auth_dct = A2(
				$turboMaCk$any_dict$Dict$Any$fromList,
				function ($) {
					return $.gS;
				},
				A2(
					$elm$core$List$map,
					function (_v3) {
						var grpId = _v3.a;
						var _v4 = _v3.b;
						var grpAuth = _v4.b;
						return _Utils_Tuple2(grpId, grpAuth);
					},
					groups.dW.dU));
			var usergroup_auth_lu_ = function (id) {
				return A2($turboMaCk$any_dict$Dict$Any$get, id, usergroup_auth_dct);
			};
			var n80group_auth_dct = A2(
				$turboMaCk$any_dict$Dict$Any$fromList,
				function ($) {
					return $.gF;
				},
				A2(
					$elm$core$List$map,
					function (_v1) {
						var grpId = _v1.a;
						var _v2 = _v1.b;
						var grpAuth = _v2.b;
						return _Utils_Tuple2(grpId, grpAuth);
					},
					groups.dW.dT));
			var n80group_auth_lu_ = function (id) {
				return A2(
					$elm$core$Maybe$withDefault,
					$author$project$Api$AccessLevelGrantedForN80Group$AccessLevelGrantedForN80Group(0),
					A2($turboMaCk$any_dict$Dict$Any$get, id, n80group_auth_dct));
			};
			var n80_auth_dct = A2($turboMaCk$any_dict$Dict$Any$fromList, $author$project$BigO$TypesAsStrings$showN80, groups.dW.dV);
			var n80_auth_lu_ = function (n80) {
				return A2(
					$elm$core$Maybe$withDefault,
					$author$project$Api$AccessGrantedForUser$AccessGrantedForUser(0),
					A2($turboMaCk$any_dict$Dict$Any$get, n80, n80_auth_dct));
			};
			return $elm$core$Maybe$Just(
				{hR: groups, ij: n80_auth_lu_, ik: n80group_auth_lu_, g5: user, i$: usergroup_auth_lu_});
		} else {
			return $elm$core$Maybe$Nothing;
		}
	}();
	return res;
};
var $elm$url$Url$Parser$State = F5(
	function (visited, unvisited, params, frag, value) {
		return {N: frag, Q: params, J: unvisited, A: value, T: visited};
	});
var $elm$url$Url$Parser$getFirstMatch = function (states) {
	getFirstMatch:
	while (true) {
		if (!states.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			var state = states.a;
			var rest = states.b;
			var _v1 = state.J;
			if (!_v1.b) {
				return $elm$core$Maybe$Just(state.A);
			} else {
				if ((_v1.a === '') && (!_v1.b.b)) {
					return $elm$core$Maybe$Just(state.A);
				} else {
					var $temp$states = rest;
					states = $temp$states;
					continue getFirstMatch;
				}
			}
		}
	}
};
var $elm$url$Url$Parser$removeFinalEmpty = function (segments) {
	if (!segments.b) {
		return _List_Nil;
	} else {
		if ((segments.a === '') && (!segments.b.b)) {
			return _List_Nil;
		} else {
			var segment = segments.a;
			var rest = segments.b;
			return A2(
				$elm$core$List$cons,
				segment,
				$elm$url$Url$Parser$removeFinalEmpty(rest));
		}
	}
};
var $elm$url$Url$Parser$preparePath = function (path) {
	var _v0 = A2($elm$core$String$split, '/', path);
	if (_v0.b && (_v0.a === '')) {
		var segments = _v0.b;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	} else {
		var segments = _v0;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	}
};
var $elm$url$Url$Parser$addToParametersHelp = F2(
	function (value, maybeList) {
		if (maybeList.$ === 1) {
			return $elm$core$Maybe$Just(
				_List_fromArray(
					[value]));
		} else {
			var list = maybeList.a;
			return $elm$core$Maybe$Just(
				A2($elm$core$List$cons, value, list));
		}
	});
var $elm$url$Url$percentDecode = _Url_percentDecode;
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (!_v0.$) {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$url$Url$Parser$addParam = F2(
	function (segment, dict) {
		var _v0 = A2($elm$core$String$split, '=', segment);
		if ((_v0.b && _v0.b.b) && (!_v0.b.b.b)) {
			var rawKey = _v0.a;
			var _v1 = _v0.b;
			var rawValue = _v1.a;
			var _v2 = $elm$url$Url$percentDecode(rawKey);
			if (_v2.$ === 1) {
				return dict;
			} else {
				var key = _v2.a;
				var _v3 = $elm$url$Url$percentDecode(rawValue);
				if (_v3.$ === 1) {
					return dict;
				} else {
					var value = _v3.a;
					return A3(
						$elm$core$Dict$update,
						key,
						$elm$url$Url$Parser$addToParametersHelp(value),
						dict);
				}
			}
		} else {
			return dict;
		}
	});
var $elm$url$Url$Parser$prepareQuery = function (maybeQuery) {
	if (maybeQuery.$ === 1) {
		return $elm$core$Dict$empty;
	} else {
		var qry = maybeQuery.a;
		return A3(
			$elm$core$List$foldr,
			$elm$url$Url$Parser$addParam,
			$elm$core$Dict$empty,
			A2($elm$core$String$split, '&', qry));
	}
};
var $elm$url$Url$Parser$parse = F2(
	function (_v0, url) {
		var parser = _v0;
		return $elm$url$Url$Parser$getFirstMatch(
			parser(
				A5(
					$elm$url$Url$Parser$State,
					_List_Nil,
					$elm$url$Url$Parser$preparePath(url.fB),
					$elm$url$Url$Parser$prepareQuery(url.fJ),
					url.d8,
					$elm$core$Basics$identity)));
	});
var $author$project$TypesPage$PageLinkFixedAdminAccess = 6;
var $author$project$TypesPage$PageLinkFixedAdminServerStatus = 7;
var $author$project$TypesPage$PageLinkFixedAllN80s = 2;
var $author$project$TypesPage$PageLinkFixedCurrent = 0;
var $author$project$TypesPage$PageLinkFixedHome = 1;
var $author$project$TypesPage$PageLinkFixedLogout = 8;
var $author$project$TypesPage$PageLinkFixedUserAccess = 3;
var $author$project$TypesPage$PageLinkFixedUserApiNotifications = 5;
var $author$project$TypesPage$PageLinkFixedUserSettings = 4;
var $elm$url$Url$Parser$Parser = $elm$core$Basics$identity;
var $elm$url$Url$Parser$mapState = F2(
	function (func, _v0) {
		var visited = _v0.T;
		var unvisited = _v0.J;
		var params = _v0.Q;
		var frag = _v0.N;
		var value = _v0.A;
		return A5(
			$elm$url$Url$Parser$State,
			visited,
			unvisited,
			params,
			frag,
			func(value));
	});
var $elm$url$Url$Parser$map = F2(
	function (subValue, _v0) {
		var parseArg = _v0;
		return function (_v1) {
			var visited = _v1.T;
			var unvisited = _v1.J;
			var params = _v1.Q;
			var frag = _v1.N;
			var value = _v1.A;
			return A2(
				$elm$core$List$map,
				$elm$url$Url$Parser$mapState(value),
				parseArg(
					A5($elm$url$Url$Parser$State, visited, unvisited, params, frag, subValue)));
		};
	});
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $elm$core$List$concatMap = F2(
	function (f, list) {
		return $elm$core$List$concat(
			A2($elm$core$List$map, f, list));
	});
var $elm$url$Url$Parser$oneOf = function (parsers) {
	return function (state) {
		return A2(
			$elm$core$List$concatMap,
			function (_v0) {
				var parser = _v0;
				return parser(state);
			},
			parsers);
	};
};
var $elm$url$Url$Parser$s = function (str) {
	return function (_v0) {
		var visited = _v0.T;
		var unvisited = _v0.J;
		var params = _v0.Q;
		var frag = _v0.N;
		var value = _v0.A;
		if (!unvisited.b) {
			return _List_Nil;
		} else {
			var next = unvisited.a;
			var rest = unvisited.b;
			return _Utils_eq(next, str) ? _List_fromArray(
				[
					A5(
					$elm$url$Url$Parser$State,
					A2($elm$core$List$cons, next, visited),
					rest,
					params,
					frag,
					value)
				]) : _List_Nil;
		}
	};
};
var $elm$url$Url$Parser$top = function (state) {
	return _List_fromArray(
		[state]);
};
var $author$project$Routing$routeParser = $elm$url$Url$Parser$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$url$Url$Parser$map,
			1,
			$elm$url$Url$Parser$s('home')),
			A2(
			$elm$url$Url$Parser$map,
			2,
			$elm$url$Url$Parser$s('n80s')),
			A2(
			$elm$url$Url$Parser$map,
			3,
			$elm$url$Url$Parser$s('user-access')),
			A2(
			$elm$url$Url$Parser$map,
			4,
			$elm$url$Url$Parser$s('user-settings')),
			A2(
			$elm$url$Url$Parser$map,
			5,
			$elm$url$Url$Parser$s('user-api-notifications')),
			A2(
			$elm$url$Url$Parser$map,
			6,
			$elm$url$Url$Parser$s('admin-access')),
			A2(
			$elm$url$Url$Parser$map,
			7,
			$elm$url$Url$Parser$s('admin-serverstatus')),
			A2(
			$elm$url$Url$Parser$map,
			8,
			$elm$url$Url$Parser$s('logout')),
			A2($elm$url$Url$Parser$map, 0, $elm$url$Url$Parser$top)
		]));
var $author$project$Routing$decode = function (url) {
	var updated_url = url;
	var pagefixed_ = A2($elm$url$Url$Parser$parse, $author$project$Routing$routeParser, updated_url);
	return pagefixed_;
};
var $author$project$Routing$modelInitForPageChangeClearAll = function (mdl) {
	return _Utils_update(
		mdl,
		{dL: $elm$core$Maybe$Nothing, dM: $elm$core$Maybe$Nothing, dN: $elm$core$Maybe$Nothing, dO: $elm$core$Maybe$Nothing, dP: $elm$core$Maybe$Nothing, dQ: $elm$core$Maybe$Nothing, hC: $elm$core$Maybe$Nothing});
};
var $author$project$Api$AccessLevelDefaultN80GroupPublic$AccessLevelDefaultN80GroupPublic = function (unAccessLevelDefaultN80GroupPublic) {
	return {gu: unAccessLevelDefaultN80GroupPublic};
};
var $author$project$Api$AccessLevel$AccessLevelReadOnly = 1;
var $author$project$Api$AccessLevel$AccessLevelReadWrite = 2;
var $author$project$Api$N80GroupName$N80GroupName = function (unN80GroupName) {
	return {gG: unN80GroupName};
};
var $rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$Area = F4(
	function (top, left, width, height) {
		return {d9: height, P: left, S: top, g7: width};
	});
var $rundis$elm_bootstrap$Bootstrap$Dropdown$Closed = 2;
var $rundis$elm_bootstrap$Bootstrap$Dropdown$State = $elm$core$Basics$identity;
var $rundis$elm_bootstrap$Bootstrap$Dropdown$initialState = {
	as: A4($rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$Area, 0, 0, 0, 0),
	v: 2,
	b1: A4($rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$Area, 0, 0, 0, 0)
};
var $rundis$elm_bootstrap$Bootstrap$Internal$Button$Coloring = function (a) {
	return {$: 1, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Internal$Button$Primary = 0;
var $rundis$elm_bootstrap$Bootstrap$Internal$Button$Roled = function (a) {
	return {$: 0, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Button$primary = $rundis$elm_bootstrap$Bootstrap$Internal$Button$Coloring(
	$rundis$elm_bootstrap$Bootstrap$Internal$Button$Roled(0));
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $rundis$elm_bootstrap$Bootstrap$Dropdown$DropdownToggle = $elm$core$Basics$identity;
var $elm$html$Html$button = _VirtualDom_node('button');
var $elm$core$Maybe$andThen = F2(
	function (callback, maybeValue) {
		if (!maybeValue.$) {
			var value = maybeValue.a;
			return callback(value);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Internal$Button$applyModifier = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 0:
				var size = modifier.a;
				return _Utils_update(
					options,
					{
						ge: $elm$core$Maybe$Just(size)
					});
			case 1:
				var coloring = modifier.a;
				return _Utils_update(
					options,
					{
						E: $elm$core$Maybe$Just(coloring)
					});
			case 2:
				return _Utils_update(
					options,
					{aX: true});
			case 3:
				var val = modifier.a;
				return _Utils_update(
					options,
					{a7: val});
			default:
				var attrs = modifier.a;
				return _Utils_update(
					options,
					{
						aU: _Utils_ap(options.aU, attrs)
					});
		}
	});
var $elm$json$Json$Encode$string = _Json_wrap;
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $elm$html$Html$Attributes$classList = function (classes) {
	return $elm$html$Html$Attributes$class(
		A2(
			$elm$core$String$join,
			' ',
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$first,
				A2($elm$core$List$filter, $elm$core$Tuple$second, classes))));
};
var $rundis$elm_bootstrap$Bootstrap$Internal$Button$defaultOptions = {aU: _List_Nil, aX: false, E: $elm$core$Maybe$Nothing, a7: false, ge: $elm$core$Maybe$Nothing};
var $elm$json$Json$Encode$bool = _Json_wrap;
var $elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $elm$html$Html$Attributes$disabled = $elm$html$Html$Attributes$boolProperty('disabled');
var $rundis$elm_bootstrap$Bootstrap$Internal$Button$roleClass = function (role) {
	switch (role) {
		case 0:
			return 'primary';
		case 1:
			return 'secondary';
		case 2:
			return 'success';
		case 3:
			return 'info';
		case 4:
			return 'warning';
		case 5:
			return 'danger';
		case 6:
			return 'dark';
		case 7:
			return 'light';
		default:
			return 'link';
	}
};
var $rundis$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption = function (size) {
	switch (size) {
		case 0:
			return $elm$core$Maybe$Nothing;
		case 1:
			return $elm$core$Maybe$Just('sm');
		case 2:
			return $elm$core$Maybe$Just('md');
		case 3:
			return $elm$core$Maybe$Just('lg');
		default:
			return $elm$core$Maybe$Just('xl');
	}
};
var $rundis$elm_bootstrap$Bootstrap$Internal$Button$buttonAttributes = function (modifiers) {
	var options = A3($elm$core$List$foldl, $rundis$elm_bootstrap$Bootstrap$Internal$Button$applyModifier, $rundis$elm_bootstrap$Bootstrap$Internal$Button$defaultOptions, modifiers);
	return _Utils_ap(
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('btn', true),
						_Utils_Tuple2('btn-block', options.aX),
						_Utils_Tuple2('disabled', options.a7)
					])),
				$elm$html$Html$Attributes$disabled(options.a7)
			]),
		_Utils_ap(
			function () {
				var _v0 = A2($elm$core$Maybe$andThen, $rundis$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption, options.ge);
				if (!_v0.$) {
					var s = _v0.a;
					return _List_fromArray(
						[
							$elm$html$Html$Attributes$class('btn-' + s)
						]);
				} else {
					return _List_Nil;
				}
			}(),
			_Utils_ap(
				function () {
					var _v1 = options.E;
					if (!_v1.$) {
						if (!_v1.a.$) {
							var role = _v1.a.a;
							return _List_fromArray(
								[
									$elm$html$Html$Attributes$class(
									'btn-' + $rundis$elm_bootstrap$Bootstrap$Internal$Button$roleClass(role))
								]);
						} else {
							var role = _v1.a.a;
							return _List_fromArray(
								[
									$elm$html$Html$Attributes$class(
									'btn-outline-' + $rundis$elm_bootstrap$Bootstrap$Internal$Button$roleClass(role))
								]);
						}
					} else {
						return _List_Nil;
					}
				}(),
				options.aU)));
};
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $rundis$elm_bootstrap$Bootstrap$Dropdown$Open = 0;
var $rundis$elm_bootstrap$Bootstrap$Dropdown$nextStatus = function (status) {
	switch (status) {
		case 0:
			return 2;
		case 1:
			return 2;
		default:
			return 0;
	}
};
var $elm$json$Json$Decode$map3 = _Json_map3;
var $elm$json$Json$Decode$field = _Json_decodeField;
var $elm$json$Json$Decode$float = _Json_decodeFloat;
var $rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$offsetHeight = A2($elm$json$Json$Decode$field, 'offsetHeight', $elm$json$Json$Decode$float);
var $rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$offsetWidth = A2($elm$json$Json$Decode$field, 'offsetWidth', $elm$json$Json$Decode$float);
var $elm$json$Json$Decode$map4 = _Json_map4;
var $rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$offsetLeft = A2($elm$json$Json$Decode$field, 'offsetLeft', $elm$json$Json$Decode$float);
var $elm$json$Json$Decode$null = _Json_decodeNull;
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$offsetParent = F2(
	function (x, decoder) {
		return $elm$json$Json$Decode$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$json$Json$Decode$field,
					'offsetParent',
					$elm$json$Json$Decode$null(x)),
					A2($elm$json$Json$Decode$field, 'offsetParent', decoder)
				]));
	});
var $rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$offsetTop = A2($elm$json$Json$Decode$field, 'offsetTop', $elm$json$Json$Decode$float);
var $rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$scrollLeft = A2($elm$json$Json$Decode$field, 'scrollLeft', $elm$json$Json$Decode$float);
var $rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$scrollTop = A2($elm$json$Json$Decode$field, 'scrollTop', $elm$json$Json$Decode$float);
var $rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$position = F2(
	function (x, y) {
		return A2(
			$elm$json$Json$Decode$andThen,
			function (_v0) {
				var x_ = _v0.a;
				var y_ = _v0.b;
				return A2(
					$rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$offsetParent,
					_Utils_Tuple2(x_, y_),
					A2($rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$position, x_, y_));
			},
			A5(
				$elm$json$Json$Decode$map4,
				F4(
					function (scrollLeft_, scrollTop_, offsetLeft_, offsetTop_) {
						return _Utils_Tuple2((x + offsetLeft_) - scrollLeft_, (y + offsetTop_) - scrollTop_);
					}),
				$rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$scrollLeft,
				$rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$scrollTop,
				$rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$offsetLeft,
				$rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$offsetTop));
	});
var $rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$boundingArea = A4(
	$elm$json$Json$Decode$map3,
	F3(
		function (_v0, width, height) {
			var x = _v0.a;
			var y = _v0.b;
			return {d9: height, P: x, S: y, g7: width};
		}),
	A2($rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$position, 0, 0),
	$rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$offsetWidth,
	$rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$offsetHeight);
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$childNode = function (idx) {
	return $elm$json$Json$Decode$at(
		_List_fromArray(
			[
				'childNodes',
				$elm$core$String$fromInt(idx)
			]));
};
var $rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$nextSibling = function (decoder) {
	return A2($elm$json$Json$Decode$field, 'nextSibling', decoder);
};
var $elm$core$Tuple$pair = F2(
	function (a, b) {
		return _Utils_Tuple2(a, b);
	});
var $elm$json$Json$Decode$string = _Json_decodeString;
var $rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$className = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['className']),
	$elm$json$Json$Decode$string);
var $elm$json$Json$Decode$fail = _Json_fail;
var $rundis$elm_bootstrap$Bootstrap$Dropdown$isToggle = A2(
	$elm$json$Json$Decode$andThen,
	function (_class) {
		return A2($elm$core$String$contains, 'dropdown-toggle', _class) ? $elm$json$Json$Decode$succeed(true) : $elm$json$Json$Decode$succeed(false);
	},
	$rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$className);
var $rundis$elm_bootstrap$Bootstrap$Dropdown$toggler = F2(
	function (path, decoder) {
		return $elm$json$Json$Decode$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$json$Json$Decode$andThen,
					function (res) {
						return res ? A2($elm$json$Json$Decode$at, path, decoder) : $elm$json$Json$Decode$fail('');
					},
					A2($elm$json$Json$Decode$at, path, $rundis$elm_bootstrap$Bootstrap$Dropdown$isToggle)),
					A2(
					$elm$json$Json$Decode$andThen,
					function (_v0) {
						return A2(
							$rundis$elm_bootstrap$Bootstrap$Dropdown$toggler,
							_Utils_ap(
								path,
								_List_fromArray(
									['parentElement'])),
							decoder);
					},
					A2(
						$elm$json$Json$Decode$at,
						_Utils_ap(
							path,
							_List_fromArray(
								['parentElement'])),
						$rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$className)),
					$elm$json$Json$Decode$fail('No toggler found')
				]));
	});
var $rundis$elm_bootstrap$Bootstrap$Dropdown$sizeDecoder = A3(
	$elm$json$Json$Decode$map2,
	$elm$core$Tuple$pair,
	A2(
		$rundis$elm_bootstrap$Bootstrap$Dropdown$toggler,
		_List_fromArray(
			['target']),
		$rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$boundingArea),
	A2(
		$rundis$elm_bootstrap$Bootstrap$Dropdown$toggler,
		_List_fromArray(
			['target']),
		$rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$nextSibling(
			A2($rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$childNode, 0, $rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$boundingArea))));
var $rundis$elm_bootstrap$Bootstrap$Dropdown$clickHandler = F2(
	function (toMsg, state) {
		var status = state.v;
		return A2(
			$elm$json$Json$Decode$andThen,
			function (_v0) {
				var b = _v0.a;
				var m = _v0.b;
				return $elm$json$Json$Decode$succeed(
					toMsg(
						{
							as: m,
							v: $rundis$elm_bootstrap$Bootstrap$Dropdown$nextStatus(status),
							b1: b
						}));
			},
			$rundis$elm_bootstrap$Bootstrap$Dropdown$sizeDecoder);
	});
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $elm$html$Html$Attributes$type_ = $elm$html$Html$Attributes$stringProperty('type');
var $rundis$elm_bootstrap$Bootstrap$Dropdown$togglePrivate = F4(
	function (buttonOptions, children, toggleMsg, state) {
		return A2(
			$elm$html$Html$button,
			_Utils_ap(
				$rundis$elm_bootstrap$Bootstrap$Internal$Button$buttonAttributes(buttonOptions),
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('dropdown-toggle'),
						$elm$html$Html$Attributes$type_('button'),
						A2(
						$elm$html$Html$Events$on,
						'click',
						A2($rundis$elm_bootstrap$Bootstrap$Dropdown$clickHandler, toggleMsg, state))
					])),
			children);
	});
var $rundis$elm_bootstrap$Bootstrap$Dropdown$toggle = F2(
	function (buttonOptions, children) {
		return A2($rundis$elm_bootstrap$Bootstrap$Dropdown$togglePrivate, buttonOptions, children);
	});
var $author$project$ElmCommon$Dropdown$buildInitialDropDown = F3(
	function (initial_value, options, show_option_func) {
		return {
			a8: $rundis$elm_bootstrap$Bootstrap$Dropdown$initialState,
			a9: A2(
				$rundis$elm_bootstrap$Bootstrap$Dropdown$toggle,
				_List_fromArray(
					[$rundis$elm_bootstrap$Bootstrap$Button$primary]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						show_option_func(initial_value))
					])),
			it: options,
			iC: initial_value,
			bZ: show_option_func
		};
	});
var $author$project$BigO$TypesAsStrings$showAccessLevel_ = function (lvl) {
	switch (lvl) {
		case 0:
			return 'None';
		case 1:
			return 'ReadOnly';
		case 2:
			return 'ReadWrite';
		default:
			return 'Admin';
	}
};
var $author$project$Components$N80GroupTableTypes$initialEditStateN80GroupTable = function () {
	var authAccessOpts = _List_fromArray(
		[0, 1, 2]);
	var authOpts = A2($elm$core$List$map, $author$project$Api$AccessLevelDefaultN80GroupPublic$AccessLevelDefaultN80GroupPublic, authAccessOpts);
	return {
		cz: $author$project$Api$N80GroupName$N80GroupName(''),
		eH: A3(
			$author$project$ElmCommon$Dropdown$buildInitialDropDown,
			$author$project$Api$AccessLevelDefaultN80GroupPublic$AccessLevelDefaultN80GroupPublic(0),
			authOpts,
			function (o) {
				return $author$project$BigO$TypesAsStrings$showAccessLevel_(o.gu);
			})
	};
}();
var $author$project$Api$UserGroupName$UserGroupName = function (unUserGroupName) {
	return {gT: unUserGroupName};
};
var $author$project$Components$UserGroupTableTypes$initialEditStateUserGroupTable = {
	co: $author$project$Api$UserGroupName$UserGroupName('')
};
var $author$project$Pages$EditStates$buildInitialEditStateAdminAccess = function (mdl) {
	var contents = {hD: $author$project$Components$N80GroupTableTypes$initialEditStateN80GroupTable, hE: $author$project$Components$UserGroupTableTypes$initialEditStateUserGroupTable};
	return contents;
};
var $author$project$Api$AccessLevel$AccessLevelAdmin = 3;
var $author$project$Components$N80GroupMembershipTableTypes$initialEditStateN80GroupMembershipTable = function (n80MetasAndAccesses) {
	var n80Metas = A2(
		$elm$core$List$map,
		$elm$core$Tuple$first,
		A2(
			$elm$core$List$filter,
			function (_v0) {
				var a = _v0.b;
				return _Utils_eq(
					a,
					$author$project$Api$AccessGrantedForUser$AccessGrantedForUser(3));
			},
			n80MetasAndAccesses));
	var add_groups_n80group_autcomplete_ = {
		hn: {
			hp: false,
			hw: function (o) {
				return o.eE.gD + (' - ' + o.eF.gE);
			},
			h9: $elm$core$Maybe$Nothing,
			it: n80Metas,
			iw: 'Add...'
		},
		hS: false,
		h$: false,
		iG: ''
	};
	return {aM: add_groups_n80group_autcomplete_};
};
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $elm$core$Dict$values = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, valueList) {
				return A2($elm$core$List$cons, value, valueList);
			}),
		_List_Nil,
		dict);
};
var $turboMaCk$any_dict$Dict$Any$toList = function (_v0) {
	var dict = _v0.c;
	return $elm$core$Dict$values(dict);
};
var $turboMaCk$any_dict$Dict$Any$values = A2(
	$elm$core$Basics$composeL,
	$elm$core$List$map($elm$core$Tuple$second),
	$turboMaCk$any_dict$Dict$Any$toList);
var $author$project$Pages$EditStates$buildInitialEditStateN80Group = F2(
	function (mdl, cmn) {
		var n80Metas = A2(
			$elm$core$List$map,
			function (n80Meta) {
				return _Utils_Tuple2(
					n80Meta,
					cmn.ij(n80Meta.eC));
			},
			$turboMaCk$any_dict$Dict$Any$values(mdl.fm.eZ));
		return $elm$core$Maybe$Just(
			{
				hF: $author$project$Components$N80GroupMembershipTableTypes$initialEditStateN80GroupMembershipTable(n80Metas)
			});
	});
var $author$project$Api$UserGroupMembershipType$UserGroupMembershipTypeAdmin = 1;
var $author$project$Api$UserGroupMembershipType$UserGroupMembershipTypeStandard = 0;
var $author$project$BigO$TypesAsStrings$showUserGroupMembershipType_ = function (lvl) {
	if (!lvl) {
		return 'Std-User';
	} else {
		return 'Admin';
	}
};
var $author$project$Components$UserGroupUserMembershipTableTypes$initialEditStateUserGroupMembershipTable = F2(
	function (visible_users, visible_n80_groups) {
		var user_auth_dropdown = A3(
			$author$project$ElmCommon$Dropdown$buildInitialDropDown,
			0,
			_List_fromArray(
				[0, 1]),
			$author$project$BigO$TypesAsStrings$showUserGroupMembershipType_);
		var add_user_autocmplete = {
			hn: {
				hp: false,
				hw: function (o) {
					return o.gR;
				},
				h9: $elm$core$Maybe$Nothing,
				it: A2(
					$elm$core$List$map,
					function (o) {
						return o.dE;
					},
					visible_users),
				iw: 'Add...'
			},
			hS: false,
			h$: false,
			iG: ''
		};
		return {cV: user_auth_dropdown, cc: add_user_autocmplete};
	});
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $author$project$Components$UserGroupToN80GroupAccessTable$initialEditStateUserGroupToN80GroupAccessTable = F2(
	function (visible_users, visible_n80_groups) {
		var connect_n80groups_auth_dropdown = A3(
			$author$project$ElmCommon$Dropdown$buildInitialDropDown,
			$author$project$Api$AccessLevelGrantedForN80Group$AccessLevelGrantedForN80Group(1),
			_List_fromArray(
				[
					$author$project$Api$AccessLevelGrantedForN80Group$AccessLevelGrantedForN80Group(1),
					$author$project$Api$AccessLevelGrantedForN80Group$AccessLevelGrantedForN80Group(2),
					$author$project$Api$AccessLevelGrantedForN80Group$AccessLevelGrantedForN80Group(3)
				]),
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.gw;
				},
				$author$project$BigO$TypesAsStrings$showAccessLevel_));
		var connect_n80groups = visible_n80_groups;
		var connect_n80groups_autcomplete = {
			hn: {
				hp: false,
				hw: function (o) {
					return o.di.gG;
				},
				h9: $elm$core$Maybe$Nothing,
				it: connect_n80groups,
				iw: 'Add...'
			},
			hS: false,
			h$: false,
			iG: ''
		};
		return {cf: connect_n80groups_auth_dropdown, cg: connect_n80groups_autcomplete};
	});
var $author$project$Pages$EditStates$buildInitialEditStateUserAccess = F2(
	function (mdl, cmn) {
		var users = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.dX;
				},
				mdl.c3));
		var n80Groups = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				$elm$core$Maybe$map,
				function (lst) {
					return A2(
						$elm$core$List$map,
						function (o) {
							return o.b.a;
						},
						lst);
				},
				A2(
					$elm$core$Maybe$map,
					function (o) {
						return o.dW.dT;
					},
					mdl.c3)));
		var contents = {
			dR: A2($author$project$Components$UserGroupToN80GroupAccessTable$initialEditStateUserGroupToN80GroupAccessTable, users, n80Groups),
			hG: $author$project$Components$N80GroupTableTypes$initialEditStateN80GroupTable,
			hH: $author$project$Components$UserGroupTableTypes$initialEditStateUserGroupTable,
			hI: A2($author$project$Components$UserGroupUserMembershipTableTypes$initialEditStateUserGroupMembershipTable, users, n80Groups)
		};
		return contents;
	});
var $author$project$Pages$EditStates$buildInitialEditStateUserGroup = F2(
	function (mdl, cmn) {
		var users = cmn.hR.dX;
		var n80Groups = A2(
			$elm$core$List$map,
			function (o) {
				return o.b.a;
			},
			cmn.hR.dW.dT);
		return $elm$core$Maybe$Just(
			{
				dR: A2($author$project$Components$UserGroupToN80GroupAccessTable$initialEditStateUserGroupToN80GroupAccessTable, users, n80Groups),
				hI: A2($author$project$Components$UserGroupUserMembershipTableTypes$initialEditStateUserGroupMembershipTable, users, n80Groups)
			});
	});
var $author$project$BigO$EditState$EditStateUneditted = function (a) {
	return {$: 0, a: a};
};
var $author$project$Msg$UiUpdateN80MetaGroupsSelectNew = function (a) {
	return {$: 5, a: a};
};
var $author$project$Model$ModelUpdate$build_n80_edit_group_info = F2(
	function (visible_n80groups, n80) {
		var all_group_names = visible_n80groups;
		var autocompleteState = {
			hn: {
				hp: true,
				hw: A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.di;
					},
					function ($) {
						return $.gG;
					}),
				h9: $elm$core$Maybe$Just($author$project$Msg$UiUpdateN80MetaGroupsSelectNew),
				it: all_group_names,
				iw: 'Add...'
			},
			hS: false,
			h$: false,
			iG: ''
		};
		return autocompleteState;
	});
var $author$project$NodeDataStore$mdlGetN80Meta = F2(
	function (mdl, n80) {
		return A2($turboMaCk$any_dict$Dict$Any$get, n80, mdl.eZ);
	});
var $author$project$Pages$EditStates$buildInitialN80PageEditState = F3(
	function (mdl, cmn, n80) {
		var _v0 = A2($author$project$NodeDataStore$mdlGetN80Meta, mdl.fm, n80);
		if (_v0.$ === 1) {
			return $elm$core$Maybe$Nothing;
		} else {
			var n80Meta = _v0.a;
			var n80_groups = A2(
				$elm$core$List$map,
				A2($elm$core$Basics$composeR, $elm$core$Tuple$second, $elm$core$Tuple$first),
				cmn.hR.dW.dT);
			var autocompleteState = A2($author$project$Model$ModelUpdate$build_n80_edit_group_info, n80_groups, n80);
			return $elm$core$Maybe$Just(
				{
					ie: autocompleteState,
					$8: n80,
					ig: $author$project$BigO$EditState$EditStateUneditted(n80Meta.eF),
					ih: $author$project$BigO$EditState$EditStateUneditted(n80_groups),
					ii: $author$project$BigO$EditState$EditStateUneditted(n80Meta.eW)
				});
		}
	});
var $author$project$Model$Model$resetModel = function (mdl) {
	return A3($author$project$Model$Model$emptyModel, mdl.cj, mdl.cA, mdl.io);
};
var $author$project$Routing$modelInitForPageChange_ = F3(
	function (cmn, page, mdl) {
		switch (page.$) {
			case 0:
				return mdl;
			case 1:
				return mdl;
			case 2:
				var n80 = page.a;
				return _Utils_update(
					mdl,
					{
						dM: A3($author$project$Pages$EditStates$buildInitialN80PageEditState, mdl, cmn, n80)
					});
			case 3:
				var n96 = page.a;
				return mdl;
			case 4:
				var n96 = page.a;
				return mdl;
			case 5:
				var n96 = page.a;
				return mdl;
			case 6:
				var n80GroupId = page.a;
				return _Utils_update(
					mdl,
					{
						dN: A2($author$project$Pages$EditStates$buildInitialEditStateN80Group, mdl, cmn)
					});
			case 7:
				var userGroupId = page.a;
				return _Utils_update(
					mdl,
					{
						dQ: A2($author$project$Pages$EditStates$buildInitialEditStateUserGroup, mdl, cmn)
					});
			case 8:
				return _Utils_update(
					mdl,
					{
						hC: $elm$core$Maybe$Just(
							A2($author$project$Pages$EditStates$buildInitialEditStateUserAccess, mdl, cmn))
					});
			case 9:
				return mdl;
			case 10:
				return mdl;
			case 12:
				return mdl;
			case 11:
				return _Utils_update(
					mdl,
					{
						dL: $elm$core$Maybe$Just(
							$author$project$Pages$EditStates$buildInitialEditStateAdminAccess(mdl))
					});
			case 13:
				var url = page.a;
				return mdl;
			case 14:
				return mdl;
			default:
				return $author$project$Model$Model$resetModel(mdl);
		}
	});
var $author$project$Routing$modelInitForPageChange = F3(
	function (cmnM, page, mdl) {
		if (cmnM.$ === 1) {
			return $author$project$Routing$modelInitForPageChangeClearAll(mdl);
		} else {
			var cmn = cmnM.a;
			return A3(
				$author$project$Routing$modelInitForPageChange_,
				cmn,
				page,
				$author$project$Routing$modelInitForPageChangeClearAll(mdl));
		}
	});
var $author$project$TypesPage$Logout = {$: 15};
var $author$project$TypesPage$PageAdminAccess = {$: 11};
var $author$project$TypesPage$PageAdminServerStatus = {$: 12};
var $author$project$TypesPage$PageHome = {$: 0};
var $author$project$TypesPage$PageUserAccess = {$: 8};
var $author$project$TypesPage$PageUserApiNotifications = {$: 10};
var $author$project$TypesPage$PageUserSettings = {$: 9};
var $author$project$TypesPage$pageLinkFixedToPage = function (link) {
	switch (link) {
		case 0:
			return $elm$core$Maybe$Nothing;
		case 1:
			return $elm$core$Maybe$Just($author$project$TypesPage$PageHome);
		case 2:
			return $elm$core$Maybe$Just($author$project$TypesPage$PageAllN80s);
		case 3:
			return $elm$core$Maybe$Just($author$project$TypesPage$PageUserAccess);
		case 4:
			return $elm$core$Maybe$Just($author$project$TypesPage$PageUserSettings);
		case 5:
			return $elm$core$Maybe$Just($author$project$TypesPage$PageUserApiNotifications);
		case 6:
			return $elm$core$Maybe$Just($author$project$TypesPage$PageAdminAccess);
		case 7:
			return $elm$core$Maybe$Just($author$project$TypesPage$PageAdminServerStatus);
		default:
			return $elm$core$Maybe$Just($author$project$TypesPage$Logout);
	}
};
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $GlobalWebIndex$cmd_extra$Cmd$Extra$pure = function (model) {
	return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
};
var $author$project$Routing$urlUpdate = F2(
	function (url_, mdl) {
		var url = url_;
		var res = function () {
			var _v0 = $author$project$Routing$decode(url);
			if (_v0.$ === 1) {
				return $GlobalWebIndex$cmd_extra$Cmd$Extra$pure(
					_Utils_update(
						mdl,
						{
							fq: $author$project$TypesPage$NotFound(url)
						}));
			} else {
				var pageFixed = _v0.a;
				var _v1 = $author$project$TypesPage$pageLinkFixedToPage(pageFixed);
				if (_v1.$ === 1) {
					return $GlobalWebIndex$cmd_extra$Cmd$Extra$pure(mdl);
				} else {
					var newPage = _v1.a;
					var _v2 = _Utils_eq(newPage, mdl.fq);
					if (_v2) {
						return $GlobalWebIndex$cmd_extra$Cmd$Extra$pure(
							_Utils_update(
								mdl,
								{fq: newPage}));
					} else {
						return $GlobalWebIndex$cmd_extra$Cmd$Extra$pure(
							A3(
								$author$project$Routing$modelInitForPageChange,
								$author$project$PageCommonInfo$buildPageCommonInfo(mdl),
								newPage,
								_Utils_update(
									mdl,
									{fq: newPage})));
					}
				}
			}
		}();
		return res;
	});
var $author$project$Main$init = F3(
	function (flags, url, key) {
		var _v0 = $rundis$elm_bootstrap$Bootstrap$Navbar$initialState($author$project$Msg$NavMsg);
		var navState = _v0.a;
		var navCmd = _v0.b;
		var _v1 = A2(
			$author$project$Routing$urlUpdate,
			url,
			A3($author$project$Model$Model$emptyModel, flags, key, navState));
		var mdl = _v1.a;
		var urlCmd = _v1.b;
		return _Utils_Tuple2(
			mdl,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[urlCmd, navCmd])));
	});
var $author$project$ElmCommon$Dropdown$DropDownMsgToggle = function (a) {
	return {$: 0, a: a};
};
var $author$project$Msg$GotHostname = function (a) {
	return {$: 8, a: a};
};
var $author$project$Msg$HandleKeyboardEvent = function (a) {
	return {$: 11, a: a};
};
var $author$project$Msg$Tick = function (a) {
	return {$: 5, a: a};
};
var $author$project$Msg$UiLoginTabMsg = function (a) {
	return {$: 12, a: a};
};
var $author$project$Msg$UiPageUpdate = function (a) {
	return {$: 25, a: a};
};
var $author$project$Msg$UiUpdatePageUserAccessUserGroupMembershipTable = function (a) {
	return {$: 15, a: a};
};
var $author$project$Components$UserGroupUserMembershipTableTypes$UserGroupMembershipTableMsgSetAuth = function (a) {
	return {$: 1, a: a};
};
var $author$project$Msg$WebsocketIn = function (a) {
	return {$: 6, a: a};
};
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $Gizra$elm_keyboard_event$Keyboard$Event$KeyboardEvent = F7(
	function (altKey, ctrlKey, key, keyCode, metaKey, repeat, shiftKey) {
		return {hf: altKey, hu: ctrlKey, h1: key, h2: keyCode, h6: metaKey, iz: repeat, iE: shiftKey};
	});
var $elm$json$Json$Decode$bool = _Json_decodeBool;
var $elm$json$Json$Decode$maybe = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder),
				$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing)
			]));
};
var $Gizra$elm_keyboard_event$Keyboard$Event$decodeKey = $elm$json$Json$Decode$maybe(
	A2(
		$elm$json$Json$Decode$andThen,
		function (key) {
			return $elm$core$String$isEmpty(key) ? $elm$json$Json$Decode$fail('empty key') : $elm$json$Json$Decode$succeed(key);
		},
		A2($elm$json$Json$Decode$field, 'key', $elm$json$Json$Decode$string)));
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $Gizra$elm_keyboard_event$Keyboard$Event$decodeNonZero = A2(
	$elm$json$Json$Decode$andThen,
	function (code) {
		return (!code) ? $elm$json$Json$Decode$fail('code was zero') : $elm$json$Json$Decode$succeed(code);
	},
	$elm$json$Json$Decode$int);
var $Gizra$elm_keyboard_event$Keyboard$Event$decodeKeyCode = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2($elm$json$Json$Decode$field, 'keyCode', $Gizra$elm_keyboard_event$Keyboard$Event$decodeNonZero),
			A2($elm$json$Json$Decode$field, 'which', $Gizra$elm_keyboard_event$Keyboard$Event$decodeNonZero),
			A2($elm$json$Json$Decode$field, 'charCode', $Gizra$elm_keyboard_event$Keyboard$Event$decodeNonZero),
			$elm$json$Json$Decode$succeed(0)
		]));
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$A = {$: 0};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Add = {$: 85};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Alt = {$: 32};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Ambiguous = function (a) {
	return {$: 89, a: a};
};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$B = {$: 1};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Backspace = {$: 38};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$C = {$: 2};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$CapsLock = {$: 34};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$ChromeSearch = {$: 59};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Command = {$: 58};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Ctrl = function (a) {
	return {$: 31, a: a};
};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$D = {$: 3};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Decimal = {$: 87};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Delete = {$: 39};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Divide = {$: 88};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Down = {$: 29};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$E = {$: 4};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Eight = {$: 52};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$End = {$: 42};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Enter = {$: 37};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Escape = {$: 36};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F = {$: 5};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F1 = {$: 62};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F10 = {$: 71};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F11 = {$: 72};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F12 = {$: 73};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F2 = {$: 63};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F3 = {$: 64};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F4 = {$: 65};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F5 = {$: 66};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F6 = {$: 67};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F7 = {$: 68};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F8 = {$: 69};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F9 = {$: 70};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Five = {$: 49};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Four = {$: 48};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$G = {$: 6};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$H = {$: 7};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Home = {$: 43};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$I = {$: 8};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Insert = {$: 54};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$J = {$: 9};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$K = {$: 10};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$L = {$: 11};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Left = {$: 26};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$M = {$: 12};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Multiply = {$: 84};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$N = {$: 13};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Nine = {$: 53};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumLock = {$: 60};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadEight = {$: 82};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadFive = {$: 79};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadFour = {$: 78};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadNine = {$: 83};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadOne = {$: 75};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadSeven = {$: 81};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadSix = {$: 80};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadThree = {$: 77};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadTwo = {$: 76};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadZero = {$: 74};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$O = {$: 14};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$One = {$: 45};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$P = {$: 15};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$PageDown = {$: 41};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$PageUp = {$: 40};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$PauseBreak = {$: 56};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$PrintScreen = {$: 55};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Q = {$: 16};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$R = {$: 17};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Right = {$: 27};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$S = {$: 18};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$ScrollLock = {$: 61};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Seven = {$: 51};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Shift = function (a) {
	return {$: 30, a: a};
};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Six = {$: 50};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Spacebar = {$: 35};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Subtract = {$: 86};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$T = {$: 19};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Tab = {$: 33};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Three = {$: 47};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Two = {$: 46};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$U = {$: 20};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Unknown = function (a) {
	return {$: 90, a: a};
};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Up = {$: 28};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$V = {$: 21};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$W = {$: 22};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Windows = {$: 57};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$X = {$: 23};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Y = {$: 24};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Z = {$: 25};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Zero = {$: 44};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$fromCode = function (keyCode) {
	switch (keyCode) {
		case 8:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Backspace;
		case 9:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Tab;
		case 13:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Enter;
		case 16:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Shift($elm$core$Maybe$Nothing);
		case 17:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Ctrl($elm$core$Maybe$Nothing);
		case 18:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Alt;
		case 19:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$PauseBreak;
		case 20:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$CapsLock;
		case 27:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Escape;
		case 32:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Spacebar;
		case 33:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$PageUp;
		case 34:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$PageDown;
		case 35:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$End;
		case 36:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Home;
		case 37:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Left;
		case 38:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Up;
		case 39:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Right;
		case 40:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Down;
		case 44:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$PrintScreen;
		case 45:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Insert;
		case 46:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Delete;
		case 48:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Zero;
		case 49:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$One;
		case 50:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Two;
		case 51:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Three;
		case 52:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Four;
		case 53:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Five;
		case 54:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Six;
		case 55:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Seven;
		case 56:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Eight;
		case 57:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Nine;
		case 65:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$A;
		case 66:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$B;
		case 67:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$C;
		case 68:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$D;
		case 69:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$E;
		case 70:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F;
		case 71:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$G;
		case 72:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$H;
		case 73:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$I;
		case 74:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$J;
		case 75:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$K;
		case 76:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$L;
		case 77:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$M;
		case 78:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$N;
		case 79:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$O;
		case 80:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$P;
		case 81:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Q;
		case 82:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$R;
		case 83:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$S;
		case 84:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$T;
		case 85:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$U;
		case 86:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$V;
		case 87:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$W;
		case 88:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$X;
		case 89:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Y;
		case 90:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Z;
		case 91:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Ambiguous(
				_List_fromArray(
					[$SwiftsNamesake$proper_keyboard$Keyboard$Key$Windows, $SwiftsNamesake$proper_keyboard$Keyboard$Key$Command, $SwiftsNamesake$proper_keyboard$Keyboard$Key$ChromeSearch]));
		case 96:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadZero;
		case 97:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadOne;
		case 98:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadTwo;
		case 99:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadThree;
		case 100:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadFour;
		case 101:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadFive;
		case 102:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadSix;
		case 103:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadSeven;
		case 104:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadEight;
		case 105:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadNine;
		case 106:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Multiply;
		case 107:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Add;
		case 109:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Subtract;
		case 110:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Decimal;
		case 111:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Divide;
		case 112:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F1;
		case 113:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F2;
		case 114:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F3;
		case 115:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F4;
		case 116:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F5;
		case 117:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F6;
		case 118:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F7;
		case 119:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F8;
		case 120:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F9;
		case 121:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F10;
		case 122:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F11;
		case 123:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F12;
		case 144:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumLock;
		case 145:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$ScrollLock;
		default:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Unknown(keyCode);
	}
};
var $elm$json$Json$Decode$map7 = _Json_map7;
var $Gizra$elm_keyboard_event$Keyboard$Event$decodeKeyboardEvent = A8(
	$elm$json$Json$Decode$map7,
	$Gizra$elm_keyboard_event$Keyboard$Event$KeyboardEvent,
	A2($elm$json$Json$Decode$field, 'altKey', $elm$json$Json$Decode$bool),
	A2($elm$json$Json$Decode$field, 'ctrlKey', $elm$json$Json$Decode$bool),
	$Gizra$elm_keyboard_event$Keyboard$Event$decodeKey,
	A2($elm$json$Json$Decode$map, $SwiftsNamesake$proper_keyboard$Keyboard$Key$fromCode, $Gizra$elm_keyboard_event$Keyboard$Event$decodeKeyCode),
	A2($elm$json$Json$Decode$field, 'metaKey', $elm$json$Json$Decode$bool),
	A2($elm$json$Json$Decode$field, 'repeat', $elm$json$Json$Decode$bool),
	A2($elm$json$Json$Decode$field, 'shiftKey', $elm$json$Json$Decode$bool));
var $elm$time$Time$Every = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$State = F2(
	function (taggers, processes) {
		return {fH: processes, gi: taggers};
	});
var $elm$time$Time$init = $elm$core$Task$succeed(
	A2($elm$time$Time$State, $elm$core$Dict$empty, $elm$core$Dict$empty));
var $elm$time$Time$addMySub = F2(
	function (_v0, state) {
		var interval = _v0.a;
		var tagger = _v0.b;
		var _v1 = A2($elm$core$Dict$get, interval, state);
		if (_v1.$ === 1) {
			return A3(
				$elm$core$Dict$insert,
				interval,
				_List_fromArray(
					[tagger]),
				state);
		} else {
			var taggers = _v1.a;
			return A3(
				$elm$core$Dict$insert,
				interval,
				A2($elm$core$List$cons, tagger, taggers),
				state);
		}
	});
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Dict$foldl = F3(
	function (func, acc, dict) {
		foldl:
		while (true) {
			if (dict.$ === -2) {
				return acc;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldl, func, acc, left)),
					$temp$dict = right;
				func = $temp$func;
				acc = $temp$acc;
				dict = $temp$dict;
				continue foldl;
			}
		}
	});
var $elm$core$Dict$merge = F6(
	function (leftStep, bothStep, rightStep, leftDict, rightDict, initialResult) {
		var stepState = F3(
			function (rKey, rValue, _v0) {
				stepState:
				while (true) {
					var list = _v0.a;
					var result = _v0.b;
					if (!list.b) {
						return _Utils_Tuple2(
							list,
							A3(rightStep, rKey, rValue, result));
					} else {
						var _v2 = list.a;
						var lKey = _v2.a;
						var lValue = _v2.b;
						var rest = list.b;
						if (_Utils_cmp(lKey, rKey) < 0) {
							var $temp$rKey = rKey,
								$temp$rValue = rValue,
								$temp$_v0 = _Utils_Tuple2(
								rest,
								A3(leftStep, lKey, lValue, result));
							rKey = $temp$rKey;
							rValue = $temp$rValue;
							_v0 = $temp$_v0;
							continue stepState;
						} else {
							if (_Utils_cmp(lKey, rKey) > 0) {
								return _Utils_Tuple2(
									list,
									A3(rightStep, rKey, rValue, result));
							} else {
								return _Utils_Tuple2(
									rest,
									A4(bothStep, lKey, lValue, rValue, result));
							}
						}
					}
				}
			});
		var _v3 = A3(
			$elm$core$Dict$foldl,
			stepState,
			_Utils_Tuple2(
				$elm$core$Dict$toList(leftDict),
				initialResult),
			rightDict);
		var leftovers = _v3.a;
		var intermediateResult = _v3.b;
		return A3(
			$elm$core$List$foldl,
			F2(
				function (_v4, result) {
					var k = _v4.a;
					var v = _v4.b;
					return A3(leftStep, k, v, result);
				}),
			intermediateResult,
			leftovers);
	});
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$time$Time$Name = function (a) {
	return {$: 0, a: a};
};
var $elm$time$Time$Offset = function (a) {
	return {$: 1, a: a};
};
var $elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$customZone = $elm$time$Time$Zone;
var $elm$time$Time$setInterval = _Time_setInterval;
var $elm$core$Process$spawn = _Scheduler_spawn;
var $elm$time$Time$spawnHelp = F3(
	function (router, intervals, processes) {
		if (!intervals.b) {
			return $elm$core$Task$succeed(processes);
		} else {
			var interval = intervals.a;
			var rest = intervals.b;
			var spawnTimer = $elm$core$Process$spawn(
				A2(
					$elm$time$Time$setInterval,
					interval,
					A2($elm$core$Platform$sendToSelf, router, interval)));
			var spawnRest = function (id) {
				return A3(
					$elm$time$Time$spawnHelp,
					router,
					rest,
					A3($elm$core$Dict$insert, interval, id, processes));
			};
			return A2($elm$core$Task$andThen, spawnRest, spawnTimer);
		}
	});
var $elm$time$Time$onEffects = F3(
	function (router, subs, _v0) {
		var processes = _v0.fH;
		var rightStep = F3(
			function (_v6, id, _v7) {
				var spawns = _v7.a;
				var existing = _v7.b;
				var kills = _v7.c;
				return _Utils_Tuple3(
					spawns,
					existing,
					A2(
						$elm$core$Task$andThen,
						function (_v5) {
							return kills;
						},
						$elm$core$Process$kill(id)));
			});
		var newTaggers = A3($elm$core$List$foldl, $elm$time$Time$addMySub, $elm$core$Dict$empty, subs);
		var leftStep = F3(
			function (interval, taggers, _v4) {
				var spawns = _v4.a;
				var existing = _v4.b;
				var kills = _v4.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, interval, spawns),
					existing,
					kills);
			});
		var bothStep = F4(
			function (interval, taggers, id, _v3) {
				var spawns = _v3.a;
				var existing = _v3.b;
				var kills = _v3.c;
				return _Utils_Tuple3(
					spawns,
					A3($elm$core$Dict$insert, interval, id, existing),
					kills);
			});
		var _v1 = A6(
			$elm$core$Dict$merge,
			leftStep,
			bothStep,
			rightStep,
			newTaggers,
			processes,
			_Utils_Tuple3(
				_List_Nil,
				$elm$core$Dict$empty,
				$elm$core$Task$succeed(0)));
		var spawnList = _v1.a;
		var existingDict = _v1.b;
		var killTask = _v1.c;
		return A2(
			$elm$core$Task$andThen,
			function (newProcesses) {
				return $elm$core$Task$succeed(
					A2($elm$time$Time$State, newTaggers, newProcesses));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v2) {
					return A3($elm$time$Time$spawnHelp, router, spawnList, existingDict);
				},
				killTask));
	});
var $elm$time$Time$now = _Time_now($elm$time$Time$millisToPosix);
var $elm$time$Time$onSelfMsg = F3(
	function (router, interval, state) {
		var _v0 = A2($elm$core$Dict$get, interval, state.gi);
		if (_v0.$ === 1) {
			return $elm$core$Task$succeed(state);
		} else {
			var taggers = _v0.a;
			var tellTaggers = function (time) {
				return $elm$core$Task$sequence(
					A2(
						$elm$core$List$map,
						function (tagger) {
							return A2(
								$elm$core$Platform$sendToApp,
								router,
								tagger(time));
						},
						taggers));
			};
			return A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$succeed(state);
				},
				A2($elm$core$Task$andThen, tellTaggers, $elm$time$Time$now));
		}
	});
var $elm$time$Time$subMap = F2(
	function (f, _v0) {
		var interval = _v0.a;
		var tagger = _v0.b;
		return A2(
			$elm$time$Time$Every,
			interval,
			A2($elm$core$Basics$composeL, f, tagger));
	});
_Platform_effectManagers['Time'] = _Platform_createManager($elm$time$Time$init, $elm$time$Time$onEffects, $elm$time$Time$onSelfMsg, 0, $elm$time$Time$subMap);
var $elm$time$Time$subscription = _Platform_leaf('Time');
var $elm$time$Time$every = F2(
	function (interval, tagger) {
		return $elm$time$Time$subscription(
			A2($elm$time$Time$Every, interval, tagger));
	});
var $author$project$Main$gotHostname = _Platform_incomingPort('gotHostname', $elm$json$Json$Decode$string);
var $elm$browser$Browser$Events$Document = 0;
var $elm$browser$Browser$Events$MySub = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$browser$Browser$Events$State = F2(
	function (subs, pids) {
		return {fC: pids, gh: subs};
	});
var $elm$browser$Browser$Events$init = $elm$core$Task$succeed(
	A2($elm$browser$Browser$Events$State, _List_Nil, $elm$core$Dict$empty));
var $elm$browser$Browser$Events$nodeToKey = function (node) {
	if (!node) {
		return 'd_';
	} else {
		return 'w_';
	}
};
var $elm$browser$Browser$Events$addKey = function (sub) {
	var node = sub.a;
	var name = sub.b;
	return _Utils_Tuple2(
		_Utils_ap(
			$elm$browser$Browser$Events$nodeToKey(node),
			name),
		sub);
};
var $elm$browser$Browser$Events$Event = F2(
	function (key, event) {
		return {d4: event, h1: key};
	});
var $elm$browser$Browser$Events$spawn = F3(
	function (router, key, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var actualNode = function () {
			if (!node) {
				return _Browser_doc;
			} else {
				return _Browser_window;
			}
		}();
		return A2(
			$elm$core$Task$map,
			function (value) {
				return _Utils_Tuple2(key, value);
			},
			A3(
				_Browser_on,
				actualNode,
				name,
				function (event) {
					return A2(
						$elm$core$Platform$sendToSelf,
						router,
						A2($elm$browser$Browser$Events$Event, key, event));
				}));
	});
var $elm$core$Dict$union = F2(
	function (t1, t2) {
		return A3($elm$core$Dict$foldl, $elm$core$Dict$insert, t2, t1);
	});
var $elm$browser$Browser$Events$onEffects = F3(
	function (router, subs, state) {
		var stepRight = F3(
			function (key, sub, _v6) {
				var deads = _v6.a;
				var lives = _v6.b;
				var news = _v6.c;
				return _Utils_Tuple3(
					deads,
					lives,
					A2(
						$elm$core$List$cons,
						A3($elm$browser$Browser$Events$spawn, router, key, sub),
						news));
			});
		var stepLeft = F3(
			function (_v4, pid, _v5) {
				var deads = _v5.a;
				var lives = _v5.b;
				var news = _v5.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, pid, deads),
					lives,
					news);
			});
		var stepBoth = F4(
			function (key, pid, _v2, _v3) {
				var deads = _v3.a;
				var lives = _v3.b;
				var news = _v3.c;
				return _Utils_Tuple3(
					deads,
					A3($elm$core$Dict$insert, key, pid, lives),
					news);
			});
		var newSubs = A2($elm$core$List$map, $elm$browser$Browser$Events$addKey, subs);
		var _v0 = A6(
			$elm$core$Dict$merge,
			stepLeft,
			stepBoth,
			stepRight,
			state.fC,
			$elm$core$Dict$fromList(newSubs),
			_Utils_Tuple3(_List_Nil, $elm$core$Dict$empty, _List_Nil));
		var deadPids = _v0.a;
		var livePids = _v0.b;
		var makeNewPids = _v0.c;
		return A2(
			$elm$core$Task$andThen,
			function (pids) {
				return $elm$core$Task$succeed(
					A2(
						$elm$browser$Browser$Events$State,
						newSubs,
						A2(
							$elm$core$Dict$union,
							livePids,
							$elm$core$Dict$fromList(pids))));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$sequence(makeNewPids);
				},
				$elm$core$Task$sequence(
					A2($elm$core$List$map, $elm$core$Process$kill, deadPids))));
	});
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (!_v0.$) {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $elm$browser$Browser$Events$onSelfMsg = F3(
	function (router, _v0, state) {
		var key = _v0.h1;
		var event = _v0.d4;
		var toMessage = function (_v2) {
			var subKey = _v2.a;
			var _v3 = _v2.b;
			var node = _v3.a;
			var name = _v3.b;
			var decoder = _v3.c;
			return _Utils_eq(subKey, key) ? A2(_Browser_decodeEvent, decoder, event) : $elm$core$Maybe$Nothing;
		};
		var messages = A2($elm$core$List$filterMap, toMessage, state.gh);
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Platform$sendToApp(router),
					messages)));
	});
var $elm$browser$Browser$Events$subMap = F2(
	function (func, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var decoder = _v0.c;
		return A3(
			$elm$browser$Browser$Events$MySub,
			node,
			name,
			A2($elm$json$Json$Decode$map, func, decoder));
	});
_Platform_effectManagers['Browser.Events'] = _Platform_createManager($elm$browser$Browser$Events$init, $elm$browser$Browser$Events$onEffects, $elm$browser$Browser$Events$onSelfMsg, 0, $elm$browser$Browser$Events$subMap);
var $elm$browser$Browser$Events$subscription = _Platform_leaf('Browser.Events');
var $elm$browser$Browser$Events$on = F3(
	function (node, name, decoder) {
		return $elm$browser$Browser$Events$subscription(
			A3($elm$browser$Browser$Events$MySub, node, name, decoder));
	});
var $elm$browser$Browser$Events$onKeyDown = A2($elm$browser$Browser$Events$on, 0, 'keydown');
var $elm$core$List$singleton = function (value) {
	return _List_fromArray(
		[value]);
};
var $rundis$elm_bootstrap$Bootstrap$Dropdown$ListenClicks = 1;
var $elm$core$Platform$Sub$none = $elm$core$Platform$Sub$batch(_List_Nil);
var $elm$browser$Browser$AnimationManager$Time = function (a) {
	return {$: 0, a: a};
};
var $elm$browser$Browser$AnimationManager$State = F3(
	function (subs, request, oldTime) {
		return {cC: oldTime, fM: request, gh: subs};
	});
var $elm$browser$Browser$AnimationManager$init = $elm$core$Task$succeed(
	A3($elm$browser$Browser$AnimationManager$State, _List_Nil, $elm$core$Maybe$Nothing, 0));
var $elm$browser$Browser$AnimationManager$now = _Browser_now(0);
var $elm$browser$Browser$AnimationManager$rAF = _Browser_rAF(0);
var $elm$browser$Browser$AnimationManager$onEffects = F3(
	function (router, subs, _v0) {
		var request = _v0.fM;
		var oldTime = _v0.cC;
		var _v1 = _Utils_Tuple2(request, subs);
		if (_v1.a.$ === 1) {
			if (!_v1.b.b) {
				var _v2 = _v1.a;
				return $elm$browser$Browser$AnimationManager$init;
			} else {
				var _v4 = _v1.a;
				return A2(
					$elm$core$Task$andThen,
					function (pid) {
						return A2(
							$elm$core$Task$andThen,
							function (time) {
								return $elm$core$Task$succeed(
									A3(
										$elm$browser$Browser$AnimationManager$State,
										subs,
										$elm$core$Maybe$Just(pid),
										time));
							},
							$elm$browser$Browser$AnimationManager$now);
					},
					$elm$core$Process$spawn(
						A2(
							$elm$core$Task$andThen,
							$elm$core$Platform$sendToSelf(router),
							$elm$browser$Browser$AnimationManager$rAF)));
			}
		} else {
			if (!_v1.b.b) {
				var pid = _v1.a.a;
				return A2(
					$elm$core$Task$andThen,
					function (_v3) {
						return $elm$browser$Browser$AnimationManager$init;
					},
					$elm$core$Process$kill(pid));
			} else {
				return $elm$core$Task$succeed(
					A3($elm$browser$Browser$AnimationManager$State, subs, request, oldTime));
			}
		}
	});
var $elm$browser$Browser$AnimationManager$onSelfMsg = F3(
	function (router, newTime, _v0) {
		var subs = _v0.gh;
		var oldTime = _v0.cC;
		var send = function (sub) {
			if (!sub.$) {
				var tagger = sub.a;
				return A2(
					$elm$core$Platform$sendToApp,
					router,
					tagger(
						$elm$time$Time$millisToPosix(newTime)));
			} else {
				var tagger = sub.a;
				return A2(
					$elm$core$Platform$sendToApp,
					router,
					tagger(newTime - oldTime));
			}
		};
		return A2(
			$elm$core$Task$andThen,
			function (pid) {
				return A2(
					$elm$core$Task$andThen,
					function (_v1) {
						return $elm$core$Task$succeed(
							A3(
								$elm$browser$Browser$AnimationManager$State,
								subs,
								$elm$core$Maybe$Just(pid),
								newTime));
					},
					$elm$core$Task$sequence(
						A2($elm$core$List$map, send, subs)));
			},
			$elm$core$Process$spawn(
				A2(
					$elm$core$Task$andThen,
					$elm$core$Platform$sendToSelf(router),
					$elm$browser$Browser$AnimationManager$rAF)));
	});
var $elm$browser$Browser$AnimationManager$Delta = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$AnimationManager$subMap = F2(
	function (func, sub) {
		if (!sub.$) {
			var tagger = sub.a;
			return $elm$browser$Browser$AnimationManager$Time(
				A2($elm$core$Basics$composeL, func, tagger));
		} else {
			var tagger = sub.a;
			return $elm$browser$Browser$AnimationManager$Delta(
				A2($elm$core$Basics$composeL, func, tagger));
		}
	});
_Platform_effectManagers['Browser.AnimationManager'] = _Platform_createManager($elm$browser$Browser$AnimationManager$init, $elm$browser$Browser$AnimationManager$onEffects, $elm$browser$Browser$AnimationManager$onSelfMsg, 0, $elm$browser$Browser$AnimationManager$subMap);
var $elm$browser$Browser$AnimationManager$subscription = _Platform_leaf('Browser.AnimationManager');
var $elm$browser$Browser$AnimationManager$onAnimationFrame = function (tagger) {
	return $elm$browser$Browser$AnimationManager$subscription(
		$elm$browser$Browser$AnimationManager$Time(tagger));
};
var $elm$browser$Browser$Events$onAnimationFrame = $elm$browser$Browser$AnimationManager$onAnimationFrame;
var $elm$browser$Browser$Events$onClick = A2($elm$browser$Browser$Events$on, 0, 'click');
var $rundis$elm_bootstrap$Bootstrap$Dropdown$updateStatus = F2(
	function (status, _v0) {
		var stateRec = _v0;
		return _Utils_update(
			stateRec,
			{v: status});
	});
var $rundis$elm_bootstrap$Bootstrap$Dropdown$subscriptions = F2(
	function (state, toMsg) {
		var status = state.v;
		switch (status) {
			case 0:
				return $elm$browser$Browser$Events$onAnimationFrame(
					function (_v1) {
						return toMsg(
							A2($rundis$elm_bootstrap$Bootstrap$Dropdown$updateStatus, 1, state));
					});
			case 1:
				return $elm$browser$Browser$Events$onClick(
					$elm$json$Json$Decode$succeed(
						toMsg(
							A2($rundis$elm_bootstrap$Bootstrap$Dropdown$updateStatus, 2, state))));
			default:
				return $elm$core$Platform$Sub$none;
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Navbar$AnimatingDown = 2;
var $rundis$elm_bootstrap$Bootstrap$Navbar$AnimatingUp = 4;
var $rundis$elm_bootstrap$Bootstrap$Navbar$Closed = 2;
var $rundis$elm_bootstrap$Bootstrap$Navbar$ListenClicks = 1;
var $rundis$elm_bootstrap$Bootstrap$Navbar$Open = 0;
var $elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var $elm$core$Dict$map = F2(
	function (func, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				A2(func, key, value),
				A2($elm$core$Dict$map, func, left),
				A2($elm$core$Dict$map, func, right));
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Navbar$dropdownSubscriptions = F2(
	function (state, toMsg) {
		var dropdowns = state.M;
		var updDropdowns = A2(
			$elm$core$Dict$map,
			F2(
				function (_v2, status) {
					switch (status) {
						case 0:
							return 1;
						case 1:
							return 2;
						default:
							return 2;
					}
				}),
			dropdowns);
		var updState = A2(
			$rundis$elm_bootstrap$Bootstrap$Navbar$mapState,
			function (s) {
				return _Utils_update(
					s,
					{M: updDropdowns});
			},
			state);
		var needsSub = function (s) {
			return A2(
				$elm$core$List$any,
				function (_v1) {
					var status = _v1.b;
					return _Utils_eq(status, s);
				},
				$elm$core$Dict$toList(dropdowns));
		};
		return $elm$core$Platform$Sub$batch(
			_List_fromArray(
				[
					needsSub(0) ? $elm$browser$Browser$Events$onAnimationFrame(
					function (_v0) {
						return toMsg(updState);
					}) : $elm$core$Platform$Sub$none,
					needsSub(1) ? $elm$browser$Browser$Events$onClick(
					$elm$json$Json$Decode$succeed(
						toMsg(updState))) : $elm$core$Platform$Sub$none
				]));
	});
var $elm$browser$Browser$Events$Window = 1;
var $elm$browser$Browser$Events$onResize = function (func) {
	return A3(
		$elm$browser$Browser$Events$on,
		1,
		'resize',
		A2(
			$elm$json$Json$Decode$field,
			'target',
			A3(
				$elm$json$Json$Decode$map2,
				func,
				A2($elm$json$Json$Decode$field, 'innerWidth', $elm$json$Json$Decode$int),
				A2($elm$json$Json$Decode$field, 'innerHeight', $elm$json$Json$Decode$int))));
};
var $rundis$elm_bootstrap$Bootstrap$Navbar$subscriptions = F2(
	function (state, toMsg) {
		var visibility = state.m;
		var updState = function (v) {
			return A2(
				$rundis$elm_bootstrap$Bootstrap$Navbar$mapState,
				function (s) {
					return _Utils_update(
						s,
						{m: v});
				},
				state);
		};
		return $elm$core$Platform$Sub$batch(
			_List_fromArray(
				[
					function () {
					switch (visibility) {
						case 1:
							return $elm$browser$Browser$Events$onAnimationFrame(
								function (_v1) {
									return toMsg(
										updState(2));
								});
						case 3:
							return $elm$browser$Browser$Events$onAnimationFrame(
								function (_v2) {
									return toMsg(
										updState(4));
								});
						default:
							return $elm$core$Platform$Sub$none;
					}
				}(),
					$elm$browser$Browser$Events$onResize(
					F2(
						function (x, _v3) {
							return toMsg(
								A2(
									$rundis$elm_bootstrap$Bootstrap$Navbar$mapState,
									function (s) {
										return _Utils_update(
											s,
											{
												aI: $elm$core$Maybe$Just(x)
											});
									},
									state));
						})),
					A2($rundis$elm_bootstrap$Bootstrap$Navbar$dropdownSubscriptions, state, toMsg)
				]));
	});
var $rundis$elm_bootstrap$Bootstrap$Tab$subscriptions = F2(
	function (_v0, toMsg) {
		var state = _v0;
		var _v1 = state.m;
		if (_v1 === 1) {
			return $elm$browser$Browser$Events$onAnimationFrame(
				function (_v2) {
					return toMsg(
						_Utils_update(
							state,
							{m: 2}));
				});
		} else {
			return $elm$core$Platform$Sub$none;
		}
	});
var $author$project$Main$websocketIn = _Platform_incomingPort('websocketIn', $elm$json$Json$Decode$string);
var $author$project$Main$subscriptions = function (mdl) {
	var subs = _List_fromArray(
		[
			A2($rundis$elm_bootstrap$Bootstrap$Navbar$subscriptions, mdl.io, $author$project$Msg$NavMsg),
			$author$project$Main$websocketIn($author$project$Msg$WebsocketIn),
			A2($elm$time$Time$every, 1000, $author$project$Msg$Tick),
			$author$project$Main$gotHostname($author$project$Msg$GotHostname),
			A2($rundis$elm_bootstrap$Bootstrap$Tab$subscriptions, mdl.h3.iM, $author$project$Msg$UiLoginTabMsg),
			$elm$browser$Browser$Events$onKeyDown(
			A2($elm$json$Json$Decode$map, $author$project$Msg$HandleKeyboardEvent, $Gizra$elm_keyboard_event$Keyboard$Event$decodeKeyboardEvent))
		]);
	var subMAuthDropDown = A2(
		$elm$core$Maybe$withDefault,
		_List_Nil,
		A2(
			$elm$core$Maybe$map,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.cV;
				},
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.a8;
					},
					A2(
						$elm$core$Basics$composeR,
						function (st) {
							return A2(
								$rundis$elm_bootstrap$Bootstrap$Dropdown$subscriptions,
								st,
								function (st_) {
									return A2($elm$core$Basics$composeL, $author$project$Msg$UiPageUpdate, $author$project$Msg$UiUpdatePageUserAccessUserGroupMembershipTable)(
										$author$project$Components$UserGroupUserMembershipTableTypes$UserGroupMembershipTableMsgSetAuth(
											$author$project$ElmCommon$Dropdown$DropDownMsgToggle(st_)));
								});
						},
						$elm$core$List$singleton))),
			A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.hI;
				},
				mdl.hC)));
	return $elm$core$Platform$Sub$batch(
		_Utils_ap(subs, subMAuthDropDown));
};
var $author$project$Model$Model$AuthStateAuthenticated = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Model$Model$AuthStateLoginRequested = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Model$Model$AuthUiStatePostPasswordReset = 2;
var $author$project$Model$Model$AuthUiStatePostRegistration = 1;
var $author$project$BigO$Common$ErrorStr = function (unErrorStr) {
	return {iX: unErrorStr};
};
var $author$project$TypesPage$Errors = {$: 14};
var $author$project$Msg$InternalError = function (a) {
	return {$: 7, a: a};
};
var $author$project$Msg$OnClickServerAuthLoginTokenRequestResponse = function (a) {
	return {$: 18, a: a};
};
var $author$project$Msg$RunGlobalAction = function (a) {
	return {$: 9, a: a};
};
var $author$project$Api$ServerActionRequest$ServerActionBlacklistN80 = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Api$ServerActionRequest$ServerActionRequestFileConversion = function (a) {
	return {$: 8, a: a};
};
var $author$project$Api$ServerActionRequestN96UpdateMetadataPayload$ServerActionRequestN96UpdateMetadataN96Comment = function (a) {
	return {$: 0, a: a};
};
var $author$project$Api$ServerActionRequestN96UpdateMetadataPayload$ServerActionRequestN96UpdateMetadataN96DescUser = function (a) {
	return {$: 1, a: a};
};
var $author$project$Api$ServerActionRequestN96UpdateMetadataPayload$ServerActionRequestN96UpdateMetadataN96IsHidden = function (a) {
	return {$: 2, a: a};
};
var $author$project$Api$ServerActionRequest$ServerActionUnBlacklistN80 = function (a) {
	return {$: 1, a: a};
};
var $author$project$Api$ServerActionRequest$ServerActionUpdateN96Metadata = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Api$WebsocketMsgFromClient$WebsocketMsgFromClientConnect = function (a) {
	return {$: 0, a: a};
};
var $author$project$Api$WebsocketMsgFromClientPayloadTrunk$WebsocketMsgFromClientPayloadTrunkServerActionRequest = $elm$core$Basics$identity;
var $author$project$Api$WebsocketMsgFromClient$WebsocketMsgFromClientPayloadTrunk_ = function (a) {
	return {$: 2, a: a};
};
var $author$project$Model$Model$LastLoginError = function (unLastLoginError) {
	return {iY: unLastLoginError};
};
var $author$project$Utils$emptyEmail = $author$project$Api$UserEmail$UserEmail('');
var $author$project$Utils$emptyPassword = $author$project$Api$UserPassword$UserPassword('');
var $author$project$Utils$authStateFailed = function (err) {
	return A3(
		$author$project$Model$Model$AuthStateSignInPage,
		$author$project$Utils$emptyEmail,
		$author$project$Utils$emptyPassword,
		$elm$core$Maybe$Just(
			$author$project$Model$Model$LastLoginError(err)));
};
var $elm$http$Http$Header = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$header = $elm$http$Http$Header;
var $elm$http$Http$Request = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$State = F2(
	function (reqs, subs) {
		return {fL: reqs, gh: subs};
	});
var $elm$http$Http$init = $elm$core$Task$succeed(
	A2($elm$http$Http$State, $elm$core$Dict$empty, _List_Nil));
var $elm$http$Http$BadStatus_ = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$http$Http$BadUrl_ = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$GoodStatus_ = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$http$Http$NetworkError_ = {$: 2};
var $elm$http$Http$Receiving = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$Sending = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$Timeout_ = {$: 1};
var $elm$core$Maybe$isJust = function (maybe) {
	if (!maybe.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$http$Http$updateReqs = F3(
	function (router, cmds, reqs) {
		updateReqs:
		while (true) {
			if (!cmds.b) {
				return $elm$core$Task$succeed(reqs);
			} else {
				var cmd = cmds.a;
				var otherCmds = cmds.b;
				if (!cmd.$) {
					var tracker = cmd.a;
					var _v2 = A2($elm$core$Dict$get, tracker, reqs);
					if (_v2.$ === 1) {
						var $temp$router = router,
							$temp$cmds = otherCmds,
							$temp$reqs = reqs;
						router = $temp$router;
						cmds = $temp$cmds;
						reqs = $temp$reqs;
						continue updateReqs;
					} else {
						var pid = _v2.a;
						return A2(
							$elm$core$Task$andThen,
							function (_v3) {
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A2($elm$core$Dict$remove, tracker, reqs));
							},
							$elm$core$Process$kill(pid));
					}
				} else {
					var req = cmd.a;
					return A2(
						$elm$core$Task$andThen,
						function (pid) {
							var _v4 = req.iV;
							if (_v4.$ === 1) {
								return A3($elm$http$Http$updateReqs, router, otherCmds, reqs);
							} else {
								var tracker = _v4.a;
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A3($elm$core$Dict$insert, tracker, pid, reqs));
							}
						},
						$elm$core$Process$spawn(
							A3(
								_Http_toTask,
								router,
								$elm$core$Platform$sendToApp(router),
								req)));
				}
			}
		}
	});
var $elm$http$Http$onEffects = F4(
	function (router, cmds, subs, state) {
		return A2(
			$elm$core$Task$andThen,
			function (reqs) {
				return $elm$core$Task$succeed(
					A2($elm$http$Http$State, reqs, subs));
			},
			A3($elm$http$Http$updateReqs, router, cmds, state.fL));
	});
var $elm$http$Http$maybeSend = F4(
	function (router, desiredTracker, progress, _v0) {
		var actualTracker = _v0.a;
		var toMsg = _v0.b;
		return _Utils_eq(desiredTracker, actualTracker) ? $elm$core$Maybe$Just(
			A2(
				$elm$core$Platform$sendToApp,
				router,
				toMsg(progress))) : $elm$core$Maybe$Nothing;
	});
var $elm$http$Http$onSelfMsg = F3(
	function (router, _v0, state) {
		var tracker = _v0.a;
		var progress = _v0.b;
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$filterMap,
					A3($elm$http$Http$maybeSend, router, tracker, progress),
					state.gh)));
	});
var $elm$http$Http$Cancel = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$cmdMap = F2(
	function (func, cmd) {
		if (!cmd.$) {
			var tracker = cmd.a;
			return $elm$http$Http$Cancel(tracker);
		} else {
			var r = cmd.a;
			return $elm$http$Http$Request(
				{
					he: r.he,
					hl: r.hl,
					hK: A2(_Http_mapExpect, func, r.hK),
					hT: r.hT,
					h7: r.h7,
					iR: r.iR,
					iV: r.iV,
					i_: r.i_
				});
		}
	});
var $elm$http$Http$MySub = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$subMap = F2(
	function (func, _v0) {
		var tracker = _v0.a;
		var toMsg = _v0.b;
		return A2(
			$elm$http$Http$MySub,
			tracker,
			A2($elm$core$Basics$composeR, toMsg, func));
	});
_Platform_effectManagers['Http'] = _Platform_createManager($elm$http$Http$init, $elm$http$Http$onEffects, $elm$http$Http$onSelfMsg, $elm$http$Http$cmdMap, $elm$http$Http$subMap);
var $elm$http$Http$command = _Platform_leaf('Http');
var $elm$http$Http$subscription = _Platform_leaf('Http');
var $elm$http$Http$request = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{he: false, hl: r.hl, hK: r.hK, hT: r.hT, h7: r.h7, iR: r.iR, iV: r.iV, i_: r.i_}));
};
var $author$project$UpdateCmds$buildAuthHttpCmd = F3(
	function (url, body, expect) {
		var headers = _List_fromArray(
			[
				A2($elm$http$Http$header, 'apikey', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.ewogICJyb2xlIjogImFub24iLAogICJpc3MiOiAic3VwYWJhc2UiLAogICJpYXQiOiAxNzA1NjIyNDAwLAogICJleHAiOiAxODYzNDc1MjAwCn0.LvJITPJ5T98QtEq8NKq2UkTRlpU_5JDSmB3aBYxv1M8')
			]);
		var cmd = $elm$http$Http$request(
			{
				hl: body,
				hK: expect,
				hT: headers,
				h7: 'POST',
				iR: $elm$core$Maybe$Just(10.0 * 1000),
				iV: $elm$core$Maybe$Nothing,
				i_: url
			});
		return cmd;
	});
var $elm$file$File$Download$bytes = F3(
	function (name, mime, content) {
		return A2(
			$elm$core$Task$perform,
			$elm$core$Basics$never,
			A3(
				_File_download,
				name,
				mime,
				_File_makeBytesSafeForInternetExplorer(content)));
	});
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $author$project$Api$SupabaseLoginResponseMsgSuccess$SupabaseLoginResponseMsgSuccess = F3(
	function (access_token, token_type, user) {
		return {cU: access_token, gp: token_type, g5: user};
	});
var $author$project$Api$SupabaseLoginResponseMsgUser$SupabaseLoginResponseMsgUser = function (email) {
	return {d$: email};
};
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom = $elm$json$Json$Decode$map2($elm$core$Basics$apR);
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required = F3(
	function (key, valDecoder, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A2($elm$json$Json$Decode$field, key, valDecoder),
			decoder);
	});
var $author$project$Api$SupabaseLoginResponseMsgUser$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'email',
	$elm$json$Json$Decode$string,
	$elm$json$Json$Decode$succeed($author$project$Api$SupabaseLoginResponseMsgUser$SupabaseLoginResponseMsgUser));
var $author$project$Api$SupabaseLoginResponseMsgSuccess$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'user',
	$author$project$Api$SupabaseLoginResponseMsgUser$decoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'token_type',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'access_token',
			$elm$json$Json$Decode$string,
			$elm$json$Json$Decode$succeed($author$project$Api$SupabaseLoginResponseMsgSuccess$SupabaseLoginResponseMsgSuccess))));
var $author$project$BigO$WebSocketMsgIn$WebSocketMsgInData = function (a) {
	return {$: 0, a: a};
};
var $author$project$BigO$WebSocketMsgIn$WebSocketMsgInOnClose = {$: 2};
var $author$project$BigO$WebSocketMsgIn$WebSocketMsgInOnError = {$: 1};
var $author$project$Api$WebsocketMsgToClient$WebsocketMsgToClientConnectResponseFail = function (a) {
	return {$: 1, a: a};
};
var $author$project$Api$WebsocketMsgToClient$WebsocketMsgToClientConnectResponseOK = function (a) {
	return {$: 0, a: a};
};
var $author$project$Api$WebsocketMsgToClient$WebsocketMsgToClientPayloadLocal_ = function (a) {
	return {$: 3, a: a};
};
var $author$project$Api$WebsocketMsgToClient$WebsocketMsgToClientPayloadTrunk_ = function (a) {
	return {$: 2, a: a};
};
var $author$project$Api$WebsocketMsgToClientPayloadLocal$WebsocketMsgToClientPayloadLocalNewUserCloudAccessInfoElm = function (a) {
	return {$: 1, a: a};
};
var $author$project$Api$WebsocketMsgToClientPayloadLocal$WebsocketMsgToClientPayloadLocalResponseSetClientPage = function (a) {
	return {$: 0, a: a};
};
var $author$project$Api$ClientPageData$ClientPageDataShowFullDetailsForN96 = function (a) {
	return {$: 1, a: a};
};
var $author$project$Api$ClientPageData$ClientPageDataShowNotifications = {$: 2};
var $author$project$Api$ClientPageData$ClientPageDataShowShortN96sForN80 = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Api$CloudMetadataN96Full$CloudMetadataN96Full = F3(
	function (n96meta_full_short, n96meta_full_dev_details, n96meta_full_partial_downloads) {
		return {e$: n96meta_full_dev_details, e0: n96meta_full_partial_downloads, e1: n96meta_full_short};
	});
var $author$project$Api$CloudMetadataN96DevDetails$CloudMetadataN96DevDetails = function (parcelup_details) {
	return {fy: parcelup_details};
};
var $author$project$Api$CloudMetadataN96DevDetailsParcelUpDetails$CloudMetadataN96DevDetailsParcelUpDetails = F6(
	function (parcelmeta_n96, parcelmeta_c32, parcelmeta_uploadtime, parcelmeta_processing_state, parcelmeta_recieptdelivered, parcelmeta_parceltype) {
		return {fs: parcelmeta_c32, ft: parcelmeta_n96, fu: parcelmeta_parceltype, fv: parcelmeta_processing_state, fw: parcelmeta_recieptdelivered, fx: parcelmeta_uploadtime};
	});
var $author$project$Api$NodeAndUpCtxId96Data$NodeAndUpCtxId96Data = $elm$core$Basics$identity;
var $elm_community$maybe_extra$Maybe$Extra$combineHelp = F2(
	function (list, acc) {
		combineHelp:
		while (true) {
			if (list.b) {
				var head = list.a;
				var tail = list.b;
				if (!head.$) {
					var a = head.a;
					var $temp$list = tail,
						$temp$acc = A2($elm$core$List$cons, a, acc);
					list = $temp$list;
					acc = $temp$acc;
					continue combineHelp;
				} else {
					return $elm$core$Maybe$Nothing;
				}
			} else {
				return $elm$core$Maybe$Just(
					$elm$core$List$reverse(acc));
			}
		}
	});
var $elm_community$maybe_extra$Maybe$Extra$combine = function (list) {
	return A2($elm_community$maybe_extra$Maybe$Extra$combineHelp, list, _List_Nil);
};
var $elm$regex$Regex$Match = F4(
	function (match, index, number, submatches) {
		return {hY: index, h5: match, ip: number, iJ: submatches};
	});
var $elm$regex$Regex$contains = _Regex_contains;
var $rtfeldman$elm_hex$Hex$fromStringHelp = F3(
	function (position, chars, accumulated) {
		fromStringHelp:
		while (true) {
			if (!chars.b) {
				return $elm$core$Result$Ok(accumulated);
			} else {
				var _char = chars.a;
				var rest = chars.b;
				switch (_char) {
					case '0':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated;
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '1':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + A2($elm$core$Basics$pow, 16, position);
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '2':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (2 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '3':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (3 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '4':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (4 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '5':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (5 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '6':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (6 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '7':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (7 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '8':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (8 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '9':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (9 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'a':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (10 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'b':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (11 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'c':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (12 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'd':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (13 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'e':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (14 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'f':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (15 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					default:
						var nonHex = _char;
						return $elm$core$Result$Err(
							$elm$core$String$fromChar(nonHex) + ' is not a valid hexadecimal character.');
				}
			}
		}
	});
var $elm$core$Result$map = F2(
	function (func, ra) {
		if (!ra.$) {
			var a = ra.a;
			return $elm$core$Result$Ok(
				func(a));
		} else {
			var e = ra.a;
			return $elm$core$Result$Err(e);
		}
	});
var $elm$core$Result$mapError = F2(
	function (f, result) {
		if (!result.$) {
			var v = result.a;
			return $elm$core$Result$Ok(v);
		} else {
			var e = result.a;
			return $elm$core$Result$Err(
				f(e));
		}
	});
var $elm$core$List$tail = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(xs);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$core$String$foldr = _String_foldr;
var $elm$core$String$toList = function (string) {
	return A3($elm$core$String$foldr, $elm$core$List$cons, _List_Nil, string);
};
var $rtfeldman$elm_hex$Hex$fromString = function (str) {
	if ($elm$core$String$isEmpty(str)) {
		return $elm$core$Result$Err('Empty strings are not valid hexadecimal strings.');
	} else {
		var result = function () {
			if (A2($elm$core$String$startsWith, '-', str)) {
				var list = A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					$elm$core$List$tail(
						$elm$core$String$toList(str)));
				return A2(
					$elm$core$Result$map,
					$elm$core$Basics$negate,
					A3(
						$rtfeldman$elm_hex$Hex$fromStringHelp,
						$elm$core$List$length(list) - 1,
						list,
						0));
			} else {
				return A3(
					$rtfeldman$elm_hex$Hex$fromStringHelp,
					$elm$core$String$length(str) - 1,
					$elm$core$String$toList(str),
					0);
			}
		}();
		var formatError = function (err) {
			return A2(
				$elm$core$String$join,
				' ',
				_List_fromArray(
					['\"' + (str + '\"'), 'is not a valid hexadecimal string because', err]));
		};
		return A2($elm$core$Result$mapError, formatError, result);
	}
};
var $elm$regex$Regex$fromStringWith = _Regex_fromStringWith;
var $elm$regex$Regex$fromString = function (string) {
	return A2(
		$elm$regex$Regex$fromStringWith,
		{hm: false, ia: false},
		string);
};
var $elm$core$List$drop = F2(
	function (n, list) {
		drop:
		while (true) {
			if (n <= 0) {
				return list;
			} else {
				if (!list.b) {
					return list;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs;
					n = $temp$n;
					list = $temp$list;
					continue drop;
				}
			}
		}
	});
var $elm$core$List$takeReverse = F3(
	function (n, list, kept) {
		takeReverse:
		while (true) {
			if (n <= 0) {
				return kept;
			} else {
				if (!list.b) {
					return kept;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs,
						$temp$kept = A2($elm$core$List$cons, x, kept);
					n = $temp$n;
					list = $temp$list;
					kept = $temp$kept;
					continue takeReverse;
				}
			}
		}
	});
var $elm$core$List$takeTailRec = F2(
	function (n, list) {
		return $elm$core$List$reverse(
			A3($elm$core$List$takeReverse, n, list, _List_Nil));
	});
var $elm$core$List$takeFast = F3(
	function (ctr, n, list) {
		if (n <= 0) {
			return _List_Nil;
		} else {
			var _v0 = _Utils_Tuple2(n, list);
			_v0$1:
			while (true) {
				_v0$5:
				while (true) {
					if (!_v0.b.b) {
						return list;
					} else {
						if (_v0.b.b.b) {
							switch (_v0.a) {
								case 1:
									break _v0$1;
								case 2:
									var _v2 = _v0.b;
									var x = _v2.a;
									var _v3 = _v2.b;
									var y = _v3.a;
									return _List_fromArray(
										[x, y]);
								case 3:
									if (_v0.b.b.b.b) {
										var _v4 = _v0.b;
										var x = _v4.a;
										var _v5 = _v4.b;
										var y = _v5.a;
										var _v6 = _v5.b;
										var z = _v6.a;
										return _List_fromArray(
											[x, y, z]);
									} else {
										break _v0$5;
									}
								default:
									if (_v0.b.b.b.b && _v0.b.b.b.b.b) {
										var _v7 = _v0.b;
										var x = _v7.a;
										var _v8 = _v7.b;
										var y = _v8.a;
										var _v9 = _v8.b;
										var z = _v9.a;
										var _v10 = _v9.b;
										var w = _v10.a;
										var tl = _v10.b;
										return (ctr > 1000) ? A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A2($elm$core$List$takeTailRec, n - 4, tl))))) : A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A3($elm$core$List$takeFast, ctr + 1, n - 4, tl)))));
									} else {
										break _v0$5;
									}
							}
						} else {
							if (_v0.a === 1) {
								break _v0$1;
							} else {
								break _v0$5;
							}
						}
					}
				}
				return list;
			}
			var _v1 = _v0.b;
			var x = _v1.a;
			return _List_fromArray(
				[x]);
		}
	});
var $elm$core$List$take = F2(
	function (n, list) {
		return A3($elm$core$List$takeFast, 0, n, list);
	});
var $elm_community$list_extra$List$Extra$greedyGroupsOfWithStep = F3(
	function (size, step, list) {
		if ((size <= 0) || (step <= 0)) {
			return _List_Nil;
		} else {
			var go = F2(
				function (xs, acc) {
					go:
					while (true) {
						if ($elm$core$List$isEmpty(xs)) {
							return $elm$core$List$reverse(acc);
						} else {
							var $temp$xs = A2($elm$core$List$drop, step, xs),
								$temp$acc = A2(
								$elm$core$List$cons,
								A2($elm$core$List$take, size, xs),
								acc);
							xs = $temp$xs;
							acc = $temp$acc;
							continue go;
						}
					}
				});
			return A2(go, list, _List_Nil);
		}
	});
var $elm_community$list_extra$List$Extra$greedyGroupsOf = F2(
	function (size, xs) {
		return A3($elm_community$list_extra$List$Extra$greedyGroupsOfWithStep, size, size, xs);
	});
var $cmditch$elm_bigint$Constants$hexDigitMagnitude = 8;
var $elm$regex$Regex$never = _Regex_never;
var $elm$core$Result$toMaybe = function (result) {
	if (!result.$) {
		var v = result.a;
		return $elm$core$Maybe$Just(v);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $cmditch$elm_bigint$BigInt$fromHexString_ = function (x) {
	var _v0 = A2(
		$elm$regex$Regex$contains,
		A2(
			$elm$core$Maybe$withDefault,
			$elm$regex$Regex$never,
			$elm$regex$Regex$fromString('^[0-9A-Fa-f]')),
		$elm$core$String$fromList(x));
	if (_v0) {
		return A2(
			$elm$core$Maybe$map,
			A2(
				$elm$core$Basics$composeR,
				$elm$core$List$reverse,
				A2(
					$elm$core$List$foldl,
					F2(
						function (e, s) {
							return A2(
								$cmditch$elm_bigint$BigInt$add,
								$cmditch$elm_bigint$BigInt$fromInt(e),
								A2($cmditch$elm_bigint$BigInt$mul, s, $cmditch$elm_bigint$BigInt$eightHexDigits));
						}),
					$cmditch$elm_bigint$BigInt$zero)),
			$elm_community$maybe_extra$Maybe$Extra$combine(
				A2(
					$elm$core$List$map,
					A2(
						$elm$core$Basics$composeR,
						$elm$core$List$reverse,
						A2(
							$elm$core$Basics$composeR,
							$elm$core$String$fromList,
							A2($elm$core$Basics$composeR, $rtfeldman$elm_hex$Hex$fromString, $elm$core$Result$toMaybe))),
					A2(
						$elm_community$list_extra$List$Extra$greedyGroupsOf,
						$cmditch$elm_bigint$Constants$hexDigitMagnitude,
						$elm$core$List$reverse(x)))));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$core$String$toLower = _String_toLower;
var $cmditch$elm_bigint$BigInt$fromHexString = function (x) {
	var _v0 = $elm$core$String$toList(
		$elm$core$String$toLower(x));
	_v0$9:
	while (true) {
		if (!_v0.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			switch (_v0.a) {
				case '-':
					if (_v0.b.b) {
						if ((('0' === _v0.b.a) && _v0.b.b.b) && ('x' === _v0.b.b.a)) {
							if (!_v0.b.b.b.b) {
								var _v1 = _v0.b;
								var _v2 = _v1.b;
								return $elm$core$Maybe$Nothing;
							} else {
								var _v3 = _v0.b;
								var _v4 = _v3.b;
								var xs = _v4.b;
								return A2(
									$elm$core$Maybe$map,
									$cmditch$elm_bigint$BigInt$mul(
										$cmditch$elm_bigint$BigInt$fromInt(-1)),
									$cmditch$elm_bigint$BigInt$fromHexString_(xs));
							}
						} else {
							var xs = _v0.b;
							return A2(
								$elm$core$Maybe$map,
								$cmditch$elm_bigint$BigInt$mul(
									$cmditch$elm_bigint$BigInt$fromInt(-1)),
								$cmditch$elm_bigint$BigInt$fromHexString_(xs));
						}
					} else {
						return $elm$core$Maybe$Nothing;
					}
				case '+':
					if (!_v0.b.b) {
						return $elm$core$Maybe$Nothing;
					} else {
						var xs = _v0.b;
						return $cmditch$elm_bigint$BigInt$fromHexString_(xs);
					}
				case '0':
					if (_v0.b.b && ('x' === _v0.b.a)) {
						if (!_v0.b.b.b) {
							var _v5 = _v0.b;
							return $elm$core$Maybe$Nothing;
						} else {
							var _v6 = _v0.b;
							var xs = _v6.b;
							return $cmditch$elm_bigint$BigInt$fromHexString_(xs);
						}
					} else {
						break _v0$9;
					}
				default:
					break _v0$9;
			}
		}
	}
	var xs = _v0;
	return $cmditch$elm_bigint$BigInt$fromHexString_(xs);
};
var $author$project$ElmCommon$Std$decodeBigIntHex = A2(
	$elm$json$Json$Decode$andThen,
	function (txt) {
		var _v0 = $cmditch$elm_bigint$BigInt$fromHexString(txt);
		if (!_v0.$) {
			var bigInt = _v0.a;
			return $elm$json$Json$Decode$succeed(bigInt);
		} else {
			return $elm$json$Json$Decode$fail(txt + ' is not a valid BigInt');
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Api$NodeAndUpCtxId96Data$decoder = A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $author$project$ElmCommon$Std$decodeBigIntHex);
var $author$project$Api$ParcelMsgUpIdx32$ParcelMsgUpIdx32 = function (unC32) {
	return {gx: unC32};
};
var $author$project$Api$ParcelMsgUpIdx32$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'unC32',
	$elm$json$Json$Decode$int,
	$elm$json$Json$Decode$succeed($author$project$Api$ParcelMsgUpIdx32$ParcelMsgUpIdx32));
var $author$project$Api$ParcelUpDataPayloadType$ParcelUpDataTypeNodeEventLog = 1;
var $author$project$Api$ParcelUpDataPayloadType$ParcelUpDataTypeRecording = 0;
var $author$project$Api$ParcelUpDataPayloadType$ParcelUpDataTypeTimesyncData = 2;
var $author$project$Api$ParcelUpDataPayloadType$ParcelUpDataTypeUpCtxEnd = 3;
var $author$project$Api$ParcelUpDataPayloadType$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (a) {
		switch (a) {
			case 'ParcelUpDataTypeRecording':
				return $elm$json$Json$Decode$succeed(0);
			case 'ParcelUpDataTypeNodeEventLog':
				return $elm$json$Json$Decode$succeed(1);
			case 'ParcelUpDataTypeTimesyncData':
				return $elm$json$Json$Decode$succeed(2);
			case 'ParcelUpDataTypeUpCtxEnd':
				return $elm$json$Json$Decode$succeed(3);
			default:
				return $elm$json$Json$Decode$fail('No matching constructor');
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Api$ParcelUpProcessingState$ParcelUpProcessingStateComplete = 1;
var $author$project$Api$ParcelUpProcessingState$ParcelUpProcessingStateReadyToProcess = 0;
var $author$project$Api$ParcelUpProcessingState$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (a) {
		switch (a) {
			case 'ParcelUpProcessingStateReadyToProcess':
				return $elm$json$Json$Decode$succeed(0);
			case 'ParcelUpProcessingStateComplete':
				return $elm$json$Json$Decode$succeed(1);
			default:
				return $elm$json$Json$Decode$fail('No matching constructor');
		}
	},
	$elm$json$Json$Decode$string);
var $elm$core$String$concat = function (strings) {
	return A2($elm$core$String$join, '', strings);
};
var $rtfeldman$elm_iso8601_date_strings$DeadEnds$problemToString = function (p) {
	switch (p.$) {
		case 0:
			var s = p.a;
			return 'expecting \'' + (s + '\'');
		case 1:
			return 'expecting int';
		case 2:
			return 'expecting hex';
		case 3:
			return 'expecting octal';
		case 4:
			return 'expecting binary';
		case 5:
			return 'expecting float';
		case 6:
			return 'expecting number';
		case 7:
			return 'expecting variable';
		case 8:
			var s = p.a;
			return 'expecting symbol \'' + (s + '\'');
		case 9:
			var s = p.a;
			return 'expecting keyword \'' + (s + '\'');
		case 10:
			return 'expecting end';
		case 11:
			return 'unexpected char';
		case 12:
			var s = p.a;
			return 'problem ' + s;
		default:
			return 'bad repeat';
	}
};
var $rtfeldman$elm_iso8601_date_strings$DeadEnds$deadEndToString = function (deadend) {
	return $rtfeldman$elm_iso8601_date_strings$DeadEnds$problemToString(deadend.fF) + (' at row ' + ($elm$core$String$fromInt(deadend.f_) + (', col ' + $elm$core$String$fromInt(deadend.dC))));
};
var $elm$core$List$intersperse = F2(
	function (sep, xs) {
		if (!xs.b) {
			return _List_Nil;
		} else {
			var hd = xs.a;
			var tl = xs.b;
			var step = F2(
				function (x, rest) {
					return A2(
						$elm$core$List$cons,
						sep,
						A2($elm$core$List$cons, x, rest));
				});
			var spersed = A3($elm$core$List$foldr, step, _List_Nil, tl);
			return A2($elm$core$List$cons, hd, spersed);
		}
	});
var $rtfeldman$elm_iso8601_date_strings$DeadEnds$deadEndsToString = function (deadEnds) {
	return $elm$core$String$concat(
		A2(
			$elm$core$List$intersperse,
			'; ',
			A2($elm$core$List$map, $rtfeldman$elm_iso8601_date_strings$DeadEnds$deadEndToString, deadEnds)));
};
var $elm$parser$Parser$Advanced$Bad = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$Good = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$parser$Parser$Advanced$Parser = $elm$core$Basics$identity;
var $elm$parser$Parser$Advanced$andThen = F2(
	function (callback, _v0) {
		var parseA = _v0;
		return function (s0) {
			var _v1 = parseA(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				var _v2 = callback(a);
				var parseB = _v2;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3($elm$parser$Parser$Advanced$Good, p1 || p2, b, s2);
				}
			}
		};
	});
var $elm$parser$Parser$andThen = $elm$parser$Parser$Advanced$andThen;
var $elm$parser$Parser$ExpectingEnd = {$: 10};
var $elm$parser$Parser$Advanced$AddRight = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$DeadEnd = F4(
	function (row, col, problem, contextStack) {
		return {dC: col, ht: contextStack, fF: problem, f_: row};
	});
var $elm$parser$Parser$Advanced$Empty = {$: 0};
var $elm$parser$Parser$Advanced$fromState = F2(
	function (s, x) {
		return A2(
			$elm$parser$Parser$Advanced$AddRight,
			$elm$parser$Parser$Advanced$Empty,
			A4($elm$parser$Parser$Advanced$DeadEnd, s.f_, s.dC, x, s.d));
	});
var $elm$parser$Parser$Advanced$end = function (x) {
	return function (s) {
		return _Utils_eq(
			$elm$core$String$length(s.a),
			s.b) ? A3($elm$parser$Parser$Advanced$Good, false, 0, s) : A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$end = $elm$parser$Parser$Advanced$end($elm$parser$Parser$ExpectingEnd);
var $elm$parser$Parser$Advanced$isSubChar = _Parser_isSubChar;
var $elm$parser$Parser$Advanced$chompWhileHelp = F5(
	function (isGood, offset, row, col, s0) {
		chompWhileHelp:
		while (true) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, offset, s0.a);
			if (_Utils_eq(newOffset, -1)) {
				return A3(
					$elm$parser$Parser$Advanced$Good,
					_Utils_cmp(s0.b, offset) < 0,
					0,
					{dC: col, d: s0.d, f: s0.f, b: offset, f_: row, a: s0.a});
			} else {
				if (_Utils_eq(newOffset, -2)) {
					var $temp$isGood = isGood,
						$temp$offset = offset + 1,
						$temp$row = row + 1,
						$temp$col = 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				} else {
					var $temp$isGood = isGood,
						$temp$offset = newOffset,
						$temp$row = row,
						$temp$col = col + 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$chompWhile = function (isGood) {
	return function (s) {
		return A5($elm$parser$Parser$Advanced$chompWhileHelp, isGood, s.b, s.f_, s.dC, s);
	};
};
var $elm$parser$Parser$chompWhile = $elm$parser$Parser$Advanced$chompWhile;
var $elm$parser$Parser$Advanced$mapChompedString = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					A2(
						func,
						A3($elm$core$String$slice, s0.b, s1.b, s0.a),
						a),
					s1);
			}
		};
	});
var $elm$parser$Parser$Advanced$getChompedString = function (parser) {
	return A2($elm$parser$Parser$Advanced$mapChompedString, $elm$core$Basics$always, parser);
};
var $elm$parser$Parser$getChompedString = $elm$parser$Parser$Advanced$getChompedString;
var $elm$parser$Parser$Problem = function (a) {
	return {$: 12, a: a};
};
var $elm$parser$Parser$Advanced$problem = function (x) {
	return function (s) {
		return A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$problem = function (msg) {
	return $elm$parser$Parser$Advanced$problem(
		$elm$parser$Parser$Problem(msg));
};
var $elm$core$Basics$round = _Basics_round;
var $elm$parser$Parser$Advanced$succeed = function (a) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$Good, false, a, s);
	};
};
var $elm$parser$Parser$succeed = $elm$parser$Parser$Advanced$succeed;
var $elm$core$String$toFloat = _String_toFloat;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$fractionsOfASecondInMs = A2(
	$elm$parser$Parser$andThen,
	function (str) {
		if ($elm$core$String$length(str) <= 9) {
			var _v0 = $elm$core$String$toFloat('0.' + str);
			if (!_v0.$) {
				var floatVal = _v0.a;
				return $elm$parser$Parser$succeed(
					$elm$core$Basics$round(floatVal * 1000));
			} else {
				return $elm$parser$Parser$problem('Invalid float: \"' + (str + '\"'));
			}
		} else {
			return $elm$parser$Parser$problem(
				'Expected at most 9 digits, but got ' + $elm$core$String$fromInt(
					$elm$core$String$length(str)));
		}
	},
	$elm$parser$Parser$getChompedString(
		$elm$parser$Parser$chompWhile($elm$core$Char$isDigit)));
var $rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts = F6(
	function (monthYearDayMs, hour, minute, second, ms, utcOffsetMinutes) {
		return $elm$time$Time$millisToPosix((((monthYearDayMs + (((hour * 60) * 60) * 1000)) + (((minute - utcOffsetMinutes) * 60) * 1000)) + (second * 1000)) + ms);
	});
var $elm$parser$Parser$Advanced$map2 = F3(
	function (func, _v0, _v1) {
		var parseA = _v0;
		var parseB = _v1;
		return function (s0) {
			var _v2 = parseA(s0);
			if (_v2.$ === 1) {
				var p = _v2.a;
				var x = _v2.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v2.a;
				var a = _v2.b;
				var s1 = _v2.c;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3(
						$elm$parser$Parser$Advanced$Good,
						p1 || p2,
						A2(func, a, b),
						s2);
				}
			}
		};
	});
var $elm$parser$Parser$Advanced$ignorer = F2(
	function (keepParser, ignoreParser) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$always, keepParser, ignoreParser);
	});
var $elm$parser$Parser$ignorer = $elm$parser$Parser$Advanced$ignorer;
var $elm$parser$Parser$Advanced$keeper = F2(
	function (parseFunc, parseArg) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$apL, parseFunc, parseArg);
	});
var $elm$parser$Parser$keeper = $elm$parser$Parser$Advanced$keeper;
var $elm$parser$Parser$Advanced$Append = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$oneOfHelp = F3(
	function (s0, bag, parsers) {
		oneOfHelp:
		while (true) {
			if (!parsers.b) {
				return A2($elm$parser$Parser$Advanced$Bad, false, bag);
			} else {
				var parse = parsers.a;
				var remainingParsers = parsers.b;
				var _v1 = parse(s0);
				if (!_v1.$) {
					var step = _v1;
					return step;
				} else {
					var step = _v1;
					var p = step.a;
					var x = step.b;
					if (p) {
						return step;
					} else {
						var $temp$s0 = s0,
							$temp$bag = A2($elm$parser$Parser$Advanced$Append, bag, x),
							$temp$parsers = remainingParsers;
						s0 = $temp$s0;
						bag = $temp$bag;
						parsers = $temp$parsers;
						continue oneOfHelp;
					}
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$oneOf = function (parsers) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$oneOfHelp, s, $elm$parser$Parser$Advanced$Empty, parsers);
	};
};
var $elm$parser$Parser$oneOf = $elm$parser$Parser$Advanced$oneOf;
var $elm$parser$Parser$Done = function (a) {
	return {$: 1, a: a};
};
var $elm$parser$Parser$Loop = function (a) {
	return {$: 0, a: a};
};
var $elm$core$String$append = _String_append;
var $elm$parser$Parser$UnexpectedChar = {$: 11};
var $elm$parser$Parser$Advanced$chompIf = F2(
	function (isGood, expecting) {
		return function (s) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, s.b, s.a);
			return _Utils_eq(newOffset, -1) ? A2(
				$elm$parser$Parser$Advanced$Bad,
				false,
				A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : (_Utils_eq(newOffset, -2) ? A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{dC: 1, d: s.d, f: s.f, b: s.b + 1, f_: s.f_ + 1, a: s.a}) : A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{dC: s.dC + 1, d: s.d, f: s.f, b: newOffset, f_: s.f_, a: s.a}));
		};
	});
var $elm$parser$Parser$chompIf = function (isGood) {
	return A2($elm$parser$Parser$Advanced$chompIf, isGood, $elm$parser$Parser$UnexpectedChar);
};
var $elm$parser$Parser$Advanced$loopHelp = F4(
	function (p, state, callback, s0) {
		loopHelp:
		while (true) {
			var _v0 = callback(state);
			var parse = _v0;
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p1 = _v1.a;
				var step = _v1.b;
				var s1 = _v1.c;
				if (!step.$) {
					var newState = step.a;
					var $temp$p = p || p1,
						$temp$state = newState,
						$temp$callback = callback,
						$temp$s0 = s1;
					p = $temp$p;
					state = $temp$state;
					callback = $temp$callback;
					s0 = $temp$s0;
					continue loopHelp;
				} else {
					var result = step.a;
					return A3($elm$parser$Parser$Advanced$Good, p || p1, result, s1);
				}
			} else {
				var p1 = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p || p1, x);
			}
		}
	});
var $elm$parser$Parser$Advanced$loop = F2(
	function (state, callback) {
		return function (s) {
			return A4($elm$parser$Parser$Advanced$loopHelp, false, state, callback, s);
		};
	});
var $elm$parser$Parser$Advanced$map = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					func(a),
					s1);
			} else {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			}
		};
	});
var $elm$parser$Parser$map = $elm$parser$Parser$Advanced$map;
var $elm$parser$Parser$Advanced$Done = function (a) {
	return {$: 1, a: a};
};
var $elm$parser$Parser$Advanced$Loop = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$toAdvancedStep = function (step) {
	if (!step.$) {
		var s = step.a;
		return $elm$parser$Parser$Advanced$Loop(s);
	} else {
		var a = step.a;
		return $elm$parser$Parser$Advanced$Done(a);
	}
};
var $elm$parser$Parser$loop = F2(
	function (state, callback) {
		return A2(
			$elm$parser$Parser$Advanced$loop,
			state,
			function (s) {
				return A2(
					$elm$parser$Parser$map,
					$elm$parser$Parser$toAdvancedStep,
					callback(s));
			});
	});
var $rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt = function (quantity) {
	var helper = function (str) {
		if (_Utils_eq(
			$elm$core$String$length(str),
			quantity)) {
			var _v0 = $elm$core$String$toInt(str);
			if (!_v0.$) {
				var intVal = _v0.a;
				return A2(
					$elm$parser$Parser$map,
					$elm$parser$Parser$Done,
					$elm$parser$Parser$succeed(intVal));
			} else {
				return $elm$parser$Parser$problem('Invalid integer: \"' + (str + '\"'));
			}
		} else {
			return A2(
				$elm$parser$Parser$map,
				function (nextChar) {
					return $elm$parser$Parser$Loop(
						A2($elm$core$String$append, str, nextChar));
				},
				$elm$parser$Parser$getChompedString(
					$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
		}
	};
	return A2($elm$parser$Parser$loop, '', helper);
};
var $elm$parser$Parser$ExpectingSymbol = function (a) {
	return {$: 8, a: a};
};
var $elm$parser$Parser$Advanced$Token = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$isSubString = _Parser_isSubString;
var $elm$parser$Parser$Advanced$token = function (_v0) {
	var str = _v0.a;
	var expecting = _v0.b;
	var progress = !$elm$core$String$isEmpty(str);
	return function (s) {
		var _v1 = A5($elm$parser$Parser$Advanced$isSubString, str, s.b, s.f_, s.dC, s.a);
		var newOffset = _v1.a;
		var newRow = _v1.b;
		var newCol = _v1.c;
		return _Utils_eq(newOffset, -1) ? A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : A3(
			$elm$parser$Parser$Advanced$Good,
			progress,
			0,
			{dC: newCol, d: s.d, f: s.f, b: newOffset, f_: newRow, a: s.a});
	};
};
var $elm$parser$Parser$Advanced$symbol = $elm$parser$Parser$Advanced$token;
var $elm$parser$Parser$symbol = function (str) {
	return $elm$parser$Parser$Advanced$symbol(
		A2(
			$elm$parser$Parser$Advanced$Token,
			str,
			$elm$parser$Parser$ExpectingSymbol(str)));
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear = 1970;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay = function (day) {
	return $elm$parser$Parser$problem(
		'Invalid day: ' + $elm$core$String$fromInt(day));
};
var $elm$core$Basics$neq = _Utils_notEqual;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear = function (year) {
	return (!A2($elm$core$Basics$modBy, 4, year)) && ((!(!A2($elm$core$Basics$modBy, 100, year))) || (!A2($elm$core$Basics$modBy, 400, year)));
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore = function (y1) {
	var y = y1 - 1;
	return (((y / 4) | 0) - ((y / 100) | 0)) + ((y / 400) | 0);
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerDay = 86400000;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerYear = 31536000000;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$yearMonthDay = function (_v0) {
	var year = _v0.a;
	var month = _v0.b;
	var dayInMonth = _v0.c;
	if (dayInMonth < 0) {
		return $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth);
	} else {
		var succeedWith = function (extraMs) {
			var yearMs = $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerYear * (year - $rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear);
			var days = ((month < 3) || (!$rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear(year))) ? (dayInMonth - 1) : dayInMonth;
			var dayMs = $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerDay * (days + ($rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore(year) - $rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore($rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear)));
			return $elm$parser$Parser$succeed((extraMs + yearMs) + dayMs);
		};
		switch (month) {
			case 1:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(0);
			case 2:
				return ((dayInMonth > 29) || ((dayInMonth === 29) && (!$rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear(year)))) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(2678400000);
			case 3:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(5097600000);
			case 4:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(7776000000);
			case 5:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(10368000000);
			case 6:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(13046400000);
			case 7:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(15638400000);
			case 8:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(18316800000);
			case 9:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(20995200000);
			case 10:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(23587200000);
			case 11:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(26265600000);
			case 12:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(28857600000);
			default:
				return $elm$parser$Parser$problem(
					'Invalid month: \"' + ($elm$core$String$fromInt(month) + '\"'));
		}
	}
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$monthYearDayInMs = A2(
	$elm$parser$Parser$andThen,
	$rtfeldman$elm_iso8601_date_strings$Iso8601$yearMonthDay,
	A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				$elm$parser$Parser$succeed(
					F3(
						function (year, month, day) {
							return _Utils_Tuple3(year, month, day);
						})),
				$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(4)),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$ignorer,
							$elm$parser$Parser$succeed($elm$core$Basics$identity),
							$elm$parser$Parser$symbol('-')),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
					]))),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$ignorer,
						$elm$parser$Parser$succeed($elm$core$Basics$identity),
						$elm$parser$Parser$symbol('-')),
					$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
					$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
				]))));
var $rtfeldman$elm_iso8601_date_strings$Iso8601$utcOffsetInMinutes = function () {
	var utcOffsetMinutesFromParts = F3(
		function (multiplier, hours, minutes) {
			return (multiplier * (hours * 60)) + minutes;
		});
	return A2(
		$elm$parser$Parser$keeper,
		$elm$parser$Parser$succeed($elm$core$Basics$identity),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$map,
					function (_v0) {
						return 0;
					},
					$elm$parser$Parser$symbol('Z')),
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							$elm$parser$Parser$succeed(utcOffsetMinutesFromParts),
							$elm$parser$Parser$oneOf(
								_List_fromArray(
									[
										A2(
										$elm$parser$Parser$map,
										function (_v1) {
											return 1;
										},
										$elm$parser$Parser$symbol('+')),
										A2(
										$elm$parser$Parser$map,
										function (_v2) {
											return -1;
										},
										$elm$parser$Parser$symbol('-'))
									]))),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								A2(
								$elm$parser$Parser$keeper,
								A2(
									$elm$parser$Parser$ignorer,
									$elm$parser$Parser$succeed($elm$core$Basics$identity),
									$elm$parser$Parser$symbol(':')),
								$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
								$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2),
								$elm$parser$Parser$succeed(0)
							]))),
					A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(0),
					$elm$parser$Parser$end)
				])));
}();
var $rtfeldman$elm_iso8601_date_strings$Iso8601$iso8601 = A2(
	$elm$parser$Parser$andThen,
	function (datePart) {
		return $elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							A2(
								$elm$parser$Parser$keeper,
								A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed(
											$rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts(datePart)),
										$elm$parser$Parser$symbol('T')),
									$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
								$elm$parser$Parser$oneOf(
									_List_fromArray(
										[
											A2(
											$elm$parser$Parser$keeper,
											A2(
												$elm$parser$Parser$ignorer,
												$elm$parser$Parser$succeed($elm$core$Basics$identity),
												$elm$parser$Parser$symbol(':')),
											$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
											$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
										]))),
							$elm$parser$Parser$oneOf(
								_List_fromArray(
									[
										A2(
										$elm$parser$Parser$keeper,
										A2(
											$elm$parser$Parser$ignorer,
											$elm$parser$Parser$succeed($elm$core$Basics$identity),
											$elm$parser$Parser$symbol(':')),
										$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
										$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2),
										$elm$parser$Parser$succeed(0)
									]))),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed($elm$core$Basics$identity),
										$elm$parser$Parser$symbol('.')),
									$rtfeldman$elm_iso8601_date_strings$Iso8601$fractionsOfASecondInMs),
									$elm$parser$Parser$succeed(0)
								]))),
					A2($elm$parser$Parser$ignorer, $rtfeldman$elm_iso8601_date_strings$Iso8601$utcOffsetInMinutes, $elm$parser$Parser$end)),
					A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(
						A6($rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts, datePart, 0, 0, 0, 0, 0)),
					$elm$parser$Parser$end)
				]));
	},
	$rtfeldman$elm_iso8601_date_strings$Iso8601$monthYearDayInMs);
var $elm$parser$Parser$DeadEnd = F3(
	function (row, col, problem) {
		return {dC: col, fF: problem, f_: row};
	});
var $elm$parser$Parser$problemToDeadEnd = function (p) {
	return A3($elm$parser$Parser$DeadEnd, p.f_, p.dC, p.fF);
};
var $elm$parser$Parser$Advanced$bagToList = F2(
	function (bag, list) {
		bagToList:
		while (true) {
			switch (bag.$) {
				case 0:
					return list;
				case 1:
					var bag1 = bag.a;
					var x = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$core$List$cons, x, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
				default:
					var bag1 = bag.a;
					var bag2 = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$parser$Parser$Advanced$bagToList, bag2, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
			}
		}
	});
var $elm$parser$Parser$Advanced$run = F2(
	function (_v0, src) {
		var parse = _v0;
		var _v1 = parse(
			{dC: 1, d: _List_Nil, f: 1, b: 0, f_: 1, a: src});
		if (!_v1.$) {
			var value = _v1.b;
			return $elm$core$Result$Ok(value);
		} else {
			var bag = _v1.b;
			return $elm$core$Result$Err(
				A2($elm$parser$Parser$Advanced$bagToList, bag, _List_Nil));
		}
	});
var $elm$parser$Parser$run = F2(
	function (parser, source) {
		var _v0 = A2($elm$parser$Parser$Advanced$run, parser, source);
		if (!_v0.$) {
			var a = _v0.a;
			return $elm$core$Result$Ok(a);
		} else {
			var problems = _v0.a;
			return $elm$core$Result$Err(
				A2($elm$core$List$map, $elm$parser$Parser$problemToDeadEnd, problems));
		}
	});
var $rtfeldman$elm_iso8601_date_strings$Iso8601$toTime = function (str) {
	return A2($elm$parser$Parser$run, $rtfeldman$elm_iso8601_date_strings$Iso8601$iso8601, str);
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (str) {
		var _v0 = $rtfeldman$elm_iso8601_date_strings$Iso8601$toTime(str);
		if (_v0.$ === 1) {
			var deadEnds = _v0.a;
			return $elm$json$Json$Decode$fail(
				$rtfeldman$elm_iso8601_date_strings$DeadEnds$deadEndsToString(deadEnds));
		} else {
			var time = _v0.a;
			return $elm$json$Json$Decode$succeed(time);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Api$CloudMetadataN96DevDetailsParcelUpDetails$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'parcelmeta_parceltype',
	$author$project$Api$ParcelUpDataPayloadType$decoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'parcelmeta_recieptdelivered',
		$elm$json$Json$Decode$bool,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'parcelmeta_processing_state',
			$author$project$Api$ParcelUpProcessingState$decoder,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'parcelmeta_uploadtime',
				$rtfeldman$elm_iso8601_date_strings$Iso8601$decoder,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'parcelmeta_c32',
					$author$project$Api$ParcelMsgUpIdx32$decoder,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'parcelmeta_n96',
						$author$project$Api$NodeAndUpCtxId96Data$decoder,
						$elm$json$Json$Decode$succeed($author$project$Api$CloudMetadataN96DevDetailsParcelUpDetails$CloudMetadataN96DevDetailsParcelUpDetails)))))));
var $elm$json$Json$Decode$list = _Json_decodeList;
var $author$project$Api$CloudMetadataN96DevDetails$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'parcelup_details',
	$elm$json$Json$Decode$list($author$project$Api$CloudMetadataN96DevDetailsParcelUpDetails$decoder),
	$elm$json$Json$Decode$succeed($author$project$Api$CloudMetadataN96DevDetails$CloudMetadataN96DevDetails));
var $author$project$Api$CloudMetadataN96PartialDownloads$CloudMetadataN96PartialDownloads = function (partial_downloads) {
	return {fz: partial_downloads};
};
var $author$project$Api$CloudWebDownloadLinkFullDataPartial$CloudWebDownloadLinkFullDataPartial = function (un_cloud_web_download_link_full_data_partial) {
	return {gY: un_cloud_web_download_link_full_data_partial};
};
var $author$project$Api$CloudWebDownloadLinkFullData$CloudWebDownloadLinkFullData = F5(
	function (link_url_prefix, link_url, link_localfname, link_filetype, link_status) {
		return {eq: link_filetype, er: link_localfname, es: link_status, et: link_url, eu: link_url_prefix};
	});
var $author$project$Api$DbN96LocalFileOutputStatus$DbN96LocalFileOutputStatusComplete = 3;
var $author$project$Api$DbN96LocalFileOutputStatus$DbN96LocalFileOutputStatusError = 4;
var $author$project$Api$DbN96LocalFileOutputStatus$DbN96LocalFileOutputStatusInProgress = 2;
var $author$project$Api$DbN96LocalFileOutputStatus$DbN96LocalFileOutputStatusNotRequested = 0;
var $author$project$Api$DbN96LocalFileOutputStatus$DbN96LocalFileOutputStatusRequested = 1;
var $author$project$Api$DbN96LocalFileOutputStatus$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (a) {
		switch (a) {
			case 'DbN96LocalFileOutputStatusNotRequested':
				return $elm$json$Json$Decode$succeed(0);
			case 'DbN96LocalFileOutputStatusRequested':
				return $elm$json$Json$Decode$succeed(1);
			case 'DbN96LocalFileOutputStatusInProgress':
				return $elm$json$Json$Decode$succeed(2);
			case 'DbN96LocalFileOutputStatusComplete':
				return $elm$json$Json$Decode$succeed(3);
			case 'DbN96LocalFileOutputStatusError':
				return $elm$json$Json$Decode$succeed(4);
			default:
				return $elm$json$Json$Decode$fail('No matching constructor');
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Api$DownloadLocalFilename$DownloadLocalFilename = function (unDownloadLocalFilename) {
	return {gC: unDownloadLocalFilename};
};
var $author$project$Api$DownloadLocalFilename$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'unDownloadLocalFilename',
	$elm$json$Json$Decode$string,
	$elm$json$Json$Decode$succeed($author$project$Api$DownloadLocalFilename$DownloadLocalFilename));
var $author$project$Api$OutputFileType$OutputFileTypeCsv = 1;
var $author$project$Api$OutputFileType$OutputFileTypeHdf5 = 3;
var $author$project$Api$OutputFileType$OutputFileTypePyBin = 0;
var $author$project$Api$OutputFileType$OutputFileTypeXlsClipped = 2;
var $author$project$Api$OutputFileType$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (a) {
		switch (a) {
			case 'OutputFileTypePyBin':
				return $elm$json$Json$Decode$succeed(0);
			case 'OutputFileTypeCsv':
				return $elm$json$Json$Decode$succeed(1);
			case 'OutputFileTypeXlsClipped':
				return $elm$json$Json$Decode$succeed(2);
			case 'OutputFileTypeHdf5':
				return $elm$json$Json$Decode$succeed(3);
			default:
				return $elm$json$Json$Decode$fail('No matching constructor');
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Api$CloudWebDownloadLinkFullData$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'link_status',
	$author$project$Api$DbN96LocalFileOutputStatus$decoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'link_filetype',
		$author$project$Api$OutputFileType$decoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'link_localfname',
			$author$project$Api$DownloadLocalFilename$decoder,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'link_url',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'link_url_prefix',
					$elm$json$Json$Decode$string,
					$elm$json$Json$Decode$succeed($author$project$Api$CloudWebDownloadLinkFullData$CloudWebDownloadLinkFullData))))));
var $author$project$Api$CloudWebDownloadLinkFullDataPartial$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'unCloudWebDownloadLinkFullDataPartial',
	$author$project$Api$CloudWebDownloadLinkFullData$decoder,
	$elm$json$Json$Decode$succeed($author$project$Api$CloudWebDownloadLinkFullDataPartial$CloudWebDownloadLinkFullDataPartial));
var $author$project$Api$N96RecordingBlockMetadata$N96RecordingBlockMetadata = function (rm_n80) {
	return function (rm_u16) {
		return function (rm_ts32) {
			return function (rm_nr32) {
				return function (rm_node_time0) {
					return function (rm_node_time_n) {
						return function (rm_time_range_norm) {
							return function (rm_n_samples) {
								return function (rm_n_samples_cum) {
									return function (rm_sample_idx_start) {
										return function (rm_sample_idx_end) {
											return {fP: rm_n80, fQ: rm_n_samples, fR: rm_n_samples_cum, fS: rm_node_time0, fT: rm_node_time_n, fU: rm_nr32, fV: rm_sample_idx_end, fW: rm_sample_idx_start, fX: rm_time_range_norm, fY: rm_ts32, fZ: rm_u16};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Api$CloudSampleCount48$CloudSampleCount48 = function (unCloudSampleCount48) {
	return {gB: unCloudSampleCount48};
};
var $author$project$Api$CloudSampleCount48$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'unCloudSampleCount48',
	$elm$json$Json$Decode$int,
	$elm$json$Json$Decode$succeed($author$project$Api$CloudSampleCount48$CloudSampleCount48));
var $author$project$Api$N96MsgUpIdx32Recording$N96MsgUpIdx32Recording = function (un_n_r32) {
	return {g0: un_n_r32};
};
var $author$project$Api$N96MsgUpIdx32Recording$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'_unNR32',
	$elm$json$Json$Decode$int,
	$elm$json$Json$Decode$succeed($author$project$Api$N96MsgUpIdx32Recording$N96MsgUpIdx32Recording));
var $author$project$Api$NodePhyId80$NodePhyId80 = $elm$core$Basics$identity;
var $author$project$Api$NodePhyId80$decoder = A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $author$project$ElmCommon$Std$decodeBigIntHex);
var $author$project$Api$NodeTimeSession32$NodeTimeSession32 = $elm$core$Basics$identity;
var $author$project$Api$NodeTimeSession32$decoder = A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $elm$json$Json$Decode$int);
var $author$project$Api$NodeTimeUs48$NodeTimeUs48 = function (un_node_time_us48) {
	return {g1: un_node_time_us48};
};
var $author$project$Api$NodeTimeUs48$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'unNodeTimeUs48',
	$elm$json$Json$Decode$int,
	$elm$json$Json$Decode$succeed($author$project$Api$NodeTimeUs48$NodeTimeUs48));
var $author$project$Api$SampleIdxCloud48$SampleIdxCloud48 = function (un_sample_idx_cloud64) {
	return {g2: un_sample_idx_cloud64};
};
var $author$project$Api$SampleIdxCloud48$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'_unSampleIdxCloud64',
	$elm$json$Json$Decode$int,
	$elm$json$Json$Decode$succeed($author$project$Api$SampleIdxCloud48$SampleIdxCloud48));
var $author$project$Api$UpCtxId16Data$UpCtxId16Data = $elm$core$Basics$identity;
var $author$project$Api$UpCtxId16Data$decoder = A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $elm$json$Json$Decode$int);
var $elm$json$Json$Decode$index = _Json_decodeIndex;
var $elm$json$Json$Decode$nullable = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder)
			]));
};
var $author$project$Api$N96RecordingBlockMetadata$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'_rmSampleIdxEnd',
	$author$project$Api$SampleIdxCloud48$decoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'_rmSampleIdxStart',
		$author$project$Api$SampleIdxCloud48$decoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'_rmNSamplesCum',
			$author$project$Api$CloudSampleCount48$decoder,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'_rmNSamples',
				$author$project$Api$CloudSampleCount48$decoder,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'_rmTimeRangeNorm',
					A3(
						$elm$json$Json$Decode$map2,
						$elm$core$Tuple$pair,
						A2(
							$elm$json$Json$Decode$index,
							0,
							$elm$json$Json$Decode$nullable($rtfeldman$elm_iso8601_date_strings$Iso8601$decoder)),
						A2(
							$elm$json$Json$Decode$index,
							1,
							$elm$json$Json$Decode$nullable($rtfeldman$elm_iso8601_date_strings$Iso8601$decoder))),
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'_rmNodeTimeN',
						$author$project$Api$NodeTimeUs48$decoder,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'_rmNodeTime0',
							$author$project$Api$NodeTimeUs48$decoder,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'_rmNr32',
								$author$project$Api$N96MsgUpIdx32Recording$decoder,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'_rmTs32',
									$author$project$Api$NodeTimeSession32$decoder,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'_rmU16',
										$author$project$Api$UpCtxId16Data$decoder,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'_rmN80',
											$author$project$Api$NodePhyId80$decoder,
											$elm$json$Json$Decode$succeed($author$project$Api$N96RecordingBlockMetadata$N96RecordingBlockMetadata))))))))))));
var $author$project$Api$SensorLocalIndex16$SensorLocalIndex16 = function (unSensorLocalIndex16) {
	return {gP: unSensorLocalIndex16};
};
var $author$project$Api$SensorLocalIndex16$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'unSensorLocalIndex16',
	$elm$json$Json$Decode$int,
	$elm$json$Json$Decode$succeed($author$project$Api$SensorLocalIndex16$SensorLocalIndex16));
var $author$project$Api$CloudMetadataN96PartialDownloads$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'partial_downloads',
	$elm$json$Json$Decode$list(
		A3(
			$elm$json$Json$Decode$map2,
			$elm$core$Tuple$pair,
			A2($elm$json$Json$Decode$index, 0, $author$project$Api$N96RecordingBlockMetadata$decoder),
			A2(
				$elm$json$Json$Decode$index,
				1,
				$elm$json$Json$Decode$list(
					A3(
						$elm$json$Json$Decode$map2,
						$elm$core$Tuple$pair,
						A2($elm$json$Json$Decode$index, 0, $author$project$Api$SensorLocalIndex16$decoder),
						A2(
							$elm$json$Json$Decode$index,
							1,
							$elm$json$Json$Decode$list($author$project$Api$CloudWebDownloadLinkFullDataPartial$decoder))))))),
	$elm$json$Json$Decode$succeed($author$project$Api$CloudMetadataN96PartialDownloads$CloudMetadataN96PartialDownloads));
var $author$project$Api$CloudMetadataN96Short$CloudMetadataN96Short = F8(
	function (n96meta_short_n96, n96meta_short_n96_n80, n96meta_short_n96_u16, n96meta_short_sensors, n96meta_short_n96_comment, n96meta_short_n96_desc_user, n96meta_short_n96_ishidden, n96meta_short_status_details) {
		return {e2: n96meta_short_n96, e3: n96meta_short_n96_comment, e4: n96meta_short_n96_desc_user, e5: n96meta_short_n96_ishidden, e6: n96meta_short_n96_n80, e7: n96meta_short_n96_u16, e8: n96meta_short_sensors, e9: n96meta_short_status_details};
	});
var $author$project$Api$CloudMetadataN96Sensor$CloudMetadataN96Sensor = function (s104) {
	return function (s104_meta_s8) {
		return function (s104_meta_n96) {
			return function (sensor_desc) {
				return function (sensor_datatype) {
					return function (sensor_sample_period_us) {
						return function (sensor_latest_recorded_value) {
							return function (sensor_unit) {
								return function (sensor_total_samples) {
									return function (sensor_status_details) {
										return {f$: s104, f0: s104_meta_n96, f1: s104_meta_s8, f5: sensor_datatype, f6: sensor_desc, f7: sensor_latest_recorded_value, f8: sensor_sample_period_us, f9: sensor_status_details, ga: sensor_total_samples, gb: sensor_unit};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Api$CloudMetadataN96SensorStatusDetails$CloudMetadataN96SensorClosed_ = function (a) {
	return {$: 1, a: a};
};
var $author$project$Api$CloudMetadataN96SensorStatusDetails$CloudMetadataN96SensorError_ = function (a) {
	return {$: 2, a: a};
};
var $author$project$Api$CloudMetadataN96SensorStatusDetails$CloudMetadataN96SensorOpen_ = function (a) {
	return {$: 0, a: a};
};
var $author$project$Api$CloudMetadataN96SensorStatusDetailsComplete$CloudMetadataN96SensorStatusDetailsComplete = function (downloads_complete) {
	return {dK: downloads_complete};
};
var $author$project$Api$CloudWebDownloadLinkFullDataComplete$CloudWebDownloadLinkFullDataComplete = function (un_cloud_web_download_link_full_data_complete) {
	return {gX: un_cloud_web_download_link_full_data_complete};
};
var $author$project$Api$CloudWebDownloadLinkFullDataComplete$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'unCloudWebDownloadLinkFullDataComplete',
	$author$project$Api$CloudWebDownloadLinkFullData$decoder,
	$elm$json$Json$Decode$succeed($author$project$Api$CloudWebDownloadLinkFullDataComplete$CloudWebDownloadLinkFullDataComplete));
var $author$project$Api$CloudMetadataN96SensorStatusDetailsComplete$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'downloads_complete',
	$elm$json$Json$Decode$list($author$project$Api$CloudWebDownloadLinkFullDataComplete$decoder),
	$elm$json$Json$Decode$succeed($author$project$Api$CloudMetadataN96SensorStatusDetailsComplete$CloudMetadataN96SensorStatusDetailsComplete));
var $author$project$Api$CloudMetadataN96SensorStatusDetailsError$CloudMetadataN96SensorStatusDetailsError = function (error_msg) {
	return {d3: error_msg};
};
var $author$project$Api$CloudMetadataN96SensorStatusDetailsError$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'error_msg',
	$elm$json$Json$Decode$string,
	$elm$json$Json$Decode$succeed($author$project$Api$CloudMetadataN96SensorStatusDetailsError$CloudMetadataN96SensorStatusDetailsError));
var $author$project$Api$CloudMetadataN96SensorStatusDetailsOpen$CloudMetadataN96SensorStatusDetailsOpen = 0;
var $author$project$Api$CloudMetadataN96SensorStatusDetailsOpen$decoder = $elm$json$Json$Decode$succeed(0);
var $author$project$Api$CloudMetadataN96SensorStatusDetails$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (a) {
		switch (a) {
			case 'CloudMetadataN96SensorOpen_':
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'contents',
					$author$project$Api$CloudMetadataN96SensorStatusDetailsOpen$decoder,
					$elm$json$Json$Decode$succeed($author$project$Api$CloudMetadataN96SensorStatusDetails$CloudMetadataN96SensorOpen_));
			case 'CloudMetadataN96SensorClosed_':
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'contents',
					$author$project$Api$CloudMetadataN96SensorStatusDetailsComplete$decoder,
					$elm$json$Json$Decode$succeed($author$project$Api$CloudMetadataN96SensorStatusDetails$CloudMetadataN96SensorClosed_));
			case 'CloudMetadataN96SensorError_':
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'contents',
					$author$project$Api$CloudMetadataN96SensorStatusDetailsError$decoder,
					$elm$json$Json$Decode$succeed($author$project$Api$CloudMetadataN96SensorStatusDetails$CloudMetadataN96SensorError_));
			default:
				return $elm$json$Json$Decode$fail('No matching constructor');
		}
	},
	A2($elm$json$Json$Decode$field, 'tag', $elm$json$Json$Decode$string));
var $author$project$Api$NodeUpCtxSensorId104$NodeUpCtxSensorId104 = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Api$NodeUpCtxSensorId104$decoder = A2(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
	A2($elm$json$Json$Decode$index, 1, $author$project$Api$SensorLocalIndex16$decoder),
	A2(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
		A2($elm$json$Json$Decode$index, 0, $author$project$Api$NodeAndUpCtxId96Data$decoder),
		$elm$json$Json$Decode$succeed($author$project$Api$NodeUpCtxSensorId104$NodeUpCtxSensorId104)));
var $author$project$Api$RawSensorReadingFloat$RawSensorReadingFloat = function (unRawSensorReadingFloat) {
	return {gN: unRawSensorReadingFloat};
};
var $author$project$Api$RawSensorReadingFloat$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'unRawSensorReadingFloat',
	$elm$json$Json$Decode$float,
	$elm$json$Json$Decode$succeed($author$project$Api$RawSensorReadingFloat$RawSensorReadingFloat));
var $author$project$Api$SamplePeriodUs32$SamplePeriodUs32 = $elm$core$Basics$identity;
var $author$project$Api$SamplePeriodUs32$decoder = A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $elm$json$Json$Decode$int);
var $author$project$Api$SensorDataType$SensorDataType = function (un_sensor_data_type) {
	return {g3: un_sensor_data_type};
};
var $author$project$Api$SensorDataType$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'unSensorDataType',
	$elm$json$Json$Decode$int,
	$elm$json$Json$Decode$succeed($author$project$Api$SensorDataType$SensorDataType));
var $author$project$Api$SensorLabelShort$SensorLabelShort = function (unSensorLabelShort) {
	return {gO: unSensorLabelShort};
};
var $author$project$Api$SensorLabelShort$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'unSensorLabelShort',
	$elm$json$Json$Decode$string,
	$elm$json$Json$Decode$succeed($author$project$Api$SensorLabelShort$SensorLabelShort));
var $author$project$Api$CloudMetadataN96Sensor$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'sensor_status_details',
	$author$project$Api$CloudMetadataN96SensorStatusDetails$decoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'sensor_total_samples',
		$author$project$Api$CloudSampleCount48$decoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'sensor_unit',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'sensor_latest_recorded_value',
				$elm$json$Json$Decode$nullable($author$project$Api$RawSensorReadingFloat$decoder),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'sensor_sample_period_us',
					$author$project$Api$SamplePeriodUs32$decoder,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'sensor_datatype',
						$author$project$Api$SensorDataType$decoder,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'sensor_desc',
							$author$project$Api$SensorLabelShort$decoder,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								's104Meta_n96',
								$author$project$Api$NodeAndUpCtxId96Data$decoder,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									's104Meta_s8',
									$author$project$Api$SensorLocalIndex16$decoder,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										's104',
										$author$project$Api$NodeUpCtxSensorId104$decoder,
										$elm$json$Json$Decode$succeed($author$project$Api$CloudMetadataN96Sensor$CloudMetadataN96Sensor)))))))))));
var $author$project$Api$CloudMetadataN96StatusDetails$CloudMetadataN96Closed_ = function (a) {
	return {$: 1, a: a};
};
var $author$project$Api$CloudMetadataN96StatusDetails$CloudMetadataN96Error_ = function (a) {
	return {$: 2, a: a};
};
var $author$project$Api$CloudMetadataN96StatusDetails$CloudMetadataN96Open_ = function (a) {
	return {$: 0, a: a};
};
var $author$project$Api$CloudMetadataN96StatusDetailsComplete$CloudMetadataN96StatusDetailsComplete = F4(
	function (n96metaclosed_start_time, n96metaclosed_end_time, n96metaclosed_total_parcels, n96metaclosed_n_bytes_total) {
		return {fa: n96metaclosed_end_time, fb: n96metaclosed_n_bytes_total, fc: n96metaclosed_start_time, fd: n96metaclosed_total_parcels};
	});
var $author$project$Api$N96ByteCount$N96ByteCount = function (un_n96_byte_count) {
	return {g$: un_n96_byte_count};
};
var $author$project$Api$CloudByteCount48$CloudByteCount48 = function (unCloudByteCount48) {
	return {gz: unCloudByteCount48};
};
var $author$project$Api$CloudByteCount48$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'unCloudByteCount48',
	$elm$json$Json$Decode$int,
	$elm$json$Json$Decode$succeed($author$project$Api$CloudByteCount48$CloudByteCount48));
var $author$project$Api$N96ByteCount$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'_unN96ByteCount',
	$author$project$Api$CloudByteCount48$decoder,
	$elm$json$Json$Decode$succeed($author$project$Api$N96ByteCount$N96ByteCount));
var $author$project$Api$ParcelUpIdx32Parcel$ParcelUpIdx32Parcel = function (unC32Parcel) {
	return {gy: unC32Parcel};
};
var $author$project$Api$ParcelUpIdx32Parcel$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'unC32Parcel',
	$author$project$Api$ParcelMsgUpIdx32$decoder,
	$elm$json$Json$Decode$succeed($author$project$Api$ParcelUpIdx32Parcel$ParcelUpIdx32Parcel));
var $author$project$Api$CloudMetadataN96StatusDetailsComplete$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'_n96metaclosed_n_bytes_total',
	$author$project$Api$N96ByteCount$decoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'_n96metaclosed_total_parcels',
		$author$project$Api$ParcelUpIdx32Parcel$decoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'_n96metaclosed_end_time',
			$elm$json$Json$Decode$nullable($rtfeldman$elm_iso8601_date_strings$Iso8601$decoder),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'_n96metaclosed_start_time',
				$elm$json$Json$Decode$nullable($rtfeldman$elm_iso8601_date_strings$Iso8601$decoder),
				$elm$json$Json$Decode$succeed($author$project$Api$CloudMetadataN96StatusDetailsComplete$CloudMetadataN96StatusDetailsComplete)))));
var $author$project$Api$CloudMetadataN96StatusDetailsError$CloudMetadataN96StatusDetailsError = F3(
	function (err, n96metaerror_total_parcels, n96metaerror_n_bytes_total) {
		return {d1: err, fe: n96metaerror_n_bytes_total, ff: n96metaerror_total_parcels};
	});
var $author$project$Api$N96DbErrorStatus$N96DbErrorStatusBadUploadOrdering = {$: 3};
var $author$project$Api$N96DbErrorStatus$N96DbErrorStatusBlockedToUnblacklist = {$: 2};
var $author$project$Api$N96DbErrorStatus$N96DbErrorStatusInvalidUpload = {$: 4};
var $author$project$Api$N96DbErrorStatus$N96DbErrorStatusN80Error = function (a) {
	return {$: 0, a: a};
};
var $author$project$Api$N96DbErrorStatus$N96DbErrorStatusNoRecordings = {$: 1};
var $author$project$Api$N96DbErrorStatus$N96DbErrorStatusParceliRecordingDecodeFail = function (a) {
	return {$: 5, a: a};
};
var $author$project$Api$N96DbErrorStatus$N96DbErrorStatusString = function (a) {
	return {$: 6, a: a};
};
var $author$project$Api$N80DbErrorStatus$N80DbErrorStatusCustomError = $elm$core$Basics$identity;
var $author$project$Api$N80DbErrorStatus$decoder = A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $elm$json$Json$Decode$string);
var $author$project$Api$RecordingDataCompleteBinaryBlobDecodeError$BinaryBlobDecodeError = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $author$project$Api$RecordingDataCompleteBinaryBlobDecodeError$BinaryBlobDecodeTrailingBytes = F3(
	function (a, b, c) {
		return {$: 1, a: a, b: b, c: c};
	});
var $author$project$Api$AesonFailBytes$AesonFailBytes = F3(
	function (fail_bytes_, fail_offset, fail_errmsg) {
		return {hM: fail_bytes_, hN: fail_errmsg, hO: fail_offset};
	});
var $author$project$Api$AesonFailDetails$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'fail_errmsg',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'fail_offset',
		$elm$json$Json$Decode$int,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'fail_bytes_',
			$elm$json$Json$Decode$string,
			$elm$json$Json$Decode$succeed($author$project$Api$AesonFailBytes$AesonFailBytes))));
var $author$project$Api$RecordingDataCompleteBinaryBlobDecodeError$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (a) {
		switch (a) {
			case 'BinaryBlobDecodeError':
				return A2(
					$elm$json$Json$Decode$field,
					'contents',
					A2(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
						A2($elm$json$Json$Decode$index, 2, $author$project$Api$AesonFailDetails$decoder),
						A2(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
							A2($elm$json$Json$Decode$index, 1, $elm$json$Json$Decode$string),
							A2(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
								A2($elm$json$Json$Decode$index, 0, $elm$json$Json$Decode$string),
								$elm$json$Json$Decode$succeed($author$project$Api$RecordingDataCompleteBinaryBlobDecodeError$BinaryBlobDecodeError)))));
			case 'BinaryBlobDecodeTrailingBytes':
				return A2(
					$elm$json$Json$Decode$field,
					'contents',
					A2(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
						A2($elm$json$Json$Decode$index, 2, $elm$json$Json$Decode$string),
						A2(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
							A2($elm$json$Json$Decode$index, 1, $elm$json$Json$Decode$string),
							A2(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
								A2($elm$json$Json$Decode$index, 0, $elm$json$Json$Decode$string),
								$elm$json$Json$Decode$succeed($author$project$Api$RecordingDataCompleteBinaryBlobDecodeError$BinaryBlobDecodeTrailingBytes)))));
			default:
				return $elm$json$Json$Decode$fail('No matching constructor');
		}
	},
	A2($elm$json$Json$Decode$field, 'tag', $elm$json$Json$Decode$string));
var $author$project$Api$N96DbErrorStatus$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (a) {
		switch (a) {
			case 'N96DbErrorStatusN80Error':
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'contents',
					$author$project$Api$N80DbErrorStatus$decoder,
					$elm$json$Json$Decode$succeed($author$project$Api$N96DbErrorStatus$N96DbErrorStatusN80Error));
			case 'N96DbErrorStatusNoRecordings':
				return $elm$json$Json$Decode$succeed($author$project$Api$N96DbErrorStatus$N96DbErrorStatusNoRecordings);
			case 'N96DbErrorStatusBlockedToUnblacklist':
				return $elm$json$Json$Decode$succeed($author$project$Api$N96DbErrorStatus$N96DbErrorStatusBlockedToUnblacklist);
			case 'N96DbErrorStatusBadUploadOrdering':
				return $elm$json$Json$Decode$succeed($author$project$Api$N96DbErrorStatus$N96DbErrorStatusBadUploadOrdering);
			case 'N96DbErrorStatusInvalidUpload':
				return $elm$json$Json$Decode$succeed($author$project$Api$N96DbErrorStatus$N96DbErrorStatusInvalidUpload);
			case 'N96DbErrorStatusParceliRecordingDecodeFail':
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'contents',
					$author$project$Api$RecordingDataCompleteBinaryBlobDecodeError$decoder,
					$elm$json$Json$Decode$succeed($author$project$Api$N96DbErrorStatus$N96DbErrorStatusParceliRecordingDecodeFail));
			case 'N96DbErrorStatusString':
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'contents',
					$elm$json$Json$Decode$string,
					$elm$json$Json$Decode$succeed($author$project$Api$N96DbErrorStatus$N96DbErrorStatusString));
			default:
				return $elm$json$Json$Decode$fail('No matching constructor');
		}
	},
	A2($elm$json$Json$Decode$field, 'tag', $elm$json$Json$Decode$string));
var $author$project$Api$CloudMetadataN96StatusDetailsError$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'_n96metaerror_n_bytes_total',
	$author$project$Api$N96ByteCount$decoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'_n96metaerror_total_parcels',
		$author$project$Api$ParcelUpIdx32Parcel$decoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'err',
			$author$project$Api$N96DbErrorStatus$decoder,
			$elm$json$Json$Decode$succeed($author$project$Api$CloudMetadataN96StatusDetailsError$CloudMetadataN96StatusDetailsError))));
var $author$project$Api$CloudMetadataN96StatusDetailsOpen$CloudMetadataN96StatusDetailsOpen = F5(
	function (n96metaopen_est_start_time, n96metaopen_est_latest_recorded_time, n96metaopen_last_upload_time, n96metaopen_latest_c32, n96metaopen_n_bytes) {
		return {fg: n96metaopen_est_latest_recorded_time, fh: n96metaopen_est_start_time, fi: n96metaopen_last_upload_time, fj: n96metaopen_latest_c32, fk: n96metaopen_n_bytes};
	});
var $author$project$Api$CloudMetadataN96StatusDetailsOpen$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'_n96metaopen_n_bytes',
	$author$project$Api$N96ByteCount$decoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'_n96metaopen_latest_c32',
		$author$project$Api$ParcelUpIdx32Parcel$decoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'_n96metaopen_last_upload_time',
			$elm$json$Json$Decode$nullable($rtfeldman$elm_iso8601_date_strings$Iso8601$decoder),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'_n96metaopen_est_latest_recorded_time',
				$elm$json$Json$Decode$nullable($rtfeldman$elm_iso8601_date_strings$Iso8601$decoder),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'_n96metaopen_est_start_time',
					$elm$json$Json$Decode$nullable($rtfeldman$elm_iso8601_date_strings$Iso8601$decoder),
					$elm$json$Json$Decode$succeed($author$project$Api$CloudMetadataN96StatusDetailsOpen$CloudMetadataN96StatusDetailsOpen))))));
var $author$project$Api$CloudMetadataN96StatusDetails$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (a) {
		switch (a) {
			case 'CloudMetadataN96Open_':
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'contents',
					$author$project$Api$CloudMetadataN96StatusDetailsOpen$decoder,
					$elm$json$Json$Decode$succeed($author$project$Api$CloudMetadataN96StatusDetails$CloudMetadataN96Open_));
			case 'CloudMetadataN96Closed_':
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'contents',
					$author$project$Api$CloudMetadataN96StatusDetailsComplete$decoder,
					$elm$json$Json$Decode$succeed($author$project$Api$CloudMetadataN96StatusDetails$CloudMetadataN96Closed_));
			case 'CloudMetadataN96Error_':
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'contents',
					$author$project$Api$CloudMetadataN96StatusDetailsError$decoder,
					$elm$json$Json$Decode$succeed($author$project$Api$CloudMetadataN96StatusDetails$CloudMetadataN96Error_));
			default:
				return $elm$json$Json$Decode$fail('No matching constructor');
		}
	},
	A2($elm$json$Json$Decode$field, 'tag', $elm$json$Json$Decode$string));
var $author$project$Api$N96Comment$N96Comment = function (unN96Comment) {
	return {gK: unN96Comment};
};
var $author$project$Api$N96Comment$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'unN96Comment',
	$elm$json$Json$Decode$string,
	$elm$json$Json$Decode$succeed($author$project$Api$N96Comment$N96Comment));
var $author$project$Api$N96DescUser$N96DescUser = function (unN96DescUser) {
	return {gL: unN96DescUser};
};
var $author$project$Api$N96DescUser$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'unN96DescUser',
	$elm$json$Json$Decode$string,
	$elm$json$Json$Decode$succeed($author$project$Api$N96DescUser$N96DescUser));
var $author$project$Api$N96IsHidden$N96IsHidden = function (unN96IsHidden) {
	return {gM: unN96IsHidden};
};
var $author$project$Api$N96IsHidden$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'unN96IsHidden',
	$elm$json$Json$Decode$bool,
	$elm$json$Json$Decode$succeed($author$project$Api$N96IsHidden$N96IsHidden));
var $author$project$Api$CloudMetadataN96Short$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'_n96meta_short_status_details',
	$author$project$Api$CloudMetadataN96StatusDetails$decoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'_n96meta_short_n96_ishidden',
		$author$project$Api$N96IsHidden$decoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'_n96meta_short_n96_desc_user',
			$elm$json$Json$Decode$nullable($author$project$Api$N96DescUser$decoder),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'_n96meta_short_n96_comment',
				$elm$json$Json$Decode$nullable($author$project$Api$N96Comment$decoder),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'_n96meta_short_sensors',
					$elm$json$Json$Decode$list($author$project$Api$CloudMetadataN96Sensor$decoder),
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'_n96meta_short_n96_u16',
						$author$project$Api$UpCtxId16Data$decoder,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'_n96meta_short_n96_n80',
							$author$project$Api$NodePhyId80$decoder,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'_n96meta_short_n96',
								$author$project$Api$NodeAndUpCtxId96Data$decoder,
								$elm$json$Json$Decode$succeed($author$project$Api$CloudMetadataN96Short$CloudMetadataN96Short)))))))));
var $author$project$Api$CloudMetadataN96Full$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'_n96meta_full_partial_downloads',
	$author$project$Api$CloudMetadataN96PartialDownloads$decoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'_n96meta_full_dev_details',
		$author$project$Api$CloudMetadataN96DevDetails$decoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'_n96meta_full_short',
			$author$project$Api$CloudMetadataN96Short$decoder,
			$elm$json$Json$Decode$succeed($author$project$Api$CloudMetadataN96Full$CloudMetadataN96Full))));
var $author$project$Api$ClientPageData$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (a) {
		switch (a) {
			case 'ClientPageDataShowShortN96sForN80':
				return A2(
					$elm$json$Json$Decode$field,
					'contents',
					A2(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
						A2(
							$elm$json$Json$Decode$index,
							1,
							$elm$json$Json$Decode$list($author$project$Api$CloudMetadataN96Short$decoder)),
						A2(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
							A2($elm$json$Json$Decode$index, 0, $author$project$Api$NodePhyId80$decoder),
							$elm$json$Json$Decode$succeed($author$project$Api$ClientPageData$ClientPageDataShowShortN96sForN80))));
			case 'ClientPageDataShowFullDetailsForN96':
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'contents',
					$elm$json$Json$Decode$nullable($author$project$Api$CloudMetadataN96Full$decoder),
					$elm$json$Json$Decode$succeed($author$project$Api$ClientPageData$ClientPageDataShowFullDetailsForN96));
			case 'ClientPageDataShowNotifications':
				return $elm$json$Json$Decode$succeed($author$project$Api$ClientPageData$ClientPageDataShowNotifications);
			default:
				return $elm$json$Json$Decode$fail('No matching constructor');
		}
	},
	A2($elm$json$Json$Decode$field, 'tag', $elm$json$Json$Decode$string));
var $author$project$Api$UserCloudAccessInfoElm$UserCloudAccessInfoElm = F5(
	function (elmUserGroupInfo, elmVisibleUsers, elm_can_create_user, elm_can_create_n80group, elm_can_create_usergroup) {
		return {dW: elmUserGroupInfo, dX: elmVisibleUsers, dY: elm_can_create_n80group, dZ: elm_can_create_user, d_: elm_can_create_usergroup};
	});
var $author$project$Api$CloudMetadataUser$CloudMetadataUser = F3(
	function (cu_email, cu_name, cu_type) {
		return {dE: cu_email, dF: cu_name, dG: cu_type};
	});
var $author$project$Api$UserEmail$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'unUserEmail',
	$elm$json$Json$Decode$string,
	$elm$json$Json$Decode$succeed($author$project$Api$UserEmail$UserEmail));
var $author$project$Api$UserName$UserName = function (unUserName) {
	return {gV: unUserName};
};
var $author$project$Api$UserName$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'unUserName',
	$elm$json$Json$Decode$string,
	$elm$json$Json$Decode$succeed($author$project$Api$UserName$UserName));
var $author$project$Api$UserType$UserTypeAdmin = 2;
var $author$project$Api$UserType$UserTypePublic = 0;
var $author$project$Api$UserType$UserTypeUser = 1;
var $author$project$Api$UserType$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (a) {
		switch (a) {
			case 'UserTypePublic':
				return $elm$json$Json$Decode$succeed(0);
			case 'UserTypeUser':
				return $elm$json$Json$Decode$succeed(1);
			case 'UserTypeAdmin':
				return $elm$json$Json$Decode$succeed(2);
			default:
				return $elm$json$Json$Decode$fail('No matching constructor');
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Api$CloudMetadataUser$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'_cu_type',
	$author$project$Api$UserType$decoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'_cu_name',
		$author$project$Api$UserName$decoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'_cu_email',
			$author$project$Api$UserEmail$decoder,
			$elm$json$Json$Decode$succeed($author$project$Api$CloudMetadataUser$CloudMetadataUser))));
var $author$project$Api$UserCloudAccessInfoGroupsElm$UserCloudAccessInfoGroupsElm = F3(
	function (elmRawUserGroups, elmRawElmN80Groups, elmResolvedN80Access) {
		return {dT: elmRawElmN80Groups, dU: elmRawUserGroups, dV: elmResolvedN80Access};
	});
var $author$project$Api$AccessLevel$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (a) {
		switch (a) {
			case 'AccessLevelNone':
				return $elm$json$Json$Decode$succeed(0);
			case 'AccessLevelReadOnly':
				return $elm$json$Json$Decode$succeed(1);
			case 'AccessLevelReadWrite':
				return $elm$json$Json$Decode$succeed(2);
			case 'AccessLevelAdmin':
				return $elm$json$Json$Decode$succeed(3);
			default:
				return $elm$json$Json$Decode$fail('No matching constructor');
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Api$AccessGrantedForUser$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'unAccessGrantedForUser',
	$author$project$Api$AccessLevel$decoder,
	$elm$json$Json$Decode$succeed($author$project$Api$AccessGrantedForUser$AccessGrantedForUser));
var $author$project$Api$AccessLevelGrantedForN80Group$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'unAccessLevelGrantedForN80Group',
	$author$project$Api$AccessLevel$decoder,
	$elm$json$Json$Decode$succeed($author$project$Api$AccessLevelGrantedForN80Group$AccessLevelGrantedForN80Group));
var $author$project$Api$CloudMetadataN80Group$CloudMetadataN80Group = F5(
	function (cmn80g_groupid, cmn80g_groupname, cmn80g_groupowner, cmn80g_access_public, cmn80g_members) {
		return {dg: cmn80g_access_public, dh: cmn80g_groupid, di: cmn80g_groupname, dj: cmn80g_groupowner, dk: cmn80g_members};
	});
var $author$project$Api$AccessLevelDefaultN80GroupPublic$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'unAccessLevelDefaultN80GroupPublic',
	$author$project$Api$AccessLevel$decoder,
	$elm$json$Json$Decode$succeed($author$project$Api$AccessLevelDefaultN80GroupPublic$AccessLevelDefaultN80GroupPublic));
var $author$project$Api$N80GroupId$N80GroupId = function (unN80GroupId) {
	return {gF: unN80GroupId};
};
var $author$project$Api$N80GroupId$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'unN80GroupId',
	$elm$json$Json$Decode$string,
	$elm$json$Json$Decode$succeed($author$project$Api$N80GroupId$N80GroupId));
var $author$project$Api$N80GroupName$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'unN80GroupName',
	$elm$json$Json$Decode$string,
	$elm$json$Json$Decode$succeed($author$project$Api$N80GroupName$N80GroupName));
var $author$project$Api$N80GroupOwnerEmail$N80GroupOwnerEmail = function (unN80GroupOwnerEmail) {
	return {gH: unN80GroupOwnerEmail};
};
var $author$project$Api$N80GroupOwnerEmail$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'unN80GroupOwnerEmail',
	$author$project$Api$UserEmail$decoder,
	$elm$json$Json$Decode$succeed($author$project$Api$N80GroupOwnerEmail$N80GroupOwnerEmail));
var $author$project$Api$CloudMetadataN80Group$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'_cmn80g_members',
	$elm$json$Json$Decode$list($author$project$Api$NodePhyId80$decoder),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'_cmn80g_access_public',
		$author$project$Api$AccessLevelDefaultN80GroupPublic$decoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'_cmn80g_groupowner',
			$author$project$Api$N80GroupOwnerEmail$decoder,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'_cmn80g_groupname',
				$author$project$Api$N80GroupName$decoder,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'_cmn80g_groupid',
					$author$project$Api$N80GroupId$decoder,
					$elm$json$Json$Decode$succeed($author$project$Api$CloudMetadataN80Group$CloudMetadataN80Group))))));
var $author$project$Api$CloudMetadataUserGroup$CloudMetadataUserGroup = F5(
	function (cmd_groupid, cmd_groupname, cmd_groupowner, cmd_members, cmd_n80group_perms) {
		return {db: cmd_groupid, dc: cmd_groupname, dd: cmd_groupowner, de: cmd_members, df: cmd_n80group_perms};
	});
var $author$project$Api$UserGroupId$UserGroupId = function (unUserGroupId) {
	return {gS: unUserGroupId};
};
var $author$project$Api$UserGroupId$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'unUserGroupId',
	$elm$json$Json$Decode$string,
	$elm$json$Json$Decode$succeed($author$project$Api$UserGroupId$UserGroupId));
var $author$project$Api$UserGroupMembershipType$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (a) {
		switch (a) {
			case 'UserGroupMembershipTypeStandard':
				return $elm$json$Json$Decode$succeed(0);
			case 'UserGroupMembershipTypeAdmin':
				return $elm$json$Json$Decode$succeed(1);
			default:
				return $elm$json$Json$Decode$fail('No matching constructor');
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Api$UserGroupName$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'unUserGroupName',
	$elm$json$Json$Decode$string,
	$elm$json$Json$Decode$succeed($author$project$Api$UserGroupName$UserGroupName));
var $author$project$Api$UserGroupOwnerEmail$UserGroupOwnerEmail = function (unUserGroupOwnerEmail) {
	return {gU: unUserGroupOwnerEmail};
};
var $author$project$Api$UserGroupOwnerEmail$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'unUserGroupOwnerEmail',
	$author$project$Api$UserEmail$decoder,
	$elm$json$Json$Decode$succeed($author$project$Api$UserGroupOwnerEmail$UserGroupOwnerEmail));
var $author$project$Api$CloudMetadataUserGroup$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'_cmd_n80group_perms',
	$elm$json$Json$Decode$list(
		A3(
			$elm$json$Json$Decode$map2,
			$elm$core$Tuple$pair,
			A2($elm$json$Json$Decode$index, 0, $author$project$Api$N80GroupId$decoder),
			A2($elm$json$Json$Decode$index, 1, $author$project$Api$AccessLevelGrantedForN80Group$decoder))),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'_cmd_members',
		$elm$json$Json$Decode$list(
			A3(
				$elm$json$Json$Decode$map2,
				$elm$core$Tuple$pair,
				A2($elm$json$Json$Decode$index, 0, $author$project$Api$UserEmail$decoder),
				A2($elm$json$Json$Decode$index, 1, $author$project$Api$UserGroupMembershipType$decoder))),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'_cmd_groupowner',
			$author$project$Api$UserGroupOwnerEmail$decoder,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'_cmd_groupname',
				$author$project$Api$UserGroupName$decoder,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'_cmd_groupid',
					$author$project$Api$UserGroupId$decoder,
					$elm$json$Json$Decode$succeed($author$project$Api$CloudMetadataUserGroup$CloudMetadataUserGroup))))));
var $author$project$Api$UserCloudAccessInfoGroupsElm$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'elmResolvedN80Access',
	$elm$json$Json$Decode$list(
		A3(
			$elm$json$Json$Decode$map2,
			$elm$core$Tuple$pair,
			A2($elm$json$Json$Decode$index, 0, $author$project$Api$NodePhyId80$decoder),
			A2($elm$json$Json$Decode$index, 1, $author$project$Api$AccessGrantedForUser$decoder))),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'elmRawElmN80Groups',
		$elm$json$Json$Decode$list(
			A3(
				$elm$json$Json$Decode$map2,
				$elm$core$Tuple$pair,
				A2($elm$json$Json$Decode$index, 0, $author$project$Api$N80GroupId$decoder),
				A2(
					$elm$json$Json$Decode$index,
					1,
					A3(
						$elm$json$Json$Decode$map2,
						$elm$core$Tuple$pair,
						A2($elm$json$Json$Decode$index, 0, $author$project$Api$CloudMetadataN80Group$decoder),
						A2($elm$json$Json$Decode$index, 1, $author$project$Api$AccessLevelGrantedForN80Group$decoder))))),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'elmRawUserGroups',
			$elm$json$Json$Decode$list(
				A3(
					$elm$json$Json$Decode$map2,
					$elm$core$Tuple$pair,
					A2($elm$json$Json$Decode$index, 0, $author$project$Api$UserGroupId$decoder),
					A2(
						$elm$json$Json$Decode$index,
						1,
						A3(
							$elm$json$Json$Decode$map2,
							$elm$core$Tuple$pair,
							A2($elm$json$Json$Decode$index, 0, $author$project$Api$CloudMetadataUserGroup$decoder),
							A2($elm$json$Json$Decode$index, 1, $author$project$Api$UserGroupMembershipType$decoder))))),
			$elm$json$Json$Decode$succeed($author$project$Api$UserCloudAccessInfoGroupsElm$UserCloudAccessInfoGroupsElm))));
var $author$project$Api$UserCloudAccessInfoElm$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'elm_can_create_usergroup',
	$elm$json$Json$Decode$bool,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'elm_can_create_n80group',
		$elm$json$Json$Decode$bool,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'elm_can_create_user',
			$elm$json$Json$Decode$bool,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'elmVisibleUsers',
				$elm$json$Json$Decode$list($author$project$Api$CloudMetadataUser$decoder),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'elmUserGroupInfo',
					$author$project$Api$UserCloudAccessInfoGroupsElm$decoder,
					$elm$json$Json$Decode$succeed($author$project$Api$UserCloudAccessInfoElm$UserCloudAccessInfoElm))))));
var $author$project$Api$WebsocketMsgToClientPayloadLocal$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (a) {
		switch (a) {
			case 'WebsocketMsgToClientPayloadLocalResponseSetClientPage':
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'contents',
					$author$project$Api$ClientPageData$decoder,
					$elm$json$Json$Decode$succeed($author$project$Api$WebsocketMsgToClientPayloadLocal$WebsocketMsgToClientPayloadLocalResponseSetClientPage));
			case 'WebsocketMsgToClientPayloadLocalNewUserCloudAccessInfoElm':
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'contents',
					$author$project$Api$UserCloudAccessInfoElm$decoder,
					$elm$json$Json$Decode$succeed($author$project$Api$WebsocketMsgToClientPayloadLocal$WebsocketMsgToClientPayloadLocalNewUserCloudAccessInfoElm));
			default:
				return $elm$json$Json$Decode$fail('No matching constructor');
		}
	},
	A2($elm$json$Json$Decode$field, 'tag', $elm$json$Json$Decode$string));
var $author$project$Api$WebsocketMsgToClientPayloadTrunk$WebsocketMsgToClientPayloadTrunkNewAdminFullUserAndGroupData = function (a) {
	return {$: 1, a: a};
};
var $author$project$Api$WebsocketMsgToClientPayloadTrunk$WebsocketMsgToClientPayloadTrunkNewLiveCacheDiffSet = function (a) {
	return {$: 0, a: a};
};
var $author$project$Api$WebsocketMsgToClientPayloadTrunk$WebsocketMsgToClientPayloadTrunkNewServerProcessingStatus = function (a) {
	return {$: 2, a: a};
};
var $author$project$Api$WebsocketMsgToClientPayloadTrunk$WebsocketMsgToClientPayloadTrunkNotification = function (a) {
	return {$: 4, a: a};
};
var $author$project$Api$WebsocketMsgToClientPayloadTrunk$WebsocketMsgToClientPayloadTrunkNotifyN96Change = function (a) {
	return {$: 3, a: a};
};
var $author$project$Api$AdminFullUserAndGroupData$AdminFullUserAndGroupData = F3(
	function (adminAuthAllUsers, adminAuthAllUserGroups, adminAuthAllN80Groups) {
		return {cX: adminAuthAllN80Groups, cY: adminAuthAllUserGroups, cZ: adminAuthAllUsers};
	});
var $author$project$Api$AdminFullUserAndGroupData$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'adminAuthAllN80Groups',
	$elm$json$Json$Decode$list($author$project$Api$CloudMetadataN80Group$decoder),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'adminAuthAllUserGroups',
		$elm$json$Json$Decode$list($author$project$Api$CloudMetadataUserGroup$decoder),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'adminAuthAllUsers',
			$elm$json$Json$Decode$list($author$project$Api$CloudMetadataUser$decoder),
			$elm$json$Json$Decode$succeed($author$project$Api$AdminFullUserAndGroupData$AdminFullUserAndGroupData))));
var $author$project$Api$ApiHttpPushNotifyApiMsgJSON$ApiHttpPushNotifyApiMsgJSON = $elm$core$Basics$identity;
var $author$project$Api$ApiHttpPushNotifyApiMsgJSON$decoder = A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $elm$json$Json$Decode$string);
var $author$project$Api$CloudMetadataLiveCacheDiffSet$CloudMetadataLiveCacheDiffSet = function (unCloudMetadataUpdates) {
	return {gA: unCloudMetadataUpdates};
};
var $author$project$Api$CloudMetadataLiveCacheDiff$CloudMetadataLiveCacheDiffUpdateN80Live = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Api$CloudMetadataN80Live$CloudMetadataN80Live = function (n80) {
	return function (n80_desc_user) {
		return function (n80_desc_simple) {
			return function (n80_ishidden) {
				return function (n80_last_upload_time) {
					return function (n80_last_n96meta) {
						return function (n80_parcelcount) {
							return function (n80_bytecount) {
								return function (n80_hw) {
									return function (n80_owner_email) {
										return function (n80_group_memberships) {
											return {eC: n80, eD: n80_bytecount, eE: n80_desc_simple, eF: n80_desc_user, eG: n80_group_memberships, eI: n80_hw, eP: n80_ishidden, eQ: n80_last_n96meta, eR: n80_last_upload_time, eW: n80_owner_email, eX: n80_parcelcount};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Api$CloudN80HwMetadata$CloudN80HwMetadata = F2(
	function (n80_hw_platform, n80_hw_instr) {
		return {eJ: n80_hw_instr, eK: n80_hw_platform};
	});
var $author$project$Api$CloudMetadataN80HwInstr$CloudMetadataN80HwInstr = F4(
	function (n80_instr_descshort, n80_instr_descfull, n80_instr_version, n80_instr_builddatetime) {
		return {eL: n80_instr_builddatetime, eM: n80_instr_descfull, eN: n80_instr_descshort, eO: n80_instr_version};
	});
var $author$project$Api$SemanticVersion$SemanticVersion = F3(
	function (major, minor, patch) {
		return {ey: major, ez: minor, fA: patch};
	});
var $author$project$Api$SemanticVersion$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'patch',
	$elm$json$Json$Decode$int,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'minor',
		$elm$json$Json$Decode$int,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'major',
			$elm$json$Json$Decode$int,
			$elm$json$Json$Decode$succeed($author$project$Api$SemanticVersion$SemanticVersion))));
var $author$project$Api$CloudMetadataN80HwInstr$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'n80_instr_builddatetime',
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'n80_instr_version',
		$elm$json$Json$Decode$nullable($author$project$Api$SemanticVersion$decoder),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'n80_instr_descfull',
			$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'n80_instr_descshort',
				$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
				$elm$json$Json$Decode$succeed($author$project$Api$CloudMetadataN80HwInstr$CloudMetadataN80HwInstr)))));
var $author$project$Api$CloudMetadataN80HwPlatform$CloudMetadataN80HwPlatform = F4(
	function (n80_node_platform, n80_node_buildtype, n80_node_version, n80_node_builddatetime) {
		return {eS: n80_node_builddatetime, eT: n80_node_buildtype, eU: n80_node_platform, eV: n80_node_version};
	});
var $author$project$Api$NodeBuildType$NodeBuildTypeDev = 3;
var $author$project$Api$NodeBuildType$NodeBuildTypeProd = 2;
var $author$project$Api$NodeBuildType$NodeBuildTypeTest = 4;
var $author$project$Api$NodeBuildType$NodeBuildTypeUndefined = 1;
var $author$project$Api$NodeBuildType$NodeBuildTypeUnknown = 0;
var $author$project$Api$NodeBuildType$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (a) {
		switch (a) {
			case 'NodeBuildTypeUnknown':
				return $elm$json$Json$Decode$succeed(0);
			case 'NodeBuildTypeUndefined':
				return $elm$json$Json$Decode$succeed(1);
			case 'NodeBuildTypeProd':
				return $elm$json$Json$Decode$succeed(2);
			case 'NodeBuildTypeDev':
				return $elm$json$Json$Decode$succeed(3);
			case 'NodeBuildTypeTest':
				return $elm$json$Json$Decode$succeed(4);
			default:
				return $elm$json$Json$Decode$fail('No matching constructor');
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Api$NodePlatform$NodePlatformEsp32V1 = 3;
var $author$project$Api$NodePlatform$NodePlatformLinuxDesktop = 2;
var $author$project$Api$NodePlatform$NodePlatformUndefined = 1;
var $author$project$Api$NodePlatform$NodePlatformUnknown = 0;
var $author$project$Api$NodePlatform$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (a) {
		switch (a) {
			case 'NodePlatformUnknown':
				return $elm$json$Json$Decode$succeed(0);
			case 'NodePlatformUndefined':
				return $elm$json$Json$Decode$succeed(1);
			case 'NodePlatformLinuxDesktop':
				return $elm$json$Json$Decode$succeed(2);
			case 'NodePlatformEsp32V1':
				return $elm$json$Json$Decode$succeed(3);
			default:
				return $elm$json$Json$Decode$fail('No matching constructor');
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Api$CloudMetadataN80HwPlatform$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'n80_node_builddatetime',
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'n80_node_version',
		$elm$json$Json$Decode$nullable($author$project$Api$SemanticVersion$decoder),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'n80_node_buildtype',
			$elm$json$Json$Decode$nullable($author$project$Api$NodeBuildType$decoder),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'n80_node_platform',
				$elm$json$Json$Decode$nullable($author$project$Api$NodePlatform$decoder),
				$elm$json$Json$Decode$succeed($author$project$Api$CloudMetadataN80HwPlatform$CloudMetadataN80HwPlatform)))));
var $author$project$Api$CloudN80HwMetadata$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'n80HwInstr',
	$elm$json$Json$Decode$nullable($author$project$Api$CloudMetadataN80HwInstr$decoder),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'n80HwPlatform',
		$elm$json$Json$Decode$nullable($author$project$Api$CloudMetadataN80HwPlatform$decoder),
		$elm$json$Json$Decode$succeed($author$project$Api$CloudN80HwMetadata$CloudN80HwMetadata)));
var $author$project$Api$N80ByteCount$N80ByteCount = function (un_n80_byte_count) {
	return {gZ: un_n80_byte_count};
};
var $author$project$Api$N80ByteCount$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'_unN80ByteCount',
	$author$project$Api$CloudByteCount48$decoder,
	$elm$json$Json$Decode$succeed($author$project$Api$N80ByteCount$N80ByteCount));
var $author$project$Api$N80DescSimple$N80DescSimple = function (unN80DescSimple) {
	return {gD: unN80DescSimple};
};
var $author$project$Api$N80DescSimple$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'unN80DescSimple',
	$elm$json$Json$Decode$string,
	$elm$json$Json$Decode$succeed($author$project$Api$N80DescSimple$N80DescSimple));
var $author$project$Api$N80DescUser$N80DescUser = function (unN80DescUser) {
	return {gE: unN80DescUser};
};
var $author$project$Api$N80DescUser$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'unN80DescUser',
	$elm$json$Json$Decode$string,
	$elm$json$Json$Decode$succeed($author$project$Api$N80DescUser$N80DescUser));
var $author$project$Api$N80IsHidden$N80IsHidden = function (unN80IsHidden) {
	return {gI: unN80IsHidden};
};
var $author$project$Api$N80IsHidden$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'unN80IsHidden',
	$elm$json$Json$Decode$bool,
	$elm$json$Json$Decode$succeed($author$project$Api$N80IsHidden$N80IsHidden));
var $author$project$Api$N80OwnerEmail$N80OwnerEmail = function (unN80OwnerEmail) {
	return {gJ: unN80OwnerEmail};
};
var $author$project$Api$N80OwnerEmail$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'unN80OwnerEmail',
	$author$project$Api$UserEmail$decoder,
	$elm$json$Json$Decode$succeed($author$project$Api$N80OwnerEmail$N80OwnerEmail));
var $author$project$Api$N80ParcelUpCount$N80ParcelUpCount = function (un_n80_parcel_up_count) {
	return {g_: un_n80_parcel_up_count};
};
var $author$project$Api$N80ParcelUpCount$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'_unN80ParcelUpCount',
	$elm$json$Json$Decode$int,
	$elm$json$Json$Decode$succeed($author$project$Api$N80ParcelUpCount$N80ParcelUpCount));
var $author$project$Api$CloudMetadataN80Live$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'n80_group_memberships',
	$elm$json$Json$Decode$list($author$project$Api$N80GroupId$decoder),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'n80_owner_email',
		$author$project$Api$N80OwnerEmail$decoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'n80_hw',
			$author$project$Api$CloudN80HwMetadata$decoder,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'n80_bytecount',
				$author$project$Api$N80ByteCount$decoder,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'n80_parcelcount',
					$author$project$Api$N80ParcelUpCount$decoder,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'n80_last_n96meta',
						$elm$json$Json$Decode$nullable($author$project$Api$CloudMetadataN96Short$decoder),
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'n80_last_upload_time',
							$elm$json$Json$Decode$nullable($rtfeldman$elm_iso8601_date_strings$Iso8601$decoder),
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'n80_ishidden',
								$author$project$Api$N80IsHidden$decoder,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'n80_desc_simple',
									$author$project$Api$N80DescSimple$decoder,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'n80_desc_user',
										$author$project$Api$N80DescUser$decoder,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'n80',
											$author$project$Api$NodePhyId80$decoder,
											$elm$json$Json$Decode$succeed($author$project$Api$CloudMetadataN80Live$CloudMetadataN80Live))))))))))));
var $author$project$Api$CloudMetadataLiveCacheDiff$decoder = A2(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
	A2($elm$json$Json$Decode$index, 1, $author$project$Api$CloudMetadataN80Live$decoder),
	A2(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
		A2($elm$json$Json$Decode$index, 0, $author$project$Api$NodePhyId80$decoder),
		$elm$json$Json$Decode$succeed($author$project$Api$CloudMetadataLiveCacheDiff$CloudMetadataLiveCacheDiffUpdateN80Live)));
var $author$project$Api$CloudMetadataLiveCacheDiffSet$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'unCloudMetadataUpdates',
	$elm$json$Json$Decode$list($author$project$Api$CloudMetadataLiveCacheDiff$decoder),
	$elm$json$Json$Decode$succeed($author$project$Api$CloudMetadataLiveCacheDiffSet$CloudMetadataLiveCacheDiffSet));
var $author$project$Api$CloudServerProcessingStatus$CloudServerProcessingStatus = function (status_timestamp) {
	return function (task_oneoff_queue_size) {
		return function (task_oneoff_ncompleted) {
			return function (cnt_parcel_up_ready_to_process) {
				return function (cnt_parcel_up_complete) {
					return function (cnt_parcel_up_rec_ready_to_process) {
						return function (cnt_parcel_up_rec_complete) {
							return function (cnt_parcel_up_rec_process_error) {
								return function (cnt_n96_status_open) {
									return function (cnt_n96_status_complete) {
										return function (cnt_n96_status_error) {
											return function (cnt_complete_local_file_status_cnt_not_requested) {
												return function (cnt_complete_local_file_status_cnt_requested) {
													return function (cnt_complete_local_file_status_cnt_in_progress) {
														return function (cnt_complete_local_file_status_cnt_complete) {
															return function (cnt_complete_local_file_status_cnt_error) {
																return function (cnt_partial_local_file_status_cnt_not_started) {
																	return function (cnt_partial_local_file_status_cnt_in_progress) {
																		return function (cnt_partial_local_file_status_cnt_complete) {
																			return function (cnt_partial_local_file_status_cnt_error) {
																				return function (blacklisted_n80s) {
																					return function (load_average_1min) {
																						return function (load_average_5min) {
																							return function (load_average_15min) {
																								return {c6: blacklisted_n80s, dl: cnt_complete_local_file_status_cnt_complete, dm: cnt_complete_local_file_status_cnt_error, dn: cnt_complete_local_file_status_cnt_in_progress, $7: cnt_complete_local_file_status_cnt_not_requested, dp: cnt_complete_local_file_status_cnt_requested, dq: cnt_n96_status_complete, dr: cnt_n96_status_error, ds: cnt_n96_status_open, dt: cnt_parcel_up_complete, du: cnt_parcel_up_ready_to_process, dv: cnt_parcel_up_rec_complete, dw: cnt_parcel_up_rec_process_error, dx: cnt_parcel_up_rec_ready_to_process, dy: cnt_partial_local_file_status_cnt_complete, dz: cnt_partial_local_file_status_cnt_error, dA: cnt_partial_local_file_status_cnt_in_progress, dB: cnt_partial_local_file_status_cnt_not_started, ev: load_average_15min, ew: load_average_1min, ex: load_average_5min, gg: status_timestamp, gj: task_oneoff_ncompleted, gk: task_oneoff_queue_size};
																							};
																						};
																					};
																				};
																			};
																		};
																	};
																};
															};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Api$TaskOneOffQueueSize$TaskOneOffQueueSize = function (un_task_one_off_queue_size) {
	return {g4: un_task_one_off_queue_size};
};
var $author$project$Api$TaskOneOffQueueSize$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'unTaskOneOffQueueSize',
	$elm$json$Json$Decode$int,
	$elm$json$Json$Decode$succeed($author$project$Api$TaskOneOffQueueSize$TaskOneOffQueueSize));
var $author$project$Api$CloudServerProcessingStatus$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'_load_average_15min',
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$float),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'_load_average_5min',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$float),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'_load_average_1min',
			$elm$json$Json$Decode$nullable($elm$json$Json$Decode$float),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'_blacklisted_n80s',
				$elm$json$Json$Decode$list(
					A3(
						$elm$json$Json$Decode$map2,
						$elm$core$Tuple$pair,
						A2($elm$json$Json$Decode$index, 0, $author$project$Api$NodePhyId80$decoder),
						A2($elm$json$Json$Decode$index, 1, $elm$json$Json$Decode$string))),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'_cntPartialLocalFileStatusCntError',
					$elm$json$Json$Decode$int,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'_cntPartialLocalFileStatusCntComplete',
						$elm$json$Json$Decode$int,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'_cntPartialLocalFileStatusCntInProgress',
							$elm$json$Json$Decode$int,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'_cntPartialLocalFileStatusCntNotStarted',
								$elm$json$Json$Decode$int,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'_cntCompleteLocalFileStatusCntError',
									$elm$json$Json$Decode$int,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'_cntCompleteLocalFileStatusCntComplete',
										$elm$json$Json$Decode$int,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'_cntCompleteLocalFileStatusCntInProgress',
											$elm$json$Json$Decode$int,
											A3(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
												'_cntCompleteLocalFileStatusCntRequested',
												$elm$json$Json$Decode$int,
												A3(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
													'_cntCompleteLocalFileStatusCntNotRequested',
													$elm$json$Json$Decode$int,
													A3(
														$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
														'_cntN96StatusError',
														$elm$json$Json$Decode$int,
														A3(
															$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
															'_cntN96StatusComplete',
															$elm$json$Json$Decode$int,
															A3(
																$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																'_cntN96StatusOpen',
																$elm$json$Json$Decode$int,
																A3(
																	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																	'_cntParcelUpRecProcessError',
																	$elm$json$Json$Decode$int,
																	A3(
																		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																		'_cntParcelUpRecComplete',
																		$elm$json$Json$Decode$int,
																		A3(
																			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																			'_cntParcelUpRecReadyToProcess',
																			$elm$json$Json$Decode$int,
																			A3(
																				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																				'_cntParcelUpComplete',
																				$elm$json$Json$Decode$int,
																				A3(
																					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																					'_cntParcelUpReadyToProcess',
																					$elm$json$Json$Decode$int,
																					A3(
																						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																						'_task_oneoff_ncompleted',
																						$elm$json$Json$Decode$int,
																						A3(
																							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																							'_task_oneoff_queue_size',
																							$author$project$Api$TaskOneOffQueueSize$decoder,
																							A3(
																								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																								'_status_timestamp',
																								$rtfeldman$elm_iso8601_date_strings$Iso8601$decoder,
																								$elm$json$Json$Decode$succeed($author$project$Api$CloudServerProcessingStatus$CloudServerProcessingStatus)))))))))))))))))))))))));
var $author$project$Api$WebsocketMsgToClientPayloadTrunk$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (a) {
		switch (a) {
			case 'WebsocketMsgToClientPayloadTrunkNewLiveCacheDiffSet':
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'contents',
					$author$project$Api$CloudMetadataLiveCacheDiffSet$decoder,
					$elm$json$Json$Decode$succeed($author$project$Api$WebsocketMsgToClientPayloadTrunk$WebsocketMsgToClientPayloadTrunkNewLiveCacheDiffSet));
			case 'WebsocketMsgToClientPayloadTrunkNewAdminFullUserAndGroupData':
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'contents',
					$author$project$Api$AdminFullUserAndGroupData$decoder,
					$elm$json$Json$Decode$succeed($author$project$Api$WebsocketMsgToClientPayloadTrunk$WebsocketMsgToClientPayloadTrunkNewAdminFullUserAndGroupData));
			case 'WebsocketMsgToClientPayloadTrunkNewServerProcessingStatus':
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'contents',
					$author$project$Api$CloudServerProcessingStatus$decoder,
					$elm$json$Json$Decode$succeed($author$project$Api$WebsocketMsgToClientPayloadTrunk$WebsocketMsgToClientPayloadTrunkNewServerProcessingStatus));
			case 'WebsocketMsgToClientPayloadTrunkNotifyN96Change':
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'contents',
					$author$project$Api$NodeAndUpCtxId96Data$decoder,
					$elm$json$Json$Decode$succeed($author$project$Api$WebsocketMsgToClientPayloadTrunk$WebsocketMsgToClientPayloadTrunkNotifyN96Change));
			case 'WebsocketMsgToClientPayloadTrunkNotification':
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'contents',
					$author$project$Api$ApiHttpPushNotifyApiMsgJSON$decoder,
					$elm$json$Json$Decode$succeed($author$project$Api$WebsocketMsgToClientPayloadTrunk$WebsocketMsgToClientPayloadTrunkNotification));
			default:
				return $elm$json$Json$Decode$fail('No matching constructor');
		}
	},
	A2($elm$json$Json$Decode$field, 'tag', $elm$json$Json$Decode$string));
var $author$project$Api$WsConnectResponseOKData$WsConnectResponseOKData = F4(
	function (initial_data, auth_user_metadata, auth_user_groups, auth_admin_data_) {
		return {c1: auth_admin_data_, c3: auth_user_groups, c4: auth_user_metadata, eg: initial_data};
	});
var $author$project$Api$WsConnectResponseOKData$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'auth_admin_data_',
	$elm$json$Json$Decode$nullable($author$project$Api$AdminFullUserAndGroupData$decoder),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'auth_user_groups',
		$author$project$Api$UserCloudAccessInfoElm$decoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'auth_user_metadata',
			$author$project$Api$CloudMetadataUser$decoder,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'initial_data',
				$author$project$Api$CloudMetadataLiveCacheDiffSet$decoder,
				$elm$json$Json$Decode$succeed($author$project$Api$WsConnectResponseOKData$WsConnectResponseOKData)))));
var $author$project$Api$WebsocketMsgToClient$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (a) {
		switch (a) {
			case 'WebsocketMsgToClientConnectResponseOK':
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'contents',
					$author$project$Api$WsConnectResponseOKData$decoder,
					$elm$json$Json$Decode$succeed($author$project$Api$WebsocketMsgToClient$WebsocketMsgToClientConnectResponseOK));
			case 'WebsocketMsgToClientConnectResponseFail':
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'contents',
					$elm$json$Json$Decode$string,
					$elm$json$Json$Decode$succeed($author$project$Api$WebsocketMsgToClient$WebsocketMsgToClientConnectResponseFail));
			case 'WebsocketMsgToClientPayloadTrunk_':
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'contents',
					$author$project$Api$WebsocketMsgToClientPayloadTrunk$decoder,
					$elm$json$Json$Decode$succeed($author$project$Api$WebsocketMsgToClient$WebsocketMsgToClientPayloadTrunk_));
			case 'WebsocketMsgToClientPayloadLocal_':
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'contents',
					$author$project$Api$WebsocketMsgToClientPayloadLocal$decoder,
					$elm$json$Json$Decode$succeed($author$project$Api$WebsocketMsgToClient$WebsocketMsgToClientPayloadLocal_));
			default:
				return $elm$json$Json$Decode$fail('No matching constructor');
		}
	},
	A2($elm$json$Json$Decode$field, 'tag', $elm$json$Json$Decode$string));
var $author$project$BigO$WebSocketMsgIn$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (a) {
		switch (a) {
			case 'WebSocketMsgInData':
				return A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'contents',
					$author$project$Api$WebsocketMsgToClient$decoder,
					$elm$json$Json$Decode$succeed($author$project$BigO$WebSocketMsgIn$WebSocketMsgInData));
			case 'WebSocketMsgInOnClose':
				return $elm$json$Json$Decode$succeed($author$project$BigO$WebSocketMsgIn$WebSocketMsgInOnClose);
			case 'WebSocketMsgInOnError':
				return $elm$json$Json$Decode$succeed($author$project$BigO$WebSocketMsgIn$WebSocketMsgInOnError);
			default:
				return $elm$json$Json$Decode$fail('No matching constructor');
		}
	},
	A2($elm$json$Json$Decode$field, 'tag', $elm$json$Json$Decode$string));
var $elm$http$Http$emptyBody = _Http_emptyBody;
var $elm$http$Http$expectBytesResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'arraybuffer',
			_Http_toDataView,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $elm$http$Http$expectStringResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'',
			$elm$core$Basics$identity,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $elm$http$Http$BadBody = function (a) {
	return {$: 4, a: a};
};
var $elm$http$Http$BadStatus = function (a) {
	return {$: 3, a: a};
};
var $elm$http$Http$BadUrl = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$NetworkError = {$: 2};
var $elm$http$Http$Timeout = {$: 1};
var $elm$http$Http$resolve = F2(
	function (toResult, response) {
		switch (response.$) {
			case 0:
				var url = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadUrl(url));
			case 1:
				return $elm$core$Result$Err($elm$http$Http$Timeout);
			case 2:
				return $elm$core$Result$Err($elm$http$Http$NetworkError);
			case 3:
				var metadata = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadStatus(metadata.iH));
			default:
				var body = response.b;
				return A2(
					$elm$core$Result$mapError,
					$elm$http$Http$BadBody,
					toResult(body));
		}
	});
var $elm$http$Http$expectJson = F2(
	function (toMsg, decoder) {
		return A2(
			$elm$http$Http$expectStringResponse,
			toMsg,
			$elm$http$Http$resolve(
				function (string) {
					return A2(
						$elm$core$Result$mapError,
						$elm$json$Json$Decode$errorToString,
						A2($elm$json$Json$Decode$decodeString, decoder, string));
				}));
	});
var $author$project$Api$ClientPage$ClientPageShowFullDetailsForN96 = function (a) {
	return {$: 1, a: a};
};
var $author$project$Api$ClientPage$ClientPageShowNotifications = {$: 2};
var $author$project$Api$ClientPage$ClientPageShowShortN96sForN80 = function (a) {
	return {$: 0, a: a};
};
var $author$project$Api$WebsocketMsgFromClient$WebsocketMsgFromClientPayloadLocal_ = function (a) {
	return {$: 1, a: a};
};
var $author$project$Api$WebsocketMsgFromClientPayloadLocal$WebsocketMsgFromClientPayloadTrunkSetClientPage = $elm$core$Basics$identity;
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var $author$project$Api$SupabaseAuthToken$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'unSupabaseAuthToken',
				$elm$json$Json$Encode$string(a.gQ))
			]));
};
var $author$project$ElmCommon$Std$encodeBigIntHex = function (b) {
	var s = $cmditch$elm_bigint$BigInt$toHexString(b);
	return $elm$json$Json$Encode$string(s);
};
var $author$project$Api$NodeAndUpCtxId96Data$encoder = function (a) {
	var b = a;
	return $author$project$ElmCommon$Std$encodeBigIntHex(b);
};
var $author$project$Api$NodePhyId80$encoder = function (a) {
	var b = a;
	return $author$project$ElmCommon$Std$encodeBigIntHex(b);
};
var $author$project$Api$ClientPage$encoder = function (a) {
	switch (a.$) {
		case 0:
			var b = a.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('ClientPageShowShortN96sForN80')),
						_Utils_Tuple2(
						'contents',
						$author$project$Api$NodePhyId80$encoder(b))
					]));
		case 1:
			var b = a.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('ClientPageShowFullDetailsForN96')),
						_Utils_Tuple2(
						'contents',
						$author$project$Api$NodeAndUpCtxId96Data$encoder(b))
					]));
		default:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('ClientPageShowNotifications'))
					]));
	}
};
var $elm$json$Json$Encode$null = _Json_encodeNull;
var $elm_community$maybe_extra$Maybe$Extra$unwrap = F3(
	function (_default, f, m) {
		if (m.$ === 1) {
			return _default;
		} else {
			var a = m.a;
			return f(a);
		}
	});
var $author$project$Api$WebsocketMsgFromClientPayloadLocal$encoder = function (a) {
	var b = a;
	return A3($elm_community$maybe_extra$Maybe$Extra$unwrap, $elm$json$Json$Encode$null, $author$project$Api$ClientPage$encoder, b);
};
var $author$project$Api$AccessLevel$encoder = function (a) {
	switch (a) {
		case 0:
			return $elm$json$Json$Encode$string('AccessLevelNone');
		case 1:
			return $elm$json$Json$Encode$string('AccessLevelReadOnly');
		case 2:
			return $elm$json$Json$Encode$string('AccessLevelReadWrite');
		default:
			return $elm$json$Json$Encode$string('AccessLevelAdmin');
	}
};
var $author$project$Api$AccessLevelDefaultN80GroupPublic$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'unAccessLevelDefaultN80GroupPublic',
				$author$project$Api$AccessLevel$encoder(a.gu))
			]));
};
var $author$project$Api$N80GroupId$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'unN80GroupId',
				$elm$json$Json$Encode$string(a.gF))
			]));
};
var $author$project$Api$N80GroupName$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'unN80GroupName',
				$elm$json$Json$Encode$string(a.gG))
			]));
};
var $author$project$Api$UserEmail$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'unUserEmail',
				$elm$json$Json$Encode$string(a.gR))
			]));
};
var $author$project$Api$N80GroupOwnerEmail$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'unN80GroupOwnerEmail',
				$author$project$Api$UserEmail$encoder(a.gH))
			]));
};
var $author$project$Api$N80DescUser$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'unN80DescUser',
				$elm$json$Json$Encode$string(a.gE))
			]));
};
var $author$project$Api$N80IsHidden$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'unN80IsHidden',
				$elm$json$Json$Encode$bool(a.gI))
			]));
};
var $author$project$Api$N80OwnerEmail$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'unN80OwnerEmail',
				$author$project$Api$UserEmail$encoder(a.gJ))
			]));
};
var $elm$json$Json$Encode$list = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$List$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(0),
				entries));
	});
var $author$project$Api$ServerActionRequestN80UpdateMetadataPayload$encoder = function (a) {
	switch (a.$) {
		case 0:
			var b = a.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('ServerActionRequestN80UpdateMetadataPayloadDescUser')),
						_Utils_Tuple2(
						'contents',
						$author$project$Api$N80DescUser$encoder(b))
					]));
		case 1:
			var b = a.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('ServerActionRequestN80UpdateMetadataPayloadSetOwner')),
						_Utils_Tuple2(
						'contents',
						$author$project$Api$N80OwnerEmail$encoder(b))
					]));
		case 2:
			var b = a.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('ServerActionRequestN80UpdateMetadataPayloadSetGroups')),
						_Utils_Tuple2(
						'contents',
						A2($elm$json$Json$Encode$list, $author$project$Api$N80GroupId$encoder, b))
					]));
		default:
			var b = a.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('ServerActionRequestN80UpdateMetadataPayloadSetHidden')),
						_Utils_Tuple2(
						'contents',
						$author$project$Api$N80IsHidden$encoder(b))
					]));
	}
};
var $author$project$Api$N96Comment$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'unN96Comment',
				$elm$json$Json$Encode$string(a.gK))
			]));
};
var $author$project$Api$N96DescUser$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'unN96DescUser',
				$elm$json$Json$Encode$string(a.gL))
			]));
};
var $author$project$Api$N96IsHidden$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'unN96IsHidden',
				$elm$json$Json$Encode$bool(a.gM))
			]));
};
var $author$project$Api$ServerActionRequestN96UpdateMetadataPayload$encoder = function (a) {
	switch (a.$) {
		case 0:
			var b = a.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('ServerActionRequestN96UpdateMetadataN96Comment')),
						_Utils_Tuple2(
						'contents',
						A3($elm_community$maybe_extra$Maybe$Extra$unwrap, $elm$json$Json$Encode$null, $author$project$Api$N96Comment$encoder, b))
					]));
		case 1:
			var b = a.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('ServerActionRequestN96UpdateMetadataN96DescUser')),
						_Utils_Tuple2(
						'contents',
						A3($elm_community$maybe_extra$Maybe$Extra$unwrap, $elm$json$Json$Encode$null, $author$project$Api$N96DescUser$encoder, b))
					]));
		default:
			var b = a.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('ServerActionRequestN96UpdateMetadataN96IsHidden')),
						_Utils_Tuple2(
						'contents',
						$author$project$Api$N96IsHidden$encoder(b))
					]));
	}
};
var $author$project$Api$ServerActionRequestUpdateN80GroupPayload$encoder = function (a) {
	switch (a.$) {
		case 0:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('ServerActionRequestUpdateN80GroupDelete'))
					]));
		case 1:
			var b = a.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('ServerActionRequestUpdateN80GroupAddN80')),
						_Utils_Tuple2(
						'contents',
						$author$project$Api$NodePhyId80$encoder(b))
					]));
		default:
			var b = a.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('ServerActionRequestUpdateN80GroupRemoveN80')),
						_Utils_Tuple2(
						'contents',
						$author$project$Api$NodePhyId80$encoder(b))
					]));
	}
};
var $author$project$Api$AccessLevelGrantedForN80Group$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'unAccessLevelGrantedForN80Group',
				$author$project$Api$AccessLevel$encoder(a.gw))
			]));
};
var $author$project$Api$UserGroupMembershipType$encoder = function (a) {
	if (!a) {
		return $elm$json$Json$Encode$string('UserGroupMembershipTypeStandard');
	} else {
		return $elm$json$Json$Encode$string('UserGroupMembershipTypeAdmin');
	}
};
var $author$project$Api$UserGroupName$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'unUserGroupName',
				$elm$json$Json$Encode$string(a.gT))
			]));
};
var $author$project$Api$UserGroupOwnerEmail$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'unUserGroupOwnerEmail',
				$author$project$Api$UserEmail$encoder(a.gU))
			]));
};
var $author$project$Api$ServerActionRequestUpdateUserGroupPayload$encoder = function (a) {
	switch (a.$) {
		case 0:
			var b = a.a;
			var c = a.b;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('ServerActionRequestUpdateUserGroupAddUser')),
						_Utils_Tuple2(
						'contents',
						A2(
							$elm$json$Json$Encode$list,
							$elm$core$Basics$identity,
							_List_fromArray(
								[
									$author$project$Api$UserEmail$encoder(b),
									$author$project$Api$UserGroupMembershipType$encoder(c)
								])))
					]));
		case 1:
			var b = a.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('ServerActionRequestUpdateUserGroupRemoveUser')),
						_Utils_Tuple2(
						'contents',
						$author$project$Api$UserEmail$encoder(b))
					]));
		case 2:
			var b = a.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('ServerActionRequestUpdateUserGroupRename')),
						_Utils_Tuple2(
						'contents',
						$author$project$Api$UserGroupName$encoder(b))
					]));
		case 3:
			var b = a.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('ServerActionRequestUpdateUserGroupSetOwner')),
						_Utils_Tuple2(
						'contents',
						$author$project$Api$UserGroupOwnerEmail$encoder(b))
					]));
		case 4:
			var b = a.a;
			var c = a.b;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('ServerActionRequestUpdateUserGroupConnectToN80Group')),
						_Utils_Tuple2(
						'contents',
						A2(
							$elm$json$Json$Encode$list,
							$elm$core$Basics$identity,
							_List_fromArray(
								[
									$author$project$Api$N80GroupId$encoder(b),
									$author$project$Api$AccessLevelGrantedForN80Group$encoder(c)
								])))
					]));
		case 5:
			var b = a.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('ServerActionRequestUpdateUserGroupDisconnectN80Group')),
						_Utils_Tuple2(
						'contents',
						$author$project$Api$N80GroupId$encoder(b))
					]));
		default:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('ServerActionRequestUpdateUserGroupDelete'))
					]));
	}
};
var $author$project$Api$UserGroupId$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'unUserGroupId',
				$elm$json$Json$Encode$string(a.gS))
			]));
};
var $author$project$Api$UserType$encoder = function (a) {
	switch (a) {
		case 0:
			return $elm$json$Json$Encode$string('UserTypePublic');
		case 1:
			return $elm$json$Json$Encode$string('UserTypeUser');
		default:
			return $elm$json$Json$Encode$string('UserTypeAdmin');
	}
};
var $author$project$Api$ServerActionRequest$encoder = function (a) {
	switch (a.$) {
		case 0:
			var b = a.a;
			var c = a.b;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('ServerActionBlacklistN80')),
						_Utils_Tuple2(
						'contents',
						A2(
							$elm$json$Json$Encode$list,
							$elm$core$Basics$identity,
							_List_fromArray(
								[
									$author$project$Api$NodePhyId80$encoder(b),
									$elm$json$Json$Encode$string(c)
								])))
					]));
		case 1:
			var b = a.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('ServerActionUnBlacklistN80')),
						_Utils_Tuple2(
						'contents',
						$author$project$Api$NodePhyId80$encoder(b))
					]));
		case 2:
			var b = a.a;
			var c = a.b;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('ServerActionUpdateN80Metadata')),
						_Utils_Tuple2(
						'contents',
						A2(
							$elm$json$Json$Encode$list,
							$elm$core$Basics$identity,
							_List_fromArray(
								[
									$author$project$Api$NodePhyId80$encoder(b),
									A2($elm$json$Json$Encode$list, $author$project$Api$ServerActionRequestN80UpdateMetadataPayload$encoder, c)
								])))
					]));
		case 3:
			var b = a.a;
			var c = a.b;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('ServerActionUpdateN96Metadata')),
						_Utils_Tuple2(
						'contents',
						A2(
							$elm$json$Json$Encode$list,
							$elm$core$Basics$identity,
							_List_fromArray(
								[
									$author$project$Api$NodeAndUpCtxId96Data$encoder(b),
									A2($elm$json$Json$Encode$list, $author$project$Api$ServerActionRequestN96UpdateMetadataPayload$encoder, c)
								])))
					]));
		case 4:
			var b = a.a;
			var c = a.b;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('ServerActionCreateUserGroup')),
						_Utils_Tuple2(
						'contents',
						A2(
							$elm$json$Json$Encode$list,
							$elm$core$Basics$identity,
							_List_fromArray(
								[
									$author$project$Api$UserGroupName$encoder(b),
									$author$project$Api$UserGroupOwnerEmail$encoder(c)
								])))
					]));
		case 5:
			var b = a.a;
			var c = a.b;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('ServerActionUpdateUserGroup')),
						_Utils_Tuple2(
						'contents',
						A2(
							$elm$json$Json$Encode$list,
							$elm$core$Basics$identity,
							_List_fromArray(
								[
									$author$project$Api$UserGroupId$encoder(b),
									$author$project$Api$ServerActionRequestUpdateUserGroupPayload$encoder(c)
								])))
					]));
		case 6:
			var b = a.a;
			var c = a.b;
			var d = a.c;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('ServerActionCreateN80Group')),
						_Utils_Tuple2(
						'contents',
						A2(
							$elm$json$Json$Encode$list,
							$elm$core$Basics$identity,
							_List_fromArray(
								[
									$author$project$Api$N80GroupName$encoder(b),
									$author$project$Api$N80GroupOwnerEmail$encoder(c),
									$author$project$Api$AccessLevelDefaultN80GroupPublic$encoder(d)
								])))
					]));
		case 7:
			var b = a.a;
			var c = a.b;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('ServerActionUpdateN80Group')),
						_Utils_Tuple2(
						'contents',
						A2(
							$elm$json$Json$Encode$list,
							$elm$core$Basics$identity,
							_List_fromArray(
								[
									$author$project$Api$N80GroupId$encoder(b),
									$author$project$Api$ServerActionRequestUpdateN80GroupPayload$encoder(c)
								])))
					]));
		case 8:
			var b = a.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('ServerActionRequestFileConversion')),
						_Utils_Tuple2(
						'contents',
						$elm$json$Json$Encode$string(b))
					]));
		case 9:
			var b = a.a;
			var c = a.b;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('ServerActionAuthUpdateUserType')),
						_Utils_Tuple2(
						'contents',
						A2(
							$elm$json$Json$Encode$list,
							$elm$core$Basics$identity,
							_List_fromArray(
								[
									$author$project$Api$UserEmail$encoder(b),
									$author$project$Api$UserType$encoder(c)
								])))
					]));
		case 10:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('ServerActionRequestRefreshData'))
					]));
		case 11:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('WebsocketMsgFromClientPayloadTrunkHideAllN96'))
					]));
		default:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('WebsocketMsgFromClientPayloadTrunkResetDb'))
					]));
	}
};
var $author$project$Api$WebsocketMsgFromClientPayloadTrunk$encoder = function (a) {
	var b = a;
	return $author$project$Api$ServerActionRequest$encoder(b);
};
var $author$project$Api$WebsocketMsgFromClient$encoder = function (a) {
	switch (a.$) {
		case 0:
			var b = a.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('WebsocketMsgFromClientConnect')),
						_Utils_Tuple2(
						'contents',
						$author$project$Api$SupabaseAuthToken$encoder(b))
					]));
		case 1:
			var b = a.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('WebsocketMsgFromClientPayloadLocal_')),
						_Utils_Tuple2(
						'contents',
						$author$project$Api$WebsocketMsgFromClientPayloadLocal$encoder(b))
					]));
		default:
			var b = a.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('WebsocketMsgFromClientPayloadTrunk_')),
						_Utils_Tuple2(
						'contents',
						$author$project$Api$WebsocketMsgFromClientPayloadTrunk$encoder(b))
					]));
	}
};
var $author$project$UpdateCmd$websocketOut = _Platform_outgoingPort('websocketOut', $elm$json$Json$Encode$string);
var $author$project$UpdateCmd$sendWebsocketMsg = function (msg_) {
	var msg_txt = A2(
		$elm$json$Json$Encode$encode,
		0,
		$author$project$Api$WebsocketMsgFromClient$encoder(msg_));
	return $author$project$UpdateCmd$websocketOut(msg_txt);
};
var $author$project$UpdateCmds$getCmdForNavigateToPage = function (page) {
	switch (page.$) {
		case 0:
			return $elm$core$Platform$Cmd$none;
		case 1:
			return $elm$core$Platform$Cmd$none;
		case 2:
			var n80 = page.a;
			return $author$project$UpdateCmd$sendWebsocketMsg(
				$author$project$Api$WebsocketMsgFromClient$WebsocketMsgFromClientPayloadLocal_(
					$elm$core$Maybe$Just(
						$author$project$Api$ClientPage$ClientPageShowShortN96sForN80(n80))));
		case 3:
			var n96 = page.a;
			return $author$project$UpdateCmd$sendWebsocketMsg(
				$author$project$Api$WebsocketMsgFromClient$WebsocketMsgFromClientPayloadLocal_(
					$elm$core$Maybe$Just(
						$author$project$Api$ClientPage$ClientPageShowFullDetailsForN96(n96))));
		case 4:
			var n96 = page.a;
			return $author$project$UpdateCmd$sendWebsocketMsg(
				$author$project$Api$WebsocketMsgFromClient$WebsocketMsgFromClientPayloadLocal_(
					$elm$core$Maybe$Just(
						$author$project$Api$ClientPage$ClientPageShowFullDetailsForN96(n96))));
		case 5:
			var n96 = page.a;
			return $author$project$UpdateCmd$sendWebsocketMsg(
				$author$project$Api$WebsocketMsgFromClient$WebsocketMsgFromClientPayloadLocal_(
					$elm$core$Maybe$Just(
						$author$project$Api$ClientPage$ClientPageShowFullDetailsForN96(n96))));
		case 6:
			var n80GroupId = page.a;
			return $elm$core$Platform$Cmd$none;
		case 7:
			var cloudUserGroupId = page.a;
			return $elm$core$Platform$Cmd$none;
		case 8:
			return $elm$core$Platform$Cmd$none;
		case 9:
			return $elm$core$Platform$Cmd$none;
		case 10:
			return $author$project$UpdateCmd$sendWebsocketMsg(
				$author$project$Api$WebsocketMsgFromClient$WebsocketMsgFromClientPayloadLocal_(
					$elm$core$Maybe$Just($author$project$Api$ClientPage$ClientPageShowNotifications)));
		case 12:
			return $elm$core$Platform$Cmd$none;
		case 11:
			return $elm$core$Platform$Cmd$none;
		case 13:
			var url = page.a;
			return $elm$core$Platform$Cmd$none;
		case 14:
			return $elm$core$Platform$Cmd$none;
		default:
			return $elm$core$Platform$Cmd$none;
	}
};
var $author$project$Api$ServerActionRequestN80UpdateMetadataPayload$ServerActionRequestN80UpdateMetadataPayloadDescUser = function (a) {
	return {$: 0, a: a};
};
var $author$project$Api$ServerActionRequestN80UpdateMetadataPayload$ServerActionRequestN80UpdateMetadataPayloadSetGroups = function (a) {
	return {$: 2, a: a};
};
var $author$project$Api$ServerActionRequestN80UpdateMetadataPayload$ServerActionRequestN80UpdateMetadataPayloadSetOwner = function (a) {
	return {$: 1, a: a};
};
var $author$project$Api$ServerActionRequest$ServerActionUpdateN80Metadata = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$ElmCommon$Utils$catMaybes = $elm$core$List$filterMap($elm$core$Basics$identity);
var $author$project$BigO$EditState$getEditStateIsUpdated = function (state) {
	switch (state.$) {
		case 0:
			var x = state.a;
			return $elm$core$Maybe$Nothing;
		case 1:
			var _v1 = state.a;
			var x = _v1.a;
			var y = _v1.b;
			return $elm$core$Maybe$Just(y);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Model$ModelUpdate$getN80PageEditStateServerPayloads = function (n80PageEditState) {
	var n80 = n80PageEditState.$8;
	var extrN80GroupIds = function (groupEditState) {
		return A2(
			$elm$core$List$map,
			function ($) {
				return $.dh;
			},
			groupEditState);
	};
	var payloads = _List_fromArray(
		[
			A2(
			$elm$core$Maybe$map,
			$author$project$Api$ServerActionRequestN80UpdateMetadataPayload$ServerActionRequestN80UpdateMetadataPayloadDescUser,
			$author$project$BigO$EditState$getEditStateIsUpdated(n80PageEditState.ig)),
			A2(
			$elm$core$Maybe$map,
			$author$project$Api$ServerActionRequestN80UpdateMetadataPayload$ServerActionRequestN80UpdateMetadataPayloadSetOwner,
			$author$project$BigO$EditState$getEditStateIsUpdated(n80PageEditState.ii)),
			A2(
			$elm$core$Maybe$map,
			A2($elm$core$Basics$composeL, $author$project$Api$ServerActionRequestN80UpdateMetadataPayload$ServerActionRequestN80UpdateMetadataPayloadSetGroups, extrN80GroupIds),
			$author$project$BigO$EditState$getEditStateIsUpdated(n80PageEditState.ih))
		]);
	var msg = function () {
		var _v0 = $author$project$ElmCommon$Utils$catMaybes(payloads);
		if (!_v0.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			var pls = _v0;
			return $elm$core$Maybe$Just(
				A2($author$project$Api$ServerActionRequest$ServerActionUpdateN80Metadata, n80, pls));
		}
	}();
	return msg;
};
var $author$project$ElmCommon$Autocomplete$getSelectUserMsg = function (msg) {
	switch (msg.$) {
		case 0:
			var str = msg.a;
			return $elm$core$Maybe$Nothing;
		case 1:
			var userMsg = msg.b;
			return userMsg;
		case 2:
			return $elm$core$Maybe$Nothing;
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Pages$PageCommon$httpErrorToStr = function (err) {
	switch (err.$) {
		case 0:
			var s = err.a;
			return 'BadUrl: ' + s;
		case 1:
			return 'Timeout';
		case 2:
			return 'NetworkError';
		case 3:
			return 'BadStatus';
		default:
			var s = err.a;
			return 'BadPayload' + s;
	}
};
var $elm$http$Http$jsonBody = function (value) {
	return A2(
		_Http_pair,
		'application/json',
		A2($elm$json$Json$Encode$encode, 0, value));
};
var $author$project$Pages$PageCommon$printValue = function (x) {
	return 'Val:(' + (A2($elm$json$Json$Encode$encode, 0, x) + ')');
};
var $author$project$Pages$PageCommon$jsonDecodeErrorToString = function (err) {
	switch (err.$) {
		case 0:
			var s = err.a;
			var err_ = err.b;
			return 'Field: ' + (s + ('->' + $author$project$Pages$PageCommon$jsonDecodeErrorToString(err_)));
		case 1:
			var n = err.a;
			var err_ = err.b;
			return 'Index: ' + ($elm$core$String$fromInt(n) + ('->' + $author$project$Pages$PageCommon$jsonDecodeErrorToString(err_)));
		case 2:
			var errs = err.a;
			return 'OneOf:[' + ($elm$core$String$concat(
				A2($elm$core$List$map, $author$project$Pages$PageCommon$jsonDecodeErrorToString, errs)) + ']');
		default:
			var s = err.a;
			var val = err.b;
			return 'GenFail: ' + (s + (' Found: ' + $author$project$Pages$PageCommon$printValue(val)));
	}
};
var $elm$core$List$member = F2(
	function (x, xs) {
		return A2(
			$elm$core$List$any,
			function (a) {
				return _Utils_eq(a, x);
			},
			xs);
	});
var $author$project$BigO$Utils$listAppendUnique = F2(
	function (x, xs) {
		return (!A2($elm$core$List$member, x, xs)) ? A2(
			$elm$core$List$append,
			xs,
			_List_fromArray(
				[x])) : xs;
	});
var $author$project$BigO$Utils$listRemove = F2(
	function (x, xs) {
		return A2(
			$elm$core$List$filter,
			function (item) {
				return !_Utils_eq(item, x);
			},
			xs);
	});
var $elm$browser$Browser$Navigation$load = _Browser_load;
var $author$project$Model$ModelUpdate$mdlUpdateEditStateAdminAccess = F2(
	function (func, mdl) {
		var _v0 = mdl.dL;
		if (_v0.$ === 1) {
			return mdl;
		} else {
			var es = _v0.a;
			return _Utils_update(
				mdl,
				{
					dL: $elm$core$Maybe$Just(
						func(es))
				});
		}
	});
var $author$project$Model$ModelUpdate$mdlUpdateEditStatePageDetailsN80Group = F2(
	function (func, mdl) {
		var _v0 = mdl.dN;
		if (_v0.$ === 1) {
			return mdl;
		} else {
			var es = _v0.a;
			return _Utils_update(
				mdl,
				{
					dN: $elm$core$Maybe$Just(
						func(es))
				});
		}
	});
var $author$project$Model$ModelUpdate$mdlUpdateEditStatePageDetailsUserGroup = F2(
	function (func, mdl) {
		var _v0 = mdl.dQ;
		if (_v0.$ === 1) {
			return mdl;
		} else {
			var es = _v0.a;
			return _Utils_update(
				mdl,
				{
					dQ: $elm$core$Maybe$Just(
						func(es))
				});
		}
	});
var $author$project$Model$ModelUpdate$mdlUpdateEditStateUserAccess = F2(
	function (func, mdl) {
		var _v0 = mdl.hC;
		if (_v0.$ === 1) {
			return mdl;
		} else {
			var es = _v0.a;
			return _Utils_update(
				mdl,
				{
					hC: $elm$core$Maybe$Just(
						func(es))
				});
		}
	});
var $author$project$Model$ModelUpdate$mdlUpdateN80EditState = F2(
	function (func, mdl) {
		var _v0 = mdl.dM;
		if (_v0.$ === 1) {
			return mdl;
		} else {
			var editStatePageDetailsN80 = _v0.a;
			return _Utils_update(
				mdl,
				{
					dM: $elm$core$Maybe$Just(
						func(editStatePageDetailsN80))
				});
		}
	});
var $author$project$Model$ModelUpdate$mdlUpdateN80DescUserEditState = F2(
	function (func, mdl) {
		return A2(
			$author$project$Model$ModelUpdate$mdlUpdateN80EditState,
			function (g) {
				return _Utils_update(
					g,
					{
						ig: func(g.ig)
					});
			},
			mdl);
	});
var $author$project$Model$ModelUpdate$mdlUpdateN80GroupEditState = F2(
	function (func, mdl) {
		return A2(
			$author$project$Model$ModelUpdate$mdlUpdateN80EditState,
			function (g) {
				return _Utils_update(
					g,
					{
						ih: func(g.ih)
					});
			},
			mdl);
	});
var $author$project$Model$ModelUpdate$mdlUpdateN80OwnerEditState = F2(
	function (func, mdl) {
		return A2(
			$author$project$Model$ModelUpdate$mdlUpdateN80EditState,
			function (g) {
				return _Utils_update(
					g,
					{
						ii: func(g.ii)
					});
			},
			mdl);
	});
var $author$project$Model$ModelUpdate$mdlUpdateN96DownloadsEditState = F2(
	function (func, mdl) {
		var _v0 = mdl.dP;
		if (_v0.$ === 1) {
			return mdl;
		} else {
			var editStatePageDetailsN96Downloads = _v0.a;
			return _Utils_update(
				mdl,
				{
					dP: $elm$core$Maybe$Just(
						func(editStatePageDetailsN96Downloads))
				});
		}
	});
var $author$project$Model$ModelUpdate$mdlUpdateN96EditState = F2(
	function (func, mdl) {
		var _v0 = mdl.dO;
		if (_v0.$ === 1) {
			return mdl;
		} else {
			var editStatePageDetailsN96 = _v0.a;
			return _Utils_update(
				mdl,
				{
					dO: $elm$core$Maybe$Just(
						func(editStatePageDetailsN96))
				});
		}
	});
var $elm$browser$Browser$Navigation$reload = _Browser_reload(false);
var $author$project$BigO$EditState$EditStateSavingToServer = {$: 2};
var $author$project$BigO$EditState$EditStateEditted = function (a) {
	return {$: 1, a: a};
};
var $author$project$BigO$EditState$chkOriginal_ = function (state) {
	switch (state.$) {
		case 0:
			var a = state.a;
			return $author$project$BigO$EditState$EditStateUneditted(a);
		case 1:
			var _v1 = state.a;
			var x = _v1.a;
			var y = _v1.b;
			return _Utils_eq(x, y) ? $author$project$BigO$EditState$EditStateUneditted(x) : $author$project$BigO$EditState$EditStateEditted(
				_Utils_Tuple2(x, y));
		default:
			return $author$project$BigO$EditState$EditStateSavingToServer;
	}
};
var $author$project$BigO$EditState$revertEditState = function (state) {
	return $author$project$BigO$EditState$chkOriginal_(
		function () {
			switch (state.$) {
				case 0:
					var x = state.a;
					return $author$project$BigO$EditState$EditStateUneditted(x);
				case 1:
					var _v1 = state.a;
					var x = _v1.a;
					var y = _v1.b;
					return $author$project$BigO$EditState$EditStateUneditted(x);
				default:
					return $author$project$BigO$EditState$EditStateSavingToServer;
			}
		}());
};
var $author$project$Model$ModelUpdate$mdlUpdateOption = F2(
	function (func, mdl) {
		return _Utils_update(
			mdl,
			{
				it: func(mdl.it)
			});
	});
var $author$project$Model$ModelUpdate$setDevMode = F2(
	function (val, mdl) {
		return A2(
			$author$project$Model$ModelUpdate$mdlUpdateOption,
			function (opts) {
				return _Utils_update(
					opts,
					{hz: val});
			},
			mdl);
	});
var $author$project$BigO$EditState$updateEditState = F2(
	function (func, state) {
		return $author$project$BigO$EditState$chkOriginal_(
			function () {
				switch (state.$) {
					case 0:
						var x = state.a;
						return $author$project$BigO$EditState$EditStateEditted(
							_Utils_Tuple2(
								x,
								func(x)));
					case 1:
						var _v1 = state.a;
						var x = _v1.a;
						var y = _v1.b;
						return $author$project$BigO$EditState$EditStateEditted(
							_Utils_Tuple2(
								x,
								func(y)));
					default:
						return $author$project$BigO$EditState$EditStateSavingToServer;
				}
			}());
	});
var $author$project$BigO$EditState$setEditState = F2(
	function (val, state) {
		return A2(
			$author$project$BigO$EditState$updateEditState,
			function (_v0) {
				return val;
			},
			state);
	});
var $author$project$Model$ModelUpdate$setN80PageEditStateServerPayloadsToSavingToServer = function (n80PageEditState) {
	var updateModifiedToSaving = function (v) {
		switch (v.$) {
			case 0:
				var x = v.a;
				return $author$project$BigO$EditState$EditStateUneditted(x);
			case 1:
				var _v1 = v.a;
				var x = _v1.a;
				var y = _v1.b;
				return $author$project$BigO$EditState$EditStateSavingToServer;
			default:
				return $author$project$BigO$EditState$EditStateSavingToServer;
		}
	};
	return {
		ie: n80PageEditState.ie,
		$8: n80PageEditState.$8,
		ig: updateModifiedToSaving(n80PageEditState.ig),
		ih: updateModifiedToSaving(n80PageEditState.ih),
		ii: updateModifiedToSaving(n80PageEditState.ii)
	};
};
var $rundis$elm_bootstrap$Bootstrap$Modal$Show = 0;
var $rundis$elm_bootstrap$Bootstrap$Modal$shown = 0;
var $author$project$Msg$OnHttpResponseCloudDownloadLink = F2(
	function (a, b) {
		return {$: 29, a: a, b: b};
	});
var $author$project$Utils$todo0 = F2(
	function (link, res) {
		return A2($author$project$Msg$OnHttpResponseCloudDownloadLink, link, res);
	});
var $author$project$Utils$todo1 = function (resp) {
	switch (resp.$) {
		case 0:
			var s = resp.a;
			return $elm$core$Result$Err(
				$elm$http$Http$BadUrl(s));
		case 1:
			return $elm$core$Result$Err($elm$http$Http$Timeout);
		case 2:
			return $elm$core$Result$Err($elm$http$Http$NetworkError);
		case 3:
			var metadata = resp.a;
			var body = resp.b;
			return $elm$core$Result$Err(
				$elm$http$Http$BadStatus(-1));
		default:
			var metadata = resp.a;
			var body = resp.b;
			return $elm$core$Result$Ok(body);
	}
};
var $author$project$BigO$GeneralOptions$TimeFormatIncludeSecondsTrue = 0;
var $author$project$BigO$GeneralOptions$toggleTimeFormatIncludeSeconds = function (timeFormatIncludeSeconds) {
	if (!timeFormatIncludeSeconds) {
		return 1;
	} else {
		return 0;
	}
};
var $author$project$Model$ModelUpdate$toggleShowTimeSeconds = function (mdl) {
	return A2(
		$author$project$Model$ModelUpdate$mdlUpdateOption,
		function (opts) {
			return _Utils_update(
				opts,
				{
					gl: $author$project$BigO$GeneralOptions$toggleTimeFormatIncludeSeconds(opts.gl)
				});
		},
		mdl);
};
var $author$project$Model$ModelUpdate$updateAuthState = F2(
	function (fnc, ls) {
		return _Utils_update(
			ls,
			{
				c$: fnc(ls.c$)
			});
	});
var $author$project$ElmCommon$Autocomplete$updateAutocompleteInputOnChange = F2(
	function (val, st) {
		return _Utils_update(
			st,
			{h$: true, iG: val});
	});
var $author$project$ElmCommon$Autocomplete$updateAutocompleteSelect = F2(
	function (val, st) {
		var txt = st.hn.hp ? '' : (st.hn.hw(val) + '');
		return _Utils_update(
			st,
			{h$: false, iG: txt});
	});
var $author$project$ElmCommon$Autocomplete$updateAutocomplete = F2(
	function (msg, st) {
		switch (msg.$) {
			case 0:
				var str = msg.a;
				return A2($author$project$ElmCommon$Autocomplete$updateAutocompleteInputOnChange, str, st);
			case 1:
				var str = msg.a;
				return A2($author$project$ElmCommon$Autocomplete$updateAutocompleteSelect, str, st);
			case 2:
				return _Utils_update(
					st,
					{hS: true});
			default:
				return _Utils_update(
					st,
					{hS: false});
		}
	});
var $author$project$Model$ModelUpdate$updateEditStateAdminAccessUpdateN80GroupTable = F2(
	function (func, o) {
		return _Utils_update(
			o,
			{
				hD: func(o.hD)
			});
	});
var $author$project$Model$ModelUpdate$updateEditStateAdminAccessUpdateUserGroupMembership = F2(
	function (func, o) {
		return _Utils_update(
			o,
			{
				hI: func(o.hI)
			});
	});
var $author$project$Model$ModelUpdate$updateEditStateAdminAccessUpdateUserGroupMembershipRENAME = F2(
	function (func, o) {
		return _Utils_update(
			o,
			{
				hI: func(o.hI)
			});
	});
var $author$project$Model$ModelUpdate$updateEditStateAdminAccessUpdateUserGroupTable = F2(
	function (func, o) {
		return _Utils_update(
			o,
			{
				hE: func(o.hE)
			});
	});
var $author$project$Model$ModelUpdate$updateEditStateAdminAccessUpdateUserGroupToN80GroupAccess = F2(
	function (func, o) {
		return _Utils_update(
			o,
			{
				dR: func(o.dR)
			});
	});
var $author$project$Model$ModelUpdate$updateEditStateAdminAccessUpdateUserGroupToN80GroupAccessRENAME = F2(
	function (func, o) {
		return _Utils_update(
			o,
			{
				dR: func(o.dR)
			});
	});
var $author$project$Model$ModelUpdate$updateEditStateDetailsN80GroupN80GroupMembership = F2(
	function (func, o) {
		return _Utils_update(
			o,
			{
				hF: func(o.hF)
			});
	});
var $toastal$either$Either$Left = function (a) {
	return {$: 0, a: a};
};
var $toastal$either$Either$Right = function (a) {
	return {$: 1, a: a};
};
var $author$project$Api$ServerActionRequestUpdateN80GroupPayload$ServerActionRequestUpdateN80GroupAddN80 = function (a) {
	return {$: 1, a: a};
};
var $author$project$Api$ServerActionRequestUpdateN80GroupPayload$ServerActionRequestUpdateN80GroupRemoveN80 = function (a) {
	return {$: 2, a: a};
};
var $author$project$Api$ServerActionRequest$ServerActionUpdateN80Group = F2(
	function (a, b) {
		return {$: 7, a: a, b: b};
	});
var $author$project$ElmCommon$Autocomplete$updateAutocompleteClear = function (st) {
	return _Utils_update(
		st,
		{h$: false, iG: ''});
};
var $author$project$Components$N80GroupMembershipTableTypes$updateEditStateN80GroupMembershipAutocomplete = F2(
	function (fnc, obj) {
		return _Utils_update(
			obj,
			{
				aM: fnc(obj.aM)
			});
	});
var $author$project$Components$N80GroupMembershipTable$updateEditStateN80GroupMembershipTable = F2(
	function (cmn, msg) {
		var res = function () {
			switch (msg.$) {
				case 0:
					var globalAction = msg.a;
					return $toastal$either$Either$Right(globalAction);
				case 1:
					var msg_ = msg.a;
					return $toastal$either$Either$Left(
						_Utils_Tuple2(
							$author$project$Components$N80GroupMembershipTableTypes$updateEditStateN80GroupMembershipAutocomplete(
								$author$project$ElmCommon$Autocomplete$updateAutocomplete(msg_)),
							$elm$core$Platform$Cmd$none));
				case 2:
					var n80GroupId = msg.a;
					var n80 = msg.b;
					var func = function (es) {
						return _Utils_update(
							es,
							{
								aM: $author$project$ElmCommon$Autocomplete$updateAutocompleteClear(es.aM)
							});
					};
					var cmd_ = $author$project$UpdateCmd$sendWebsocketMsg(
						$author$project$Api$WebsocketMsgFromClient$WebsocketMsgFromClientPayloadTrunk_(
							A2(
								$author$project$Api$ServerActionRequest$ServerActionUpdateN80Group,
								n80GroupId,
								$author$project$Api$ServerActionRequestUpdateN80GroupPayload$ServerActionRequestUpdateN80GroupAddN80(n80))));
					return $toastal$either$Either$Left(
						_Utils_Tuple2(func, cmd_));
				default:
					var n80GroupId = msg.a;
					var n80 = msg.b;
					var cmd_ = $author$project$UpdateCmd$sendWebsocketMsg(
						$author$project$Api$WebsocketMsgFromClient$WebsocketMsgFromClientPayloadTrunk_(
							A2(
								$author$project$Api$ServerActionRequest$ServerActionUpdateN80Group,
								n80GroupId,
								$author$project$Api$ServerActionRequestUpdateN80GroupPayload$ServerActionRequestUpdateN80GroupRemoveN80(n80))));
					return $toastal$either$Either$Left(
						_Utils_Tuple2($elm$core$Basics$identity, cmd_));
			}
		}();
		return res;
	});
var $author$project$Api$ServerActionRequest$ServerActionCreateN80Group = F3(
	function (a, b, c) {
		return {$: 6, a: a, b: b, c: c};
	});
var $author$project$Api$ServerActionRequestUpdateN80GroupPayload$ServerActionRequestUpdateN80GroupDelete = {$: 0};
var $author$project$ElmCommon$Dropdown$updateDropDown = F2(
	function (dropdownMsg, dropDown) {
		if (!dropdownMsg.$) {
			var st = dropdownMsg.a;
			return _Utils_update(
				dropDown,
				{a8: st});
		} else {
			var elem = dropdownMsg.a;
			return _Utils_update(
				dropDown,
				{
					a9: A2(
						$rundis$elm_bootstrap$Bootstrap$Dropdown$toggle,
						_List_fromArray(
							[$rundis$elm_bootstrap$Bootstrap$Button$primary]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								dropDown.bZ(elem))
							])),
					iC: elem
				});
		}
	});
var $author$project$Components$N80GroupTableTypes$updateEditStateN80GroupTable = F2(
	function (cmn, msg) {
		var res = function () {
			switch (msg.$) {
				case 0:
					var n80GroupName = msg.a;
					return $toastal$either$Either$Left(
						_Utils_Tuple2(
							function (grp) {
								return _Utils_update(
									grp,
									{cz: n80GroupName});
							},
							$elm$core$Platform$Cmd$none));
				case 1:
					var globalAction = msg.a;
					return $toastal$either$Either$Right(globalAction);
				case 3:
					var n80GroupId = msg.a;
					var cmd_ = $author$project$UpdateCmd$sendWebsocketMsg(
						$author$project$Api$WebsocketMsgFromClient$WebsocketMsgFromClientPayloadTrunk_(
							A2($author$project$Api$ServerActionRequest$ServerActionUpdateN80Group, n80GroupId, $author$project$Api$ServerActionRequestUpdateN80GroupPayload$ServerActionRequestUpdateN80GroupDelete)));
					return $toastal$either$Either$Left(
						_Utils_Tuple2($elm$core$Basics$identity, cmd_));
				case 2:
					var n80GroupName = msg.a;
					var n80OwnerEmail = msg.b;
					var authPublic = msg.c;
					var func = function (es) {
						return _Utils_update(
							es,
							{
								cz: $author$project$Api$N80GroupName$N80GroupName('')
							});
					};
					var cmd_ = $author$project$UpdateCmd$sendWebsocketMsg(
						$author$project$Api$WebsocketMsgFromClient$WebsocketMsgFromClientPayloadTrunk_(
							A3($author$project$Api$ServerActionRequest$ServerActionCreateN80Group, n80GroupName, n80OwnerEmail, authPublic)));
					return $toastal$either$Either$Left(
						_Utils_Tuple2(func, cmd_));
				default:
					var dropdownMsg = msg.a;
					var func = function (tbl) {
						return _Utils_update(
							tbl,
							{
								eH: A2($author$project$ElmCommon$Dropdown$updateDropDown, dropdownMsg, tbl.eH)
							});
					};
					var cmd = $elm$core$Platform$Cmd$none;
					return $toastal$either$Either$Left(
						_Utils_Tuple2(func, cmd));
			}
		}();
		return res;
	});
var $author$project$Model$ModelUpdate$updateEditStateUserAccessUpdateN80GroupTable = F2(
	function (func, o) {
		return _Utils_update(
			o,
			{
				hG: func(o.hG)
			});
	});
var $author$project$Model$ModelUpdate$updateEditStateUserAccessUpdateUserGroupTable = F2(
	function (func, o) {
		return _Utils_update(
			o,
			{
				hH: func(o.hH)
			});
	});
var $author$project$Api$ServerActionRequestUpdateUserGroupPayload$ServerActionRequestUpdateUserGroupAddUser = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Api$ServerActionRequestUpdateUserGroupPayload$ServerActionRequestUpdateUserGroupRemoveUser = function (a) {
	return {$: 1, a: a};
};
var $author$project$Api$ServerActionRequest$ServerActionUpdateUserGroup = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$Components$UserGroupUserMembershipTableTypes$updateEditStateUserGroupMembershipTable = F2(
	function (cmn, msg) {
		var res = function () {
			switch (msg.$) {
				case 0:
					var globalAction = msg.a;
					return $toastal$either$Either$Right(globalAction);
				case 1:
					var dropDownMsg = msg.a;
					return $toastal$either$Either$Left(
						_Utils_Tuple2(
							function (tbl) {
								return _Utils_update(
									tbl,
									{
										cV: A2($author$project$ElmCommon$Dropdown$updateDropDown, dropDownMsg, tbl.cV)
									});
							},
							$elm$core$Platform$Cmd$none));
				case 2:
					var autocompleteMsg = msg.a;
					return $toastal$either$Either$Left(
						_Utils_Tuple2(
							function (tbl) {
								return _Utils_update(
									tbl,
									{
										cc: A2($author$project$ElmCommon$Autocomplete$updateAutocomplete, autocompleteMsg, tbl.cc)
									});
							},
							$elm$core$Platform$Cmd$none));
				case 3:
					var userGroupId = msg.a;
					var userEmail = msg.b;
					var userGroupMembershipType = msg.c;
					var func = $elm$core$Basics$identity;
					var cmd_ = $author$project$UpdateCmd$sendWebsocketMsg(
						$author$project$Api$WebsocketMsgFromClient$WebsocketMsgFromClientPayloadTrunk_(
							A2(
								$author$project$Api$ServerActionRequest$ServerActionUpdateUserGroup,
								userGroupId,
								A2($author$project$Api$ServerActionRequestUpdateUserGroupPayload$ServerActionRequestUpdateUserGroupAddUser, userEmail, userGroupMembershipType))));
					return $toastal$either$Either$Left(
						_Utils_Tuple2(func, cmd_));
				default:
					var userGroupId = msg.a;
					var userEmail = msg.b;
					var func = $elm$core$Basics$identity;
					var cmd_ = $author$project$UpdateCmd$sendWebsocketMsg(
						$author$project$Api$WebsocketMsgFromClient$WebsocketMsgFromClientPayloadTrunk_(
							A2(
								$author$project$Api$ServerActionRequest$ServerActionUpdateUserGroup,
								userGroupId,
								$author$project$Api$ServerActionRequestUpdateUserGroupPayload$ServerActionRequestUpdateUserGroupRemoveUser(userEmail))));
					return $toastal$either$Either$Left(
						_Utils_Tuple2(func, cmd_));
			}
		}();
		return res;
	});
var $author$project$Api$ServerActionRequest$ServerActionCreateUserGroup = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$Api$ServerActionRequestUpdateUserGroupPayload$ServerActionRequestUpdateUserGroupDelete = {$: 6};
var $author$project$Components$UserGroupTableTypes$updateEditStateUserGroupTable = F2(
	function (cmn, msg) {
		var owner_email = {gU: cmn.g5.dE};
		var res = function () {
			switch (msg.$) {
				case 3:
					var globalAction = msg.a;
					return $toastal$either$Either$Right(globalAction);
				case 0:
					var groupName = msg.a;
					return $toastal$either$Either$Left(
						_Utils_Tuple2(
							function (grp) {
								return _Utils_update(
									grp,
									{co: groupName});
							},
							$elm$core$Platform$Cmd$none));
				case 1:
					var groupName = msg.a;
					var func = function (editState) {
						return _Utils_update(
							editState,
							{
								co: $author$project$Api$UserGroupName$UserGroupName('')
							});
					};
					var cmd_ = $author$project$UpdateCmd$sendWebsocketMsg(
						$author$project$Api$WebsocketMsgFromClient$WebsocketMsgFromClientPayloadTrunk_(
							A2($author$project$Api$ServerActionRequest$ServerActionCreateUserGroup, groupName, owner_email)));
					return $toastal$either$Either$Left(
						_Utils_Tuple2(func, cmd_));
				default:
					var userGroupId = msg.a;
					var cmd_ = $author$project$UpdateCmd$sendWebsocketMsg(
						$author$project$Api$WebsocketMsgFromClient$WebsocketMsgFromClientPayloadTrunk_(
							A2($author$project$Api$ServerActionRequest$ServerActionUpdateUserGroup, userGroupId, $author$project$Api$ServerActionRequestUpdateUserGroupPayload$ServerActionRequestUpdateUserGroupDelete)));
					return $toastal$either$Either$Left(
						_Utils_Tuple2($elm$core$Basics$identity, cmd_));
			}
		}();
		return res;
	});
var $author$project$Api$ServerActionRequestUpdateUserGroupPayload$ServerActionRequestUpdateUserGroupConnectToN80Group = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$Api$ServerActionRequestUpdateUserGroupPayload$ServerActionRequestUpdateUserGroupDisconnectN80Group = function (a) {
	return {$: 5, a: a};
};
var $author$project$Components$UserGroupToN80GroupAccessTableTypes$updateEditStateUserGroupToN80GroupAccessTableAuthLevel = F2(
	function (fnc, obj) {
		return _Utils_update(
			obj,
			{
				cf: fnc(obj.cf)
			});
	});
var $author$project$Components$UserGroupToN80GroupAccessTableTypes$updateEditStateUserGroupToN80GroupAccessTableAutoComplete = F2(
	function (fnc, obj) {
		return _Utils_update(
			obj,
			{
				cg: fnc(obj.cg)
			});
	});
var $author$project$Components$UserGroupToN80GroupAccessTable$updateEditStateUserGroupToN80GroupAccessTable = F2(
	function (cmn, msg) {
		var res = function () {
			switch (msg.$) {
				case 0:
					var globalAction = msg.a;
					return $toastal$either$Either$Right(globalAction);
				case 1:
					var autocompleteMsg = msg.a;
					return $toastal$either$Either$Left(
						_Utils_Tuple2(
							$author$project$Components$UserGroupToN80GroupAccessTableTypes$updateEditStateUserGroupToN80GroupAccessTableAutoComplete(
								$author$project$ElmCommon$Autocomplete$updateAutocomplete(autocompleteMsg)),
							$elm$core$Platform$Cmd$none));
				case 2:
					var dropdownMsg = msg.a;
					return $toastal$either$Either$Left(
						_Utils_Tuple2(
							$author$project$Components$UserGroupToN80GroupAccessTableTypes$updateEditStateUserGroupToN80GroupAccessTableAuthLevel(
								$author$project$ElmCommon$Dropdown$updateDropDown(dropdownMsg)),
							$elm$core$Platform$Cmd$none));
				case 3:
					var userGroupId = msg.a;
					var n80GroupId = msg.b;
					var cmd_ = $author$project$UpdateCmd$sendWebsocketMsg(
						$author$project$Api$WebsocketMsgFromClient$WebsocketMsgFromClientPayloadTrunk_(
							A2(
								$author$project$Api$ServerActionRequest$ServerActionUpdateUserGroup,
								userGroupId,
								$author$project$Api$ServerActionRequestUpdateUserGroupPayload$ServerActionRequestUpdateUserGroupDisconnectN80Group(n80GroupId))));
					return $toastal$either$Either$Left(
						_Utils_Tuple2($elm$core$Basics$identity, cmd_));
				default:
					var userGroupId = msg.a;
					var n80GroupId = msg.b;
					var accessLevel = msg.c;
					var cmd_ = $author$project$UpdateCmd$sendWebsocketMsg(
						$author$project$Api$WebsocketMsgFromClient$WebsocketMsgFromClientPayloadTrunk_(
							A2(
								$author$project$Api$ServerActionRequest$ServerActionUpdateUserGroup,
								userGroupId,
								A2($author$project$Api$ServerActionRequestUpdateUserGroupPayload$ServerActionRequestUpdateUserGroupConnectToN80Group, n80GroupId, accessLevel))));
					return $toastal$either$Either$Left(
						_Utils_Tuple2($elm$core$Basics$identity, cmd_));
			}
		}();
		return res;
	});
var $author$project$NodeDataStore$mdlGetN96MetaFull = function (ns) {
	var _v0 = ns.da;
	if ((!_v0.$) && (_v0.a.$ === 1)) {
		var n96MetaFullM = _v0.a.a;
		return n96MetaFullM;
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Pages$EditStates$buildInitialN96PageEditState = function (mdl) {
	var _v0 = $author$project$NodeDataStore$mdlGetN96MetaFull(mdl.fm);
	if (_v0.$ === 1) {
		return $elm$core$Maybe$Nothing;
	} else {
		var n96Meta = _v0.a;
		return $elm$core$Maybe$Just(
			{
				il: A2(
					$elm$core$Maybe$withDefault,
					$author$project$Api$N96Comment$N96Comment(''),
					n96Meta.e1.e3),
				im: A2(
					$elm$core$Maybe$withDefault,
					$author$project$Api$N96DescUser$N96DescUser(''),
					n96Meta.e1.e4)
			});
	}
};
var $author$project$Update$createN96EditStateIfMissing = F2(
	function (clientPageData, mdl) {
		var _v0 = mdl.dO;
		if (!_v0.$) {
			var x = _v0.a;
			return mdl;
		} else {
			return _Utils_update(
				mdl,
				{
					dO: $author$project$Pages$EditStates$buildInitialN96PageEditState(mdl)
				});
		}
	});
var $author$project$NodeDataStore$mdlSetServerProcessingStatus = F2(
	function (status, mdl) {
		return _Utils_update(
			mdl,
			{
				gd: $elm$core$Maybe$Just(status)
			});
	});
var $turboMaCk$any_dict$Dict$Any$insert = F3(
	function (k, v, _v0) {
		var inner = _v0;
		return _Utils_update(
			inner,
			{
				c: A3(
					$elm$core$Dict$insert,
					inner.t(k),
					_Utils_Tuple2(k, v),
					inner.c)
			});
	});
var $author$project$NodeDataStore$anyDictInsertTup = F2(
	function (_v0, dct) {
		var k = _v0.a;
		var v = _v0.b;
		return A3($turboMaCk$any_dict$Dict$Any$insert, k, v, dct);
	});
var $author$project$NodeDataStore$anyDictInsertTupMany = F2(
	function (kvs, dct) {
		return A3($elm$core$List$foldl, $author$project$NodeDataStore$anyDictInsertTup, dct, kvs);
	});
var $author$project$NodeDataStore$anyDictInsertMany_ = F3(
	function (vs, fnc, dct) {
		var kvs = A2(
			$elm$core$List$map,
			function (v) {
				return _Utils_Tuple2(
					fnc(v),
					v);
			},
			vs);
		return A2($author$project$NodeDataStore$anyDictInsertTupMany, kvs, dct);
	});
var $author$project$NodeDataStore$mdlUpdateMetadataN80 = F2(
	function (metadataN80s, mdl) {
		return _Utils_update(
			mdl,
			{
				eZ: A3(
					$author$project$NodeDataStore$anyDictInsertMany_,
					metadataN80s,
					function ($) {
						return $.eC;
					},
					mdl.eZ)
			});
	});
var $author$project$NodeDataStore$mdlUpdateDiff = F2(
	function (diff, ds) {
		var n80 = diff.a;
		var n80Meta = diff.b;
		return A2(
			$author$project$NodeDataStore$mdlUpdateMetadataN80,
			_List_fromArray(
				[n80Meta]),
			ds);
	});
var $author$project$NodeDataStore$mdlUpdateMultiple = F2(
	function (data, mdl) {
		return A3($elm$core$List$foldr, $author$project$NodeDataStore$mdlUpdateDiff, mdl, data.gA);
	});
var $author$project$BigO$EditState$setSavingToServerToVal = F2(
	function (val, state) {
		return $author$project$BigO$EditState$chkOriginal_(
			function () {
				switch (state.$) {
					case 0:
						var x = state.a;
						return $author$project$BigO$EditState$EditStateUneditted(x);
					case 1:
						var _v1 = state.a;
						var x = _v1.a;
						var y = _v1.b;
						return $author$project$BigO$EditState$EditStateEditted(
							_Utils_Tuple2(x, y));
					default:
						return $author$project$BigO$EditState$EditStateUneditted(val);
				}
			}());
	});
var $author$project$Model$ModelUpdate$updateN80EditPage = F2(
	function (diff, editState) {
		var n80 = diff.a;
		var n80Meta = diff.b;
		var _v1 = _Utils_eq(editState.$8, n80);
		if (!_v1) {
			return editState;
		} else {
			return {
				ie: editState.ie,
				$8: editState.$8,
				ig: A2($author$project$BigO$EditState$setSavingToServerToVal, n80Meta.eF, editState.ig),
				ih: editState.ih,
				ii: A2($author$project$BigO$EditState$setSavingToServerToVal, n80Meta.eW, editState.ii)
			};
		}
	});
var $author$project$Model$ModelUpdate$mdlUpdateN80EditPageNewLiveCacheDiff = F2(
	function (diff, mdl) {
		return A2(
			$author$project$Model$ModelUpdate$mdlUpdateN80EditState,
			$author$project$Model$ModelUpdate$updateN80EditPage(diff),
			mdl);
	});
var $author$project$Model$ModelUpdate$mdlUpdateN80EditPageNewLiveCacheDiffSet = F2(
	function (diffSet, mdl) {
		return A3($elm$core$List$foldl, $author$project$Model$ModelUpdate$mdlUpdateN80EditPageNewLiveCacheDiff, mdl, diffSet.gA);
	});
var $rtfeldman$elm_iso8601_date_strings$Iso8601$fromMonth = function (month) {
	switch (month) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		case 6:
			return 7;
		case 7:
			return 8;
		case 8:
			return 9;
		case 9:
			return 10;
		case 10:
			return 11;
		default:
			return 12;
	}
};
var $elm$time$Time$flooredDiv = F2(
	function (numerator, denominator) {
		return $elm$core$Basics$floor(numerator / denominator);
	});
var $elm$time$Time$posixToMillis = function (_v0) {
	var millis = _v0;
	return millis;
};
var $elm$time$Time$toAdjustedMinutesHelp = F3(
	function (defaultOffset, posixMinutes, eras) {
		toAdjustedMinutesHelp:
		while (true) {
			if (!eras.b) {
				return posixMinutes + defaultOffset;
			} else {
				var era = eras.a;
				var olderEras = eras.b;
				if (_Utils_cmp(era.cM, posixMinutes) < 0) {
					return posixMinutes + era.b;
				} else {
					var $temp$defaultOffset = defaultOffset,
						$temp$posixMinutes = posixMinutes,
						$temp$eras = olderEras;
					defaultOffset = $temp$defaultOffset;
					posixMinutes = $temp$posixMinutes;
					eras = $temp$eras;
					continue toAdjustedMinutesHelp;
				}
			}
		}
	});
var $elm$time$Time$toAdjustedMinutes = F2(
	function (_v0, time) {
		var defaultOffset = _v0.a;
		var eras = _v0.b;
		return A3(
			$elm$time$Time$toAdjustedMinutesHelp,
			defaultOffset,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				60000),
			eras);
	});
var $elm$core$Basics$ge = _Utils_ge;
var $elm$time$Time$toCivil = function (minutes) {
	var rawDay = A2($elm$time$Time$flooredDiv, minutes, 60 * 24) + 719468;
	var era = (((rawDay >= 0) ? rawDay : (rawDay - 146096)) / 146097) | 0;
	var dayOfEra = rawDay - (era * 146097);
	var yearOfEra = ((((dayOfEra - ((dayOfEra / 1460) | 0)) + ((dayOfEra / 36524) | 0)) - ((dayOfEra / 146096) | 0)) / 365) | 0;
	var dayOfYear = dayOfEra - (((365 * yearOfEra) + ((yearOfEra / 4) | 0)) - ((yearOfEra / 100) | 0));
	var mp = (((5 * dayOfYear) + 2) / 153) | 0;
	var month = mp + ((mp < 10) ? 3 : (-9));
	var year = yearOfEra + (era * 400);
	return {
		dH: (dayOfYear - ((((153 * mp) + 2) / 5) | 0)) + 1,
		eB: month,
		hc: year + ((month <= 2) ? 1 : 0)
	};
};
var $elm$time$Time$toDay = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).dH;
	});
var $elm$time$Time$toHour = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			24,
			A2(
				$elm$time$Time$flooredDiv,
				A2($elm$time$Time$toAdjustedMinutes, zone, time),
				60));
	});
var $elm$time$Time$toMillis = F2(
	function (_v0, time) {
		return A2(
			$elm$core$Basics$modBy,
			1000,
			$elm$time$Time$posixToMillis(time));
	});
var $elm$time$Time$toMinute = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2($elm$time$Time$toAdjustedMinutes, zone, time));
	});
var $elm$time$Time$Apr = 3;
var $elm$time$Time$Aug = 7;
var $elm$time$Time$Dec = 11;
var $elm$time$Time$Feb = 1;
var $elm$time$Time$Jan = 0;
var $elm$time$Time$Jul = 6;
var $elm$time$Time$Jun = 5;
var $elm$time$Time$Mar = 2;
var $elm$time$Time$May = 4;
var $elm$time$Time$Nov = 10;
var $elm$time$Time$Oct = 9;
var $elm$time$Time$Sep = 8;
var $elm$time$Time$toMonth = F2(
	function (zone, time) {
		var _v0 = $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).eB;
		switch (_v0) {
			case 1:
				return 0;
			case 2:
				return 1;
			case 3:
				return 2;
			case 4:
				return 3;
			case 5:
				return 4;
			case 6:
				return 5;
			case 7:
				return 6;
			case 8:
				return 7;
			case 9:
				return 8;
			case 10:
				return 9;
			case 11:
				return 10;
			default:
				return 11;
		}
	});
var $rtfeldman$elm_iso8601_date_strings$Iso8601$toPaddedString = F2(
	function (digits, time) {
		return A3(
			$elm$core$String$padLeft,
			digits,
			'0',
			$elm$core$String$fromInt(time));
	});
var $elm$time$Time$toSecond = F2(
	function (_v0, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				1000));
	});
var $elm$time$Time$toYear = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).hc;
	});
var $elm$time$Time$utc = A2($elm$time$Time$Zone, 0, _List_Nil);
var $rtfeldman$elm_iso8601_date_strings$Iso8601$fromTime = function (time) {
	return A2(
		$rtfeldman$elm_iso8601_date_strings$Iso8601$toPaddedString,
		4,
		A2($elm$time$Time$toYear, $elm$time$Time$utc, time)) + ('-' + (A2(
		$rtfeldman$elm_iso8601_date_strings$Iso8601$toPaddedString,
		2,
		$rtfeldman$elm_iso8601_date_strings$Iso8601$fromMonth(
			A2($elm$time$Time$toMonth, $elm$time$Time$utc, time))) + ('-' + (A2(
		$rtfeldman$elm_iso8601_date_strings$Iso8601$toPaddedString,
		2,
		A2($elm$time$Time$toDay, $elm$time$Time$utc, time)) + ('T' + (A2(
		$rtfeldman$elm_iso8601_date_strings$Iso8601$toPaddedString,
		2,
		A2($elm$time$Time$toHour, $elm$time$Time$utc, time)) + (':' + (A2(
		$rtfeldman$elm_iso8601_date_strings$Iso8601$toPaddedString,
		2,
		A2($elm$time$Time$toMinute, $elm$time$Time$utc, time)) + (':' + (A2(
		$rtfeldman$elm_iso8601_date_strings$Iso8601$toPaddedString,
		2,
		A2($elm$time$Time$toSecond, $elm$time$Time$utc, time)) + ('.' + (A2(
		$rtfeldman$elm_iso8601_date_strings$Iso8601$toPaddedString,
		3,
		A2($elm$time$Time$toMillis, $elm$time$Time$utc, time)) + 'Z'))))))))))));
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$encode = A2($elm$core$Basics$composeR, $rtfeldman$elm_iso8601_date_strings$Iso8601$fromTime, $elm$json$Json$Encode$string);
var $elm$json$Json$Encode$int = _Json_wrap;
var $author$project$Api$ParcelMsgUpIdx32$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'unC32',
				$elm$json$Json$Encode$int(a.gx))
			]));
};
var $author$project$Api$ParcelUpDataPayloadType$encoder = function (a) {
	switch (a) {
		case 0:
			return $elm$json$Json$Encode$string('ParcelUpDataTypeRecording');
		case 1:
			return $elm$json$Json$Encode$string('ParcelUpDataTypeNodeEventLog');
		case 2:
			return $elm$json$Json$Encode$string('ParcelUpDataTypeTimesyncData');
		default:
			return $elm$json$Json$Encode$string('ParcelUpDataTypeUpCtxEnd');
	}
};
var $author$project$Api$ParcelUpProcessingState$encoder = function (a) {
	if (!a) {
		return $elm$json$Json$Encode$string('ParcelUpProcessingStateReadyToProcess');
	} else {
		return $elm$json$Json$Encode$string('ParcelUpProcessingStateComplete');
	}
};
var $author$project$Api$CloudMetadataN96DevDetailsParcelUpDetails$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'parcelmeta_n96',
				$author$project$Api$NodeAndUpCtxId96Data$encoder(a.ft)),
				_Utils_Tuple2(
				'parcelmeta_c32',
				$author$project$Api$ParcelMsgUpIdx32$encoder(a.fs)),
				_Utils_Tuple2(
				'parcelmeta_uploadtime',
				$rtfeldman$elm_iso8601_date_strings$Iso8601$encode(a.fx)),
				_Utils_Tuple2(
				'parcelmeta_processing_state',
				$author$project$Api$ParcelUpProcessingState$encoder(a.fv)),
				_Utils_Tuple2(
				'parcelmeta_recieptdelivered',
				$elm$json$Json$Encode$bool(a.fw)),
				_Utils_Tuple2(
				'parcelmeta_parceltype',
				$author$project$Api$ParcelUpDataPayloadType$encoder(a.fu))
			]));
};
var $author$project$Api$CloudMetadataN96DevDetails$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'parcelup_details',
				A2($elm$json$Json$Encode$list, $author$project$Api$CloudMetadataN96DevDetailsParcelUpDetails$encoder, a.fy))
			]));
};
var $author$project$Api$DbN96LocalFileOutputStatus$encoder = function (a) {
	switch (a) {
		case 0:
			return $elm$json$Json$Encode$string('DbN96LocalFileOutputStatusNotRequested');
		case 1:
			return $elm$json$Json$Encode$string('DbN96LocalFileOutputStatusRequested');
		case 2:
			return $elm$json$Json$Encode$string('DbN96LocalFileOutputStatusInProgress');
		case 3:
			return $elm$json$Json$Encode$string('DbN96LocalFileOutputStatusComplete');
		default:
			return $elm$json$Json$Encode$string('DbN96LocalFileOutputStatusError');
	}
};
var $author$project$Api$DownloadLocalFilename$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'unDownloadLocalFilename',
				$elm$json$Json$Encode$string(a.gC))
			]));
};
var $author$project$Api$OutputFileType$encoder = function (a) {
	switch (a) {
		case 0:
			return $elm$json$Json$Encode$string('OutputFileTypePyBin');
		case 1:
			return $elm$json$Json$Encode$string('OutputFileTypeCsv');
		case 2:
			return $elm$json$Json$Encode$string('OutputFileTypeXlsClipped');
		default:
			return $elm$json$Json$Encode$string('OutputFileTypeHdf5');
	}
};
var $author$project$Api$CloudWebDownloadLinkFullData$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'link_url_prefix',
				$elm$json$Json$Encode$string(a.eu)),
				_Utils_Tuple2(
				'link_url',
				$elm$json$Json$Encode$string(a.et)),
				_Utils_Tuple2(
				'link_localfname',
				$author$project$Api$DownloadLocalFilename$encoder(a.er)),
				_Utils_Tuple2(
				'link_filetype',
				$author$project$Api$OutputFileType$encoder(a.eq)),
				_Utils_Tuple2(
				'link_status',
				$author$project$Api$DbN96LocalFileOutputStatus$encoder(a.es))
			]));
};
var $author$project$Api$CloudWebDownloadLinkFullDataPartial$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'unCloudWebDownloadLinkFullDataPartial',
				$author$project$Api$CloudWebDownloadLinkFullData$encoder(a.gY))
			]));
};
var $author$project$Api$CloudSampleCount48$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'unCloudSampleCount48',
				$elm$json$Json$Encode$int(a.gB))
			]));
};
var $author$project$Api$N96MsgUpIdx32Recording$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'_unNR32',
				$elm$json$Json$Encode$int(a.g0))
			]));
};
var $author$project$Api$NodeTimeSession32$encoder = function (a) {
	var b = a;
	return $elm$json$Json$Encode$int(b);
};
var $author$project$Api$NodeTimeUs48$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'unNodeTimeUs48',
				$elm$json$Json$Encode$int(a.g1))
			]));
};
var $author$project$Api$SampleIdxCloud48$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'_unSampleIdxCloud64',
				$elm$json$Json$Encode$int(a.g2))
			]));
};
var $author$project$Api$UpCtxId16Data$encoder = function (a) {
	var b = a;
	return $elm$json$Json$Encode$int(b);
};
var $author$project$Api$N96RecordingBlockMetadata$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'_rmN80',
				$author$project$Api$NodePhyId80$encoder(a.fP)),
				_Utils_Tuple2(
				'_rmU16',
				$author$project$Api$UpCtxId16Data$encoder(a.fZ)),
				_Utils_Tuple2(
				'_rmTs32',
				$author$project$Api$NodeTimeSession32$encoder(a.fY)),
				_Utils_Tuple2(
				'_rmNr32',
				$author$project$Api$N96MsgUpIdx32Recording$encoder(a.fU)),
				_Utils_Tuple2(
				'_rmNodeTime0',
				$author$project$Api$NodeTimeUs48$encoder(a.fS)),
				_Utils_Tuple2(
				'_rmNodeTimeN',
				$author$project$Api$NodeTimeUs48$encoder(a.fT)),
				_Utils_Tuple2(
				'_rmTimeRangeNorm',
				function () {
					var _v0 = a.fX;
					var b = _v0.a;
					var c = _v0.b;
					return A2(
						$elm$json$Json$Encode$list,
						$elm$core$Basics$identity,
						_List_fromArray(
							[
								A3($elm_community$maybe_extra$Maybe$Extra$unwrap, $elm$json$Json$Encode$null, $rtfeldman$elm_iso8601_date_strings$Iso8601$encode, b),
								A3($elm_community$maybe_extra$Maybe$Extra$unwrap, $elm$json$Json$Encode$null, $rtfeldman$elm_iso8601_date_strings$Iso8601$encode, c)
							]));
				}()),
				_Utils_Tuple2(
				'_rmNSamples',
				$author$project$Api$CloudSampleCount48$encoder(a.fQ)),
				_Utils_Tuple2(
				'_rmNSamplesCum',
				$author$project$Api$CloudSampleCount48$encoder(a.fR)),
				_Utils_Tuple2(
				'_rmSampleIdxStart',
				$author$project$Api$SampleIdxCloud48$encoder(a.fW)),
				_Utils_Tuple2(
				'_rmSampleIdxEnd',
				$author$project$Api$SampleIdxCloud48$encoder(a.fV))
			]));
};
var $author$project$Api$SensorLocalIndex16$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'unSensorLocalIndex16',
				$elm$json$Json$Encode$int(a.gP))
			]));
};
var $author$project$Api$CloudMetadataN96PartialDownloads$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'partial_downloads',
				A2(
					$elm$json$Json$Encode$list,
					function (b) {
						var c = b.a;
						var d = b.b;
						return A2(
							$elm$json$Json$Encode$list,
							$elm$core$Basics$identity,
							_List_fromArray(
								[
									$author$project$Api$N96RecordingBlockMetadata$encoder(c),
									A2(
									$elm$json$Json$Encode$list,
									function (e) {
										var f = e.a;
										var g = e.b;
										return A2(
											$elm$json$Json$Encode$list,
											$elm$core$Basics$identity,
											_List_fromArray(
												[
													$author$project$Api$SensorLocalIndex16$encoder(f),
													A2($elm$json$Json$Encode$list, $author$project$Api$CloudWebDownloadLinkFullDataPartial$encoder, g)
												]));
									},
									d)
								]));
					},
					a.fz))
			]));
};
var $author$project$Api$CloudWebDownloadLinkFullDataComplete$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'unCloudWebDownloadLinkFullDataComplete',
				$author$project$Api$CloudWebDownloadLinkFullData$encoder(a.gX))
			]));
};
var $author$project$Api$CloudMetadataN96SensorStatusDetailsComplete$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'downloads_complete',
				A2($elm$json$Json$Encode$list, $author$project$Api$CloudWebDownloadLinkFullDataComplete$encoder, a.dK))
			]));
};
var $author$project$Api$CloudMetadataN96SensorStatusDetailsError$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'error_msg',
				$elm$json$Json$Encode$string(a.d3))
			]));
};
var $author$project$Api$CloudMetadataN96SensorStatusDetailsOpen$encoder = function (a) {
	return A2($elm$json$Json$Encode$list, $elm$core$Basics$identity, _List_Nil);
};
var $author$project$Api$CloudMetadataN96SensorStatusDetails$encoder = function (a) {
	switch (a.$) {
		case 0:
			var b = a.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('CloudMetadataN96SensorOpen_')),
						_Utils_Tuple2(
						'contents',
						$author$project$Api$CloudMetadataN96SensorStatusDetailsOpen$encoder(b))
					]));
		case 1:
			var b = a.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('CloudMetadataN96SensorClosed_')),
						_Utils_Tuple2(
						'contents',
						$author$project$Api$CloudMetadataN96SensorStatusDetailsComplete$encoder(b))
					]));
		default:
			var b = a.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('CloudMetadataN96SensorError_')),
						_Utils_Tuple2(
						'contents',
						$author$project$Api$CloudMetadataN96SensorStatusDetailsError$encoder(b))
					]));
	}
};
var $author$project$Api$NodeUpCtxSensorId104$encoder = function (a) {
	var b = a.a;
	var c = a.b;
	return A2(
		$elm$json$Json$Encode$list,
		$elm$core$Basics$identity,
		_List_fromArray(
			[
				$author$project$Api$NodeAndUpCtxId96Data$encoder(b),
				$author$project$Api$SensorLocalIndex16$encoder(c)
			]));
};
var $elm$json$Json$Encode$float = _Json_wrap;
var $author$project$Api$RawSensorReadingFloat$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'unRawSensorReadingFloat',
				$elm$json$Json$Encode$float(a.gN))
			]));
};
var $author$project$Api$SamplePeriodUs32$encoder = function (a) {
	var b = a;
	return $elm$json$Json$Encode$int(b);
};
var $author$project$Api$SensorDataType$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'unSensorDataType',
				$elm$json$Json$Encode$int(a.g3))
			]));
};
var $author$project$Api$SensorLabelShort$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'unSensorLabelShort',
				$elm$json$Json$Encode$string(a.gO))
			]));
};
var $author$project$Api$CloudMetadataN96Sensor$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				's104',
				$author$project$Api$NodeUpCtxSensorId104$encoder(a.f$)),
				_Utils_Tuple2(
				's104Meta_s8',
				$author$project$Api$SensorLocalIndex16$encoder(a.f1)),
				_Utils_Tuple2(
				's104Meta_n96',
				$author$project$Api$NodeAndUpCtxId96Data$encoder(a.f0)),
				_Utils_Tuple2(
				'sensor_desc',
				$author$project$Api$SensorLabelShort$encoder(a.f6)),
				_Utils_Tuple2(
				'sensor_datatype',
				$author$project$Api$SensorDataType$encoder(a.f5)),
				_Utils_Tuple2(
				'sensor_sample_period_us',
				$author$project$Api$SamplePeriodUs32$encoder(a.f8)),
				_Utils_Tuple2(
				'sensor_latest_recorded_value',
				A3($elm_community$maybe_extra$Maybe$Extra$unwrap, $elm$json$Json$Encode$null, $author$project$Api$RawSensorReadingFloat$encoder, a.f7)),
				_Utils_Tuple2(
				'sensor_unit',
				$elm$json$Json$Encode$string(a.gb)),
				_Utils_Tuple2(
				'sensor_total_samples',
				$author$project$Api$CloudSampleCount48$encoder(a.ga)),
				_Utils_Tuple2(
				'sensor_status_details',
				$author$project$Api$CloudMetadataN96SensorStatusDetails$encoder(a.f9))
			]));
};
var $author$project$Api$CloudByteCount48$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'unCloudByteCount48',
				$elm$json$Json$Encode$int(a.gz))
			]));
};
var $author$project$Api$N96ByteCount$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'_unN96ByteCount',
				$author$project$Api$CloudByteCount48$encoder(a.g$))
			]));
};
var $author$project$Api$ParcelUpIdx32Parcel$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'unC32Parcel',
				$author$project$Api$ParcelMsgUpIdx32$encoder(a.gy))
			]));
};
var $author$project$Api$CloudMetadataN96StatusDetailsComplete$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'_n96metaclosed_start_time',
				A3($elm_community$maybe_extra$Maybe$Extra$unwrap, $elm$json$Json$Encode$null, $rtfeldman$elm_iso8601_date_strings$Iso8601$encode, a.fc)),
				_Utils_Tuple2(
				'_n96metaclosed_end_time',
				A3($elm_community$maybe_extra$Maybe$Extra$unwrap, $elm$json$Json$Encode$null, $rtfeldman$elm_iso8601_date_strings$Iso8601$encode, a.fa)),
				_Utils_Tuple2(
				'_n96metaclosed_total_parcels',
				$author$project$Api$ParcelUpIdx32Parcel$encoder(a.fd)),
				_Utils_Tuple2(
				'_n96metaclosed_n_bytes_total',
				$author$project$Api$N96ByteCount$encoder(a.fb))
			]));
};
var $author$project$Api$N80DbErrorStatus$encoder = function (a) {
	var b = a;
	return $elm$json$Json$Encode$string(b);
};
var $author$project$Api$AesonFailDetails$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'fail_bytes_',
				$elm$json$Json$Encode$string(a.hM)),
				_Utils_Tuple2(
				'fail_offset',
				$elm$json$Json$Encode$int(a.hO)),
				_Utils_Tuple2(
				'fail_errmsg',
				$elm$json$Json$Encode$string(a.hN))
			]));
};
var $author$project$Api$RecordingDataCompleteBinaryBlobDecodeError$encoder = function (a) {
	if (!a.$) {
		var b = a.a;
		var c = a.b;
		var d = a.c;
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'tag',
					$elm$json$Json$Encode$string('BinaryBlobDecodeError')),
					_Utils_Tuple2(
					'contents',
					A2(
						$elm$json$Json$Encode$list,
						$elm$core$Basics$identity,
						_List_fromArray(
							[
								$elm$json$Json$Encode$string(b),
								$elm$json$Json$Encode$string(c),
								$author$project$Api$AesonFailDetails$encoder(d)
							])))
				]));
	} else {
		var b = a.a;
		var c = a.b;
		var d = a.c;
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'tag',
					$elm$json$Json$Encode$string('BinaryBlobDecodeTrailingBytes')),
					_Utils_Tuple2(
					'contents',
					A2(
						$elm$json$Json$Encode$list,
						$elm$core$Basics$identity,
						_List_fromArray(
							[
								$elm$json$Json$Encode$string(b),
								$elm$json$Json$Encode$string(c),
								$elm$json$Json$Encode$string(d)
							])))
				]));
	}
};
var $author$project$Api$N96DbErrorStatus$encoder = function (a) {
	switch (a.$) {
		case 0:
			var b = a.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('N96DbErrorStatusN80Error')),
						_Utils_Tuple2(
						'contents',
						$author$project$Api$N80DbErrorStatus$encoder(b))
					]));
		case 1:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('N96DbErrorStatusNoRecordings'))
					]));
		case 2:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('N96DbErrorStatusBlockedToUnblacklist'))
					]));
		case 3:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('N96DbErrorStatusBadUploadOrdering'))
					]));
		case 4:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('N96DbErrorStatusInvalidUpload'))
					]));
		case 5:
			var b = a.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('N96DbErrorStatusParceliRecordingDecodeFail')),
						_Utils_Tuple2(
						'contents',
						$author$project$Api$RecordingDataCompleteBinaryBlobDecodeError$encoder(b))
					]));
		default:
			var b = a.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('N96DbErrorStatusString')),
						_Utils_Tuple2(
						'contents',
						$elm$json$Json$Encode$string(b))
					]));
	}
};
var $author$project$Api$CloudMetadataN96StatusDetailsError$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'err',
				$author$project$Api$N96DbErrorStatus$encoder(a.d1)),
				_Utils_Tuple2(
				'_n96metaerror_total_parcels',
				$author$project$Api$ParcelUpIdx32Parcel$encoder(a.ff)),
				_Utils_Tuple2(
				'_n96metaerror_n_bytes_total',
				$author$project$Api$N96ByteCount$encoder(a.fe))
			]));
};
var $author$project$Api$CloudMetadataN96StatusDetailsOpen$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'_n96metaopen_est_start_time',
				A3($elm_community$maybe_extra$Maybe$Extra$unwrap, $elm$json$Json$Encode$null, $rtfeldman$elm_iso8601_date_strings$Iso8601$encode, a.fh)),
				_Utils_Tuple2(
				'_n96metaopen_est_latest_recorded_time',
				A3($elm_community$maybe_extra$Maybe$Extra$unwrap, $elm$json$Json$Encode$null, $rtfeldman$elm_iso8601_date_strings$Iso8601$encode, a.fg)),
				_Utils_Tuple2(
				'_n96metaopen_last_upload_time',
				A3($elm_community$maybe_extra$Maybe$Extra$unwrap, $elm$json$Json$Encode$null, $rtfeldman$elm_iso8601_date_strings$Iso8601$encode, a.fi)),
				_Utils_Tuple2(
				'_n96metaopen_latest_c32',
				$author$project$Api$ParcelUpIdx32Parcel$encoder(a.fj)),
				_Utils_Tuple2(
				'_n96metaopen_n_bytes',
				$author$project$Api$N96ByteCount$encoder(a.fk))
			]));
};
var $author$project$Api$CloudMetadataN96StatusDetails$encoder = function (a) {
	switch (a.$) {
		case 0:
			var b = a.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('CloudMetadataN96Open_')),
						_Utils_Tuple2(
						'contents',
						$author$project$Api$CloudMetadataN96StatusDetailsOpen$encoder(b))
					]));
		case 1:
			var b = a.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('CloudMetadataN96Closed_')),
						_Utils_Tuple2(
						'contents',
						$author$project$Api$CloudMetadataN96StatusDetailsComplete$encoder(b))
					]));
		default:
			var b = a.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('CloudMetadataN96Error_')),
						_Utils_Tuple2(
						'contents',
						$author$project$Api$CloudMetadataN96StatusDetailsError$encoder(b))
					]));
	}
};
var $author$project$Api$CloudMetadataN96Short$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'_n96meta_short_n96',
				$author$project$Api$NodeAndUpCtxId96Data$encoder(a.e2)),
				_Utils_Tuple2(
				'_n96meta_short_n96_n80',
				$author$project$Api$NodePhyId80$encoder(a.e6)),
				_Utils_Tuple2(
				'_n96meta_short_n96_u16',
				$author$project$Api$UpCtxId16Data$encoder(a.e7)),
				_Utils_Tuple2(
				'_n96meta_short_sensors',
				A2($elm$json$Json$Encode$list, $author$project$Api$CloudMetadataN96Sensor$encoder, a.e8)),
				_Utils_Tuple2(
				'_n96meta_short_n96_comment',
				A3($elm_community$maybe_extra$Maybe$Extra$unwrap, $elm$json$Json$Encode$null, $author$project$Api$N96Comment$encoder, a.e3)),
				_Utils_Tuple2(
				'_n96meta_short_n96_desc_user',
				A3($elm_community$maybe_extra$Maybe$Extra$unwrap, $elm$json$Json$Encode$null, $author$project$Api$N96DescUser$encoder, a.e4)),
				_Utils_Tuple2(
				'_n96meta_short_n96_ishidden',
				$author$project$Api$N96IsHidden$encoder(a.e5)),
				_Utils_Tuple2(
				'_n96meta_short_status_details',
				$author$project$Api$CloudMetadataN96StatusDetails$encoder(a.e9))
			]));
};
var $author$project$Api$CloudMetadataN96Full$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'_n96meta_full_short',
				$author$project$Api$CloudMetadataN96Short$encoder(a.e1)),
				_Utils_Tuple2(
				'_n96meta_full_dev_details',
				$author$project$Api$CloudMetadataN96DevDetails$encoder(a.e$)),
				_Utils_Tuple2(
				'_n96meta_full_partial_downloads',
				$author$project$Api$CloudMetadataN96PartialDownloads$encoder(a.e0))
			]));
};
var $author$project$Api$ClientPageData$encoder = function (a) {
	switch (a.$) {
		case 0:
			var b = a.a;
			var c = a.b;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('ClientPageDataShowShortN96sForN80')),
						_Utils_Tuple2(
						'contents',
						A2(
							$elm$json$Json$Encode$list,
							$elm$core$Basics$identity,
							_List_fromArray(
								[
									$author$project$Api$NodePhyId80$encoder(b),
									A2($elm$json$Json$Encode$list, $author$project$Api$CloudMetadataN96Short$encoder, c)
								])))
					]));
		case 1:
			var b = a.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('ClientPageDataShowFullDetailsForN96')),
						_Utils_Tuple2(
						'contents',
						A3($elm_community$maybe_extra$Maybe$Extra$unwrap, $elm$json$Json$Encode$null, $author$project$Api$CloudMetadataN96Full$encoder, b))
					]));
		default:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('ClientPageDataShowNotifications'))
					]));
	}
};
var $author$project$Api$UserName$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'unUserName',
				$elm$json$Json$Encode$string(a.gV))
			]));
};
var $author$project$Api$CloudMetadataUser$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'_cu_email',
				$author$project$Api$UserEmail$encoder(a.dE)),
				_Utils_Tuple2(
				'_cu_name',
				$author$project$Api$UserName$encoder(a.dF)),
				_Utils_Tuple2(
				'_cu_type',
				$author$project$Api$UserType$encoder(a.dG))
			]));
};
var $author$project$Api$AccessGrantedForUser$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'unAccessGrantedForUser',
				$author$project$Api$AccessLevel$encoder(a.gt))
			]));
};
var $author$project$Api$CloudMetadataN80Group$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'_cmn80g_groupid',
				$author$project$Api$N80GroupId$encoder(a.dh)),
				_Utils_Tuple2(
				'_cmn80g_groupname',
				$author$project$Api$N80GroupName$encoder(a.di)),
				_Utils_Tuple2(
				'_cmn80g_groupowner',
				$author$project$Api$N80GroupOwnerEmail$encoder(a.dj)),
				_Utils_Tuple2(
				'_cmn80g_access_public',
				$author$project$Api$AccessLevelDefaultN80GroupPublic$encoder(a.dg)),
				_Utils_Tuple2(
				'_cmn80g_members',
				A2($elm$json$Json$Encode$list, $author$project$Api$NodePhyId80$encoder, a.dk))
			]));
};
var $author$project$Api$CloudMetadataUserGroup$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'_cmd_groupid',
				$author$project$Api$UserGroupId$encoder(a.db)),
				_Utils_Tuple2(
				'_cmd_groupname',
				$author$project$Api$UserGroupName$encoder(a.dc)),
				_Utils_Tuple2(
				'_cmd_groupowner',
				$author$project$Api$UserGroupOwnerEmail$encoder(a.dd)),
				_Utils_Tuple2(
				'_cmd_members',
				A2(
					$elm$json$Json$Encode$list,
					function (b) {
						var c = b.a;
						var d = b.b;
						return A2(
							$elm$json$Json$Encode$list,
							$elm$core$Basics$identity,
							_List_fromArray(
								[
									$author$project$Api$UserEmail$encoder(c),
									$author$project$Api$UserGroupMembershipType$encoder(d)
								]));
					},
					a.de)),
				_Utils_Tuple2(
				'_cmd_n80group_perms',
				A2(
					$elm$json$Json$Encode$list,
					function (b) {
						var c = b.a;
						var d = b.b;
						return A2(
							$elm$json$Json$Encode$list,
							$elm$core$Basics$identity,
							_List_fromArray(
								[
									$author$project$Api$N80GroupId$encoder(c),
									$author$project$Api$AccessLevelGrantedForN80Group$encoder(d)
								]));
					},
					a.df))
			]));
};
var $author$project$Api$UserCloudAccessInfoGroupsElm$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'elmRawUserGroups',
				A2(
					$elm$json$Json$Encode$list,
					function (b) {
						var c = b.a;
						var d = b.b;
						return A2(
							$elm$json$Json$Encode$list,
							$elm$core$Basics$identity,
							_List_fromArray(
								[
									$author$project$Api$UserGroupId$encoder(c),
									function () {
									var e = d.a;
									var f = d.b;
									return A2(
										$elm$json$Json$Encode$list,
										$elm$core$Basics$identity,
										_List_fromArray(
											[
												$author$project$Api$CloudMetadataUserGroup$encoder(e),
												$author$project$Api$UserGroupMembershipType$encoder(f)
											]));
								}()
								]));
					},
					a.dU)),
				_Utils_Tuple2(
				'elmRawElmN80Groups',
				A2(
					$elm$json$Json$Encode$list,
					function (b) {
						var c = b.a;
						var d = b.b;
						return A2(
							$elm$json$Json$Encode$list,
							$elm$core$Basics$identity,
							_List_fromArray(
								[
									$author$project$Api$N80GroupId$encoder(c),
									function () {
									var e = d.a;
									var f = d.b;
									return A2(
										$elm$json$Json$Encode$list,
										$elm$core$Basics$identity,
										_List_fromArray(
											[
												$author$project$Api$CloudMetadataN80Group$encoder(e),
												$author$project$Api$AccessLevelGrantedForN80Group$encoder(f)
											]));
								}()
								]));
					},
					a.dT)),
				_Utils_Tuple2(
				'elmResolvedN80Access',
				A2(
					$elm$json$Json$Encode$list,
					function (b) {
						var c = b.a;
						var d = b.b;
						return A2(
							$elm$json$Json$Encode$list,
							$elm$core$Basics$identity,
							_List_fromArray(
								[
									$author$project$Api$NodePhyId80$encoder(c),
									$author$project$Api$AccessGrantedForUser$encoder(d)
								]));
					},
					a.dV))
			]));
};
var $author$project$Api$UserCloudAccessInfoElm$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'elmUserGroupInfo',
				$author$project$Api$UserCloudAccessInfoGroupsElm$encoder(a.dW)),
				_Utils_Tuple2(
				'elmVisibleUsers',
				A2($elm$json$Json$Encode$list, $author$project$Api$CloudMetadataUser$encoder, a.dX)),
				_Utils_Tuple2(
				'elm_can_create_user',
				$elm$json$Json$Encode$bool(a.dZ)),
				_Utils_Tuple2(
				'elm_can_create_n80group',
				$elm$json$Json$Encode$bool(a.dY)),
				_Utils_Tuple2(
				'elm_can_create_usergroup',
				$elm$json$Json$Encode$bool(a.d_))
			]));
};
var $author$project$Api$WebsocketMsgToClientPayloadLocal$encoder = function (a) {
	if (!a.$) {
		var b = a.a;
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'tag',
					$elm$json$Json$Encode$string('WebsocketMsgToClientPayloadLocalResponseSetClientPage')),
					_Utils_Tuple2(
					'contents',
					$author$project$Api$ClientPageData$encoder(b))
				]));
	} else {
		var b = a.a;
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'tag',
					$elm$json$Json$Encode$string('WebsocketMsgToClientPayloadLocalNewUserCloudAccessInfoElm')),
					_Utils_Tuple2(
					'contents',
					$author$project$Api$UserCloudAccessInfoElm$encoder(b))
				]));
	}
};
var $author$project$Api$AdminFullUserAndGroupData$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'adminAuthAllUsers',
				A2($elm$json$Json$Encode$list, $author$project$Api$CloudMetadataUser$encoder, a.cZ)),
				_Utils_Tuple2(
				'adminAuthAllUserGroups',
				A2($elm$json$Json$Encode$list, $author$project$Api$CloudMetadataUserGroup$encoder, a.cY)),
				_Utils_Tuple2(
				'adminAuthAllN80Groups',
				A2($elm$json$Json$Encode$list, $author$project$Api$CloudMetadataN80Group$encoder, a.cX))
			]));
};
var $author$project$Api$ApiHttpPushNotifyApiMsgJSON$encoder = function (a) {
	var b = a;
	return $elm$json$Json$Encode$string(b);
};
var $author$project$Api$SemanticVersion$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'major',
				$elm$json$Json$Encode$int(a.ey)),
				_Utils_Tuple2(
				'minor',
				$elm$json$Json$Encode$int(a.ez)),
				_Utils_Tuple2(
				'patch',
				$elm$json$Json$Encode$int(a.fA))
			]));
};
var $author$project$Api$CloudMetadataN80HwInstr$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'n80_instr_descshort',
				A3($elm_community$maybe_extra$Maybe$Extra$unwrap, $elm$json$Json$Encode$null, $elm$json$Json$Encode$string, a.eN)),
				_Utils_Tuple2(
				'n80_instr_descfull',
				A3($elm_community$maybe_extra$Maybe$Extra$unwrap, $elm$json$Json$Encode$null, $elm$json$Json$Encode$string, a.eM)),
				_Utils_Tuple2(
				'n80_instr_version',
				A3($elm_community$maybe_extra$Maybe$Extra$unwrap, $elm$json$Json$Encode$null, $author$project$Api$SemanticVersion$encoder, a.eO)),
				_Utils_Tuple2(
				'n80_instr_builddatetime',
				A3($elm_community$maybe_extra$Maybe$Extra$unwrap, $elm$json$Json$Encode$null, $elm$json$Json$Encode$string, a.eL))
			]));
};
var $author$project$Api$NodeBuildType$encoder = function (a) {
	switch (a) {
		case 0:
			return $elm$json$Json$Encode$string('NodeBuildTypeUnknown');
		case 1:
			return $elm$json$Json$Encode$string('NodeBuildTypeUndefined');
		case 2:
			return $elm$json$Json$Encode$string('NodeBuildTypeProd');
		case 3:
			return $elm$json$Json$Encode$string('NodeBuildTypeDev');
		default:
			return $elm$json$Json$Encode$string('NodeBuildTypeTest');
	}
};
var $author$project$Api$NodePlatform$encoder = function (a) {
	switch (a) {
		case 0:
			return $elm$json$Json$Encode$string('NodePlatformUnknown');
		case 1:
			return $elm$json$Json$Encode$string('NodePlatformUndefined');
		case 2:
			return $elm$json$Json$Encode$string('NodePlatformLinuxDesktop');
		default:
			return $elm$json$Json$Encode$string('NodePlatformEsp32V1');
	}
};
var $author$project$Api$CloudMetadataN80HwPlatform$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'n80_node_platform',
				A3($elm_community$maybe_extra$Maybe$Extra$unwrap, $elm$json$Json$Encode$null, $author$project$Api$NodePlatform$encoder, a.eU)),
				_Utils_Tuple2(
				'n80_node_buildtype',
				A3($elm_community$maybe_extra$Maybe$Extra$unwrap, $elm$json$Json$Encode$null, $author$project$Api$NodeBuildType$encoder, a.eT)),
				_Utils_Tuple2(
				'n80_node_version',
				A3($elm_community$maybe_extra$Maybe$Extra$unwrap, $elm$json$Json$Encode$null, $author$project$Api$SemanticVersion$encoder, a.eV)),
				_Utils_Tuple2(
				'n80_node_builddatetime',
				A3($elm_community$maybe_extra$Maybe$Extra$unwrap, $elm$json$Json$Encode$null, $elm$json$Json$Encode$string, a.eS))
			]));
};
var $author$project$Api$CloudN80HwMetadata$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'n80HwPlatform',
				A3($elm_community$maybe_extra$Maybe$Extra$unwrap, $elm$json$Json$Encode$null, $author$project$Api$CloudMetadataN80HwPlatform$encoder, a.eK)),
				_Utils_Tuple2(
				'n80HwInstr',
				A3($elm_community$maybe_extra$Maybe$Extra$unwrap, $elm$json$Json$Encode$null, $author$project$Api$CloudMetadataN80HwInstr$encoder, a.eJ))
			]));
};
var $author$project$Api$N80ByteCount$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'_unN80ByteCount',
				$author$project$Api$CloudByteCount48$encoder(a.gZ))
			]));
};
var $author$project$Api$N80DescSimple$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'unN80DescSimple',
				$elm$json$Json$Encode$string(a.gD))
			]));
};
var $author$project$Api$N80ParcelUpCount$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'_unN80ParcelUpCount',
				$elm$json$Json$Encode$int(a.g_))
			]));
};
var $author$project$Api$CloudMetadataN80Live$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'n80',
				$author$project$Api$NodePhyId80$encoder(a.eC)),
				_Utils_Tuple2(
				'n80_desc_user',
				$author$project$Api$N80DescUser$encoder(a.eF)),
				_Utils_Tuple2(
				'n80_desc_simple',
				$author$project$Api$N80DescSimple$encoder(a.eE)),
				_Utils_Tuple2(
				'n80_ishidden',
				$author$project$Api$N80IsHidden$encoder(a.eP)),
				_Utils_Tuple2(
				'n80_last_upload_time',
				A3($elm_community$maybe_extra$Maybe$Extra$unwrap, $elm$json$Json$Encode$null, $rtfeldman$elm_iso8601_date_strings$Iso8601$encode, a.eR)),
				_Utils_Tuple2(
				'n80_last_n96meta',
				A3($elm_community$maybe_extra$Maybe$Extra$unwrap, $elm$json$Json$Encode$null, $author$project$Api$CloudMetadataN96Short$encoder, a.eQ)),
				_Utils_Tuple2(
				'n80_parcelcount',
				$author$project$Api$N80ParcelUpCount$encoder(a.eX)),
				_Utils_Tuple2(
				'n80_bytecount',
				$author$project$Api$N80ByteCount$encoder(a.eD)),
				_Utils_Tuple2(
				'n80_hw',
				$author$project$Api$CloudN80HwMetadata$encoder(a.eI)),
				_Utils_Tuple2(
				'n80_owner_email',
				$author$project$Api$N80OwnerEmail$encoder(a.eW)),
				_Utils_Tuple2(
				'n80_group_memberships',
				A2($elm$json$Json$Encode$list, $author$project$Api$N80GroupId$encoder, a.eG))
			]));
};
var $author$project$Api$CloudMetadataLiveCacheDiff$encoder = function (a) {
	var b = a.a;
	var c = a.b;
	return A2(
		$elm$json$Json$Encode$list,
		$elm$core$Basics$identity,
		_List_fromArray(
			[
				$author$project$Api$NodePhyId80$encoder(b),
				$author$project$Api$CloudMetadataN80Live$encoder(c)
			]));
};
var $author$project$Api$CloudMetadataLiveCacheDiffSet$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'unCloudMetadataUpdates',
				A2($elm$json$Json$Encode$list, $author$project$Api$CloudMetadataLiveCacheDiff$encoder, a.gA))
			]));
};
var $author$project$Api$TaskOneOffQueueSize$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'unTaskOneOffQueueSize',
				$elm$json$Json$Encode$int(a.g4))
			]));
};
var $author$project$Api$CloudServerProcessingStatus$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'_status_timestamp',
				$rtfeldman$elm_iso8601_date_strings$Iso8601$encode(a.gg)),
				_Utils_Tuple2(
				'_task_oneoff_queue_size',
				$author$project$Api$TaskOneOffQueueSize$encoder(a.gk)),
				_Utils_Tuple2(
				'_task_oneoff_ncompleted',
				$elm$json$Json$Encode$int(a.gj)),
				_Utils_Tuple2(
				'_cntParcelUpReadyToProcess',
				$elm$json$Json$Encode$int(a.du)),
				_Utils_Tuple2(
				'_cntParcelUpComplete',
				$elm$json$Json$Encode$int(a.dt)),
				_Utils_Tuple2(
				'_cntParcelUpRecReadyToProcess',
				$elm$json$Json$Encode$int(a.dx)),
				_Utils_Tuple2(
				'_cntParcelUpRecComplete',
				$elm$json$Json$Encode$int(a.dv)),
				_Utils_Tuple2(
				'_cntParcelUpRecProcessError',
				$elm$json$Json$Encode$int(a.dw)),
				_Utils_Tuple2(
				'_cntN96StatusOpen',
				$elm$json$Json$Encode$int(a.ds)),
				_Utils_Tuple2(
				'_cntN96StatusComplete',
				$elm$json$Json$Encode$int(a.dq)),
				_Utils_Tuple2(
				'_cntN96StatusError',
				$elm$json$Json$Encode$int(a.dr)),
				_Utils_Tuple2(
				'_cntCompleteLocalFileStatusCntNotRequested',
				$elm$json$Json$Encode$int(a.$7)),
				_Utils_Tuple2(
				'_cntCompleteLocalFileStatusCntRequested',
				$elm$json$Json$Encode$int(a.dp)),
				_Utils_Tuple2(
				'_cntCompleteLocalFileStatusCntInProgress',
				$elm$json$Json$Encode$int(a.dn)),
				_Utils_Tuple2(
				'_cntCompleteLocalFileStatusCntComplete',
				$elm$json$Json$Encode$int(a.dl)),
				_Utils_Tuple2(
				'_cntCompleteLocalFileStatusCntError',
				$elm$json$Json$Encode$int(a.dm)),
				_Utils_Tuple2(
				'_cntPartialLocalFileStatusCntNotStarted',
				$elm$json$Json$Encode$int(a.dB)),
				_Utils_Tuple2(
				'_cntPartialLocalFileStatusCntInProgress',
				$elm$json$Json$Encode$int(a.dA)),
				_Utils_Tuple2(
				'_cntPartialLocalFileStatusCntComplete',
				$elm$json$Json$Encode$int(a.dy)),
				_Utils_Tuple2(
				'_cntPartialLocalFileStatusCntError',
				$elm$json$Json$Encode$int(a.dz)),
				_Utils_Tuple2(
				'_blacklisted_n80s',
				A2(
					$elm$json$Json$Encode$list,
					function (b) {
						var c = b.a;
						var d = b.b;
						return A2(
							$elm$json$Json$Encode$list,
							$elm$core$Basics$identity,
							_List_fromArray(
								[
									$author$project$Api$NodePhyId80$encoder(c),
									$elm$json$Json$Encode$string(d)
								]));
					},
					a.c6)),
				_Utils_Tuple2(
				'_load_average_1min',
				A3($elm_community$maybe_extra$Maybe$Extra$unwrap, $elm$json$Json$Encode$null, $elm$json$Json$Encode$float, a.ew)),
				_Utils_Tuple2(
				'_load_average_5min',
				A3($elm_community$maybe_extra$Maybe$Extra$unwrap, $elm$json$Json$Encode$null, $elm$json$Json$Encode$float, a.ex)),
				_Utils_Tuple2(
				'_load_average_15min',
				A3($elm_community$maybe_extra$Maybe$Extra$unwrap, $elm$json$Json$Encode$null, $elm$json$Json$Encode$float, a.ev))
			]));
};
var $author$project$Api$WebsocketMsgToClientPayloadTrunk$encoder = function (a) {
	switch (a.$) {
		case 0:
			var b = a.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('WebsocketMsgToClientPayloadTrunkNewLiveCacheDiffSet')),
						_Utils_Tuple2(
						'contents',
						$author$project$Api$CloudMetadataLiveCacheDiffSet$encoder(b))
					]));
		case 1:
			var b = a.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('WebsocketMsgToClientPayloadTrunkNewAdminFullUserAndGroupData')),
						_Utils_Tuple2(
						'contents',
						$author$project$Api$AdminFullUserAndGroupData$encoder(b))
					]));
		case 2:
			var b = a.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('WebsocketMsgToClientPayloadTrunkNewServerProcessingStatus')),
						_Utils_Tuple2(
						'contents',
						$author$project$Api$CloudServerProcessingStatus$encoder(b))
					]));
		case 3:
			var b = a.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('WebsocketMsgToClientPayloadTrunkNotifyN96Change')),
						_Utils_Tuple2(
						'contents',
						$author$project$Api$NodeAndUpCtxId96Data$encoder(b))
					]));
		default:
			var b = a.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('WebsocketMsgToClientPayloadTrunkNotification')),
						_Utils_Tuple2(
						'contents',
						$author$project$Api$ApiHttpPushNotifyApiMsgJSON$encoder(b))
					]));
	}
};
var $author$project$Api$WsConnectResponseOKData$encoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'initial_data',
				$author$project$Api$CloudMetadataLiveCacheDiffSet$encoder(a.eg)),
				_Utils_Tuple2(
				'auth_user_metadata',
				$author$project$Api$CloudMetadataUser$encoder(a.c4)),
				_Utils_Tuple2(
				'auth_user_groups',
				$author$project$Api$UserCloudAccessInfoElm$encoder(a.c3)),
				_Utils_Tuple2(
				'auth_admin_data_',
				A3($elm_community$maybe_extra$Maybe$Extra$unwrap, $elm$json$Json$Encode$null, $author$project$Api$AdminFullUserAndGroupData$encoder, a.c1))
			]));
};
var $author$project$Api$WebsocketMsgToClient$encoder = function (a) {
	switch (a.$) {
		case 0:
			var b = a.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('WebsocketMsgToClientConnectResponseOK')),
						_Utils_Tuple2(
						'contents',
						$author$project$Api$WsConnectResponseOKData$encoder(b))
					]));
		case 1:
			var b = a.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('WebsocketMsgToClientConnectResponseFail')),
						_Utils_Tuple2(
						'contents',
						$elm$json$Json$Encode$string(b))
					]));
		case 2:
			var b = a.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('WebsocketMsgToClientPayloadTrunk_')),
						_Utils_Tuple2(
						'contents',
						$author$project$Api$WebsocketMsgToClientPayloadTrunk$encoder(b))
					]));
		default:
			var b = a.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('WebsocketMsgToClientPayloadLocal_')),
						_Utils_Tuple2(
						'contents',
						$author$project$Api$WebsocketMsgToClientPayloadLocal$encoder(b))
					]));
	}
};
var $author$project$Model$ModelUpdate$mdlUpdateNewWsMsgDecoded = F2(
	function (wsStr, mdl) {
		return _Utils_update(
			mdl,
			{
				g8: A2(
					$elm$json$Json$Encode$encode,
					0,
					$author$project$Api$WebsocketMsgToClient$encoder(wsStr)),
				g9: mdl.g9 + 1
			});
	});
var $author$project$NodeDataStore$mdlUpdateRecvNotification = F2(
	function (notification, nsData) {
		return _Utils_update(
			nsData,
			{
				fn: A2(
					$elm$core$List$take,
					100,
					A2($elm$core$List$cons, notification, nsData.fn))
			});
	});
var $author$project$Model$ModelExtractAdmin$getAdminN80GroupsAll = function (mdl) {
	return A2(
		$elm$core$Maybe$withDefault,
		_List_Nil,
		A2(
			$elm$core$Maybe$map,
			function ($) {
				return $.cX;
			},
			mdl.c0));
};
var $author$project$Model$ModelExtractAdmin$getAdminN80GroupsForN80 = F2(
	function (mdl, n80) {
		var all_groups = $author$project$Model$ModelExtractAdmin$getAdminN80GroupsAll(mdl);
		var n80_groups = A2(
			$elm$core$List$filter,
			function (grp) {
				return A2($elm$core$List$member, n80, grp.dk);
			},
			all_groups);
		return n80_groups;
	});
var $author$project$BigO$EditState$newDataFromServer = F2(
	function (newVal, state) {
		return $author$project$BigO$EditState$chkOriginal_(
			function () {
				switch (state.$) {
					case 0:
						return $author$project$BigO$EditState$EditStateUneditted(newVal);
					case 1:
						var _v1 = state.a;
						var x = _v1.a;
						var y = _v1.b;
						return $author$project$BigO$EditState$EditStateEditted(
							_Utils_Tuple2(newVal, newVal));
					default:
						return $author$project$BigO$EditState$EditStateUneditted(newVal);
				}
			}());
	});
var $author$project$Model$ModelUpdate$updateN80EditPageUserCloudAccessInfoElm = function (mdl) {
	var _v0 = mdl.dM;
	if (_v0.$ === 1) {
		return mdl;
	} else {
		var editstate = _v0.a;
		var n80 = editstate.$8;
		var n80_groups = A2($author$project$Model$ModelExtractAdmin$getAdminN80GroupsForN80, mdl, n80);
		var new_editstate = {
			ie: A2($author$project$Model$ModelUpdate$build_n80_edit_group_info, n80_groups, n80),
			$8: editstate.$8,
			ig: editstate.ig,
			ih: A2($author$project$BigO$EditState$newDataFromServer, n80_groups, editstate.ih),
			ii: editstate.ii
		};
		return _Utils_update(
			mdl,
			{
				dM: $elm$core$Maybe$Just(new_editstate)
			});
	}
};
var $author$project$Model$ModelUpdate$mdlUpdateWithFullUserAndGroupData = F2(
	function (fullUserAndGroupData, mdl) {
		return $author$project$Model$ModelUpdate$updateN80EditPageUserCloudAccessInfoElm(
			function (mdl_) {
				return _Utils_update(
					mdl_,
					{c0: fullUserAndGroupData});
			}(mdl));
	});
var $author$project$Model$ModelUpdate$mdlUpdateWithUserCloudAccessInfo = F2(
	function (userCloudAccessInfoElm, mdl) {
		return $author$project$Model$ModelUpdate$updateN80EditPageUserCloudAccessInfoElm(
			function (mdl_) {
				return _Utils_update(
					mdl_,
					{
						c3: $elm$core$Maybe$Just(userCloudAccessInfoElm)
					});
			}(mdl));
	});
var $author$project$NodeDataStore$ndsSetClientPageData = F2(
	function (client_page_data, mdl) {
		return _Utils_update(
			mdl,
			{da: client_page_data});
	});
var $author$project$Model$ModelUpdate$updateLoginState = F2(
	function (fnc, mdl) {
		return _Utils_update(
			mdl,
			{
				h3: fnc(mdl.h3)
			});
	});
var $author$project$Model$ModelUpdate$updateNodeDatastore = F2(
	function (fnc, mdl) {
		return _Utils_update(
			mdl,
			{
				fm: fnc(mdl.fm)
			});
	});
var $author$project$Update$updateFromWebsocketMsg = F2(
	function (mdl, val) {
		switch (val.$) {
			case 0:
				var res = val.a;
				var model_ = function (m) {
					return _Utils_update(
						m,
						{
							c2: $elm$core$Maybe$Just(res.c4)
						});
				}(
					A2(
						$author$project$Model$ModelUpdate$mdlUpdateWithUserCloudAccessInfo,
						res.c3,
						A2(
							$author$project$Model$ModelUpdate$mdlUpdateWithFullUserAndGroupData,
							res.c1,
							A2(
								$author$project$Model$ModelUpdate$updateNodeDatastore,
								$author$project$NodeDataStore$mdlUpdateMultiple(res.eg),
								A2(
									$author$project$Model$ModelUpdate$updateLoginState,
									function (ls) {
										return _Utils_update(
											ls,
											{d5: true});
									},
									A2($author$project$Model$ModelUpdate$mdlUpdateNewWsMsgDecoded, val, mdl))))));
				return _Utils_Tuple2(model_, $elm$core$Platform$Cmd$none);
			case 1:
				var res = val.a;
				var model_ = A2(
					$author$project$Model$ModelUpdate$updateLoginState,
					$author$project$Model$ModelUpdate$updateAuthState(
						function (_v1) {
							return $author$project$Utils$authStateFailed(res);
						}),
					A2($author$project$Model$ModelUpdate$mdlUpdateNewWsMsgDecoded, val, mdl));
				return _Utils_Tuple2(model_, $elm$core$Platform$Cmd$none);
			case 2:
				var pl = val.a;
				switch (pl.$) {
					case 0:
						var updates = pl.a;
						return _Utils_Tuple2(
							A2(
								$author$project$Model$ModelUpdate$mdlUpdateN80EditPageNewLiveCacheDiffSet,
								updates,
								A2(
									$author$project$Model$ModelUpdate$updateNodeDatastore,
									$author$project$NodeDataStore$mdlUpdateMultiple(updates),
									A2($author$project$Model$ModelUpdate$mdlUpdateNewWsMsgDecoded, val, mdl))),
							$elm$core$Platform$Cmd$none);
					case 1:
						var newFullAuthDeails = pl.a;
						var model_ = A2(
							$author$project$Model$ModelUpdate$mdlUpdateWithFullUserAndGroupData,
							$elm$core$Maybe$Just(newFullAuthDeails),
							mdl);
						return _Utils_Tuple2(model_, $elm$core$Platform$Cmd$none);
					case 2:
						var status = pl.a;
						return _Utils_Tuple2(
							A2(
								$author$project$Model$ModelUpdate$updateNodeDatastore,
								$author$project$NodeDataStore$mdlSetServerProcessingStatus(status),
								A2($author$project$Model$ModelUpdate$mdlUpdateNewWsMsgDecoded, val, mdl)),
							$elm$core$Platform$Cmd$none);
					case 3:
						var n96Meta = pl.a;
						return _Utils_Tuple2(
							A2($author$project$Model$ModelUpdate$mdlUpdateNewWsMsgDecoded, val, mdl),
							$elm$core$Platform$Cmd$none);
					default:
						var notification = pl.a;
						return _Utils_Tuple2(
							A2(
								$author$project$Model$ModelUpdate$updateNodeDatastore,
								$author$project$NodeDataStore$mdlUpdateRecvNotification(notification),
								A2($author$project$Model$ModelUpdate$mdlUpdateNewWsMsgDecoded, val, mdl)),
							$elm$core$Platform$Cmd$none);
				}
			default:
				var pl = val.a;
				if (!pl.$) {
					var pageData = pl.a;
					var mdl_ = A2(
						$author$project$Update$createN96EditStateIfMissing,
						pageData,
						A2(
							$author$project$Model$ModelUpdate$updateNodeDatastore,
							$author$project$NodeDataStore$ndsSetClientPageData(
								$elm$core$Maybe$Just(pageData)),
							mdl));
					return _Utils_Tuple2(mdl_, $elm$core$Platform$Cmd$none);
				} else {
					var accessInfo = pl.a;
					return _Utils_Tuple2(
						A2($author$project$Model$ModelUpdate$mdlUpdateWithUserCloudAccessInfo, accessInfo, mdl),
						$elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Model$ModelUpdate$updateGroupsAutocomplete_ = F2(
	function (fnc, es) {
		return _Utils_update(
			es,
			{
				ie: fnc(es.ie)
			});
	});
var $author$project$Model$ModelUpdate$updateGroupsAutocomplete = F2(
	function (fnc, mdl) {
		return A2(
			$author$project$Model$ModelUpdate$mdlUpdateN80EditState,
			$author$project$Model$ModelUpdate$updateGroupsAutocomplete_(fnc),
			mdl);
	});
var $author$project$Model$ModelUpdate$updateLoginStateSignInLoginName = F2(
	function (email, st) {
		if (!st.$) {
			var password = st.b;
			var lst = st.c;
			return A3($author$project$Model$Model$AuthStateSignInPage, email, password, lst);
		} else {
			return st;
		}
	});
var $author$project$Model$ModelUpdate$updateLoginStateSignInLoginPassword = F2(
	function (password, st) {
		if (!st.$) {
			var email = st.a;
			var lst = st.c;
			return A3($author$project$Model$Model$AuthStateSignInPage, email, password, lst);
		} else {
			return st;
		}
	});
var $author$project$Pages$PageCommon$updateModelUpdateOptions = F2(
	function (oFunc, mdl) {
		return _Utils_update(
			mdl,
			{
				it: oFunc(mdl.it)
			});
	});
var $author$project$Update$update = F2(
	function (msg, mdl) {
		update:
		while (true) {
			switch (msg.$) {
				case 11:
					var evt = msg.a;
					var mdl_ = function () {
						var _v1 = _Utils_Tuple2(evt.h2, evt.iE);
						switch (_v1.a.$) {
							case 3:
								if (_v1.b) {
									var _v2 = _v1.a;
									return A2($author$project$Model$ModelUpdate$setDevMode, true, mdl);
								} else {
									var _v3 = _v1.a;
									return A2($author$project$Model$ModelUpdate$setDevMode, false, mdl);
								}
							case 18:
								var _v4 = _v1.a;
								return $author$project$Model$ModelUpdate$toggleShowTimeSeconds(mdl);
							default:
								return mdl;
						}
					}();
					return _Utils_Tuple2(mdl_, $elm$core$Platform$Cmd$none);
				case 10:
					return _Utils_Tuple2(mdl, $elm$core$Platform$Cmd$none);
				case 1:
					var req = msg.a;
					if (!req.$) {
						var url = req.a;
						return A2($author$project$Routing$urlUpdate, url, mdl);
					} else {
						var href = req.a;
						return _Utils_Tuple2(
							mdl,
							$elm$browser$Browser$Navigation$load(href));
					}
				case 0:
					var url = msg.a;
					return _Utils_Tuple2(mdl, $elm$core$Platform$Cmd$none);
				case 2:
					var state = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							mdl,
							{io: state}),
						$elm$core$Platform$Cmd$none);
				case 9:
					var action = msg.a;
					var p = action;
					var model_ = A3(
						$author$project$Routing$modelInitForPageChange,
						$author$project$PageCommonInfo$buildPageCommonInfo(mdl),
						p,
						_Utils_update(
							mdl,
							{fq: p}));
					var cmd_ = $author$project$UpdateCmds$getCmdForNavigateToPage(p);
					return _Utils_Tuple2(model_, cmd_);
				case 26:
					var s104 = msg.a;
					var state = msg.b;
					var mdl_ = A2(
						$author$project$Model$ModelUpdate$mdlUpdateN96DownloadsEditState,
						function (es) {
							return _Utils_update(
								es,
								{hB: state, f$: s104});
						},
						mdl);
					var cmd_ = $elm$core$Platform$Cmd$none;
					return _Utils_Tuple2(mdl_, cmd_);
				case 27:
					var details = msg.a;
					var model_ = mdl;
					var cmd = $elm$http$Http$request(
						{
							hl: $elm$http$Http$emptyBody,
							hK: A2(
								$elm$http$Http$expectBytesResponse,
								$author$project$Utils$todo0(details),
								$author$project$Utils$todo1),
							hT: _List_fromArray(
								[
									A2($elm$http$Http$header, 'Cache-Control', 'no-cache')
								]),
							h7: 'GET',
							iR: $elm$core$Maybe$Just(30.0 * 1000),
							iV: $elm$core$Maybe$Nothing,
							i_: _Utils_ap(details.eu, details.et)
						});
					return _Utils_Tuple2(model_, cmd);
				case 28:
					var link = msg.a;
					var msg1_ = $author$project$UpdateCmd$sendWebsocketMsg(
						$author$project$Api$WebsocketMsgFromClient$WebsocketMsgFromClientPayloadTrunk_(
							$author$project$Api$ServerActionRequest$ServerActionRequestFileConversion(link.et)));
					var cmd_ = $elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[msg1_]));
					return _Utils_Tuple2(mdl, cmd_);
				case 29:
					if (msg.b.$ === 1) {
						var e = msg.b.a;
						var model_ = _Utils_update(
							mdl,
							{
								d2: _List_fromArray(
									[
										$author$project$BigO$Common$ErrorStr(
										'OnHttpResponseCloudDownloadLink Error:' + $author$project$Pages$PageCommon$httpErrorToStr(e))
									]),
								fq: $author$project$TypesPage$Errors
							});
						return _Utils_Tuple2(model_, $elm$core$Platform$Cmd$none);
					} else {
						var details = msg.a;
						var txt = msg.b.a;
						var filename = details.er.gC;
						var cmd_ = A3($elm$file$File$Download$bytes, filename, 'text/csv', txt);
						return _Utils_Tuple2(mdl, cmd_);
					}
				case 5:
					var tNow = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							mdl,
							{gm: tNow}),
						$elm$core$Platform$Cmd$none);
				case 7:
					var str = msg.a;
					var _v7 = mdl.ek;
					if (_v7.$ === 1) {
						return _Utils_Tuple2(
							_Utils_update(
								mdl,
								{
									ek: $elm$core$Maybe$Just(str)
								}),
							$elm$core$Platform$Cmd$none);
					} else {
						return _Utils_Tuple2(mdl, $elm$core$Platform$Cmd$none);
					}
				case 3:
					return _Utils_Tuple2(
						_Utils_update(
							mdl,
							{eA: $rundis$elm_bootstrap$Bootstrap$Modal$hidden}),
						$elm$core$Platform$Cmd$none);
				case 4:
					return _Utils_Tuple2(
						_Utils_update(
							mdl,
							{eA: $rundis$elm_bootstrap$Bootstrap$Modal$shown}),
						$elm$core$Platform$Cmd$none);
				case 8:
					var hostname = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							mdl,
							{
								eb: $elm$core$Maybe$Just(hostname)
							}),
						$elm$core$Platform$Cmd$none);
				case 6:
					var wsVal = msg.a;
					var _v8 = A2($elm$json$Json$Decode$decodeString, $author$project$BigO$WebSocketMsgIn$decoder, wsVal);
					if (!_v8.$) {
						switch (_v8.a.$) {
							case 0:
								var val = _v8.a.a;
								return A2($author$project$Update$updateFromWebsocketMsg, mdl, val);
							case 1:
								var _v9 = _v8.a;
								var model_ = A2(
									$author$project$Model$ModelUpdate$updateLoginState,
									$author$project$Model$ModelUpdate$updateAuthState(
										function (_v11) {
											return $author$project$Utils$authStateFailed('WS-Error');
										}),
									mdl);
								var _v10 = mdl.h3.d5;
								if (_v10) {
									return _Utils_Tuple2(model_, $elm$browser$Browser$Navigation$reload);
								} else {
									return _Utils_Tuple2(model_, $elm$core$Platform$Cmd$none);
								}
							default:
								var _v12 = _v8.a;
								var model_ = A2(
									$author$project$Model$ModelUpdate$updateLoginState,
									$author$project$Model$ModelUpdate$updateAuthState(
										function (_v14) {
											return $author$project$Utils$authStateFailed('WS-Close');
										}),
									mdl);
								var _v13 = mdl.h3.d5;
								if (_v13) {
									return _Utils_Tuple2(model_, $elm$browser$Browser$Navigation$reload);
								} else {
									return _Utils_Tuple2(model_, $elm$core$Platform$Cmd$none);
								}
						}
					} else {
						var error = _v8.a;
						var model_ = _Utils_update(
							mdl,
							{
								d2: _List_fromArray(
									[
										$author$project$BigO$Common$ErrorStr(
										$author$project$Pages$PageCommon$jsonDecodeErrorToString(error) + ('\n BIGO Decoding:' + wsVal))
									]),
								fq: $author$project$TypesPage$Errors,
								g8: wsVal
							});
						return _Utils_Tuple2(model_, $elm$core$Platform$Cmd$none);
					}
				case 12:
					var tabState = msg.a;
					return _Utils_Tuple2(
						A2(
							$author$project$Model$ModelUpdate$updateLoginState,
							function (ls) {
								return _Utils_update(
									ls,
									{iM: tabState});
							},
							mdl),
						$elm$core$Platform$Cmd$none);
				case 13:
					var str = msg.a;
					return _Utils_Tuple2(
						A2(
							$author$project$Model$ModelUpdate$updateLoginState,
							$author$project$Model$ModelUpdate$updateAuthState(
								$author$project$Model$ModelUpdate$updateLoginStateSignInLoginName(str)),
							mdl),
						$elm$core$Platform$Cmd$none);
				case 14:
					var str = msg.a;
					return _Utils_Tuple2(
						A2(
							$author$project$Model$ModelUpdate$updateLoginState,
							$author$project$Model$ModelUpdate$updateAuthState(
								$author$project$Model$ModelUpdate$updateLoginStateSignInLoginPassword(str)),
							mdl),
						$elm$core$Platform$Cmd$none);
				case 17:
					var email = msg.a;
					var password = msg.b;
					var url = 'https://auth.super-easy-sensors.com/auth/v1/signup';
					var model_ = A2(
						$author$project$Model$ModelUpdate$updateLoginState,
						function (ls) {
							return _Utils_update(
								ls,
								{gs: 1});
						},
						mdl);
					var expect = A2($elm$http$Http$expectJson, $author$project$Msg$OnClickServerAuthLoginTokenRequestResponse, $author$project$Api$SupabaseLoginResponseMsgSuccess$decoder);
					var body = $elm$http$Http$jsonBody(
						$elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'email',
									$elm$json$Json$Encode$string(email.gR)),
									_Utils_Tuple2(
									'password',
									$elm$json$Json$Encode$string(password.gW))
								])));
					var cmd = A3($author$project$UpdateCmds$buildAuthHttpCmd, url, body, expect);
					return _Utils_Tuple2(model_, cmd);
				case 16:
					var email = msg.a;
					var url = 'https://auth.super-easy-sensors.com/auth/v1/recover';
					var model_ = A2(
						$author$project$Model$ModelUpdate$updateLoginState,
						function (ls) {
							return _Utils_update(
								ls,
								{gs: 2});
						},
						mdl);
					var expect = A2($elm$http$Http$expectJson, $author$project$Msg$OnClickServerAuthLoginTokenRequestResponse, $author$project$Api$SupabaseLoginResponseMsgSuccess$decoder);
					var body = $elm$http$Http$jsonBody(
						$elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'email',
									$elm$json$Json$Encode$string(email.gR))
								])));
					var cmd = A3($author$project$UpdateCmds$buildAuthHttpCmd, url, body, expect);
					return _Utils_Tuple2(model_, cmd);
				case 15:
					var email = msg.a;
					var password = msg.b;
					var url = 'https://auth.super-easy-sensors.com/auth/v1/token?grant_type=password';
					var model_ = A2(
						$author$project$Model$ModelUpdate$updateLoginState,
						$author$project$Model$ModelUpdate$updateAuthState(
							function (_v15) {
								return A2($author$project$Model$Model$AuthStateLoginRequested, email, password);
							}),
						mdl);
					var expect = A2($elm$http$Http$expectJson, $author$project$Msg$OnClickServerAuthLoginTokenRequestResponse, $author$project$Api$SupabaseLoginResponseMsgSuccess$decoder);
					var body = $elm$http$Http$jsonBody(
						$elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'email',
									$elm$json$Json$Encode$string(email.gR)),
									_Utils_Tuple2(
									'password',
									$elm$json$Json$Encode$string(password.gW))
								])));
					var cmd = A3($author$project$UpdateCmds$buildAuthHttpCmd, url, body, expect);
					return _Utils_Tuple2(model_, cmd);
				case 18:
					if (!msg.a.$) {
						var msgR = msg.a.a;
						var token = function (t) {
							return {gQ: t};
						}(msgR.cU);
						var msg_ = $author$project$Api$WebsocketMsgFromClient$WebsocketMsgFromClientConnect(token);
						var model_ = A2(
							$author$project$Model$ModelUpdate$updateLoginState,
							$author$project$Model$ModelUpdate$updateAuthState(
								function (_v16) {
									return A2(
										$author$project$Model$Model$AuthStateAuthenticated,
										$author$project$Api$UserEmail$UserEmail('JUNK'),
										token);
								}),
							mdl);
						var cmd_ = $author$project$UpdateCmd$sendWebsocketMsg(msg_);
						return _Utils_Tuple2(model_, cmd_);
					} else {
						var err = msg.a.a;
						return _Utils_Tuple2(
							A2(
								$author$project$Model$ModelUpdate$updateLoginState,
								$author$project$Model$ModelUpdate$updateAuthState(
									function (_v17) {
										return $author$project$Utils$authStateFailed(
											'Login Error' + $author$project$Pages$PageCommon$httpErrorToStr(err));
									}),
								mdl),
							$elm$core$Platform$Cmd$none);
					}
				case 21:
					var desc = msg.b;
					return _Utils_Tuple2(
						A2(
							$author$project$Model$ModelUpdate$mdlUpdateN96EditState,
							function (e) {
								return _Utils_update(
									e,
									{im: desc});
							},
							mdl),
						$elm$core$Platform$Cmd$none);
				case 22:
					var desc = msg.b;
					return _Utils_Tuple2(
						A2(
							$author$project$Model$ModelUpdate$mdlUpdateN96EditState,
							function (e) {
								return _Utils_update(
									e,
									{il: desc});
							},
							mdl),
						$elm$core$Platform$Cmd$none);
				case 23:
					var n96 = msg.a;
					var _v18 = mdl.dO;
					if (_v18.$ === 1) {
						return _Utils_Tuple2(mdl, $elm$core$Platform$Cmd$none);
					} else {
						var editStatePageDetailsN96 = _v18.a;
						var metadata_payloads = _List_fromArray(
							[
								$author$project$Api$ServerActionRequestN96UpdateMetadataPayload$ServerActionRequestN96UpdateMetadataN96Comment(
								$elm$core$Maybe$Just(editStatePageDetailsN96.il)),
								$author$project$Api$ServerActionRequestN96UpdateMetadataPayload$ServerActionRequestN96UpdateMetadataN96DescUser(
								$elm$core$Maybe$Just(editStatePageDetailsN96.im))
							]);
						var cmd_ = $author$project$UpdateCmd$sendWebsocketMsg(
							$author$project$Api$WebsocketMsgFromClient$WebsocketMsgFromClientPayloadTrunk_(
								A2($author$project$Api$ServerActionRequest$ServerActionUpdateN96Metadata, n96, metadata_payloads)));
						return _Utils_Tuple2(mdl, cmd_);
					}
				case 24:
					var n96 = msg.a;
					var isHidden = msg.b;
					var cmd_ = $author$project$UpdateCmd$sendWebsocketMsg(
						$author$project$Api$WebsocketMsgFromClient$WebsocketMsgFromClientPayloadTrunk_(
							A2(
								$author$project$Api$ServerActionRequest$ServerActionUpdateN96Metadata,
								n96,
								_List_fromArray(
									[
										$author$project$Api$ServerActionRequestN96UpdateMetadataPayload$ServerActionRequestN96UpdateMetadataN96IsHidden(isHidden)
									]))));
					return _Utils_Tuple2(mdl, cmd_);
				case 19:
					var val = msg.a;
					var model_ = A2(
						$author$project$Pages$PageCommon$updateModelUpdateOptions,
						function (o) {
							return _Utils_update(
								o,
								{iF: val});
						},
						mdl);
					return _Utils_Tuple2(model_, $elm$core$Platform$Cmd$none);
				case 20:
					var val = msg.a;
					var model_ = A2(
						$author$project$Pages$PageCommon$updateModelUpdateOptions,
						function (o) {
							return _Utils_update(
								o,
								{hz: val});
						},
						mdl);
					return _Utils_Tuple2(model_, $elm$core$Platform$Cmd$none);
				default:
					var uiPageUpdateType = msg.a;
					var page_infoM = $author$project$PageCommonInfo$buildPageCommonInfo(mdl);
					if (page_infoM.$ === 1) {
						var $temp$msg = $author$project$Msg$InternalError('NoPageInfo??'),
							$temp$mdl = mdl;
						msg = $temp$msg;
						mdl = $temp$mdl;
						continue update;
					} else {
						var pageInfo = page_infoM.a;
						switch (uiPageUpdateType.$) {
							case 11:
								var editmsg = uiPageUpdateType.a;
								var _v21 = A2($author$project$Components$UserGroupTableTypes$updateEditStateUserGroupTable, pageInfo, editmsg);
								if (!_v21.$) {
									var _v22 = _v21.a;
									var updateFunc = _v22.a;
									var cmd = _v22.b;
									return _Utils_Tuple2(
										A2(
											$author$project$Model$ModelUpdate$mdlUpdateEditStateUserAccess,
											$author$project$Model$ModelUpdate$updateEditStateUserAccessUpdateUserGroupTable(updateFunc),
											mdl),
										cmd);
								} else {
									var page = _v21.a;
									var $temp$msg = $author$project$Msg$RunGlobalAction(page),
										$temp$mdl = mdl;
									msg = $temp$msg;
									mdl = $temp$mdl;
									continue update;
								}
							case 12:
								var editmsg = uiPageUpdateType.a;
								var _v23 = A2($author$project$Components$N80GroupTableTypes$updateEditStateN80GroupTable, pageInfo, editmsg);
								if (!_v23.$) {
									var _v24 = _v23.a;
									var updateFunc = _v24.a;
									var cmd = _v24.b;
									return _Utils_Tuple2(
										A2(
											$author$project$Model$ModelUpdate$mdlUpdateEditStateUserAccess,
											$author$project$Model$ModelUpdate$updateEditStateUserAccessUpdateN80GroupTable(updateFunc),
											mdl),
										cmd);
								} else {
									var page = _v23.a;
									var $temp$msg = $author$project$Msg$RunGlobalAction(page),
										$temp$mdl = mdl;
									msg = $temp$msg;
									mdl = $temp$mdl;
									continue update;
								}
							case 13:
								var editmsg = uiPageUpdateType.a;
								var _v25 = A2($author$project$Components$UserGroupTableTypes$updateEditStateUserGroupTable, pageInfo, editmsg);
								if (!_v25.$) {
									var _v26 = _v25.a;
									var updateFunc = _v26.a;
									var cmd = _v26.b;
									return _Utils_Tuple2(
										A2(
											$author$project$Model$ModelUpdate$mdlUpdateEditStateAdminAccess,
											$author$project$Model$ModelUpdate$updateEditStateAdminAccessUpdateUserGroupTable(updateFunc),
											mdl),
										cmd);
								} else {
									var page = _v25.a;
									var $temp$msg = $author$project$Msg$RunGlobalAction(page),
										$temp$mdl = mdl;
									msg = $temp$msg;
									mdl = $temp$mdl;
									continue update;
								}
							case 14:
								var editmsg = uiPageUpdateType.a;
								var _v27 = A2($author$project$Components$N80GroupTableTypes$updateEditStateN80GroupTable, pageInfo, editmsg);
								if (!_v27.$) {
									var _v28 = _v27.a;
									var updateFunc = _v28.a;
									var cmd = _v28.b;
									return _Utils_Tuple2(
										A2(
											$author$project$Model$ModelUpdate$mdlUpdateEditStateAdminAccess,
											$author$project$Model$ModelUpdate$updateEditStateAdminAccessUpdateN80GroupTable(updateFunc),
											mdl),
										cmd);
								} else {
									var page = _v27.a;
									var $temp$msg = $author$project$Msg$RunGlobalAction(page),
										$temp$mdl = mdl;
									msg = $temp$msg;
									mdl = $temp$mdl;
									continue update;
								}
							case 15:
								var editmsg = uiPageUpdateType.a;
								var _v29 = A2($author$project$Components$UserGroupUserMembershipTableTypes$updateEditStateUserGroupMembershipTable, pageInfo, editmsg);
								if (!_v29.$) {
									var _v30 = _v29.a;
									var updateFunc = _v30.a;
									var cmd = _v30.b;
									return _Utils_Tuple2(
										A2(
											$author$project$Model$ModelUpdate$mdlUpdateEditStateUserAccess,
											$author$project$Model$ModelUpdate$updateEditStateAdminAccessUpdateUserGroupMembership(updateFunc),
											mdl),
										cmd);
								} else {
									var page = _v29.a;
									var $temp$msg = $author$project$Msg$RunGlobalAction(page),
										$temp$mdl = mdl;
									msg = $temp$msg;
									mdl = $temp$mdl;
									continue update;
								}
							case 16:
								var editmsg = uiPageUpdateType.a;
								var _v31 = A2($author$project$Components$UserGroupToN80GroupAccessTable$updateEditStateUserGroupToN80GroupAccessTable, pageInfo, editmsg);
								if (!_v31.$) {
									var _v32 = _v31.a;
									var updateFunc = _v32.a;
									var cmd = _v32.b;
									return _Utils_Tuple2(
										A2(
											$author$project$Model$ModelUpdate$mdlUpdateEditStateUserAccess,
											$author$project$Model$ModelUpdate$updateEditStateAdminAccessUpdateUserGroupToN80GroupAccess(updateFunc),
											mdl),
										cmd);
								} else {
									var page = _v31.a;
									var $temp$msg = $author$project$Msg$RunGlobalAction(page),
										$temp$mdl = mdl;
									msg = $temp$msg;
									mdl = $temp$mdl;
									continue update;
								}
							case 17:
								var editmsg = uiPageUpdateType.a;
								var _v33 = A2($author$project$Components$UserGroupUserMembershipTableTypes$updateEditStateUserGroupMembershipTable, pageInfo, editmsg);
								if (!_v33.$) {
									var _v34 = _v33.a;
									var updateFunc = _v34.a;
									var cmd = _v34.b;
									return _Utils_Tuple2(
										A2(
											$author$project$Model$ModelUpdate$mdlUpdateEditStatePageDetailsUserGroup,
											$author$project$Model$ModelUpdate$updateEditStateAdminAccessUpdateUserGroupMembershipRENAME(updateFunc),
											mdl),
										cmd);
								} else {
									var page = _v33.a;
									var $temp$msg = $author$project$Msg$RunGlobalAction(page),
										$temp$mdl = mdl;
									msg = $temp$msg;
									mdl = $temp$mdl;
									continue update;
								}
							case 18:
								var editmsg = uiPageUpdateType.a;
								var _v35 = A2($author$project$Components$UserGroupToN80GroupAccessTable$updateEditStateUserGroupToN80GroupAccessTable, pageInfo, editmsg);
								if (!_v35.$) {
									var _v36 = _v35.a;
									var updateFunc = _v36.a;
									var cmd = _v36.b;
									return _Utils_Tuple2(
										A2(
											$author$project$Model$ModelUpdate$mdlUpdateEditStatePageDetailsUserGroup,
											$author$project$Model$ModelUpdate$updateEditStateAdminAccessUpdateUserGroupToN80GroupAccessRENAME(updateFunc),
											mdl),
										cmd);
								} else {
									var page = _v35.a;
									var $temp$msg = $author$project$Msg$RunGlobalAction(page),
										$temp$mdl = mdl;
									msg = $temp$msg;
									mdl = $temp$mdl;
									continue update;
								}
							case 19:
								var editmsg = uiPageUpdateType.a;
								var _v37 = A2($author$project$Components$N80GroupMembershipTable$updateEditStateN80GroupMembershipTable, pageInfo, editmsg);
								if (!_v37.$) {
									var _v38 = _v37.a;
									var updateFunc = _v38.a;
									var cmd = _v38.b;
									return _Utils_Tuple2(
										A2(
											$author$project$Model$ModelUpdate$mdlUpdateEditStatePageDetailsN80Group,
											$author$project$Model$ModelUpdate$updateEditStateDetailsN80GroupN80GroupMembership(updateFunc),
											mdl),
										cmd);
								} else {
									var page = _v37.a;
									var $temp$msg = $author$project$Msg$RunGlobalAction(page),
										$temp$mdl = mdl;
									msg = $temp$msg;
									mdl = $temp$mdl;
									continue update;
								}
							case 2:
								var owner = uiPageUpdateType.a;
								return _Utils_Tuple2(
									A2(
										$author$project$Model$ModelUpdate$mdlUpdateN80OwnerEditState,
										$author$project$BigO$EditState$setEditState(owner),
										mdl),
									$elm$core$Platform$Cmd$none);
							case 5:
								var grp = uiPageUpdateType.a;
								var model_ = A2(
									$author$project$Model$ModelUpdate$mdlUpdateN80GroupEditState,
									$author$project$BigO$EditState$updateEditState(
										$author$project$BigO$Utils$listAppendUnique(grp)),
									mdl);
								return _Utils_Tuple2(model_, $elm$core$Platform$Cmd$none);
							case 6:
								var grp = uiPageUpdateType.a;
								var model_ = A2(
									$author$project$Model$ModelUpdate$mdlUpdateN80GroupEditState,
									$author$project$BigO$EditState$updateEditState(
										$author$project$BigO$Utils$listRemove(grp)),
									mdl);
								return _Utils_Tuple2(model_, $elm$core$Platform$Cmd$none);
							case 0:
								var desc = uiPageUpdateType.a;
								return _Utils_Tuple2(
									A2(
										$author$project$Model$ModelUpdate$mdlUpdateN80DescUserEditState,
										$author$project$BigO$EditState$setEditState(desc),
										mdl),
									$elm$core$Platform$Cmd$none);
							case 8:
								var _v39 = mdl.dM;
								if (_v39.$ === 1) {
									return _Utils_Tuple2(mdl, $elm$core$Platform$Cmd$none);
								} else {
									var editStatePageDetailsN80 = _v39.a;
									var _v40 = $author$project$Model$ModelUpdate$getN80PageEditStateServerPayloads(editStatePageDetailsN80);
									if (_v40.$ === 1) {
										return _Utils_Tuple2(mdl, $elm$core$Platform$Cmd$none);
									} else {
										var msg_payload = _v40.a;
										var mdl_ = A2($author$project$Model$ModelUpdate$mdlUpdateN80EditState, $author$project$Model$ModelUpdate$setN80PageEditStateServerPayloadsToSavingToServer, mdl);
										var cmd_ = $author$project$UpdateCmd$sendWebsocketMsg(
											$author$project$Api$WebsocketMsgFromClient$WebsocketMsgFromClientPayloadTrunk_(msg_payload));
										return _Utils_Tuple2(mdl_, cmd_);
									}
								}
							case 1:
								return _Utils_Tuple2(
									A2($author$project$Model$ModelUpdate$mdlUpdateN80DescUserEditState, $author$project$BigO$EditState$revertEditState, mdl),
									$elm$core$Platform$Cmd$none);
							case 3:
								return _Utils_Tuple2(
									A2($author$project$Model$ModelUpdate$mdlUpdateN80OwnerEditState, $author$project$BigO$EditState$revertEditState, mdl),
									$elm$core$Platform$Cmd$none);
							case 7:
								return _Utils_Tuple2(
									A2($author$project$Model$ModelUpdate$mdlUpdateN80GroupEditState, $author$project$BigO$EditState$revertEditState, mdl),
									$elm$core$Platform$Cmd$none);
							case 4:
								var autoCompleteMsg = uiPageUpdateType.a;
								var usrMsgFunc = function () {
									var _v41 = $author$project$ElmCommon$Autocomplete$getSelectUserMsg(autoCompleteMsg);
									if (_v41.$ === 1) {
										return function (m) {
											return _Utils_Tuple2(m, $elm$core$Platform$Cmd$none);
										};
									} else {
										var func = _v41.a;
										var func_ = $author$project$Msg$UiPageUpdate(func);
										return $author$project$Update$update(func_);
									}
								}();
								return usrMsgFunc(
									A2(
										$author$project$Model$ModelUpdate$updateGroupsAutocomplete,
										$author$project$ElmCommon$Autocomplete$updateAutocomplete(autoCompleteMsg),
										mdl));
							case 9:
								var n80 = uiPageUpdateType.a;
								var cmd_ = $author$project$UpdateCmd$sendWebsocketMsg(
									$author$project$Api$WebsocketMsgFromClient$WebsocketMsgFromClientPayloadTrunk_(
										A2($author$project$Api$ServerActionRequest$ServerActionBlacklistN80, n80, 'Manually Blacklisted')));
								return _Utils_Tuple2(mdl, cmd_);
							default:
								var n80 = uiPageUpdateType.a;
								var cmd_ = $author$project$UpdateCmd$sendWebsocketMsg(
									$author$project$Api$WebsocketMsgFromClient$WebsocketMsgFromClientPayloadTrunk_(
										$author$project$Api$ServerActionRequest$ServerActionUnBlacklistN80(n80)));
								return _Utils_Tuple2(mdl, cmd_);
						}
					}
			}
		}
	});
var $elm$html$Html$div = _VirtualDom_node('div');
var $rundis$elm_bootstrap$Bootstrap$Grid$container = F2(
	function (attributes, children) {
		return A2(
			$elm$html$Html$div,
			_Utils_ap(
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('container')
					]),
				attributes),
			children);
	});
var $author$project$Components$N80GroupTableTypes$N80GroupTblColAccessPublic = 2;
var $author$project$Components$N80GroupTableTypes$N80GroupTblColAccessUser = 1;
var $author$project$Components$N80GroupTableTypes$N80GroupTblColActions = 5;
var $author$project$Components$N80GroupTableTypes$N80GroupTblColGroupOwner = 3;
var $author$project$Components$N80GroupTableTypes$N80GroupTblColMembers = 4;
var $author$project$Components$N80GroupTableTypes$N80GroupTblColName = 0;
var $author$project$Msg$UiUpdatePageAdminAccessN80GroupTable = function (a) {
	return {$: 14, a: a};
};
var $author$project$Msg$UiUpdatePageAdminAccessUserGroupTable = function (a) {
	return {$: 13, a: a};
};
var $author$project$Components$UserGroupTableTypes$UserGroupTblColActions = 5;
var $author$project$Components$UserGroupTableTypes$UserGroupTblColGroupAccess = 1;
var $author$project$Components$UserGroupTableTypes$UserGroupTblColGroupName = 0;
var $author$project$Components$UserGroupTableTypes$UserGroupTblColN80Groups = 4;
var $author$project$Components$UserGroupTableTypes$UserGroupTblColNMembers = 3;
var $author$project$Components$UserGroupTableTypes$UserGroupTblColOwner = 2;
var $author$project$Components$N80GroupTableTypes$N80GroupTableActionCreate = F3(
	function (a, b, c) {
		return {$: 2, a: a, b: b, c: c};
	});
var $author$project$Components$N80GroupTableTypes$N80GroupTableActionUpdateDropdownAuth = function (a) {
	return {$: 4, a: a};
};
var $author$project$Components$N80GroupTableTypes$N80GroupTableMsgUpdateGroupName = function (a) {
	return {$: 0, a: a};
};
var $author$project$ElmCommon$Dropdown$DropDownMsgSelect = function (a) {
	return {$: 1, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Dropdown$AlignMenuRight = {$: 1};
var $rundis$elm_bootstrap$Bootstrap$Dropdown$alignMenuRight = $rundis$elm_bootstrap$Bootstrap$Dropdown$AlignMenuRight;
var $rundis$elm_bootstrap$Bootstrap$Dropdown$DropdownItem = $elm$core$Basics$identity;
var $rundis$elm_bootstrap$Bootstrap$Dropdown$buttonItem = F2(
	function (attributes, children) {
		return A2(
			$elm$html$Html$button,
			_Utils_ap(
				_List_fromArray(
					[
						$elm$html$Html$Attributes$type_('button'),
						$elm$html$Html$Attributes$class('dropdown-item')
					]),
				attributes),
			children);
	});
var $rundis$elm_bootstrap$Bootstrap$Dropdown$dropDir = function (maybeDir) {
	var toAttrs = function (dir) {
		return _List_fromArray(
			[
				$elm$html$Html$Attributes$class(
				'drop' + function () {
					if (!dir) {
						return 'left';
					} else {
						return 'right';
					}
				}())
			]);
	};
	return A2(
		$elm$core$Maybe$withDefault,
		_List_Nil,
		A2($elm$core$Maybe$map, toAttrs, maybeDir));
};
var $rundis$elm_bootstrap$Bootstrap$Dropdown$dropdownAttributes = F2(
	function (status, config) {
		return _Utils_ap(
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('btn-group', true),
							_Utils_Tuple2('show', status !== 2),
							_Utils_Tuple2('dropup', config.ao)
						]))
				]),
			_Utils_ap(
				$rundis$elm_bootstrap$Bootstrap$Dropdown$dropDir(config.ak),
				config.aU));
	});
var $elm$core$String$fromFloat = _String_fromNumber;
var $elm$virtual_dom$VirtualDom$style = _VirtualDom_style;
var $elm$html$Html$Attributes$style = $elm$virtual_dom$VirtualDom$style;
var $rundis$elm_bootstrap$Bootstrap$Dropdown$menuStyles = F2(
	function (_v0, config) {
		var status = _v0.v;
		var toggleSize = _v0.b1;
		var menuSize = _v0.as;
		var px = function (n) {
			return $elm$core$String$fromFloat(n) + 'px';
		};
		var translate = F3(
			function (x, y, z) {
				return 'translate3d(' + (px(x) + (',' + (px(y) + (',' + (px(z) + ')')))));
			});
		var _default = _List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'top', '0'),
				A2($elm$html$Html$Attributes$style, 'left', '0')
			]);
		var _v1 = _Utils_Tuple2(config.ao, config.ak);
		_v1$0:
		while (true) {
			if (!_v1.b.$) {
				if (_v1.b.a === 1) {
					if (_v1.a) {
						break _v1$0;
					} else {
						var _v2 = _v1.b.a;
						return _default;
					}
				} else {
					if (_v1.a) {
						break _v1$0;
					} else {
						var _v3 = _v1.b.a;
						return _Utils_ap(
							_default,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$Attributes$style,
									'transform',
									A3(translate, (-toggleSize.g7) - menuSize.g7, 0, 0))
								]));
					}
				}
			} else {
				if (_v1.a) {
					break _v1$0;
				} else {
					return _Utils_ap(
						_default,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$Attributes$style,
								'transform',
								A3(translate, -toggleSize.g7, toggleSize.d9, 0))
							]));
				}
			}
		}
		return _Utils_ap(
			_default,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$Attributes$style,
					'transform',
					A3(translate, -toggleSize.g7, -menuSize.d9, 0))
				]));
	});
var $rundis$elm_bootstrap$Bootstrap$Dropdown$dropdownMenu = F3(
	function (state, config, items) {
		var status = state.v;
		var menuSize = state.as;
		var wrapperStyles = (status === 2) ? _List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'height', '0'),
				A2($elm$html$Html$Attributes$style, 'overflow', 'hidden'),
				A2($elm$html$Html$Attributes$style, 'position', 'relative')
			]) : _List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'position', 'relative')
			]);
		return A2(
			$elm$html$Html$div,
			wrapperStyles,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_Utils_ap(
						_List_fromArray(
							[
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2('dropdown-menu', true),
										_Utils_Tuple2('dropdown-menu-right', config.bg),
										_Utils_Tuple2('show', status !== 2)
									]))
							]),
						_Utils_ap(
							A2($rundis$elm_bootstrap$Bootstrap$Dropdown$menuStyles, state, config),
							config.bn)),
					A2(
						$elm$core$List$map,
						function (_v0) {
							var x = _v0;
							return x;
						},
						items))
				]));
	});
var $rundis$elm_bootstrap$Bootstrap$Dropdown$applyModifier = F2(
	function (option, options) {
		switch (option.$) {
			case 1:
				return _Utils_update(
					options,
					{bg: true});
			case 0:
				return _Utils_update(
					options,
					{ao: true});
			case 4:
				var attrs_ = option.a;
				return _Utils_update(
					options,
					{aU: attrs_});
			case 2:
				var dir = option.a;
				return _Utils_update(
					options,
					{
						ak: $elm$core$Maybe$Just(dir)
					});
			default:
				var attrs_ = option.a;
				return _Utils_update(
					options,
					{bn: attrs_});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Dropdown$defaultOptions = {aU: _List_Nil, ak: $elm$core$Maybe$Nothing, bg: false, ao: false, bn: _List_Nil};
var $rundis$elm_bootstrap$Bootstrap$Dropdown$toConfig = function (options) {
	return A3($elm$core$List$foldl, $rundis$elm_bootstrap$Bootstrap$Dropdown$applyModifier, $rundis$elm_bootstrap$Bootstrap$Dropdown$defaultOptions, options);
};
var $rundis$elm_bootstrap$Bootstrap$Dropdown$dropdown = F2(
	function (state, _v0) {
		var status = state.v;
		var toggleMsg = _v0.iU;
		var toggleButton = _v0.iT;
		var items = _v0.h0;
		var options = _v0.it;
		var config = $rundis$elm_bootstrap$Bootstrap$Dropdown$toConfig(options);
		var _v1 = toggleButton;
		var buttonFn = _v1;
		return A2(
			$elm$html$Html$div,
			A2($rundis$elm_bootstrap$Bootstrap$Dropdown$dropdownAttributes, status, config),
			_List_fromArray(
				[
					A2(buttonFn, toggleMsg, state),
					A3($rundis$elm_bootstrap$Bootstrap$Dropdown$dropdownMenu, state, config, items)
				]));
	});
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $author$project$ElmCommon$Dropdown$buildDropdownWidget = function (dropdown) {
	var items1 = A2(
		$elm$core$List$map,
		function (o) {
			return A2(
				$rundis$elm_bootstrap$Bootstrap$Dropdown$buttonItem,
				_List_fromArray(
					[
						$elm$html$Html$Events$onClick(
						$author$project$ElmCommon$Dropdown$DropDownMsgSelect(o))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						dropdown.bZ(o))
					]));
		},
		dropdown.it);
	var widget = A2(
		$rundis$elm_bootstrap$Bootstrap$Dropdown$dropdown,
		dropdown.a8,
		{
			h0: items1,
			it: _List_fromArray(
				[$rundis$elm_bootstrap$Bootstrap$Dropdown$alignMenuRight]),
			iT: dropdown.a9,
			iU: $author$project$ElmCommon$Dropdown$DropDownMsgToggle
		});
	return widget;
};
var $author$project$Components$N80GroupTable$buildN80GroupTblColHdr = function (col) {
	switch (col) {
		case 0:
			return $elm$html$Html$text('Name');
		case 1:
			return $elm$html$Html$text('Access Granted');
		case 2:
			return $elm$html$Html$text('Public Access');
		case 3:
			return $elm$html$Html$text('Group Owner');
		case 4:
			return $elm$html$Html$text('Members');
		default:
			return $elm$html$Html$text('Actions');
	}
};
var $author$project$Components$N80GroupTableTypes$N80GroupTableActionDelete = function (a) {
	return {$: 3, a: a};
};
var $author$project$Components$N80GroupTableTypes$N80GroupTableMsgUpdateGlobalAction = function (a) {
	return {$: 1, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Badge$Pill = {$: 1};
var $rundis$elm_bootstrap$Bootstrap$Badge$Primary = 0;
var $rundis$elm_bootstrap$Bootstrap$Badge$Roled = function (a) {
	return {$: 0, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Badge$roleOption = function (role) {
	switch (role) {
		case 0:
			return 'badge-primary';
		case 1:
			return 'badge-secondary';
		case 2:
			return 'badge-success';
		case 3:
			return 'badge-info';
		case 4:
			return 'badge-warning';
		case 5:
			return 'badge-danger';
		case 6:
			return 'badge-light';
		default:
			return 'badge-dark';
	}
};
var $rundis$elm_bootstrap$Bootstrap$Badge$badgeClass = function (option) {
	return $elm$html$Html$Attributes$class(
		function () {
			if (option.$ === 1) {
				return 'badge-pill';
			} else {
				var role = option.a;
				return $rundis$elm_bootstrap$Bootstrap$Badge$roleOption(role);
			}
		}());
};
var $rundis$elm_bootstrap$Bootstrap$Badge$badgeAttributes = function (options) {
	return A2(
		$elm$core$List$cons,
		$elm$html$Html$Attributes$class('badge'),
		A2($elm$core$List$map, $rundis$elm_bootstrap$Bootstrap$Badge$badgeClass, options));
};
var $elm$html$Html$span = _VirtualDom_node('span');
var $rundis$elm_bootstrap$Bootstrap$Badge$badgeInternal = F3(
	function (options, attributes, children) {
		return A2(
			$elm$html$Html$span,
			_Utils_ap(
				$rundis$elm_bootstrap$Bootstrap$Badge$badgeAttributes(options),
				attributes),
			children);
	});
var $rundis$elm_bootstrap$Bootstrap$Badge$pillPrimary = $rundis$elm_bootstrap$Bootstrap$Badge$badgeInternal(
	_List_fromArray(
		[
			$rundis$elm_bootstrap$Bootstrap$Badge$Roled(0),
			$rundis$elm_bootstrap$Bootstrap$Badge$Pill
		]));
var $author$project$Components$CommonPageComponents$buildAccessLevel = function (typ_) {
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Badge$pillPrimary,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text(
				$author$project$BigO$TypesAsStrings$showAccessLevel_(typ_))
			]));
};
var $author$project$TypesPage$NavigateTo = $elm$core$Basics$identity;
var $author$project$TypesPage$PageDetailsN80Group = function (a) {
	return {$: 6, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Button$button = F2(
	function (options, children) {
		return A2(
			$elm$html$Html$button,
			$rundis$elm_bootstrap$Bootstrap$Internal$Button$buttonAttributes(options),
			children);
	});
var $rundis$elm_bootstrap$Bootstrap$Internal$Button$Attrs = function (a) {
	return {$: 4, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Button$attrs = function (attrs_) {
	return $rundis$elm_bootstrap$Bootstrap$Internal$Button$Attrs(attrs_);
};
var $elm$virtual_dom$VirtualDom$MayPreventDefault = function (a) {
	return {$: 2, a: a};
};
var $elm$html$Html$Events$preventDefaultOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayPreventDefault(decoder));
	});
var $rundis$elm_bootstrap$Bootstrap$Button$onClick = function (message) {
	return $rundis$elm_bootstrap$Bootstrap$Button$attrs(
		_List_fromArray(
			[
				A2(
				$elm$html$Html$Events$preventDefaultOn,
				'click',
				$elm$json$Json$Decode$succeed(
					_Utils_Tuple2(message, true)))
			]));
};
var $rundis$elm_bootstrap$Bootstrap$Internal$Button$Info = 3;
var $rundis$elm_bootstrap$Bootstrap$Internal$Button$Outlined = function (a) {
	return {$: 1, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Button$outlineInfo = $rundis$elm_bootstrap$Bootstrap$Internal$Button$Coloring(
	$rundis$elm_bootstrap$Bootstrap$Internal$Button$Outlined(3));
var $rundis$elm_bootstrap$Bootstrap$General$Internal$SM = 1;
var $rundis$elm_bootstrap$Bootstrap$Internal$Button$Size = function (a) {
	return {$: 0, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Button$small = $rundis$elm_bootstrap$Bootstrap$Internal$Button$Size(1);
var $author$project$Components$CommonPageComponents$buildElemN80GroupStd = function (n80GroupInfo) {
	var contents_std = _List_fromArray(
		[
			$elm$html$Html$text(n80GroupInfo.di.gG)
		]);
	var contents_remove = _List_Nil;
	var contents = _Utils_ap(contents_std, contents_remove);
	var attrs_onclick = _List_fromArray(
		[
			$rundis$elm_bootstrap$Bootstrap$Button$onClick(
			$author$project$TypesPage$PageDetailsN80Group(n80GroupInfo.dh))
		]);
	var attrs = _Utils_ap(
		_List_fromArray(
			[$rundis$elm_bootstrap$Bootstrap$Button$small, $rundis$elm_bootstrap$Bootstrap$Button$outlineInfo]),
		attrs_onclick);
	return A2($rundis$elm_bootstrap$Bootstrap$Button$button, attrs, contents);
};
var $author$project$TypesPage$PageDetailsN80 = function (a) {
	return {$: 2, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Badge$badgePrimary = $rundis$elm_bootstrap$Bootstrap$Badge$badgeInternal(
	_List_fromArray(
		[
			$rundis$elm_bootstrap$Bootstrap$Badge$Roled(0)
		]));
var $author$project$Pages$PageCommon$isN80DescSimple = function (descSimpleM) {
	return (descSimpleM.gD === '') ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(descSimpleM);
};
var $author$project$Pages$PageCommon$isN80DescUser = function (descUserM) {
	return (descUserM.gE === '') ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(descUserM);
};
var $author$project$BigO$TypesAsStrings$showN80DescSimple = function (n80DescSimple) {
	return n80DescSimple.gD;
};
var $author$project$BigO$TypesAsStrings$showN80DescUser = function (n80DescUser) {
	return n80DescUser.gE;
};
var $elm$core$String$right = F2(
	function (n, string) {
		return (n < 1) ? '' : A3(
			$elm$core$String$slice,
			-n,
			$elm$core$String$length(string),
			string);
	});
var $author$project$BigO$TypesAsStrings$showN80Short = F2(
	function (cnt, n80) {
		var n80Full = $author$project$BigO$TypesAsStrings$toHexStrN80(n80);
		var n80TrimL = A2($elm$core$String$left, cnt, n80Full);
		var n80TrimR = A2($elm$core$String$right, cnt, n80Full);
		return n80TrimL + ('..' + n80TrimR);
	});
var $author$project$Components$CommonPageComponents$build_tblcell_device = function (n80Meta) {
	var node_name = function () {
		var _v0 = _Utils_Tuple2(
			$author$project$Pages$PageCommon$isN80DescUser(n80Meta.eF),
			$author$project$Pages$PageCommon$isN80DescSimple(n80Meta.eE));
		if (!_v0.a.$) {
			var descUser = _v0.a.a;
			return $author$project$BigO$TypesAsStrings$showN80DescUser(descUser);
		} else {
			if (!_v0.b.$) {
				var _v1 = _v0.a;
				var descSimple = _v0.b.a;
				return $author$project$BigO$TypesAsStrings$showN80DescSimple(descSimple);
			} else {
				var _v2 = _v0.a;
				var _v3 = _v0.b;
				return 'Node: ' + A2($author$project$BigO$TypesAsStrings$showN80Short, 2, n80Meta.eC);
			}
		}
	}();
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Badge$badgePrimary,
		_List_fromArray(
			[
				$elm$html$Html$Events$onClick(
				$author$project$TypesPage$PageDetailsN80(n80Meta.eC))
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(node_name)
			]));
};
var $elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var $elm$html$Html$map = $elm$virtual_dom$VirtualDom$map;
var $author$project$Components$N80GroupTable$buildN80Member = F2(
	function (n80, n80MetaM) {
		if (n80MetaM.$ === 1) {
			return $elm$html$Html$text('WHY IS THIS MISSING?');
		} else {
			var metaN80 = n80MetaM.a;
			return A2(
				$elm$html$Html$map,
				$author$project$Components$N80GroupTableTypes$N80GroupTableMsgUpdateGlobalAction,
				$author$project$Components$CommonPageComponents$build_tblcell_device(metaN80));
		}
	});
var $author$project$BigO$Utils$htmlEmpty = A2($elm$html$Html$div, _List_Nil, _List_Nil);
var $elm$svg$Svg$Attributes$class = _VirtualDom_attribute('class');
var $lattyware$elm_fontawesome$FontAwesome$Attributes$fw = $elm$svg$Svg$Attributes$class('fa-fw');
var $lattyware$elm_fontawesome$FontAwesome$Attributes$sm = $elm$svg$Svg$Attributes$class('fa-sm');
var $lattyware$elm_fontawesome$FontAwesome$Internal$Icon = $elm$core$Basics$identity;
var $lattyware$elm_fontawesome$FontAwesome$styled = F2(
	function (attributes, _v0) {
		var presentation = _v0;
		return _Utils_update(
			presentation,
			{
				aU: _Utils_ap(presentation.aU, attributes)
			});
	});
var $lattyware$elm_fontawesome$FontAwesome$present = function (icon) {
	return {aU: _List_Nil, ee: icon, ef: $elm$core$Maybe$Nothing, cD: $elm$core$Maybe$Nothing, cK: 'img', iS: $elm$core$Maybe$Nothing, b2: _List_Nil};
};
var $lattyware$elm_fontawesome$FontAwesome$IconDef = F4(
	function (prefix, name, size, paths) {
		return {$9: name, iv: paths, ix: prefix, ge: size};
	});
var $lattyware$elm_fontawesome$FontAwesome$Solid$Definitions$trash = A4(
	$lattyware$elm_fontawesome$FontAwesome$IconDef,
	'fas',
	'trash',
	_Utils_Tuple2(448, 512),
	_Utils_Tuple2('M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z', $elm$core$Maybe$Nothing));
var $lattyware$elm_fontawesome$FontAwesome$Solid$trash = $lattyware$elm_fontawesome$FontAwesome$present($lattyware$elm_fontawesome$FontAwesome$Solid$Definitions$trash);
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $elm$html$Html$Attributes$attribute = $elm$virtual_dom$VirtualDom$attribute;
var $elm$svg$Svg$Attributes$id = _VirtualDom_attribute('id');
var $elm$virtual_dom$VirtualDom$mapAttribute = _VirtualDom_mapAttribute;
var $elm$html$Html$Attributes$map = $elm$virtual_dom$VirtualDom$mapAttribute;
var $elm$svg$Svg$trustedNode = _VirtualDom_nodeNS('http://www.w3.org/2000/svg');
var $elm$svg$Svg$svg = $elm$svg$Svg$trustedNode('svg');
var $elm$svg$Svg$text = $elm$virtual_dom$VirtualDom$text;
var $elm$svg$Svg$title = $elm$svg$Svg$trustedNode('title');
var $lattyware$elm_fontawesome$FontAwesome$Internal$topLevelDimensions = function (_v1) {
	var icon = _v1.ee;
	var outer = _v1.cD;
	return A2(
		$elm$core$Maybe$withDefault,
		icon.ge,
		A2($elm$core$Maybe$map, $lattyware$elm_fontawesome$FontAwesome$Internal$topLevelDimensionsInternal, outer));
};
var $lattyware$elm_fontawesome$FontAwesome$Internal$topLevelDimensionsInternal = function (_v0) {
	var icon = _v0.ee;
	var outer = _v0.cD;
	return A2(
		$elm$core$Maybe$withDefault,
		icon.ge,
		A2($elm$core$Maybe$map, $lattyware$elm_fontawesome$FontAwesome$Internal$topLevelDimensions, outer));
};
var $elm$svg$Svg$defs = $elm$svg$Svg$trustedNode('defs');
var $elm$svg$Svg$Attributes$height = _VirtualDom_attribute('height');
var $elm$svg$Svg$Attributes$width = _VirtualDom_attribute('width');
var $elm$svg$Svg$Attributes$x = _VirtualDom_attribute('x');
var $elm$svg$Svg$Attributes$y = _VirtualDom_attribute('y');
var $lattyware$elm_fontawesome$FontAwesome$Svg$fill = _List_fromArray(
	[
		$elm$svg$Svg$Attributes$x('0'),
		$elm$svg$Svg$Attributes$y('0'),
		$elm$svg$Svg$Attributes$width('100%'),
		$elm$svg$Svg$Attributes$height('100%')
	]);
var $elm$svg$Svg$Attributes$fill = _VirtualDom_attribute('fill');
var $elm$svg$Svg$g = $elm$svg$Svg$trustedNode('g');
var $elm$svg$Svg$mask = $elm$svg$Svg$trustedNode('mask');
var $elm$svg$Svg$Attributes$mask = _VirtualDom_attribute('mask');
var $elm$svg$Svg$Attributes$maskContentUnits = _VirtualDom_attribute('maskContentUnits');
var $elm$svg$Svg$Attributes$maskUnits = _VirtualDom_attribute('maskUnits');
var $lattyware$elm_fontawesome$FontAwesome$Transforms$Internal$add = F2(
	function (transform, combined) {
		switch (transform.$) {
			case 0:
				var by = transform.a;
				return _Utils_update(
					combined,
					{ge: combined.ge + by});
			case 1:
				var axis = transform.a;
				var by = transform.b;
				var _v1 = function () {
					if (!axis) {
						return _Utils_Tuple2(0, by);
					} else {
						return _Utils_Tuple2(by, 0);
					}
				}();
				var x = _v1.a;
				var y = _v1.b;
				return _Utils_update(
					combined,
					{ha: combined.ha + x, hb: combined.hb + y});
			case 2:
				var rotation = transform.a;
				return _Utils_update(
					combined,
					{iB: combined.iB + rotation});
			default:
				var axis = transform.a;
				if (!axis) {
					return _Utils_update(
						combined,
						{hQ: !combined.hQ});
				} else {
					return _Utils_update(
						combined,
						{hP: !combined.hP});
				}
		}
	});
var $lattyware$elm_fontawesome$FontAwesome$Transforms$Internal$baseSize = 16;
var $lattyware$elm_fontawesome$FontAwesome$Transforms$Internal$meaninglessTransform = {hP: false, hQ: false, iB: 0, ge: $lattyware$elm_fontawesome$FontAwesome$Transforms$Internal$baseSize, ha: 0, hb: 0};
var $lattyware$elm_fontawesome$FontAwesome$Transforms$Internal$combine = function (transforms) {
	return A3($elm$core$List$foldl, $lattyware$elm_fontawesome$FontAwesome$Transforms$Internal$add, $lattyware$elm_fontawesome$FontAwesome$Transforms$Internal$meaninglessTransform, transforms);
};
var $lattyware$elm_fontawesome$FontAwesome$Transforms$Internal$meaningfulTransform = function (transforms) {
	var combined = $lattyware$elm_fontawesome$FontAwesome$Transforms$Internal$combine(transforms);
	return _Utils_eq(combined, $lattyware$elm_fontawesome$FontAwesome$Transforms$Internal$meaninglessTransform) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(combined);
};
var $elm$svg$Svg$rect = $elm$svg$Svg$trustedNode('rect');
var $elm$svg$Svg$Attributes$transform = _VirtualDom_attribute('transform');
var $lattyware$elm_fontawesome$FontAwesome$Transforms$Internal$transformForSvg = F3(
	function (containerWidth, iconWidth, transform) {
		var path = 'translate(' + ($elm$core$String$fromFloat((iconWidth / 2) * (-1)) + ' -256)');
		var outer = 'translate(' + ($elm$core$String$fromFloat(containerWidth / 2) + ' 256)');
		var innerTranslate = 'translate(' + ($elm$core$String$fromFloat(transform.ha * 32) + (',' + ($elm$core$String$fromFloat(transform.hb * 32) + ') ')));
		var innerRotate = 'rotate(' + ($elm$core$String$fromFloat(transform.iB) + ' 0 0)');
		var flipY = transform.hQ ? (-1) : 1;
		var scaleY = (transform.ge / $lattyware$elm_fontawesome$FontAwesome$Transforms$Internal$baseSize) * flipY;
		var flipX = transform.hP ? (-1) : 1;
		var scaleX = (transform.ge / $lattyware$elm_fontawesome$FontAwesome$Transforms$Internal$baseSize) * flipX;
		var innerScale = 'scale(' + ($elm$core$String$fromFloat(scaleX) + (', ' + ($elm$core$String$fromFloat(scaleY) + ') ')));
		return {
			eh: $elm$svg$Svg$Attributes$transform(
				_Utils_ap(
					innerTranslate,
					_Utils_ap(innerScale, innerRotate))),
			cD: $elm$svg$Svg$Attributes$transform(outer),
			fB: $elm$svg$Svg$Attributes$transform(path)
		};
	});
var $elm$svg$Svg$Attributes$d = _VirtualDom_attribute('d');
var $elm$svg$Svg$path = $elm$svg$Svg$trustedNode('path');
var $lattyware$elm_fontawesome$FontAwesome$Svg$viewPath = F2(
	function (attrs, d) {
		return A2(
			$elm$svg$Svg$path,
			A2(
				$elm$core$List$cons,
				$elm$svg$Svg$Attributes$d(d),
				attrs),
			_List_Nil);
	});
var $lattyware$elm_fontawesome$FontAwesome$Svg$viewPaths = F2(
	function (attrs, _v0) {
		var paths = _v0.iv;
		if (paths.b.$ === 1) {
			var only = paths.a;
			var _v2 = paths.b;
			return A2($lattyware$elm_fontawesome$FontAwesome$Svg$viewPath, attrs, only);
		} else {
			var secondary = paths.a;
			var primary = paths.b.a;
			return A2(
				$elm$svg$Svg$g,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$class('fa-group')
					]),
				_List_fromArray(
					[
						A2(
						$lattyware$elm_fontawesome$FontAwesome$Svg$viewPath,
						A2(
							$elm$core$List$cons,
							$elm$svg$Svg$Attributes$class('fa-secondary'),
							attrs),
						secondary),
						A2(
						$lattyware$elm_fontawesome$FontAwesome$Svg$viewPath,
						A2(
							$elm$core$List$cons,
							$elm$svg$Svg$Attributes$class('fa-primary'),
							attrs),
						primary)
					]));
		}
	});
var $lattyware$elm_fontawesome$FontAwesome$Svg$viewWithTransform = F3(
	function (color, _v0, icon) {
		var outer = _v0.cD;
		var inner = _v0.eh;
		var path = _v0.fB;
		return A2(
			$elm$svg$Svg$g,
			_List_fromArray(
				[outer]),
			_List_fromArray(
				[
					A2(
					$elm$svg$Svg$g,
					_List_fromArray(
						[inner]),
					_List_fromArray(
						[
							A2(
							$lattyware$elm_fontawesome$FontAwesome$Svg$viewPaths,
							_List_fromArray(
								[
									$elm$svg$Svg$Attributes$fill(color),
									path
								]),
							icon)
						]))
				]));
	});
var $lattyware$elm_fontawesome$FontAwesome$Svg$viewInColor = F2(
	function (color, fullIcon) {
		var icon = fullIcon.ee;
		var transforms = fullIcon.b2;
		var id = fullIcon.ef;
		var outer = fullIcon.cD;
		var combinedTransforms = $lattyware$elm_fontawesome$FontAwesome$Transforms$Internal$meaningfulTransform(transforms);
		var _v0 = icon.ge;
		var width = _v0.a;
		var _v1 = $lattyware$elm_fontawesome$FontAwesome$Internal$topLevelDimensions(fullIcon);
		var topLevelWidth = _v1.a;
		if (!combinedTransforms.$) {
			var meaningfulTransform = combinedTransforms.a;
			var svgTransform = A3($lattyware$elm_fontawesome$FontAwesome$Transforms$Internal$transformForSvg, topLevelWidth, width, meaningfulTransform);
			if (!outer.$) {
				var outerIcon = outer.a;
				return A4($lattyware$elm_fontawesome$FontAwesome$Svg$viewMaskedWithTransform, color, svgTransform, icon, outerIcon);
			} else {
				return A3($lattyware$elm_fontawesome$FontAwesome$Svg$viewWithTransform, color, svgTransform, icon);
			}
		} else {
			return A2(
				$lattyware$elm_fontawesome$FontAwesome$Svg$viewPaths,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$fill(color)
					]),
				icon);
		}
	});
var $lattyware$elm_fontawesome$FontAwesome$Svg$viewMaskedWithTransform = F4(
	function (color, transforms, exclude, include) {
		var id = include.ef;
		var alwaysId = A2($elm$core$Maybe$withDefault, '', id);
		var clipId = 'clip-' + alwaysId;
		var maskId = 'mask-' + alwaysId;
		var maskTag = A2(
			$elm$svg$Svg$mask,
			A2(
				$elm$core$List$cons,
				$elm$svg$Svg$Attributes$id(maskId),
				A2(
					$elm$core$List$cons,
					$elm$svg$Svg$Attributes$maskUnits('userSpaceOnUse'),
					A2(
						$elm$core$List$cons,
						$elm$svg$Svg$Attributes$maskContentUnits('userSpaceOnUse'),
						$lattyware$elm_fontawesome$FontAwesome$Svg$fill))),
			_List_fromArray(
				[
					A2($lattyware$elm_fontawesome$FontAwesome$Svg$viewInColor, 'white', include),
					A3($lattyware$elm_fontawesome$FontAwesome$Svg$viewWithTransform, 'black', transforms, exclude)
				]));
		var defs = A2(
			$elm$svg$Svg$defs,
			_List_Nil,
			_List_fromArray(
				[maskTag]));
		var rect = A2(
			$elm$svg$Svg$rect,
			A2(
				$elm$core$List$cons,
				$elm$svg$Svg$Attributes$fill(color),
				A2(
					$elm$core$List$cons,
					$elm$svg$Svg$Attributes$mask('url(#' + (maskId + ')')),
					$lattyware$elm_fontawesome$FontAwesome$Svg$fill)),
			_List_Nil);
		return A2(
			$elm$svg$Svg$g,
			_List_Nil,
			_List_fromArray(
				[defs, rect]));
	});
var $lattyware$elm_fontawesome$FontAwesome$Svg$view = $lattyware$elm_fontawesome$FontAwesome$Svg$viewInColor('currentColor');
var $elm$svg$Svg$Attributes$viewBox = _VirtualDom_attribute('viewBox');
var $lattyware$elm_fontawesome$FontAwesome$internalView = F2(
	function (fullIcon, extraAttributes) {
		var icon = fullIcon.ee;
		var transforms = fullIcon.b2;
		var role = fullIcon.cK;
		var id = fullIcon.ef;
		var title = fullIcon.iS;
		var outer = fullIcon.cD;
		var attributes = fullIcon.aU;
		var contents = $lattyware$elm_fontawesome$FontAwesome$Svg$view(fullIcon);
		var _v0 = function () {
			if (!title.$) {
				var givenTitle = title.a;
				var titleId = A2($elm$core$Maybe$withDefault, '', id) + '-title';
				return _Utils_Tuple2(
					A2($elm$html$Html$Attributes$attribute, 'aria-labelledby', titleId),
					_List_fromArray(
						[
							A2(
							$elm$svg$Svg$title,
							_List_fromArray(
								[
									$elm$svg$Svg$Attributes$id(titleId)
								]),
							_List_fromArray(
								[
									$elm$svg$Svg$text(givenTitle)
								])),
							contents
						]));
			} else {
				return _Utils_Tuple2(
					A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true'),
					_List_fromArray(
						[contents]));
			}
		}();
		var semantics = _v0.a;
		var potentiallyTitledContents = _v0.b;
		var _v2 = $lattyware$elm_fontawesome$FontAwesome$Internal$topLevelDimensions(fullIcon);
		var width = _v2.a;
		var height = _v2.b;
		var aspectRatio = $elm$core$Basics$ceiling((width / height) * 16);
		var classes = _List_fromArray(
			[
				'svg-inline--fa',
				'fa-' + icon.$9,
				'fa-w-' + $elm$core$String$fromInt(aspectRatio)
			]);
		return A2(
			$elm$svg$Svg$svg,
			$elm$core$List$concat(
				_List_fromArray(
					[
						_List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'role', role),
							A2($elm$html$Html$Attributes$attribute, 'xmlns', 'http://www.w3.org/2000/svg'),
							$elm$svg$Svg$Attributes$viewBox(
							'0 0 ' + ($elm$core$String$fromInt(width) + (' ' + $elm$core$String$fromInt(height)))),
							semantics
						]),
						A2($elm$core$List$map, $elm$svg$Svg$Attributes$class, classes),
						A2(
						$elm$core$List$map,
						$elm$html$Html$Attributes$map($elm$core$Basics$never),
						attributes),
						extraAttributes
					])),
			potentiallyTitledContents);
	});
var $lattyware$elm_fontawesome$FontAwesome$view = function (presentation) {
	return A2($lattyware$elm_fontawesome$FontAwesome$internalView, presentation, _List_Nil);
};
var $author$project$Components$CommonPageComponents$iconDelete = A2(
	$elm$html$Html$span,
	_List_Nil,
	_List_fromArray(
		[
			$lattyware$elm_fontawesome$FontAwesome$view(
			A2(
				$lattyware$elm_fontawesome$FontAwesome$styled,
				_List_fromArray(
					[$lattyware$elm_fontawesome$FontAwesome$Attributes$sm, $lattyware$elm_fontawesome$FontAwesome$Attributes$fw]),
				$lattyware$elm_fontawesome$FontAwesome$Solid$trash))
		]));
var $rundis$elm_bootstrap$Bootstrap$Internal$Button$Warning = 4;
var $rundis$elm_bootstrap$Bootstrap$Button$outlineWarning = $rundis$elm_bootstrap$Bootstrap$Internal$Button$Coloring(
	$rundis$elm_bootstrap$Bootstrap$Internal$Button$Outlined(4));
var $author$project$Components$CommonPageComponents$iconBtnDelete = function (onClick) {
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Button$button,
		_List_fromArray(
			[
				$rundis$elm_bootstrap$Bootstrap$Button$outlineWarning,
				$rundis$elm_bootstrap$Bootstrap$Button$onClick(onClick)
			]),
		_List_fromArray(
			[$author$project$Components$CommonPageComponents$iconDelete]));
};
var $author$project$Components$N80GroupTable$buildN80GroupTblCol = F5(
	function (cmn, data, n80Grp, access, col) {
		switch (col) {
			case 0:
				return A2(
					$elm$html$Html$map,
					$author$project$Components$N80GroupTableTypes$N80GroupTableMsgUpdateGlobalAction,
					$author$project$Components$CommonPageComponents$buildElemN80GroupStd(n80Grp));
			case 1:
				return $author$project$Components$CommonPageComponents$buildAccessLevel(access.gw);
			case 2:
				return $author$project$Components$CommonPageComponents$buildAccessLevel(n80Grp.dg.gu);
			case 4:
				return A2(
					$elm$html$Html$div,
					_List_Nil,
					A2(
						$elm$core$List$map,
						function (n80) {
							return A2(
								$author$project$Components$N80GroupTable$buildN80Member,
								n80,
								data.ic(n80));
						},
						n80Grp.dk));
			case 3:
				return $elm$html$Html$text(n80Grp.dj.gH.gR);
			default:
				var n80group_admin = _Utils_eq(
					cmn.ik(n80Grp.dh),
					$author$project$Api$AccessLevelGrantedForN80Group$AccessLevelGrantedForN80Group(3));
				var deleteBtn = $author$project$Components$CommonPageComponents$iconBtnDelete(
					$author$project$Components$N80GroupTableTypes$N80GroupTableActionDelete(n80Grp.dh));
				var can_delete = n80group_admin;
				return can_delete ? deleteBtn : $author$project$BigO$Utils$htmlEmpty;
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Table$Td = function (a) {
	return {$: 0, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Table$td = F2(
	function (options, children) {
		return $rundis$elm_bootstrap$Bootstrap$Table$Td(
			{c9: children, it: options});
	});
var $rundis$elm_bootstrap$Bootstrap$Table$Row = function (a) {
	return {$: 0, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Table$tr = F2(
	function (options, cells) {
		return $rundis$elm_bootstrap$Bootstrap$Table$Row(
			{n: cells, it: options});
	});
var $author$project$Utils$htmlsToRow = function (cells) {
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Table$tr,
		_List_Nil,
		A2(
			$elm$core$List$map,
			function (c) {
				return A2(
					$rundis$elm_bootstrap$Bootstrap$Table$td,
					_List_Nil,
					_List_fromArray(
						[c]));
			},
			cells));
};
var $author$project$Components$N80GroupTable$buildN80GroupsDivTableRow = F4(
	function (cmn, cfg, data, _v0) {
		var n80Grp = _v0.a;
		var grpAccess = _v0.b;
		var row = $author$project$Utils$htmlsToRow(
			A2(
				$elm$core$List$map,
				function (col) {
					return A5($author$project$Components$N80GroupTable$buildN80GroupTblCol, cmn, data, n80Grp, grpAccess, col);
				},
				cfg.eY));
		return row;
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Col = $elm$core$Basics$identity;
var $rundis$elm_bootstrap$Bootstrap$Form$col = F2(
	function (options, children) {
		return {c9: children, dS: $elm$html$Html$div, it: options};
	});
var $rundis$elm_bootstrap$Bootstrap$Table$Hover = {$: 3};
var $rundis$elm_bootstrap$Bootstrap$Table$hover = $rundis$elm_bootstrap$Bootstrap$Table$Hover;
var $lattyware$elm_fontawesome$FontAwesome$Solid$Definitions$plus = A4(
	$lattyware$elm_fontawesome$FontAwesome$IconDef,
	'fas',
	'plus',
	_Utils_Tuple2(448, 512),
	_Utils_Tuple2('M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z', $elm$core$Maybe$Nothing));
var $lattyware$elm_fontawesome$FontAwesome$Solid$plus = $lattyware$elm_fontawesome$FontAwesome$present($lattyware$elm_fontawesome$FontAwesome$Solid$Definitions$plus);
var $lattyware$elm_fontawesome$FontAwesome$Solid$add = $lattyware$elm_fontawesome$FontAwesome$Solid$plus;
var $author$project$Components$CommonPageComponents$iconAdd = A2(
	$elm$html$Html$span,
	_List_Nil,
	_List_fromArray(
		[
			$lattyware$elm_fontawesome$FontAwesome$view(
			A2(
				$lattyware$elm_fontawesome$FontAwesome$styled,
				_List_fromArray(
					[$lattyware$elm_fontawesome$FontAwesome$Attributes$sm, $lattyware$elm_fontawesome$FontAwesome$Attributes$fw]),
				$lattyware$elm_fontawesome$FontAwesome$Solid$add))
		]));
var $author$project$Components$CommonPageComponents$iconBtnAdd = function (onClick) {
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Button$button,
		_List_fromArray(
			[
				$rundis$elm_bootstrap$Bootstrap$Button$outlineWarning,
				$rundis$elm_bootstrap$Bootstrap$Button$onClick(onClick)
			]),
		_List_fromArray(
			[$author$project$Components$CommonPageComponents$iconAdd]));
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$OnInput = function (a) {
	return {$: 5, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$onInput = function (toMsg) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Input$OnInput(toMsg);
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$Placeholder = function (a) {
	return {$: 7, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$placeholder = function (value_) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Input$Placeholder(value_);
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$Col = 0;
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$Width = F2(
	function (screenSize, columnCount) {
		return {dD: columnCount, f4: screenSize};
	});
var $rundis$elm_bootstrap$Bootstrap$General$Internal$XS = 0;
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColAlign = F2(
	function (align_, options) {
		var _v0 = align_.f4;
		switch (_v0) {
			case 0:
				return _Utils_update(
					options,
					{
						aR: $elm$core$Maybe$Just(align_)
					});
			case 1:
				return _Utils_update(
					options,
					{
						aP: $elm$core$Maybe$Just(align_)
					});
			case 2:
				return _Utils_update(
					options,
					{
						aO: $elm$core$Maybe$Just(align_)
					});
			case 3:
				return _Utils_update(
					options,
					{
						aN: $elm$core$Maybe$Just(align_)
					});
			default:
				return _Utils_update(
					options,
					{
						aQ: $elm$core$Maybe$Just(align_)
					});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColOffset = F2(
	function (offset_, options) {
		var _v0 = offset_.f4;
		switch (_v0) {
			case 0:
				return _Utils_update(
					options,
					{
						bv: $elm$core$Maybe$Just(offset_)
					});
			case 1:
				return _Utils_update(
					options,
					{
						bs: $elm$core$Maybe$Just(offset_)
					});
			case 2:
				return _Utils_update(
					options,
					{
						br: $elm$core$Maybe$Just(offset_)
					});
			case 3:
				return _Utils_update(
					options,
					{
						bq: $elm$core$Maybe$Just(offset_)
					});
			default:
				return _Utils_update(
					options,
					{
						bu: $elm$core$Maybe$Just(offset_)
					});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColOrder = F2(
	function (order_, options) {
		var _v0 = order_.f4;
		switch (_v0) {
			case 0:
				return _Utils_update(
					options,
					{
						bG: $elm$core$Maybe$Just(order_)
					});
			case 1:
				return _Utils_update(
					options,
					{
						bE: $elm$core$Maybe$Just(order_)
					});
			case 2:
				return _Utils_update(
					options,
					{
						bD: $elm$core$Maybe$Just(order_)
					});
			case 3:
				return _Utils_update(
					options,
					{
						bC: $elm$core$Maybe$Just(order_)
					});
			default:
				return _Utils_update(
					options,
					{
						bF: $elm$core$Maybe$Just(order_)
					});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColPull = F2(
	function (pull_, options) {
		var _v0 = pull_.f4;
		switch (_v0) {
			case 0:
				return _Utils_update(
					options,
					{
						bO: $elm$core$Maybe$Just(pull_)
					});
			case 1:
				return _Utils_update(
					options,
					{
						bM: $elm$core$Maybe$Just(pull_)
					});
			case 2:
				return _Utils_update(
					options,
					{
						bL: $elm$core$Maybe$Just(pull_)
					});
			case 3:
				return _Utils_update(
					options,
					{
						bK: $elm$core$Maybe$Just(pull_)
					});
			default:
				return _Utils_update(
					options,
					{
						bN: $elm$core$Maybe$Just(pull_)
					});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColPush = F2(
	function (push_, options) {
		var _v0 = push_.f4;
		switch (_v0) {
			case 0:
				return _Utils_update(
					options,
					{
						bT: $elm$core$Maybe$Just(push_)
					});
			case 1:
				return _Utils_update(
					options,
					{
						bR: $elm$core$Maybe$Just(push_)
					});
			case 2:
				return _Utils_update(
					options,
					{
						bQ: $elm$core$Maybe$Just(push_)
					});
			case 3:
				return _Utils_update(
					options,
					{
						bP: $elm$core$Maybe$Just(push_)
					});
			default:
				return _Utils_update(
					options,
					{
						bS: $elm$core$Maybe$Just(push_)
					});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColWidth = F2(
	function (width_, options) {
		var _v0 = width_.f4;
		switch (_v0) {
			case 0:
				return _Utils_update(
					options,
					{
						aH: $elm$core$Maybe$Just(width_)
					});
			case 1:
				return _Utils_update(
					options,
					{
						aF: $elm$core$Maybe$Just(width_)
					});
			case 2:
				return _Utils_update(
					options,
					{
						aE: $elm$core$Maybe$Just(width_)
					});
			case 3:
				return _Utils_update(
					options,
					{
						aD: $elm$core$Maybe$Just(width_)
					});
			default:
				return _Utils_update(
					options,
					{
						aG: $elm$core$Maybe$Just(width_)
					});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColOption = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 6:
				var attrs = modifier.a;
				return _Utils_update(
					options,
					{
						aU: _Utils_ap(options.aU, attrs)
					});
			case 0:
				var width_ = modifier.a;
				return A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColWidth, width_, options);
			case 1:
				var offset_ = modifier.a;
				return A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColOffset, offset_, options);
			case 2:
				var pull_ = modifier.a;
				return A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColPull, pull_, options);
			case 3:
				var push_ = modifier.a;
				return A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColPush, push_, options);
			case 4:
				var order_ = modifier.a;
				return A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColOrder, order_, options);
			case 5:
				var align = modifier.a;
				return A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColAlign, align, options);
			default:
				var align = modifier.a;
				return _Utils_update(
					options,
					{
						b0: $elm$core$Maybe$Just(align)
					});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$columnCountOption = function (size) {
	switch (size) {
		case 0:
			return $elm$core$Maybe$Nothing;
		case 1:
			return $elm$core$Maybe$Just('1');
		case 2:
			return $elm$core$Maybe$Just('2');
		case 3:
			return $elm$core$Maybe$Just('3');
		case 4:
			return $elm$core$Maybe$Just('4');
		case 5:
			return $elm$core$Maybe$Just('5');
		case 6:
			return $elm$core$Maybe$Just('6');
		case 7:
			return $elm$core$Maybe$Just('7');
		case 8:
			return $elm$core$Maybe$Just('8');
		case 9:
			return $elm$core$Maybe$Just('9');
		case 10:
			return $elm$core$Maybe$Just('10');
		case 11:
			return $elm$core$Maybe$Just('11');
		case 12:
			return $elm$core$Maybe$Just('12');
		default:
			return $elm$core$Maybe$Just('auto');
	}
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$colWidthClass = function (_v0) {
	var screenSize = _v0.f4;
	var columnCount = _v0.dD;
	return $elm$html$Html$Attributes$class(
		'col' + (A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$map,
				function (v) {
					return '-' + v;
				},
				$rundis$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption(screenSize))) + A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$map,
				function (v) {
					return '-' + v;
				},
				$rundis$elm_bootstrap$Bootstrap$Grid$Internal$columnCountOption(columnCount)))));
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$colWidthsToAttributes = function (widths) {
	var width_ = function (w) {
		return A2($elm$core$Maybe$map, $rundis$elm_bootstrap$Bootstrap$Grid$Internal$colWidthClass, w);
	};
	return A2(
		$elm$core$List$filterMap,
		$elm$core$Basics$identity,
		A2($elm$core$List$map, width_, widths));
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$defaultColOptions = {aN: $elm$core$Maybe$Nothing, aO: $elm$core$Maybe$Nothing, aP: $elm$core$Maybe$Nothing, aQ: $elm$core$Maybe$Nothing, aR: $elm$core$Maybe$Nothing, aU: _List_Nil, bq: $elm$core$Maybe$Nothing, br: $elm$core$Maybe$Nothing, bs: $elm$core$Maybe$Nothing, bu: $elm$core$Maybe$Nothing, bv: $elm$core$Maybe$Nothing, bC: $elm$core$Maybe$Nothing, bD: $elm$core$Maybe$Nothing, bE: $elm$core$Maybe$Nothing, bF: $elm$core$Maybe$Nothing, bG: $elm$core$Maybe$Nothing, bK: $elm$core$Maybe$Nothing, bL: $elm$core$Maybe$Nothing, bM: $elm$core$Maybe$Nothing, bN: $elm$core$Maybe$Nothing, bO: $elm$core$Maybe$Nothing, bP: $elm$core$Maybe$Nothing, bQ: $elm$core$Maybe$Nothing, bR: $elm$core$Maybe$Nothing, bS: $elm$core$Maybe$Nothing, bT: $elm$core$Maybe$Nothing, b0: $elm$core$Maybe$Nothing, aD: $elm$core$Maybe$Nothing, aE: $elm$core$Maybe$Nothing, aF: $elm$core$Maybe$Nothing, aG: $elm$core$Maybe$Nothing, aH: $elm$core$Maybe$Nothing};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$offsetCountOption = function (size) {
	switch (size) {
		case 0:
			return '0';
		case 1:
			return '1';
		case 2:
			return '2';
		case 3:
			return '3';
		case 4:
			return '4';
		case 5:
			return '5';
		case 6:
			return '6';
		case 7:
			return '7';
		case 8:
			return '8';
		case 9:
			return '9';
		case 10:
			return '10';
		default:
			return '11';
	}
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$screenSizeToPartialString = function (screenSize) {
	var _v0 = $rundis$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption(screenSize);
	if (!_v0.$) {
		var s = _v0.a;
		return '-' + (s + '-');
	} else {
		return '-';
	}
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$offsetClass = function (_v0) {
	var screenSize = _v0.f4;
	var offsetCount = _v0.fp;
	return $elm$html$Html$Attributes$class(
		'offset' + ($rundis$elm_bootstrap$Bootstrap$Grid$Internal$screenSizeToPartialString(screenSize) + $rundis$elm_bootstrap$Bootstrap$Grid$Internal$offsetCountOption(offsetCount)));
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$offsetsToAttributes = function (offsets) {
	var offset_ = function (m) {
		return A2($elm$core$Maybe$map, $rundis$elm_bootstrap$Bootstrap$Grid$Internal$offsetClass, m);
	};
	return A2(
		$elm$core$List$filterMap,
		$elm$core$Basics$identity,
		A2($elm$core$List$map, offset_, offsets));
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$orderColOption = function (size) {
	switch (size) {
		case 0:
			return 'first';
		case 1:
			return '1';
		case 2:
			return '2';
		case 3:
			return '3';
		case 4:
			return '4';
		case 5:
			return '5';
		case 6:
			return '6';
		case 7:
			return '7';
		case 8:
			return '8';
		case 9:
			return '9';
		case 10:
			return '10';
		case 11:
			return '11';
		case 12:
			return '12';
		default:
			return 'last';
	}
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$orderToAttributes = function (orders) {
	var order_ = function (m) {
		if (!m.$) {
			var screenSize = m.a.f4;
			var moveCount = m.a.ac;
			return $elm$core$Maybe$Just(
				$elm$html$Html$Attributes$class(
					'order' + ($rundis$elm_bootstrap$Bootstrap$Grid$Internal$screenSizeToPartialString(screenSize) + $rundis$elm_bootstrap$Bootstrap$Grid$Internal$orderColOption(moveCount))));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	};
	return A2(
		$elm$core$List$filterMap,
		$elm$core$Basics$identity,
		A2($elm$core$List$map, order_, orders));
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$moveCountOption = function (size) {
	switch (size) {
		case 0:
			return '0';
		case 1:
			return '1';
		case 2:
			return '2';
		case 3:
			return '3';
		case 4:
			return '4';
		case 5:
			return '5';
		case 6:
			return '6';
		case 7:
			return '7';
		case 8:
			return '8';
		case 9:
			return '9';
		case 10:
			return '10';
		case 11:
			return '11';
		default:
			return '12';
	}
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$pullsToAttributes = function (pulls) {
	var pull_ = function (m) {
		if (!m.$) {
			var screenSize = m.a.f4;
			var moveCount = m.a.ac;
			return $elm$core$Maybe$Just(
				$elm$html$Html$Attributes$class(
					'pull' + ($rundis$elm_bootstrap$Bootstrap$Grid$Internal$screenSizeToPartialString(screenSize) + $rundis$elm_bootstrap$Bootstrap$Grid$Internal$moveCountOption(moveCount))));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	};
	return A2(
		$elm$core$List$filterMap,
		$elm$core$Basics$identity,
		A2($elm$core$List$map, pull_, pulls));
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$pushesToAttributes = function (pushes) {
	var push_ = function (m) {
		if (!m.$) {
			var screenSize = m.a.f4;
			var moveCount = m.a.ac;
			return $elm$core$Maybe$Just(
				$elm$html$Html$Attributes$class(
					'push' + ($rundis$elm_bootstrap$Bootstrap$Grid$Internal$screenSizeToPartialString(screenSize) + $rundis$elm_bootstrap$Bootstrap$Grid$Internal$moveCountOption(moveCount))));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	};
	return A2(
		$elm$core$List$filterMap,
		$elm$core$Basics$identity,
		A2($elm$core$List$map, push_, pushes));
};
var $rundis$elm_bootstrap$Bootstrap$Internal$Text$textAlignDirOption = function (dir) {
	switch (dir) {
		case 1:
			return 'center';
		case 0:
			return 'left';
		default:
			return 'right';
	}
};
var $rundis$elm_bootstrap$Bootstrap$Internal$Text$textAlignClass = function (_v0) {
	var dir = _v0.dJ;
	var size = _v0.ge;
	return $elm$html$Html$Attributes$class(
		'text' + (A2(
			$elm$core$Maybe$withDefault,
			'-',
			A2(
				$elm$core$Maybe$map,
				function (s) {
					return '-' + (s + '-');
				},
				$rundis$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption(size))) + $rundis$elm_bootstrap$Bootstrap$Internal$Text$textAlignDirOption(dir)));
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$verticalAlignOption = function (align) {
	switch (align) {
		case 0:
			return 'start';
		case 1:
			return 'center';
		default:
			return 'end';
	}
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$vAlignClass = F2(
	function (prefix, _v0) {
		var align = _v0.c_;
		var screenSize = _v0.f4;
		return $elm$html$Html$Attributes$class(
			_Utils_ap(
				prefix,
				_Utils_ap(
					A2(
						$elm$core$Maybe$withDefault,
						'',
						A2(
							$elm$core$Maybe$map,
							function (v) {
								return v + '-';
							},
							$rundis$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption(screenSize))),
					$rundis$elm_bootstrap$Bootstrap$Grid$Internal$verticalAlignOption(align))));
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$vAlignsToAttributes = F2(
	function (prefix, aligns) {
		var align = function (a) {
			return A2(
				$elm$core$Maybe$map,
				$rundis$elm_bootstrap$Bootstrap$Grid$Internal$vAlignClass(prefix),
				a);
		};
		return A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			A2($elm$core$List$map, align, aligns));
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$colAttributes = function (modifiers) {
	var options = A3($elm$core$List$foldl, $rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColOption, $rundis$elm_bootstrap$Bootstrap$Grid$Internal$defaultColOptions, modifiers);
	var shouldAddDefaultXs = !$elm$core$List$length(
		A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[options.aH, options.aF, options.aE, options.aD, options.aG])));
	return _Utils_ap(
		$rundis$elm_bootstrap$Bootstrap$Grid$Internal$colWidthsToAttributes(
			_List_fromArray(
				[
					shouldAddDefaultXs ? $elm$core$Maybe$Just(
					A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$Width, 0, 0)) : options.aH,
					options.aF,
					options.aE,
					options.aD,
					options.aG
				])),
		_Utils_ap(
			$rundis$elm_bootstrap$Bootstrap$Grid$Internal$offsetsToAttributes(
				_List_fromArray(
					[options.bv, options.bs, options.br, options.bq, options.bu])),
			_Utils_ap(
				$rundis$elm_bootstrap$Bootstrap$Grid$Internal$pullsToAttributes(
					_List_fromArray(
						[options.bO, options.bM, options.bL, options.bK, options.bN])),
				_Utils_ap(
					$rundis$elm_bootstrap$Bootstrap$Grid$Internal$pushesToAttributes(
						_List_fromArray(
							[options.bT, options.bR, options.bQ, options.bP, options.bS])),
					_Utils_ap(
						$rundis$elm_bootstrap$Bootstrap$Grid$Internal$orderToAttributes(
							_List_fromArray(
								[options.bG, options.bE, options.bD, options.bC, options.bF])),
						_Utils_ap(
							A2(
								$rundis$elm_bootstrap$Bootstrap$Grid$Internal$vAlignsToAttributes,
								'align-self-',
								_List_fromArray(
									[options.aR, options.aP, options.aO, options.aN, options.aQ])),
							_Utils_ap(
								function () {
									var _v0 = options.b0;
									if (!_v0.$) {
										var a = _v0.a;
										return _List_fromArray(
											[
												$rundis$elm_bootstrap$Bootstrap$Internal$Text$textAlignClass(a)
											]);
									} else {
										return _List_Nil;
									}
								}(),
								options.aU)))))));
};
var $rundis$elm_bootstrap$Bootstrap$Form$renderCol = function (_v0) {
	var elemFn = _v0.dS;
	var options = _v0.it;
	var children = _v0.c9;
	return A2(
		elemFn,
		$rundis$elm_bootstrap$Bootstrap$Grid$Internal$colAttributes(options),
		children);
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyRowHAlign = F2(
	function (align, options) {
		var _v0 = align.f4;
		switch (_v0) {
			case 0:
				return _Utils_update(
					options,
					{
						bf: $elm$core$Maybe$Just(align)
					});
			case 1:
				return _Utils_update(
					options,
					{
						bd: $elm$core$Maybe$Just(align)
					});
			case 2:
				return _Utils_update(
					options,
					{
						bc: $elm$core$Maybe$Just(align)
					});
			case 3:
				return _Utils_update(
					options,
					{
						bb: $elm$core$Maybe$Just(align)
					});
			default:
				return _Utils_update(
					options,
					{
						be: $elm$core$Maybe$Just(align)
					});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyRowVAlign = F2(
	function (align_, options) {
		var _v0 = align_.f4;
		switch (_v0) {
			case 0:
				return _Utils_update(
					options,
					{
						b9: $elm$core$Maybe$Just(align_)
					});
			case 1:
				return _Utils_update(
					options,
					{
						b7: $elm$core$Maybe$Just(align_)
					});
			case 2:
				return _Utils_update(
					options,
					{
						b6: $elm$core$Maybe$Just(align_)
					});
			case 3:
				return _Utils_update(
					options,
					{
						b5: $elm$core$Maybe$Just(align_)
					});
			default:
				return _Utils_update(
					options,
					{
						b8: $elm$core$Maybe$Just(align_)
					});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyRowOption = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 2:
				var attrs = modifier.a;
				return _Utils_update(
					options,
					{
						aU: _Utils_ap(options.aU, attrs)
					});
			case 0:
				var align = modifier.a;
				return A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyRowVAlign, align, options);
			default:
				var align = modifier.a;
				return A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyRowHAlign, align, options);
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$defaultRowOptions = {aU: _List_Nil, bb: $elm$core$Maybe$Nothing, bc: $elm$core$Maybe$Nothing, bd: $elm$core$Maybe$Nothing, be: $elm$core$Maybe$Nothing, bf: $elm$core$Maybe$Nothing, b5: $elm$core$Maybe$Nothing, b6: $elm$core$Maybe$Nothing, b7: $elm$core$Maybe$Nothing, b8: $elm$core$Maybe$Nothing, b9: $elm$core$Maybe$Nothing};
var $rundis$elm_bootstrap$Bootstrap$General$Internal$horizontalAlignOption = function (align) {
	switch (align) {
		case 0:
			return 'start';
		case 1:
			return 'center';
		case 2:
			return 'end';
		case 3:
			return 'around';
		default:
			return 'between';
	}
};
var $rundis$elm_bootstrap$Bootstrap$General$Internal$hAlignClass = function (_v0) {
	var align = _v0.c_;
	var screenSize = _v0.f4;
	return $elm$html$Html$Attributes$class(
		'justify-content-' + (A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$map,
				function (v) {
					return v + '-';
				},
				$rundis$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption(screenSize))) + $rundis$elm_bootstrap$Bootstrap$General$Internal$horizontalAlignOption(align)));
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$hAlignsToAttributes = function (aligns) {
	var align = function (a) {
		return A2($elm$core$Maybe$map, $rundis$elm_bootstrap$Bootstrap$General$Internal$hAlignClass, a);
	};
	return A2(
		$elm$core$List$filterMap,
		$elm$core$Basics$identity,
		A2($elm$core$List$map, align, aligns));
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$rowAttributes = function (modifiers) {
	var options = A3($elm$core$List$foldl, $rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyRowOption, $rundis$elm_bootstrap$Bootstrap$Grid$Internal$defaultRowOptions, modifiers);
	return _Utils_ap(
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('row')
			]),
		_Utils_ap(
			A2(
				$rundis$elm_bootstrap$Bootstrap$Grid$Internal$vAlignsToAttributes,
				'align-items-',
				_List_fromArray(
					[options.b9, options.b7, options.b6, options.b5, options.b8])),
			_Utils_ap(
				$rundis$elm_bootstrap$Bootstrap$Grid$Internal$hAlignsToAttributes(
					_List_fromArray(
						[options.bf, options.bd, options.bc, options.bb, options.be])),
				options.aU)));
};
var $rundis$elm_bootstrap$Bootstrap$Form$row = F2(
	function (options, cols) {
		return A2(
			$elm$html$Html$div,
			A2(
				$elm$core$List$cons,
				$elm$html$Html$Attributes$class('form-group'),
				$rundis$elm_bootstrap$Bootstrap$Grid$Internal$rowAttributes(options)),
			A2($elm$core$List$map, $rundis$elm_bootstrap$Bootstrap$Form$renderCol, cols));
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$Col1 = 1;
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$ColWidth = function (a) {
	return {$: 0, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$width = F2(
	function (size, count) {
		return $rundis$elm_bootstrap$Bootstrap$Grid$Internal$ColWidth(
			A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$Width, size, count));
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Col$sm1 = A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$width, 1, 1);
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$Col2 = 2;
var $rundis$elm_bootstrap$Bootstrap$Grid$Col$sm2 = A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$width, 1, 2);
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$Col8 = 8;
var $rundis$elm_bootstrap$Bootstrap$Grid$Col$sm8 = A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$width, 1, 8);
var $rundis$elm_bootstrap$Bootstrap$Table$Small = {$: 4};
var $rundis$elm_bootstrap$Bootstrap$Table$small = $rundis$elm_bootstrap$Bootstrap$Table$Small;
var $rundis$elm_bootstrap$Bootstrap$Table$Striped = {$: 1};
var $rundis$elm_bootstrap$Bootstrap$Table$striped = $rundis$elm_bootstrap$Bootstrap$Table$Striped;
var $rundis$elm_bootstrap$Bootstrap$Table$Inversed = {$: 0};
var $rundis$elm_bootstrap$Bootstrap$Table$isResponsive = function (option) {
	if (option.$ === 5) {
		return true;
	} else {
		return false;
	}
};
var $rundis$elm_bootstrap$Bootstrap$Table$KeyedTBody = function (a) {
	return {$: 1, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Table$TBody = function (a) {
	return {$: 0, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Table$InversedRow = function (a) {
	return {$: 1, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Table$KeyedRow = function (a) {
	return {$: 1, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Table$InversedCell = function (a) {
	return {$: 1, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Table$Th = function (a) {
	return {$: 1, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Table$mapInversedCell = function (cell) {
	var inverseOptions = function (options) {
		return A2(
			$elm$core$List$map,
			function (opt) {
				if (!opt.$) {
					var role = opt.a;
					return $rundis$elm_bootstrap$Bootstrap$Table$InversedCell(role);
				} else {
					return opt;
				}
			},
			options);
	};
	if (cell.$ === 1) {
		var cellCfg = cell.a;
		return $rundis$elm_bootstrap$Bootstrap$Table$Th(
			_Utils_update(
				cellCfg,
				{
					it: inverseOptions(cellCfg.it)
				}));
	} else {
		var cellCfg = cell.a;
		return $rundis$elm_bootstrap$Bootstrap$Table$Td(
			_Utils_update(
				cellCfg,
				{
					it: inverseOptions(cellCfg.it)
				}));
	}
};
var $rundis$elm_bootstrap$Bootstrap$Table$mapInversedRow = function (row) {
	var inversedOptions = function (options) {
		return A2(
			$elm$core$List$map,
			function (opt) {
				if (!opt.$) {
					var role = opt.a;
					return $rundis$elm_bootstrap$Bootstrap$Table$InversedRow(role);
				} else {
					return opt;
				}
			},
			options);
	};
	if (!row.$) {
		var options = row.a.it;
		var cells = row.a.n;
		return $rundis$elm_bootstrap$Bootstrap$Table$Row(
			{
				n: A2($elm$core$List$map, $rundis$elm_bootstrap$Bootstrap$Table$mapInversedCell, cells),
				it: inversedOptions(options)
			});
	} else {
		var options = row.a.it;
		var cells = row.a.n;
		return $rundis$elm_bootstrap$Bootstrap$Table$KeyedRow(
			{
				n: A2(
					$elm$core$List$map,
					function (_v1) {
						var key = _v1.a;
						var cell = _v1.b;
						return _Utils_Tuple2(
							key,
							$rundis$elm_bootstrap$Bootstrap$Table$mapInversedCell(cell));
					},
					cells),
				it: inversedOptions(options)
			});
	}
};
var $rundis$elm_bootstrap$Bootstrap$Table$maybeMapInversedTBody = F2(
	function (isTableInversed, tbody_) {
		var _v0 = _Utils_Tuple2(isTableInversed, tbody_);
		if (!_v0.a) {
			return tbody_;
		} else {
			if (!_v0.b.$) {
				var body = _v0.b.a;
				return $rundis$elm_bootstrap$Bootstrap$Table$TBody(
					_Utils_update(
						body,
						{
							bV: A2($elm$core$List$map, $rundis$elm_bootstrap$Bootstrap$Table$mapInversedRow, body.bV)
						}));
			} else {
				var keyedBody = _v0.b.a;
				return $rundis$elm_bootstrap$Bootstrap$Table$KeyedTBody(
					_Utils_update(
						keyedBody,
						{
							bV: A2(
								$elm$core$List$map,
								function (_v1) {
									var key = _v1.a;
									var row = _v1.b;
									return _Utils_Tuple2(
										key,
										$rundis$elm_bootstrap$Bootstrap$Table$mapInversedRow(row));
								},
								keyedBody.bV)
						}));
			}
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Table$InversedHead = {$: 0};
var $rundis$elm_bootstrap$Bootstrap$Table$THead = $elm$core$Basics$identity;
var $rundis$elm_bootstrap$Bootstrap$Table$maybeMapInversedTHead = F2(
	function (isTableInversed, _v0) {
		var thead_ = _v0;
		var isHeadInversed = A2(
			$elm$core$List$any,
			function (opt) {
				return _Utils_eq(opt, $rundis$elm_bootstrap$Bootstrap$Table$InversedHead);
			},
			thead_.it);
		return (isTableInversed || isHeadInversed) ? _Utils_update(
			thead_,
			{
				bV: A2($elm$core$List$map, $rundis$elm_bootstrap$Bootstrap$Table$mapInversedRow, thead_.bV)
			}) : thead_;
	});
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $rundis$elm_bootstrap$Bootstrap$Table$maybeWrapResponsive = F2(
	function (options, table_) {
		var responsiveClass = $elm$html$Html$Attributes$class(
			'table-responsive' + A2(
				$elm$core$Maybe$withDefault,
				'',
				A2(
					$elm$core$Maybe$map,
					function (v) {
						return '-' + v;
					},
					A2(
						$elm$core$Maybe$andThen,
						$rundis$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption,
						A2(
							$elm$core$Maybe$andThen,
							function (opt) {
								if (opt.$ === 5) {
									var val = opt.a;
									return val;
								} else {
									return $elm$core$Maybe$Nothing;
								}
							},
							$elm$core$List$head(
								A2($elm$core$List$filter, $rundis$elm_bootstrap$Bootstrap$Table$isResponsive, options)))))));
		return A2($elm$core$List$any, $rundis$elm_bootstrap$Bootstrap$Table$isResponsive, options) ? A2(
			$elm$html$Html$div,
			_List_fromArray(
				[responsiveClass]),
			_List_fromArray(
				[table_])) : table_;
	});
var $rundis$elm_bootstrap$Bootstrap$Table$CellAttr = function (a) {
	return {$: 2, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Table$cellAttr = function (attr_) {
	return $rundis$elm_bootstrap$Bootstrap$Table$CellAttr(attr_);
};
var $elm$html$Html$Attributes$scope = $elm$html$Html$Attributes$stringProperty('scope');
var $rundis$elm_bootstrap$Bootstrap$Table$addScopeIfTh = function (cell) {
	if (cell.$ === 1) {
		var cellConfig = cell.a;
		return $rundis$elm_bootstrap$Bootstrap$Table$Th(
			_Utils_update(
				cellConfig,
				{
					it: A2(
						$elm$core$List$cons,
						$rundis$elm_bootstrap$Bootstrap$Table$cellAttr(
							$elm$html$Html$Attributes$scope('row')),
						cellConfig.it)
				}));
	} else {
		return cell;
	}
};
var $rundis$elm_bootstrap$Bootstrap$Table$maybeAddScopeToFirstCell = function (row) {
	if (!row.$) {
		var options = row.a.it;
		var cells = row.a.n;
		if (!cells.b) {
			return row;
		} else {
			var first = cells.a;
			var rest = cells.b;
			return $rundis$elm_bootstrap$Bootstrap$Table$Row(
				{
					n: A2(
						$elm$core$List$cons,
						$rundis$elm_bootstrap$Bootstrap$Table$addScopeIfTh(first),
						rest),
					it: options
				});
		}
	} else {
		var options = row.a.it;
		var cells = row.a.n;
		if (!cells.b) {
			return row;
		} else {
			var _v3 = cells.a;
			var firstKey = _v3.a;
			var first = _v3.b;
			var rest = cells.b;
			return $rundis$elm_bootstrap$Bootstrap$Table$KeyedRow(
				{
					n: A2(
						$elm$core$List$cons,
						_Utils_Tuple2(
							firstKey,
							$rundis$elm_bootstrap$Bootstrap$Table$addScopeIfTh(first)),
						rest),
					it: options
				});
		}
	}
};
var $elm$virtual_dom$VirtualDom$keyedNode = function (tag) {
	return _VirtualDom_keyedNode(
		_VirtualDom_noScript(tag));
};
var $elm$html$Html$Keyed$node = $elm$virtual_dom$VirtualDom$keyedNode;
var $rundis$elm_bootstrap$Bootstrap$Internal$Role$toClass = F2(
	function (prefix, role) {
		return $elm$html$Html$Attributes$class(
			prefix + ('-' + function () {
				switch (role) {
					case 0:
						return 'primary';
					case 1:
						return 'secondary';
					case 2:
						return 'success';
					case 3:
						return 'info';
					case 4:
						return 'warning';
					case 5:
						return 'danger';
					case 6:
						return 'light';
					default:
						return 'dark';
				}
			}()));
	});
var $rundis$elm_bootstrap$Bootstrap$Table$cellAttribute = function (option) {
	switch (option.$) {
		case 0:
			if (!option.a.$) {
				var role = option.a.a;
				return A2($rundis$elm_bootstrap$Bootstrap$Internal$Role$toClass, 'table', role);
			} else {
				var _v1 = option.a;
				return $elm$html$Html$Attributes$class('table-active');
			}
		case 1:
			if (!option.a.$) {
				var role = option.a.a;
				return A2($rundis$elm_bootstrap$Bootstrap$Internal$Role$toClass, 'bg-', role);
			} else {
				var _v2 = option.a;
				return $elm$html$Html$Attributes$class('bg-active');
			}
		default:
			var attr_ = option.a;
			return attr_;
	}
};
var $rundis$elm_bootstrap$Bootstrap$Table$cellAttributes = function (options) {
	return A2($elm$core$List$map, $rundis$elm_bootstrap$Bootstrap$Table$cellAttribute, options);
};
var $elm$html$Html$td = _VirtualDom_node('td');
var $elm$html$Html$th = _VirtualDom_node('th');
var $rundis$elm_bootstrap$Bootstrap$Table$renderCell = function (cell) {
	if (!cell.$) {
		var options = cell.a.it;
		var children = cell.a.c9;
		return A2(
			$elm$html$Html$td,
			$rundis$elm_bootstrap$Bootstrap$Table$cellAttributes(options),
			children);
	} else {
		var options = cell.a.it;
		var children = cell.a.c9;
		return A2(
			$elm$html$Html$th,
			$rundis$elm_bootstrap$Bootstrap$Table$cellAttributes(options),
			children);
	}
};
var $rundis$elm_bootstrap$Bootstrap$Table$rowClass = function (option) {
	switch (option.$) {
		case 0:
			if (!option.a.$) {
				var role_ = option.a.a;
				return A2($rundis$elm_bootstrap$Bootstrap$Internal$Role$toClass, 'table', role_);
			} else {
				var _v1 = option.a;
				return $elm$html$Html$Attributes$class('table-active');
			}
		case 1:
			if (!option.a.$) {
				var role_ = option.a.a;
				return A2($rundis$elm_bootstrap$Bootstrap$Internal$Role$toClass, 'bg', role_);
			} else {
				var _v2 = option.a;
				return $elm$html$Html$Attributes$class('bg-active');
			}
		default:
			var attr_ = option.a;
			return attr_;
	}
};
var $rundis$elm_bootstrap$Bootstrap$Table$rowAttributes = function (options) {
	return A2($elm$core$List$map, $rundis$elm_bootstrap$Bootstrap$Table$rowClass, options);
};
var $elm$html$Html$tr = _VirtualDom_node('tr');
var $rundis$elm_bootstrap$Bootstrap$Table$renderRow = function (row) {
	if (!row.$) {
		var options = row.a.it;
		var cells = row.a.n;
		return A2(
			$elm$html$Html$tr,
			$rundis$elm_bootstrap$Bootstrap$Table$rowAttributes(options),
			A2($elm$core$List$map, $rundis$elm_bootstrap$Bootstrap$Table$renderCell, cells));
	} else {
		var options = row.a.it;
		var cells = row.a.n;
		return A3(
			$elm$html$Html$Keyed$node,
			'tr',
			$rundis$elm_bootstrap$Bootstrap$Table$rowAttributes(options),
			A2(
				$elm$core$List$map,
				function (_v1) {
					var key = _v1.a;
					var cell = _v1.b;
					return _Utils_Tuple2(
						key,
						$rundis$elm_bootstrap$Bootstrap$Table$renderCell(cell));
				},
				cells));
	}
};
var $elm$html$Html$tbody = _VirtualDom_node('tbody');
var $rundis$elm_bootstrap$Bootstrap$Table$renderTBody = function (body) {
	if (!body.$) {
		var attributes = body.a.aU;
		var rows = body.a.bV;
		return A2(
			$elm$html$Html$tbody,
			attributes,
			A2(
				$elm$core$List$map,
				function (row) {
					return $rundis$elm_bootstrap$Bootstrap$Table$renderRow(
						$rundis$elm_bootstrap$Bootstrap$Table$maybeAddScopeToFirstCell(row));
				},
				rows));
	} else {
		var attributes = body.a.aU;
		var rows = body.a.bV;
		return A3(
			$elm$html$Html$Keyed$node,
			'tbody',
			attributes,
			A2(
				$elm$core$List$map,
				function (_v1) {
					var key = _v1.a;
					var row = _v1.b;
					return _Utils_Tuple2(
						key,
						$rundis$elm_bootstrap$Bootstrap$Table$renderRow(
							$rundis$elm_bootstrap$Bootstrap$Table$maybeAddScopeToFirstCell(row)));
				},
				rows));
	}
};
var $elm$html$Html$thead = _VirtualDom_node('thead');
var $rundis$elm_bootstrap$Bootstrap$Table$theadAttribute = function (option) {
	switch (option.$) {
		case 0:
			return $elm$html$Html$Attributes$class('thead-dark');
		case 1:
			return $elm$html$Html$Attributes$class('thead-default');
		default:
			var attr_ = option.a;
			return attr_;
	}
};
var $rundis$elm_bootstrap$Bootstrap$Table$theadAttributes = function (options) {
	return A2($elm$core$List$map, $rundis$elm_bootstrap$Bootstrap$Table$theadAttribute, options);
};
var $rundis$elm_bootstrap$Bootstrap$Table$renderTHead = function (_v0) {
	var options = _v0.it;
	var rows = _v0.bV;
	return A2(
		$elm$html$Html$thead,
		$rundis$elm_bootstrap$Bootstrap$Table$theadAttributes(options),
		A2($elm$core$List$map, $rundis$elm_bootstrap$Bootstrap$Table$renderRow, rows));
};
var $elm$html$Html$table = _VirtualDom_node('table');
var $rundis$elm_bootstrap$Bootstrap$Table$tableClass = function (option) {
	switch (option.$) {
		case 0:
			return $elm$core$Maybe$Just(
				$elm$html$Html$Attributes$class('table-dark'));
		case 1:
			return $elm$core$Maybe$Just(
				$elm$html$Html$Attributes$class('table-striped'));
		case 2:
			return $elm$core$Maybe$Just(
				$elm$html$Html$Attributes$class('table-bordered'));
		case 3:
			return $elm$core$Maybe$Just(
				$elm$html$Html$Attributes$class('table-hover'));
		case 4:
			return $elm$core$Maybe$Just(
				$elm$html$Html$Attributes$class('table-sm'));
		case 5:
			return $elm$core$Maybe$Nothing;
		case 6:
			return $elm$core$Maybe$Just(
				$elm$html$Html$Attributes$class('table-reflow'));
		default:
			var attr_ = option.a;
			return $elm$core$Maybe$Just(attr_);
	}
};
var $rundis$elm_bootstrap$Bootstrap$Table$tableAttributes = function (options) {
	return A2(
		$elm$core$List$cons,
		$elm$html$Html$Attributes$class('table'),
		A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			A2($elm$core$List$map, $rundis$elm_bootstrap$Bootstrap$Table$tableClass, options)));
};
var $rundis$elm_bootstrap$Bootstrap$Table$table = function (rec) {
	var isInversed = A2(
		$elm$core$List$any,
		function (opt) {
			return _Utils_eq(opt, $rundis$elm_bootstrap$Bootstrap$Table$Inversed);
		},
		rec.it);
	var classOptions = A2(
		$elm$core$List$filter,
		function (opt) {
			return !$rundis$elm_bootstrap$Bootstrap$Table$isResponsive(opt);
		},
		rec.it);
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Table$maybeWrapResponsive,
		rec.it,
		A2(
			$elm$html$Html$table,
			$rundis$elm_bootstrap$Bootstrap$Table$tableAttributes(classOptions),
			_List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Table$renderTHead(
					A2($rundis$elm_bootstrap$Bootstrap$Table$maybeMapInversedTHead, isInversed, rec.iO)),
					$rundis$elm_bootstrap$Bootstrap$Table$renderTBody(
					A2($rundis$elm_bootstrap$Bootstrap$Table$maybeMapInversedTBody, isInversed, rec.iN))
				])));
};
var $rundis$elm_bootstrap$Bootstrap$Table$tbody = F2(
	function (attributes, rows) {
		return $rundis$elm_bootstrap$Bootstrap$Table$TBody(
			{aU: attributes, bV: rows});
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Input$Text = 0;
var $rundis$elm_bootstrap$Bootstrap$Form$Input$Input = $elm$core$Basics$identity;
var $rundis$elm_bootstrap$Bootstrap$Form$Input$Type = function (a) {
	return {$: 2, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$create = F2(
	function (tipe, options) {
		return {
			it: A2(
				$elm$core$List$cons,
				$rundis$elm_bootstrap$Bootstrap$Form$Input$Type(tipe),
				options)
		};
	});
var $elm$html$Html$input = _VirtualDom_node('input');
var $rundis$elm_bootstrap$Bootstrap$Form$Input$applyModifier = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 0:
				var size_ = modifier.a;
				return _Utils_update(
					options,
					{
						ge: $elm$core$Maybe$Just(size_)
					});
			case 1:
				var id_ = modifier.a;
				return _Utils_update(
					options,
					{
						ef: $elm$core$Maybe$Just(id_)
					});
			case 2:
				var tipe = modifier.a;
				return _Utils_update(
					options,
					{ay: tipe});
			case 3:
				var val = modifier.a;
				return _Utils_update(
					options,
					{a7: val});
			case 4:
				var value_ = modifier.a;
				return _Utils_update(
					options,
					{
						A: $elm$core$Maybe$Just(value_)
					});
			case 7:
				var value_ = modifier.a;
				return _Utils_update(
					options,
					{
						iw: $elm$core$Maybe$Just(value_)
					});
			case 5:
				var onInput_ = modifier.a;
				return _Utils_update(
					options,
					{
						bz: $elm$core$Maybe$Just(onInput_)
					});
			case 6:
				var validation_ = modifier.a;
				return _Utils_update(
					options,
					{
						ca: $elm$core$Maybe$Just(validation_)
					});
			case 8:
				var val = modifier.a;
				return _Utils_update(
					options,
					{bU: val});
			case 9:
				var val = modifier.a;
				return _Utils_update(
					options,
					{av: val});
			default:
				var attrs_ = modifier.a;
				return _Utils_update(
					options,
					{
						aU: _Utils_ap(options.aU, attrs_)
					});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Input$defaultOptions = {aU: _List_Nil, a7: false, ef: $elm$core$Maybe$Nothing, bz: $elm$core$Maybe$Nothing, iw: $elm$core$Maybe$Nothing, av: false, bU: false, ge: $elm$core$Maybe$Nothing, ay: 0, ca: $elm$core$Maybe$Nothing, A: $elm$core$Maybe$Nothing};
var $elm$html$Html$Attributes$id = $elm$html$Html$Attributes$stringProperty('id');
var $elm$html$Html$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 1, a: a};
};
var $elm$html$Html$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $elm$html$Html$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $elm$html$Html$Events$onInput = function (tagger) {
	return A2(
		$elm$html$Html$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue)));
};
var $elm$html$Html$Attributes$placeholder = $elm$html$Html$Attributes$stringProperty('placeholder');
var $elm$html$Html$Attributes$readonly = $elm$html$Html$Attributes$boolProperty('readOnly');
var $rundis$elm_bootstrap$Bootstrap$Form$Input$sizeAttribute = function (size) {
	return A2(
		$elm$core$Maybe$map,
		function (s) {
			return $elm$html$Html$Attributes$class('form-control-' + s);
		},
		$rundis$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption(size));
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$typeAttribute = function (inputType) {
	return $elm$html$Html$Attributes$type_(
		function () {
			switch (inputType) {
				case 0:
					return 'text';
				case 1:
					return 'password';
				case 2:
					return 'datetime-local';
				case 3:
					return 'date';
				case 4:
					return 'month';
				case 5:
					return 'time';
				case 6:
					return 'week';
				case 7:
					return 'number';
				case 8:
					return 'email';
				case 9:
					return 'url';
				case 10:
					return 'search';
				case 11:
					return 'tel';
				default:
					return 'color';
			}
		}());
};
var $rundis$elm_bootstrap$Bootstrap$Form$FormInternal$validationToString = function (validation) {
	if (!validation) {
		return 'is-valid';
	} else {
		return 'is-invalid';
	}
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$validationAttribute = function (validation) {
	return $elm$html$Html$Attributes$class(
		$rundis$elm_bootstrap$Bootstrap$Form$FormInternal$validationToString(validation));
};
var $elm$html$Html$Attributes$value = $elm$html$Html$Attributes$stringProperty('value');
var $rundis$elm_bootstrap$Bootstrap$Form$Input$toAttributes = function (modifiers) {
	var options = A3($elm$core$List$foldl, $rundis$elm_bootstrap$Bootstrap$Form$Input$applyModifier, $rundis$elm_bootstrap$Bootstrap$Form$Input$defaultOptions, modifiers);
	return _Utils_ap(
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class(
				options.av ? 'form-control-plaintext' : 'form-control'),
				$elm$html$Html$Attributes$disabled(options.a7),
				$elm$html$Html$Attributes$readonly(options.bU || options.av),
				$rundis$elm_bootstrap$Bootstrap$Form$Input$typeAttribute(options.ay)
			]),
		_Utils_ap(
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				_List_fromArray(
					[
						A2($elm$core$Maybe$map, $elm$html$Html$Attributes$id, options.ef),
						A2($elm$core$Maybe$andThen, $rundis$elm_bootstrap$Bootstrap$Form$Input$sizeAttribute, options.ge),
						A2($elm$core$Maybe$map, $elm$html$Html$Attributes$value, options.A),
						A2($elm$core$Maybe$map, $elm$html$Html$Attributes$placeholder, options.iw),
						A2($elm$core$Maybe$map, $elm$html$Html$Events$onInput, options.bz),
						A2($elm$core$Maybe$map, $rundis$elm_bootstrap$Bootstrap$Form$Input$validationAttribute, options.ca)
					])),
			options.aU));
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$view = function (_v0) {
	var options = _v0.it;
	return A2(
		$elm$html$Html$input,
		$rundis$elm_bootstrap$Bootstrap$Form$Input$toAttributes(options),
		_List_Nil);
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$input = F2(
	function (tipe, options) {
		return $rundis$elm_bootstrap$Bootstrap$Form$Input$view(
			A2($rundis$elm_bootstrap$Bootstrap$Form$Input$create, tipe, options));
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Input$text = $rundis$elm_bootstrap$Bootstrap$Form$Input$input(0);
var $rundis$elm_bootstrap$Bootstrap$Table$th = F2(
	function (options, children) {
		return $rundis$elm_bootstrap$Bootstrap$Table$Th(
			{c9: children, it: options});
	});
var $rundis$elm_bootstrap$Bootstrap$Table$thead = F2(
	function (options, rows) {
		return {it: options, bV: rows};
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Input$Value = function (a) {
	return {$: 4, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$value = function (value_) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Input$Value(value_);
};
var $author$project$Components$N80GroupTable$buildN80GroupsTable = F4(
	function (cmn, cfg, data, pageState) {
		var row_data = A2(
			$elm$core$List$map,
			A3($author$project$Components$N80GroupTable$buildN80GroupsDivTableRow, cmn, cfg, data),
			data.ib);
		var cols = cfg.eY;
		var tbl_hdr = A2(
			$rundis$elm_bootstrap$Bootstrap$Table$tr,
			_List_Nil,
			A2(
				$elm$core$List$map,
				function (col) {
					return A2(
						$rundis$elm_bootstrap$Bootstrap$Table$th,
						_List_Nil,
						_List_fromArray(
							[
								$author$project$Components$N80GroupTable$buildN80GroupTblColHdr(col)
							]));
				},
				cols));
		var tbl = $elm$core$Maybe$Just(
			$rundis$elm_bootstrap$Bootstrap$Table$table(
				{
					it: _List_fromArray(
						[$rundis$elm_bootstrap$Bootstrap$Table$striped, $rundis$elm_bootstrap$Bootstrap$Table$hover, $rundis$elm_bootstrap$Bootstrap$Table$small]),
					iN: A2($rundis$elm_bootstrap$Bootstrap$Table$tbody, _List_Nil, row_data),
					iO: A2(
						$rundis$elm_bootstrap$Bootstrap$Table$thead,
						_List_Nil,
						_List_fromArray(
							[tbl_hdr]))
				}));
		var can_add = cfg.hh;
		var add_row = function () {
			if (!can_add) {
				return $elm$core$Maybe$Nothing;
			} else {
				var show_add = pageState.cz.gG !== '';
				var public_auth_dropdown = A2(
					$elm$html$Html$map,
					$author$project$Components$N80GroupTableTypes$N80GroupTableActionUpdateDropdownAuth,
					$author$project$ElmCommon$Dropdown$buildDropdownWidget(pageState.eH));
				var add_input = $rundis$elm_bootstrap$Bootstrap$Form$Input$text(
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Form$Input$placeholder('Create N80Group ...'),
							$rundis$elm_bootstrap$Bootstrap$Form$Input$value(pageState.cz.gG),
							$rundis$elm_bootstrap$Bootstrap$Form$Input$onInput(
							function (txt) {
								return $author$project$Components$N80GroupTableTypes$N80GroupTableMsgUpdateGroupName(
									{gG: txt});
							})
						]));
				var add_action = A3($author$project$Components$N80GroupTableTypes$N80GroupTableActionCreate, pageState.cz, cfg.hj, pageState.eH.iC);
				var add_button = show_add ? _List_fromArray(
					[
						$author$project$Components$CommonPageComponents$iconBtnAdd(add_action)
					]) : _List_Nil;
				var add_row_ = A2(
					$rundis$elm_bootstrap$Bootstrap$Form$row,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rundis$elm_bootstrap$Bootstrap$Form$col,
							_List_fromArray(
								[$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm8]),
							_List_fromArray(
								[add_input])),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Form$col,
							_List_fromArray(
								[$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm2]),
							_List_fromArray(
								[public_auth_dropdown])),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Form$col,
							_List_fromArray(
								[$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm1]),
							_List_Nil),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Form$col,
							_List_fromArray(
								[$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm1]),
							add_button)
						]));
				return $elm$core$Maybe$Just(add_row_);
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			$author$project$ElmCommon$Utils$catMaybes(
				_List_fromArray(
					[tbl, add_row])));
	});
var $author$project$Components$UserGroupTableTypes$UserGroupTableActionCreate = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$UserGroupTableTypes$UserGroupTableMsgUpdateGroupName = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$UserGroupTable$buildUserGroupTblColHdr = function (col) {
	switch (col) {
		case 0:
			return $elm$html$Html$text('User-Group Name');
		case 1:
			return $elm$html$Html$text('Membership type');
		case 2:
			return $elm$html$Html$text('Owner');
		case 3:
			return $elm$html$Html$text('#Members');
		case 4:
			return $elm$html$Html$text('N80Groups');
		default:
			return $elm$html$Html$text('Actions');
	}
};
var $author$project$Components$UserGroupTable$buildUserGroupTblHdr = function (cols) {
	var hdrs = A2(
		$elm$core$List$map,
		function (col) {
			return A2(
				$rundis$elm_bootstrap$Bootstrap$Table$th,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$Components$UserGroupTable$buildUserGroupTblColHdr(col)
					]));
		},
		cols);
	return A2($rundis$elm_bootstrap$Bootstrap$Table$tr, _List_Nil, hdrs);
};
var $author$project$Components$UserGroupTableTypes$UserGroupTableActionDelete = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$UserGroupTableTypes$UserGroupTableMsgGlobalAction = function (a) {
	return {$: 3, a: a};
};
var $author$project$Components$CommonPageComponents$buildLinkN80Group = F2(
	function (cId, nodes_) {
		return $elm$core$List$singleton(
			A2(
				$rundis$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$rundis$elm_bootstrap$Bootstrap$Button$small,
						$rundis$elm_bootstrap$Bootstrap$Button$outlineInfo,
						$rundis$elm_bootstrap$Bootstrap$Button$onClick(
						$author$project$TypesPage$PageDetailsN80Group(cId))
					]),
				nodes_));
	});
var $author$project$TypesPage$PageDetailsUserGroup = function (a) {
	return {$: 7, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Internal$Button$Secondary = 1;
var $rundis$elm_bootstrap$Bootstrap$Button$outlineSecondary = $rundis$elm_bootstrap$Bootstrap$Internal$Button$Coloring(
	$rundis$elm_bootstrap$Bootstrap$Internal$Button$Outlined(1));
var $author$project$Components$CommonPageComponents$buildLinkUserGroup_ = F2(
	function (cId, nodes_) {
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Button$button,
			_List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Button$small,
					$rundis$elm_bootstrap$Bootstrap$Button$outlineSecondary,
					$rundis$elm_bootstrap$Bootstrap$Button$onClick(
					$author$project$TypesPage$PageDetailsUserGroup(cId))
				]),
			nodes_);
	});
var $author$project$Components$CommonPageComponents$buildLinkUserGroup = function (grp) {
	return A2(
		$author$project$Components$CommonPageComponents$buildLinkUserGroup_,
		grp.db,
		_List_fromArray(
			[
				$elm$html$Html$text(grp.dc.gT)
			]));
};
var $rundis$elm_bootstrap$Bootstrap$Badge$Info = 3;
var $rundis$elm_bootstrap$Bootstrap$Badge$pillInfo = $rundis$elm_bootstrap$Bootstrap$Badge$badgeInternal(
	_List_fromArray(
		[
			$rundis$elm_bootstrap$Bootstrap$Badge$Roled(3),
			$rundis$elm_bootstrap$Bootstrap$Badge$Pill
		]));
var $author$project$Components$CommonPageComponents$buildUserGroupMembershipType = function (typ_) {
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Badge$pillInfo,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text(
				$author$project$BigO$TypesAsStrings$showUserGroupMembershipType_(typ_))
			]));
};
var $author$project$Components$UserGroupTable$buildUserGroupTblCol = F4(
	function (cmn, _v0, tblData, col) {
		var grp = _v0.a;
		var auth = _v0.b;
		switch (col) {
			case 0:
				return A2(
					$elm$html$Html$map,
					$author$project$Components$UserGroupTableTypes$UserGroupTableMsgGlobalAction,
					$author$project$Components$CommonPageComponents$buildLinkUserGroup(grp));
			case 1:
				return $author$project$Components$CommonPageComponents$buildUserGroupMembershipType(auth);
			case 2:
				return $elm$html$Html$text(grp.dd.gU.gR);
			case 3:
				return $elm$html$Html$text(
					$elm$core$String$fromInt(
						$elm$core$List$length(grp.de)));
			case 4:
				var n80GroupName = function (n80GroupId) {
					return A2(
						$elm$core$Maybe$withDefault,
						$author$project$Api$N80GroupName$N80GroupName(''),
						$elm$core$List$head(
							A2(
								$elm$core$List$map,
								function (o) {
									return o.di;
								},
								A2(
									$elm$core$List$filter,
									function (o) {
										return _Utils_eq(o.dh, n80GroupId);
									},
									tblData.i2))));
				};
				var n80Conns = function (_v2) {
					var n80GroupId = _v2.a;
					var authLevel = _v2.b;
					var txt = n80GroupName(n80GroupId).gG + (' [' + ($author$project$BigO$TypesAsStrings$showAccessLevel_(authLevel.gw) + ']'));
					return A2(
						$elm$html$Html$map,
						$author$project$Components$UserGroupTableTypes$UserGroupTableMsgGlobalAction,
						A2(
							$elm$html$Html$div,
							_List_Nil,
							A2(
								$author$project$Components$CommonPageComponents$buildLinkN80Group,
								n80GroupId,
								_List_fromArray(
									[
										$elm$html$Html$text(txt)
									]))));
				};
				var n80sGroups = A2($elm$core$List$map, n80Conns, grp.df);
				return A2($elm$html$Html$div, _List_Nil, n80sGroups);
			default:
				var usergroup_admin = _Utils_eq(
					cmn.i$(grp.db),
					$elm$core$Maybe$Just(1));
				var deleteBtn = $author$project$Components$CommonPageComponents$iconBtnDelete(
					$author$project$Components$UserGroupTableTypes$UserGroupTableActionDelete(grp.db));
				var can_delete = usergroup_admin;
				return can_delete ? deleteBtn : $author$project$BigO$Utils$htmlEmpty;
		}
	});
var $author$project$Components$UserGroupTable$buildUserGroupTblRow = F4(
	function (cmn, cols, data, group) {
		var row = A2(
			$rundis$elm_bootstrap$Bootstrap$Table$tr,
			_List_Nil,
			A2(
				$elm$core$List$map,
				function (t) {
					return A2(
						$rundis$elm_bootstrap$Bootstrap$Table$td,
						_List_Nil,
						_List_fromArray(
							[t]));
				},
				A2(
					$elm$core$List$map,
					function (col) {
						return A4($author$project$Components$UserGroupTable$buildUserGroupTblCol, cmn, group, data, col);
					},
					cols)));
		return row;
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$Col11 = 11;
var $rundis$elm_bootstrap$Bootstrap$Grid$Col$sm11 = A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$width, 1, 11);
var $author$project$Components$UserGroupTable$buildUserGroupsTable = F4(
	function (cmn, cfg, pageState, tblData) {
		var cols = cfg.i0;
		var group_rows = A2(
			$elm$core$List$map,
			A3($author$project$Components$UserGroupTable$buildUserGroupTblRow, cmn, cols, tblData),
			tblData.i3);
		var table = $rundis$elm_bootstrap$Bootstrap$Table$table(
			{
				it: _List_fromArray(
					[$rundis$elm_bootstrap$Bootstrap$Table$striped, $rundis$elm_bootstrap$Bootstrap$Table$hover, $rundis$elm_bootstrap$Bootstrap$Table$small]),
				iN: A2($rundis$elm_bootstrap$Bootstrap$Table$tbody, _List_Nil, group_rows),
				iO: A2(
					$rundis$elm_bootstrap$Bootstrap$Table$thead,
					_List_Nil,
					_List_fromArray(
						[
							$author$project$Components$UserGroupTable$buildUserGroupTblHdr(cols)
						]))
			});
		var can_add_usergroup = cfg.hi;
		var add_form = function () {
			if (!can_add_usergroup) {
				return $elm$core$Maybe$Nothing;
			} else {
				var show_add = pageState.co.gT !== '';
				var add_input = $rundis$elm_bootstrap$Bootstrap$Form$Input$text(
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Form$Input$placeholder('Create UserGroup ...'),
							$rundis$elm_bootstrap$Bootstrap$Form$Input$value(pageState.co.gT),
							$rundis$elm_bootstrap$Bootstrap$Form$Input$onInput(
							function (txt) {
								return $author$project$Components$UserGroupTableTypes$UserGroupTableMsgUpdateGroupName(
									{gT: txt});
							})
						]));
				var add_action = $author$project$Components$UserGroupTableTypes$UserGroupTableActionCreate(pageState.co);
				var add_button = show_add ? _List_fromArray(
					[
						$author$project$Components$CommonPageComponents$iconBtnAdd(add_action)
					]) : _List_Nil;
				var add_row = A2(
					$rundis$elm_bootstrap$Bootstrap$Form$row,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rundis$elm_bootstrap$Bootstrap$Form$col,
							_List_fromArray(
								[$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm11]),
							_List_fromArray(
								[add_input])),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Form$col,
							_List_fromArray(
								[$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm1]),
							add_button)
						]));
				return $elm$core$Maybe$Just(add_row);
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			$author$project$ElmCommon$Utils$catMaybes(
				_List_fromArray(
					[
						$elm$core$Maybe$Just(table),
						add_form
					])));
	});
var $author$project$Components$UserTable$buildUserTblColHdr = function (col) {
	switch (col) {
		case 0:
			return $elm$html$Html$text('Name');
		case 1:
			return $elm$html$Html$text('Email');
		default:
			return $elm$html$Html$text('Type');
	}
};
var $author$project$Components$UserTable$buildUserTblHdr = function (cols) {
	var hdrs = A2(
		$elm$core$List$map,
		function (col) {
			return A2(
				$rundis$elm_bootstrap$Bootstrap$Table$th,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$Components$UserTable$buildUserTblColHdr(col)
					]));
		},
		cols);
	return A2($rundis$elm_bootstrap$Bootstrap$Table$tr, _List_Nil, hdrs);
};
var $author$project$BigO$TypesAsStrings$showUserEmail = function (email) {
	return email.gR;
};
var $author$project$BigO$TypesAsStrings$showUserName = function (name) {
	return name.gV;
};
var $author$project$BigO$TypesAsStrings$showUserType = function (cloudUserType) {
	switch (cloudUserType) {
		case 0:
			return 'Public';
		case 1:
			return 'StdUser';
		default:
			return 'AdminUser';
	}
};
var $author$project$Components$UserTable$buildUserTblCol = F2(
	function (user, col) {
		switch (col) {
			case 0:
				return $elm$html$Html$text(
					$author$project$BigO$TypesAsStrings$showUserName(user.dF));
			case 1:
				return $elm$html$Html$text(
					$author$project$BigO$TypesAsStrings$showUserEmail(user.dE));
			default:
				return $elm$html$Html$text(
					$author$project$BigO$TypesAsStrings$showUserType(user.dG));
		}
	});
var $author$project$Components$UserTable$buildUserTblRow = F2(
	function (cols, user) {
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Table$tr,
			_List_Nil,
			A2(
				$elm$core$List$map,
				function (t) {
					return A2(
						$rundis$elm_bootstrap$Bootstrap$Table$td,
						_List_Nil,
						_List_fromArray(
							[t]));
				},
				A2(
					$elm$core$List$map,
					function (col) {
						return A2($author$project$Components$UserTable$buildUserTblCol, user, col);
					},
					cols)));
	});
var $author$project$Components$UserTable$UserTblColEmail = 1;
var $author$project$Components$UserTable$UserTblColName = 0;
var $author$project$Components$UserTable$UserTblColType = 2;
var $author$project$Components$UserTable$getUserTableCols = _List_fromArray(
	[0, 1, 2]);
var $author$project$Components$UserTable$buildUsersTable = function (users) {
	var cols = $author$project$Components$UserTable$getUserTableCols;
	return $rundis$elm_bootstrap$Bootstrap$Table$table(
		{
			it: _List_fromArray(
				[$rundis$elm_bootstrap$Bootstrap$Table$striped, $rundis$elm_bootstrap$Bootstrap$Table$hover, $rundis$elm_bootstrap$Bootstrap$Table$small]),
			iN: A2(
				$rundis$elm_bootstrap$Bootstrap$Table$tbody,
				_List_Nil,
				A2(
					$elm$core$List$map,
					$author$project$Components$UserTable$buildUserTblRow(cols),
					users)),
			iO: A2(
				$rundis$elm_bootstrap$Bootstrap$Table$thead,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$Components$UserTable$buildUserTblHdr(cols)
					]))
		});
};
var $elm$html$Html$br = _VirtualDom_node('br');
var $author$project$ElmCommon$HtmlCommon$dashboardElemDivTitle_ = function (m) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('float-left'),
				$elm$html$Html$Attributes$class('dashboard-elem-title')
			]),
		_List_fromArray(
			[m]));
};
var $author$project$ElmCommon$HtmlCommon$dashboardElemDiv_ = function (m) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('dashboard-elem')
			]),
		_List_fromArray(
			[m]));
};
var $author$project$ElmCommon$HtmlCommon$dashboardElemTitledDiv_ = F2(
	function (title, m) {
		return $author$project$ElmCommon$HtmlCommon$dashboardElemDiv_(
			A2(
				$elm$html$Html$div,
				_List_Nil,
				_Utils_ap(
					_List_fromArray(
						[
							$author$project$ElmCommon$HtmlCommon$dashboardElemDivTitle_(
							$elm$html$Html$text(title)),
							A2($elm$html$Html$br, _List_Nil, _List_Nil)
						]),
					m)));
	});
var $author$project$Pages$PageAdminUsersAndGroups$pageAdminAccess = F2(
	function (mdl, cmn) {
		var _v0 = _Utils_Tuple2(
			_Utils_Tuple2(mdl.c2, mdl.c3),
			_Utils_Tuple2(mdl.c0, mdl.dL));
		if ((((!_v0.a.a.$) && (!_v0.a.b.$)) && (!_v0.b.a.$)) && (!_v0.b.b.$)) {
			var _v1 = _v0.a;
			var auth_user_details = _v1.a.a;
			var auth_user_groups = _v1.b.a;
			var _v2 = _v0.b;
			var auth_admin_data = _v2.a.a;
			var editStatePageAdminAccess = _v2.b.a;
			var users_table = $author$project$Components$UserTable$buildUsersTable(auth_admin_data.cZ);
			var users = A2(
				$author$project$ElmCommon$HtmlCommon$dashboardElemTitledDiv_,
				'Users',
				_List_fromArray(
					[users_table]));
			var usergroups_table_state = editStatePageAdminAccess.hE;
			var usergroups_table_cfg = {
				hi: auth_user_groups.d_,
				i0: _List_fromArray(
					[0, 1, 2, 3, 4, 5])
			};
			var usergroups_access = A2(
				$elm$core$List$map,
				function (grp) {
					return _Utils_Tuple2(grp, 1);
				},
				auth_admin_data.cY);
			var n80groups_table_cfg = {
				hh: auth_user_groups.dY,
				hj: $author$project$Api$N80GroupOwnerEmail$N80GroupOwnerEmail(auth_user_details.dE),
				eY: _List_fromArray(
					[0, 3, 2, 1, 4, 5])
			};
			var n80groups_access = auth_admin_data.cX;
			var usergroups_table_data = {i2: n80groups_access, i3: usergroups_access};
			var usergroups_table = A4($author$project$Components$UserGroupTable$buildUserGroupsTable, cmn, usergroups_table_cfg, usergroups_table_state, usergroups_table_data);
			var user_groups = A2(
				$author$project$ElmCommon$HtmlCommon$dashboardElemTitledDiv_,
				'User Groups',
				_List_fromArray(
					[
						A2(
						$elm$html$Html$map,
						$author$project$Msg$UiPageUpdate,
						A2($elm$html$Html$map, $author$project$Msg$UiUpdatePageAdminAccessUserGroupTable, usergroups_table))
					]));
			var n80group_admin_access = {gw: 3};
			var n80groups_table_data = {
				ib: A2(
					$elm$core$List$map,
					function (grp) {
						return _Utils_Tuple2(grp, n80group_admin_access);
					},
					n80groups_access),
				ic: $author$project$NodeDataStore$mdlGetN80Meta(mdl.fm)
			};
			var n80groups_table = A4($author$project$Components$N80GroupTable$buildN80GroupsTable, cmn, n80groups_table_cfg, n80groups_table_data, editStatePageAdminAccess.hD);
			var n80_groups = A2(
				$author$project$ElmCommon$HtmlCommon$dashboardElemTitledDiv_,
				'Node Groups',
				_List_fromArray(
					[
						A2(
						$elm$html$Html$map,
						$author$project$Msg$UiPageUpdate,
						A2($elm$html$Html$map, $author$project$Msg$UiUpdatePageAdminAccessN80GroupTable, n80groups_table))
					]));
			return _List_fromArray(
				[users, user_groups, n80_groups]);
		} else {
			return _List_fromArray(
				[
					$elm$html$Html$text('MISSING ADMIN DATA or editStatePageUserAccess ?? ')
				]);
		}
	});
var $author$project$Pages$PageAdminServerProcessingStatus$buildBlock = function (_v0) {
	var str = _v0.a;
	var kvs = _v0.b;
	return $author$project$ElmCommon$HtmlCommon$dashboardElemDiv_(
		A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					$author$project$ElmCommon$HtmlCommon$dashboardElemDivTitle_(
					$elm$html$Html$text(str)),
					A2($elm$html$Html$br, _List_Nil, _List_Nil),
					$rundis$elm_bootstrap$Bootstrap$Table$table(
					{
						it: _List_fromArray(
							[$rundis$elm_bootstrap$Bootstrap$Table$striped, $rundis$elm_bootstrap$Bootstrap$Table$hover, $rundis$elm_bootstrap$Bootstrap$Table$small]),
						iN: A2(
							$rundis$elm_bootstrap$Bootstrap$Table$tbody,
							_List_Nil,
							A2(
								$elm$core$List$map,
								function (_v1) {
									var k = _v1.a;
									var v = _v1.b;
									return A2(
										$rundis$elm_bootstrap$Bootstrap$Table$tr,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$rundis$elm_bootstrap$Bootstrap$Table$td,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text(k)
													])),
												A2(
												$rundis$elm_bootstrap$Bootstrap$Table$td,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text(v)
													]))
											]));
								},
								kvs)),
						iO: A2($rundis$elm_bootstrap$Bootstrap$Table$thead, _List_Nil, _List_Nil)
					})
				])));
};
var $author$project$GeneratedBuildConstants$getBuildTimeStr = 'Thu Sep 26 06:26:28 PM UTC 2024';
var $author$project$GeneratedBuildConstants$getGitShaStr = 'No git?';
var $ggb$numeral_elm$Numeral$empty = F3(
	function (lang, format_val, value) {
		return {
			B: '',
			aY: '',
			a4: '',
			hx: '',
			e: format_val,
			O: lang,
			bk: 0,
			bo: '',
			ad: false,
			ae: false,
			bA: false,
			iu: '',
			bH: _Utils_Tuple2('', ''),
			bI: '',
			bJ: '',
			aw: false,
			b$: $elm$core$String$fromFloat(value),
			A: value,
			k: ''
		};
	});
var $ggb$numeral_elm$Numeral$regex = function (str) {
	return A2(
		$elm$core$Maybe$withDefault,
		$elm$regex$Regex$never,
		$elm$regex$Regex$fromString(str));
};
var $elm$regex$Regex$replace = _Regex_replaceAtMost(_Regex_infinity);
var $ggb$numeral_elm$Numeral$emptyReplace = function (str) {
	return A2(
		$elm$regex$Regex$replace,
		$ggb$numeral_elm$Numeral$regex(str),
		function (_v0) {
			return '';
		});
};
var $ggb$numeral_elm$Numeral$checkAbbreviation = function (numeral) {
	var _v0 = numeral;
	var language = _v0.O;
	var format_value = _v0.e;
	var value = _v0.A;
	var abbrB = A2($elm$core$String$contains, 'aB', format_value);
	var abbrK = A2($elm$core$String$contains, 'aK', format_value);
	var abbrM = A2($elm$core$String$contains, 'aM', format_value);
	var abbrT = A2($elm$core$String$contains, 'aT', format_value);
	var abbrForce = !(abbrK || (abbrM || (abbrB || abbrT)));
	var absValue = $elm$core$Basics$abs(value);
	var _v1 = A2($elm$core$String$contains, ' a', format_value) ? _Utils_Tuple2(
		' ',
		A2($ggb$numeral_elm$Numeral$emptyReplace, ' a', format_value)) : _Utils_Tuple2(
		'',
		A2($ggb$numeral_elm$Numeral$emptyReplace, 'a', format_value));
	var abbr = _v1.a;
	var format1 = _v1.b;
	return (!A2($elm$core$String$contains, 'a', format_value)) ? numeral : ((((_Utils_cmp(
		absValue,
		A2($elm$core$Basics$pow, 10, 12)) > -1) && abbrForce) || abbrT) ? _Utils_update(
		numeral,
		{
			B: _Utils_ap(abbr, language.hd.iW),
			e: format1,
			A: value / A2($elm$core$Basics$pow, 10, 12)
		}) : ((((_Utils_cmp(
		absValue,
		A2($elm$core$Basics$pow, 10, 12)) < 0) && ((_Utils_cmp(
		absValue,
		A2($elm$core$Basics$pow, 10, 9)) > -1) && abbrForce)) || abbrB) ? _Utils_update(
		numeral,
		{
			B: _Utils_ap(abbr, language.hd.hk),
			e: format1,
			A: value / A2($elm$core$Basics$pow, 10, 9)
		}) : ((((_Utils_cmp(
		absValue,
		A2($elm$core$Basics$pow, 10, 9)) < 0) && ((_Utils_cmp(
		absValue,
		A2($elm$core$Basics$pow, 10, 6)) > -1) && abbrForce)) || abbrM) ? _Utils_update(
		numeral,
		{
			B: _Utils_ap(abbr, language.hd.h8),
			e: format1,
			A: value / A2($elm$core$Basics$pow, 10, 6)
		}) : ((((_Utils_cmp(
		absValue,
		A2($elm$core$Basics$pow, 10, 6)) < 0) && ((_Utils_cmp(
		absValue,
		A2($elm$core$Basics$pow, 10, 3)) > -1) && abbrForce)) || abbrK) ? _Utils_update(
		numeral,
		{
			B: _Utils_ap(abbr, language.hd.iP),
			e: format1,
			A: value / A2($elm$core$Basics$pow, 10, 3)
		}) : _Utils_update(
		numeral,
		{B: abbr, e: format1})))));
};
var $elm$core$Bitwise$shiftRightZfBy = _Bitwise_shiftRightZfBy;
var $elm$core$Array$bitMask = 4294967295 >>> (32 - $elm$core$Array$shiftStep);
var $elm$core$Elm$JsArray$unsafeGet = _JsArray_unsafeGet;
var $elm$core$Array$getHelp = F3(
	function (shift, index, tree) {
		getHelp:
		while (true) {
			var pos = $elm$core$Array$bitMask & (index >>> shift);
			var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, pos, tree);
			if (!_v0.$) {
				var subTree = _v0.a;
				var $temp$shift = shift - $elm$core$Array$shiftStep,
					$temp$index = index,
					$temp$tree = subTree;
				shift = $temp$shift;
				index = $temp$index;
				tree = $temp$tree;
				continue getHelp;
			} else {
				var values = _v0.a;
				return A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, values);
			}
		}
	});
var $elm$core$Bitwise$shiftLeftBy = _Bitwise_shiftLeftBy;
var $elm$core$Array$tailIndex = function (len) {
	return (len >>> 5) << 5;
};
var $elm$core$Array$get = F2(
	function (index, _v0) {
		var len = _v0.a;
		var startShift = _v0.b;
		var tree = _v0.c;
		var tail = _v0.d;
		return ((index < 0) || (_Utils_cmp(index, len) > -1)) ? $elm$core$Maybe$Nothing : ((_Utils_cmp(
			index,
			$elm$core$Array$tailIndex(len)) > -1) ? $elm$core$Maybe$Just(
			A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, tail)) : $elm$core$Maybe$Just(
			A3($elm$core$Array$getHelp, startShift, index, tree)));
	});
var $elm$core$Array$fromListHelp = F3(
	function (list, nodeList, nodeListSize) {
		fromListHelp:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, list);
			var jsArray = _v0.a;
			var remainingItems = _v0.b;
			if (_Utils_cmp(
				$elm$core$Elm$JsArray$length(jsArray),
				$elm$core$Array$branchFactor) < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					true,
					{j: nodeList, g: nodeListSize, i: jsArray});
			} else {
				var $temp$list = remainingItems,
					$temp$nodeList = A2(
					$elm$core$List$cons,
					$elm$core$Array$Leaf(jsArray),
					nodeList),
					$temp$nodeListSize = nodeListSize + 1;
				list = $temp$list;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue fromListHelp;
			}
		}
	});
var $elm$core$Array$fromList = function (list) {
	if (!list.b) {
		return $elm$core$Array$empty;
	} else {
		return A3($elm$core$Array$fromListHelp, list, _List_Nil, 0);
	}
};
var $ggb$numeral_elm$Numeral$suffixes = $elm$core$Array$fromList(
	_List_fromArray(
		['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']));
var $ggb$numeral_elm$Numeral$checkByte = function (numeral) {
	var _v0 = numeral;
	var format_value = _v0.e;
	var value = _v0.A;
	var suffixIndex1 = function (power) {
		suffixIndex1:
		while (true) {
			var minValue = A2($elm$core$Basics$pow, 1024, power);
			var maxValue = A2($elm$core$Basics$pow, 1024, power + 1);
			if ((_Utils_cmp(value, minValue) > -1) && (_Utils_cmp(value, maxValue) < 0)) {
				return (minValue > 0) ? _Utils_Tuple2(power, value / minValue) : _Utils_Tuple2(power, value);
			} else {
				if (power < 10) {
					var $temp$power = power + 1;
					power = $temp$power;
					continue suffixIndex1;
				} else {
					return _Utils_Tuple2(-1, value);
				}
			}
		}
	};
	var _v1 = suffixIndex1(0);
	var suffixIndex = _v1.a;
	var value1 = _v1.b;
	var suffix = A2(
		$elm$core$Maybe$withDefault,
		'',
		A2(
			$elm$core$Array$get,
			$elm$core$Basics$round(suffixIndex),
			$ggb$numeral_elm$Numeral$suffixes));
	var _v2 = A2($elm$core$String$contains, ' b', format_value) ? _Utils_Tuple2(
		' ',
		A2($ggb$numeral_elm$Numeral$emptyReplace, ' b', format_value)) : _Utils_Tuple2(
		'',
		A2($ggb$numeral_elm$Numeral$emptyReplace, 'b', format_value));
	var bytes = _v2.a;
	var format1 = _v2.b;
	return A2($elm$core$String$contains, 'b', format_value) ? _Utils_update(
		numeral,
		{
			aY: _Utils_ap(bytes, suffix),
			e: format1,
			A: value1
		}) : numeral;
};
var $elm$regex$Regex$find = _Regex_findAtMost(_Regex_infinity);
var $elm$core$String$replace = F3(
	function (before, after, string) {
		return A2(
			$elm$core$String$join,
			after,
			A2($elm$core$String$split, before, string));
	});
var $ggb$numeral_elm$Numeral$checkForCustomSuffix = function (numeral) {
	var hasSuffix = $elm$core$List$head(
		A2(
			$elm$regex$Regex$find,
			$ggb$numeral_elm$Numeral$regex('\\[\\D+\\]$'),
			numeral.e));
	if (hasSuffix.$ === 1) {
		return numeral;
	} else {
		var match = hasSuffix.a.h5;
		return _Utils_update(
			numeral,
			{
				a4: A3(
					$elm$regex$Regex$replace,
					$ggb$numeral_elm$Numeral$regex('\\[|\\]'),
					function (_v1) {
						return '';
					},
					match),
				e: A3($elm$core$String$replace, match, '', numeral.e)
			});
	}
};
var $ggb$numeral_elm$Numeral$checkIfNegative = function (numeral) {
	return A2($elm$core$String$contains, '-', numeral.k) ? _Utils_update(
		numeral,
		{
			ad: true,
			k: A3(
				$elm$core$String$slice,
				1,
				$elm$core$String$length(numeral.k),
				numeral.k)
		}) : numeral;
};
var $ggb$numeral_elm$Numeral$checkOptionalDec = function (numeral) {
	return A2($elm$core$String$contains, '[.]', numeral.e) ? _Utils_update(
		numeral,
		{
			e: A3(
				$elm$regex$Regex$replace,
				$ggb$numeral_elm$Numeral$regex('\\[\\.\\]'),
				function (_v0) {
					return '.';
				},
				numeral.e),
			bA: true
		}) : numeral;
};
var $ggb$numeral_elm$Numeral$checkOrdinal = function (numeral) {
	var _v0 = numeral;
	var language = _v0.O;
	var format_value = _v0.e;
	var value = _v0.A;
	var _v1 = A2($elm$core$String$contains, ' o', format_value) ? _Utils_Tuple2(
		' ',
		A2($ggb$numeral_elm$Numeral$emptyReplace, ' o', format_value)) : _Utils_Tuple2(
		'',
		A2($ggb$numeral_elm$Numeral$emptyReplace, 'o', format_value));
	var ord = _v1.a;
	var format1 = _v1.b;
	return A2($elm$core$String$contains, 'o', format_value) ? _Utils_update(
		numeral,
		{
			e: format1,
			iu: _Utils_ap(
				ord,
				numeral.O.iu(value))
		}) : numeral;
};
var $ggb$numeral_elm$Numeral$checkParensAndSign = function (numeral) {
	return A2($elm$core$String$contains, '(', numeral.e) ? _Utils_update(
		numeral,
		{
			e: A3($elm$core$String$slice, 1, -1, numeral.e),
			ae: true,
			aw: false
		}) : (A2($elm$core$String$contains, '+', numeral.e) ? _Utils_update(
		numeral,
		{
			e: A2($ggb$numeral_elm$Numeral$emptyReplace, '\\+', numeral.e),
			ae: false,
			aw: true
		}) : numeral);
};
var $ggb$numeral_elm$Numeral$addThousandsDelimiter = F2(
	function (lang, word) {
		return A3(
			$elm$regex$Regex$replace,
			$ggb$numeral_elm$Numeral$regex('(\\d)(?=(\\d{3})+(?!\\d))'),
			function (_v0) {
				var match = _v0.h5;
				return _Utils_ap(match, lang.hy.iQ);
			},
			word);
	});
var $ggb$numeral_elm$Numeral$checkThousandsDelimiter = function (numeral) {
	return A2($elm$core$String$contains, ',', numeral.e) ? _Utils_update(
		numeral,
		{
			k: A2($ggb$numeral_elm$Numeral$addThousandsDelimiter, numeral.O, numeral.k)
		}) : numeral;
};
var $ggb$numeral_elm$Numeral$createFinalString = function (_v0) {
	var parens = _v0.bH;
	var minus = _v0.bo;
	var plus = _v0.bI;
	var word = _v0.k;
	var decimal = _v0.hx;
	var ordinal = _v0.iu;
	var abbreviation = _v0.B;
	var bytes = _v0.aY;
	var customSuffix = _v0.a4;
	return A2(
		$elm$core$String$join,
		'',
		_List_fromArray(
			[parens.a, minus, plus, word, decimal, ordinal, abbreviation, bytes, customSuffix, parens.b]));
};
var $ggb$numeral_elm$Numeral$indexOf = F2(
	function (part, word) {
		return A2(
			$elm$core$Maybe$withDefault,
			-1,
			$elm$core$List$head(
				A2($elm$core$String$indexes, part, word)));
	});
var $ggb$numeral_elm$Numeral$createFinalWord = function (numeral) {
	return (!A2($ggb$numeral_elm$Numeral$indexOf, '.', numeral.e)) ? _Utils_update(
		numeral,
		{k: ''}) : numeral;
};
var $ggb$numeral_elm$Numeral$createParens = function (numeral) {
	return (numeral.ae && numeral.ad) ? _Utils_update(
		numeral,
		{
			bH: _Utils_Tuple2('(', ')')
		}) : numeral;
};
var $ggb$numeral_elm$Numeral$getPrecision = function (numeral) {
	var splitted = A2($elm$core$String$split, '.', numeral.e);
	var precision = A2(
		$elm$core$Maybe$withDefault,
		'',
		$elm$core$List$head(
			A2($elm$core$List$drop, 1, splitted)));
	var leadingZeros = $elm$core$String$length(
		A2(
			$elm$core$Maybe$withDefault,
			'',
			$elm$core$List$head(splitted)));
	var leadingZeros_ = A2($elm$core$String$contains, ',', numeral.e) ? 0 : leadingZeros;
	return _Utils_update(
		numeral,
		{bk: leadingZeros_, bJ: precision});
};
var $ggb$numeral_elm$Numeral$hasMinus = function (numeral) {
	return ((!numeral.ae) && numeral.ad) ? _Utils_update(
		numeral,
		{bo: '-'}) : numeral;
};
var $ggb$numeral_elm$Numeral$hasPlus = function (numeral) {
	return ((!numeral.ad) && numeral.aw) ? _Utils_update(
		numeral,
		{bI: '+'}) : numeral;
};
var $ggb$numeral_elm$Numeral$processDecimal = function (numeral) {
	var d = numeral.hx;
	var result = A2(
		$elm$core$Maybe$withDefault,
		1,
		$elm$core$String$toInt(
			A3(
				$elm$core$String$slice,
				1,
				$elm$core$String$length(d),
				d)));
	return (numeral.bA && (!result)) ? _Utils_update(
		numeral,
		{hx: ''}) : _Utils_update(
		numeral,
		{hx: d});
};
var $ggb$numeral_elm$Numeral$processLeadingZeros = function (numeral) {
	var w = $elm$core$String$length(
		A2(
			$elm$core$Maybe$withDefault,
			'',
			$elm$core$List$head(
				A2($elm$core$String$split, '.', numeral.k))));
	var n = numeral.bk - w;
	var prefix = A2($elm$core$String$repeat, n, '0');
	return _Utils_update(
		numeral,
		{
			k: _Utils_ap(prefix, numeral.k)
		});
};
var $ggb$numeral_elm$Numeral$flip = F3(
	function (f, x, y) {
		return A2(f, y, x);
	});
var $elm$core$String$padRight = F3(
	function (n, _char, string) {
		return _Utils_ap(
			string,
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)));
	});
var $ggb$numeral_elm$Numeral$toFixed = F2(
	function (precision, value) {
		var power = A2($elm$core$Basics$pow, 10, precision);
		var pad = function (num) {
			_v0$2:
			while (true) {
				if (num.b) {
					if (num.b.b) {
						if (!num.b.b.b) {
							var x = num.a;
							var _v1 = num.b;
							var y = _v1.a;
							return _List_fromArray(
								[
									x,
									A3($elm$core$String$padRight, precision, '0', y)
								]);
						} else {
							break _v0$2;
						}
					} else {
						var val = num.a;
						return _List_fromArray(
							[
								val,
								A3($elm$core$String$padRight, precision, '0', '')
							]);
					}
				} else {
					break _v0$2;
				}
			}
			var val = num;
			return val;
		};
		return A2(
			$elm$core$String$join,
			'.',
			pad(
				A2(
					$elm$core$String$split,
					'.',
					$elm$core$String$fromFloat(
						$elm$core$Basics$round((value * power) + 0.01) / power))));
	});
var $ggb$numeral_elm$Numeral$toFixedWithOptional = F2(
	function (prs, value) {
		_v0$2:
		while (true) {
			if (prs.b) {
				if (prs.b.b) {
					if (!prs.b.b.b) {
						var x = prs.a;
						var _v1 = prs.b;
						var y = _v1.a;
						return A2(
							$ggb$numeral_elm$Numeral$emptyReplace,
							'0{1,' + ($elm$core$String$fromInt(y) + '}$'),
							A2($ggb$numeral_elm$Numeral$toFixed, x + y, value));
					} else {
						break _v0$2;
					}
				} else {
					var x = prs.a;
					return A2($ggb$numeral_elm$Numeral$toFixed, x, value);
				}
			} else {
				break _v0$2;
			}
		}
		return $elm$core$String$fromFloat(value);
	});
var $ggb$numeral_elm$Numeral$processPrecision = function (numeral) {
	var _v0 = numeral;
	var language = _v0.O;
	var format_value = _v0.e;
	var value = _v0.A;
	var precision = _v0.bJ;
	var fst = A2($elm$core$String$contains, '[', precision) ? A2(
		$ggb$numeral_elm$Numeral$flip($ggb$numeral_elm$Numeral$toFixedWithOptional),
		value,
		A2(
			$elm$core$List$take,
			2,
			A2(
				$elm$core$List$map,
				$elm$core$String$length,
				A2(
					$elm$core$String$split,
					'[',
					A2($ggb$numeral_elm$Numeral$emptyReplace, ']', precision))))) : A2(
		$ggb$numeral_elm$Numeral$toFixed,
		$elm$core$String$length(precision),
		value);
	var snd = function () {
		var _v1 = A2($elm$core$String$split, '.', fst);
		if ((_v1.b && _v1.b.b) && (!_v1.b.b.b)) {
			var x = _v1.a;
			var _v2 = _v1.b;
			var y = _v2.a;
			return ($elm$core$String$length(y) > 0) ? _Utils_ap(language.hy.hx, y) : '';
		} else {
			return '';
		}
	}();
	var w = A2(
		$elm$core$Maybe$withDefault,
		'',
		$elm$core$List$head(
			A2($elm$core$String$split, '.', fst)));
	return (precision === '') ? _Utils_update(
		numeral,
		{hx: '', k: w}) : _Utils_update(
		numeral,
		{hx: snd, k: w});
};
var $ggb$numeral_elm$Numeral$processWord = function (numeral) {
	var w = A2(
		$elm$core$Maybe$withDefault,
		'',
		$elm$core$List$head(
			A2($elm$core$String$split, '.', numeral.b$)));
	return _Utils_update(
		numeral,
		{k: w});
};
var $ggb$numeral_elm$Numeral$updateStringValue = function (numeral) {
	return _Utils_update(
		numeral,
		{
			b$: $elm$core$String$fromFloat(numeral.A)
		});
};
var $ggb$numeral_elm$Numeral$formatNumber = function (numeral) {
	return $ggb$numeral_elm$Numeral$createFinalString(
		$ggb$numeral_elm$Numeral$hasPlus(
			$ggb$numeral_elm$Numeral$hasMinus(
				$ggb$numeral_elm$Numeral$createParens(
					$ggb$numeral_elm$Numeral$createFinalWord(
						$ggb$numeral_elm$Numeral$checkIfNegative(
							$ggb$numeral_elm$Numeral$checkThousandsDelimiter(
								$ggb$numeral_elm$Numeral$processDecimal(
									$ggb$numeral_elm$Numeral$processLeadingZeros(
										$ggb$numeral_elm$Numeral$processPrecision(
											$ggb$numeral_elm$Numeral$getPrecision(
												$ggb$numeral_elm$Numeral$processWord(
													$ggb$numeral_elm$Numeral$updateStringValue(
														$ggb$numeral_elm$Numeral$checkOptionalDec(
															$ggb$numeral_elm$Numeral$checkOrdinal(
																$ggb$numeral_elm$Numeral$checkByte(
																	$ggb$numeral_elm$Numeral$checkAbbreviation(
																		$ggb$numeral_elm$Numeral$checkForCustomSuffix(
																			$ggb$numeral_elm$Numeral$checkParensAndSign(numeral)))))))))))))))))));
};
var $ggb$numeral_elm$Numeral$formatWithoutCurrency = function (lformat) {
	return A2($elm$core$String$contains, ' $', lformat) ? _Utils_Tuple2(
		' ',
		A2($ggb$numeral_elm$Numeral$emptyReplace, ' \\$', lformat)) : (A2($elm$core$String$contains, '$ ', lformat) ? _Utils_Tuple2(
		' ',
		A2($ggb$numeral_elm$Numeral$emptyReplace, '\\$ ', lformat)) : _Utils_Tuple2(
		'',
		A2($ggb$numeral_elm$Numeral$emptyReplace, '\\$', lformat)));
};
var $ggb$numeral_elm$Numeral$formatCurrency = F4(
	function (lang, lformat, value, strValue) {
		var symbolIndex = A2($ggb$numeral_elm$Numeral$indexOf, '$', lformat);
		var openParenIndex = A2($ggb$numeral_elm$Numeral$indexOf, '(', lformat);
		var minusSignIndex = A2($ggb$numeral_elm$Numeral$indexOf, '-', lformat);
		var currencySymbol = lang.hv.iL;
		var _v0 = $ggb$numeral_elm$Numeral$formatWithoutCurrency(lformat);
		var space = _v0.a;
		var format1 = _v0.b;
		var formatted = $ggb$numeral_elm$Numeral$formatNumber(
			A3($ggb$numeral_elm$Numeral$empty, lang, format1, value));
		return (symbolIndex <= 1) ? ((A2($elm$core$String$contains, '(', formatted) || A2($elm$core$String$contains, '-', formatted)) ? (((_Utils_cmp(symbolIndex, openParenIndex) < 0) || (_Utils_cmp(symbolIndex, minusSignIndex) < 0)) ? A2(
			$elm$core$String$join,
			'',
			_List_fromArray(
				[
					currencySymbol,
					space,
					A2($elm$core$String$contains, '-', formatted) ? '-' : '',
					A2($elm$core$String$contains, '(', formatted) ? '(' : '',
					A3(
					$elm$core$String$slice,
					1,
					$elm$core$String$length(formatted),
					formatted)
				])) : A2(
			$elm$core$String$join,
			'',
			_List_fromArray(
				[
					A2($elm$core$String$contains, '-', formatted) ? '-' : '',
					A2($elm$core$String$contains, '(', formatted) ? '(' : '',
					currencySymbol,
					space,
					A3(
					$elm$core$String$slice,
					1,
					$elm$core$String$length(formatted),
					formatted)
				]))) : _Utils_ap(
			currencySymbol,
			_Utils_ap(space, formatted))) : (A2($elm$core$String$contains, ')', formatted) ? A2(
			$elm$core$String$join,
			'',
			_List_fromArray(
				[
					A3(
					$elm$core$String$slice,
					0,
					$elm$core$String$length(formatted) - 1,
					formatted),
					space,
					currencySymbol,
					')'
				])) : _Utils_ap(
			formatted,
			_Utils_ap(space, currencySymbol)));
	});
var $ggb$numeral_elm$Numeral$formatWithoutPercent = function (lformat) {
	return A2($elm$core$String$contains, ' %', lformat) ? _Utils_Tuple2(
		' ',
		A2($ggb$numeral_elm$Numeral$emptyReplace, ' %', lformat)) : _Utils_Tuple2(
		'',
		A2($ggb$numeral_elm$Numeral$emptyReplace, '%', lformat));
};
var $ggb$numeral_elm$Numeral$formatPercentage = F4(
	function (lang, lformat, value, strValue) {
		var value1 = value * 100;
		var _v0 = $ggb$numeral_elm$Numeral$formatWithoutPercent(lformat);
		var space = _v0.a;
		var format1 = _v0.b;
		var formatted = $ggb$numeral_elm$Numeral$formatNumber(
			A3($ggb$numeral_elm$Numeral$empty, lang, format1, value1));
		return A2($elm$core$String$contains, ')', formatted) ? A2(
			$elm$core$String$join,
			'',
			_List_fromArray(
				[
					A3(
					$elm$core$String$slice,
					0,
					$elm$core$String$length(formatted) - 1,
					formatted),
					space,
					'%',
					')'
				])) : (formatted + (space + '%'));
	});
var $ggb$numeral_elm$Numeral$formatTime = F4(
	function (lang, lformat, value, strValue) {
		var hours = $elm$core$Basics$floor((value / 60) / 60);
		var minutes = $elm$core$Basics$floor((value - ((hours * 60) * 60)) / 60);
		var seconds = $elm$core$Basics$round((value - ((hours * 60) * 60)) - (minutes * 60));
		var hasOneDigit = function (val) {
			return ($elm$core$String$length(val) < 2) ? ('0' + val) : val;
		};
		return A2(
			$elm$core$String$join,
			':',
			_List_fromArray(
				[
					$elm$core$String$fromFloat(hours),
					hasOneDigit(
					$elm$core$String$fromFloat(minutes)),
					hasOneDigit(
					$elm$core$String$fromInt(seconds))
				]));
	});
var $ggb$numeral_elm$Numeral$formatWithLanguage = F3(
	function (lang, lformat, value) {
		return A2($elm$core$String$contains, '$', lformat) ? A4(
			$ggb$numeral_elm$Numeral$formatCurrency,
			lang,
			lformat,
			value,
			$elm$core$String$fromFloat(value)) : (A2($elm$core$String$contains, '%', lformat) ? A4(
			$ggb$numeral_elm$Numeral$formatPercentage,
			lang,
			lformat,
			value,
			$elm$core$String$fromFloat(value)) : (A2($elm$core$String$contains, ':', lformat) ? A4(
			$ggb$numeral_elm$Numeral$formatTime,
			lang,
			lformat,
			value,
			$elm$core$String$fromFloat(value)) : $ggb$numeral_elm$Numeral$formatNumber(
			A3($ggb$numeral_elm$Numeral$empty, lang, lformat, value))));
	});
var $ggb$numeral_elm$Languages$English$englishOrdinal = function (number) {
	var number1 = $elm$core$Basics$floor(number);
	var b = A2($elm$core$Basics$modBy, 10, number1);
	return ($elm$core$Basics$floor(
		A2($elm$core$Basics$modBy, 100, number1) / 10) === 1) ? 'th' : ((b === 1) ? 'st' : ((b === 2) ? 'nd' : ((b === 3) ? 'rd' : 'th')));
};
var $ggb$numeral_elm$Languages$English$lang = {
	hd: {hk: 'b', h8: 'm', iP: 'k', iW: 't'},
	hv: {iL: '$'},
	hy: {hx: '.', iQ: ','},
	iu: $ggb$numeral_elm$Languages$English$englishOrdinal
};
var $ggb$numeral_elm$Numeral$format = $ggb$numeral_elm$Numeral$formatWithLanguage($ggb$numeral_elm$Languages$English$lang);
var $author$project$BigO$TimeUtils$monthToString = function (m) {
	switch (m) {
		case 0:
			return 'Jan';
		case 1:
			return 'Feb';
		case 2:
			return 'Mar';
		case 3:
			return 'Apr';
		case 4:
			return 'May';
		case 5:
			return 'Jun';
		case 6:
			return 'Jul';
		case 7:
			return 'Aug';
		case 8:
			return 'Sep';
		case 9:
			return 'Oct';
		case 10:
			return 'Nov';
		default:
			return 'Dec';
	}
};
var $elm$time$Time$Fri = 4;
var $elm$time$Time$Mon = 0;
var $elm$time$Time$Sat = 5;
var $elm$time$Time$Sun = 6;
var $elm$time$Time$Thu = 3;
var $elm$time$Time$Tue = 1;
var $elm$time$Time$Wed = 2;
var $elm$time$Time$toWeekday = F2(
	function (zone, time) {
		var _v0 = A2(
			$elm$core$Basics$modBy,
			7,
			A2(
				$elm$time$Time$flooredDiv,
				A2($elm$time$Time$toAdjustedMinutes, zone, time),
				60 * 24));
		switch (_v0) {
			case 0:
				return 3;
			case 1:
				return 4;
			case 2:
				return 5;
			case 3:
				return 6;
			case 4:
				return 0;
			case 5:
				return 1;
			default:
				return 2;
		}
	});
var $author$project$BigO$TimeUtils$toWeekdayStr = function (wkday) {
	switch (wkday) {
		case 0:
			return 'Mon';
		case 1:
			return 'Tue';
		case 2:
			return 'Wed';
		case 3:
			return 'Thu';
		case 4:
			return 'Fri';
		case 5:
			return 'Sat';
		default:
			return 'Sun';
	}
};
var $author$project$BigO$TimeUtils$toUtcStringDateTime = function (time) {
	return A2(
		$ggb$numeral_elm$Numeral$format,
		'00',
		A2($elm$time$Time$toHour, $elm$time$Time$utc, time)) + (':' + (A2(
		$ggb$numeral_elm$Numeral$format,
		'00',
		A2($elm$time$Time$toMinute, $elm$time$Time$utc, time)) + (':' + (A2(
		$ggb$numeral_elm$Numeral$format,
		'00',
		A2($elm$time$Time$toSecond, $elm$time$Time$utc, time)) + (' ' + (' (' + ($author$project$BigO$TimeUtils$toWeekdayStr(
		A2($elm$time$Time$toWeekday, $elm$time$Time$utc, time)) + (') ' + (A2(
		$ggb$numeral_elm$Numeral$format,
		'00',
		A2($elm$time$Time$toDay, $elm$time$Time$utc, time)) + (' ' + ($author$project$BigO$TimeUtils$monthToString(
		A2($elm$time$Time$toMonth, $elm$time$Time$utc, time)) + (' ' + $elm$core$String$fromInt(
		A2($elm$time$Time$toYear, $elm$time$Time$utc, time))))))))))))));
};
var $author$project$Pages$PageAdminServerProcessingStatus$serverProcessingStatusBlocks = function (status) {
	return _List_fromArray(
		[
			_Utils_Tuple2(
			'Elm-Cloud',
			_List_fromArray(
				[
					_Utils_Tuple2('Build time', $author$project$GeneratedBuildConstants$getGitShaStr),
					_Utils_Tuple2('Build Git SHA', $author$project$GeneratedBuildConstants$getBuildTimeStr)
				])),
			_Utils_Tuple2(
			'General',
			_List_fromArray(
				[
					_Utils_Tuple2(
					'Last Server Timestamp',
					$author$project$BigO$TimeUtils$toUtcStringDateTime(status.gg))
				])),
			_Utils_Tuple2(
			'Server-Load',
			_List_fromArray(
				[
					_Utils_Tuple2(
					'Load last 1 minute',
					A2(
						$elm$core$Maybe$withDefault,
						'<unknown>',
						A2($elm$core$Maybe$map, $elm$core$String$fromFloat, status.ew))),
					_Utils_Tuple2(
					'Load last 5 minutes',
					A2(
						$elm$core$Maybe$withDefault,
						'<unknown>',
						A2($elm$core$Maybe$map, $elm$core$String$fromFloat, status.ex))),
					_Utils_Tuple2(
					'Load last 15 minutes',
					A2(
						$elm$core$Maybe$withDefault,
						'<unknown>',
						A2($elm$core$Maybe$map, $elm$core$String$fromFloat, status.ev)))
				])),
			_Utils_Tuple2(
			'Processing',
			_List_fromArray(
				[
					_Utils_Tuple2(
					'Pending-Tasks',
					$elm$core$String$fromInt(status.gk.g4)),
					_Utils_Tuple2(
					'Completed-Tasks',
					$elm$core$String$fromInt(status.gj))
				])),
			_Utils_Tuple2(
			'Parcel-Up',
			_List_fromArray(
				[
					_Utils_Tuple2(
					'Ready-To-Process',
					$elm$core$String$fromInt(status.du)),
					_Utils_Tuple2(
					'Complete',
					$elm$core$String$fromInt(status.dt))
				])),
			_Utils_Tuple2(
			'Parcel-Up-Recording',
			_List_fromArray(
				[
					_Utils_Tuple2(
					'Ready-to-process',
					$elm$core$String$fromInt(status.dx)),
					_Utils_Tuple2(
					'Complete',
					$elm$core$String$fromInt(status.dv)),
					_Utils_Tuple2(
					'Error',
					$elm$core$String$fromInt(status.dw))
				])),
			_Utils_Tuple2(
			'N96-Status',
			_List_fromArray(
				[
					_Utils_Tuple2(
					'Ready-to-process',
					$elm$core$String$fromInt(status.ds)),
					_Utils_Tuple2(
					'Complete',
					$elm$core$String$fromInt(status.dq)),
					_Utils_Tuple2(
					'Error',
					$elm$core$String$fromInt(status.dr))
				])),
			_Utils_Tuple2(
			'File Conversions (Complete)',
			_List_fromArray(
				[
					_Utils_Tuple2(
					'NotRequested',
					$elm$core$String$fromInt(status.$7)),
					_Utils_Tuple2(
					'Requested',
					$elm$core$String$fromInt(status.dp)),
					_Utils_Tuple2(
					'InProgress',
					$elm$core$String$fromInt(status.dn)),
					_Utils_Tuple2(
					'Complete',
					$elm$core$String$fromInt(status.dl)),
					_Utils_Tuple2(
					'Error',
					$elm$core$String$fromInt(status.dm))
				])),
			_Utils_Tuple2(
			'File Conversions (Partial)',
			_List_fromArray(
				[
					_Utils_Tuple2(
					'Pending',
					$elm$core$String$fromInt(status.dB)),
					_Utils_Tuple2(
					'InProgress',
					$elm$core$String$fromInt(status.dA)),
					_Utils_Tuple2(
					'Complete',
					$elm$core$String$fromInt(status.dy)),
					_Utils_Tuple2(
					'Error',
					$elm$core$String$fromInt(status.dz))
				]))
		]);
};
var $author$project$Pages$PageAdminServerProcessingStatus$pageAdminServerStatus = function (mdl) {
	var _v0 = mdl.fm.gd;
	if (_v0.$ === 1) {
		return _List_fromArray(
			[
				$elm$html$Html$text('No Server data')
			]);
	} else {
		var ps = _v0.a;
		return A2(
			$elm$core$List$map,
			$author$project$Pages$PageAdminServerProcessingStatus$buildBlock,
			$author$project$Pages$PageAdminServerProcessingStatus$serverProcessingStatusBlocks(ps));
	}
};
var $author$project$Msg$ServerBlacklistN80 = function (a) {
	return {$: 9, a: a};
};
var $author$project$Msg$ServerUnblacklistN80 = function (a) {
	return {$: 10, a: a};
};
var $elm$core$String$dropRight = F2(
	function (n, string) {
		return (n < 1) ? string : A3($elm$core$String$slice, 0, -n, string);
	});
var $author$project$ElmCommon$HtmlCommon$addCommaToLargeNumberString = function (s) {
	var right = A2($elm$core$String$right, 3, s);
	var left = A2($elm$core$String$dropRight, 3, s);
	return (left === '') ? right : ($author$project$ElmCommon$HtmlCommon$addCommaToLargeNumberString(left) + (',' + right));
};
var $rundis$elm_bootstrap$Bootstrap$Badge$badgeInfo = $rundis$elm_bootstrap$Bootstrap$Badge$badgeInternal(
	_List_fromArray(
		[
			$rundis$elm_bootstrap$Bootstrap$Badge$Roled(3)
		]));
var $author$project$BigO$TypesAsStrings$getSampleValue = function (x) {
	if (x.$ === 1) {
		return '<No Value>';
	} else {
		var x_ = x.a;
		return A2($ggb$numeral_elm$Numeral$format, '0.0', x_.gN);
	}
};
var $author$project$Pages$PageAllN80s$buildCloudN96SensorMetadataTxt = function (n96SensorMetadata) {
	return $author$project$BigO$TypesAsStrings$getSampleValue(n96SensorMetadata.f7) + (' ' + n96SensorMetadata.gb);
};
var $author$project$TypesPage$PageDetailsN96 = function (a) {
	return {$: 3, a: a};
};
var $author$project$BigO$TypesAsStrings$showU16 = function (_v0) {
	var u16 = _v0;
	return $elm$core$String$fromInt(u16);
};
var $author$project$BigO$TypesAsStrings$fromHexStrN80 = function (n80HexStr) {
	return A2(
		$elm$core$Maybe$map,
		$elm$core$Basics$identity,
		$cmditch$elm_bigint$BigInt$fromHexString(n80HexStr));
};
var $author$project$BigO$TypesAsStrings$resultToMaybe = function (x) {
	if (!x.$) {
		var e = x.a;
		return $elm$core$Maybe$Just(e);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$BigO$TypesAsStrings$toHexStrN96 = function (_v0) {
	var n80 = _v0;
	return A3(
		$elm$core$String$padLeft,
		24,
		'0',
		$cmditch$elm_bigint$BigInt$toHexString(n80));
};
var $author$project$BigO$TypesAsStrings$splitN96 = function (n96) {
	var hN96 = $author$project$BigO$TypesAsStrings$toHexStrN96(n96);
	var hU16 = A2($elm$core$String$right, 4, hN96);
	var u16 = A2(
		$elm$core$Maybe$withDefault,
		65535,
		$author$project$BigO$TypesAsStrings$resultToMaybe(
			$rtfeldman$elm_hex$Hex$fromString(hU16)));
	var hN80 = A2($elm$core$String$dropRight, 4, hN96);
	var n80_ = A2(
		$elm$core$Maybe$withDefault,
		$cmditch$elm_bigint$BigInt$fromInt(0),
		$author$project$BigO$TypesAsStrings$fromHexStrN80(hN80));
	return _Utils_Tuple2(n80_, u16);
};
var $author$project$Components$CommonPageComponents$buildN96PageLink = F2(
	function (n96, meta) {
		var n96Meta = meta;
		var _v0 = $author$project$BigO$TypesAsStrings$splitN96(n96);
		var n80 = _v0.a;
		var u16 = _v0.b;
		var txt = $author$project$BigO$TypesAsStrings$showU16(u16) + (' ' + ('[' + (A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.gL;
				},
				n96Meta.e4)) + ']')));
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Button$button,
			_List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Button$primary,
					$rundis$elm_bootstrap$Bootstrap$Button$onClick(
					$author$project$Msg$RunGlobalAction(
						$author$project$TypesPage$PageDetailsN96(n96)))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(txt)
				]));
	});
var $rundis$elm_bootstrap$Bootstrap$Badge$Secondary = 1;
var $rundis$elm_bootstrap$Bootstrap$Badge$pillSecondary = $rundis$elm_bootstrap$Bootstrap$Badge$badgeInternal(
	_List_fromArray(
		[
			$rundis$elm_bootstrap$Bootstrap$Badge$Roled(1),
			$rundis$elm_bootstrap$Bootstrap$Badge$Pill
		]));
var $author$project$Components$CommonPageComponents$build_tblcell_typeM = F2(
	function (n80, n80MetaLiveM) {
		if (n80MetaLiveM.$ === 1) {
			return $elm$html$Html$text('FIX ME ');
		} else {
			var n80Meta = n80MetaLiveM.a;
			var instr_typ_txt = $elm$html$Html$text(
				A2(
					$elm$core$Maybe$withDefault,
					'',
					A2(
						$elm$core$Maybe$andThen,
						function ($) {
							return $.eN;
						},
						n80Meta.eI.eJ)));
			return A2(
				$rundis$elm_bootstrap$Bootstrap$Badge$pillSecondary,
				_List_Nil,
				_List_fromArray(
					[instr_typ_txt]));
		}
	});
var $elm$html$Html$li = _VirtualDom_node('li');
var $elm$html$Html$ul = _VirtualDom_node('ul');
var $author$project$Components$CommonPageComponents$build_tblcell_device_and_type = function (n80Meta) {
	var res = A2(
		$elm$html$Html$ul,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$li,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$Components$CommonPageComponents$build_tblcell_device(n80Meta)
					])),
				A2(
				$elm$html$Html$li,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$author$project$Components$CommonPageComponents$build_tblcell_typeM,
						n80Meta.eC,
						$elm$core$Maybe$Just(n80Meta))
					]))
			]));
	return res;
};
var $author$project$Pages$PageCommon$isN96DescUser = function (descUserM) {
	if (descUserM.$ === 1) {
		return $elm$core$Maybe$Nothing;
	} else {
		var descUser = descUserM.a;
		return (descUser.gL === '') ? $elm$core$Maybe$Nothing : descUserM;
	}
};
var $rundis$elm_bootstrap$Bootstrap$Internal$Button$Light = 7;
var $rundis$elm_bootstrap$Bootstrap$Button$light = $rundis$elm_bootstrap$Bootstrap$Internal$Button$Coloring(
	$rundis$elm_bootstrap$Bootstrap$Internal$Button$Roled(7));
var $author$project$BigO$TypesAsStrings$showN96DescUser = function (n96DescUser) {
	return n96DescUser.gL;
};
var $author$project$Components$CommonPageComponents$build_tblcell_n96_link = function (cloudMetadataN96Short) {
	var txt = function () {
		var _v0 = $author$project$Pages$PageCommon$isN96DescUser(cloudMetadataN96Short.e4);
		if (_v0.$ === 1) {
			return '#' + $author$project$BigO$TypesAsStrings$showU16(cloudMetadataN96Short.e7);
		} else {
			var descUser = _v0.a;
			return $author$project$BigO$TypesAsStrings$showN96DescUser(descUser);
		}
	}();
	var btn = A2(
		$rundis$elm_bootstrap$Bootstrap$Button$button,
		_List_fromArray(
			[
				$rundis$elm_bootstrap$Bootstrap$Button$light,
				$rundis$elm_bootstrap$Bootstrap$Button$small,
				$rundis$elm_bootstrap$Bootstrap$Button$onClick(
				$author$project$Msg$RunGlobalAction(
					$author$project$TypesPage$PageDetailsN96(cloudMetadataN96Short.e2)))
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(txt)
			]));
	return btn;
};
var $rundis$elm_bootstrap$Bootstrap$Badge$Danger = 5;
var $rundis$elm_bootstrap$Bootstrap$Badge$pillDanger = $rundis$elm_bootstrap$Bootstrap$Badge$badgeInternal(
	_List_fromArray(
		[
			$rundis$elm_bootstrap$Bootstrap$Badge$Roled(5),
			$rundis$elm_bootstrap$Bootstrap$Badge$Pill
		]));
var $rundis$elm_bootstrap$Bootstrap$Badge$Success = 2;
var $rundis$elm_bootstrap$Bootstrap$Badge$pillSuccess = $rundis$elm_bootstrap$Bootstrap$Badge$badgeInternal(
	_List_fromArray(
		[
			$rundis$elm_bootstrap$Bootstrap$Badge$Roled(2),
			$rundis$elm_bootstrap$Bootstrap$Badge$Pill
		]));
var $author$project$Components$CommonPageComponents$build_n96_status_pill = function (status) {
	switch (status) {
		case 0:
			return A2(
				$rundis$elm_bootstrap$Bootstrap$Badge$pillInfo,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Running')
					]));
		case 1:
			return A2(
				$rundis$elm_bootstrap$Bootstrap$Badge$pillSuccess,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Complete')
					]));
		default:
			return A2(
				$rundis$elm_bootstrap$Bootstrap$Badge$pillDanger,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Error')
					]));
	}
};
var $author$project$Components$CommonPageComponents$N96RecordingStatusComplete = 1;
var $author$project$Components$CommonPageComponents$N96RecordingStatusError = 2;
var $author$project$Components$CommonPageComponents$N96RecordingStatusRunning = 0;
var $author$project$Components$CommonPageComponents$cloudMetadataN96StatusDetailsToStatus = function (details) {
	switch (details.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		default:
			return 2;
	}
};
var $author$project$Components$CommonPageComponents$build_tblcell_n96_status = function (cloudMetadataN96Short) {
	return $author$project$Components$CommonPageComponents$build_n96_status_pill(
		$author$project$Components$CommonPageComponents$cloudMetadataN96StatusDetailsToStatus(cloudMetadataN96Short.e9));
};
var $author$project$BigO$Common$emptyRecordingStatus = {ct: '', cu: '', cv: '', cw: '', cx: ''};
var $author$project$BigO$TypesAsStrings$durationStrHuman = F2(
	function (start, end) {
		var t1 = $elm$time$Time$posixToMillis(end);
		var t0 = $elm$time$Time$posixToMillis(start);
		var diff_millis = t1 - t0;
		var diff_secs = (diff_millis / 1000) | 0;
		var mod_days = A2($elm$core$Basics$modBy, 100000000, (diff_secs / ((60 * 60) * 24)) | 0);
		var mod_hours = A2($elm$core$Basics$modBy, 24, (diff_secs / (60 * 60)) | 0);
		var mod_mins = A2($elm$core$Basics$modBy, 60, (diff_secs / 60) | 0);
		var mod_seconds = A2($elm$core$Basics$modBy, 60, diff_secs);
		var n_mins = (diff_secs / 60) | 0;
		var n_hours = (n_mins / 60) | 0;
		var n_days = (n_hours / 24) | 0;
		var _v0 = n_mins <= 0;
		if (_v0) {
			return $elm$core$String$fromInt(mod_seconds) + ' s';
		} else {
			var _v1 = n_hours <= 0;
			if (_v1) {
				return $elm$core$String$fromInt(n_mins) + ' mins';
			} else {
				var _v2 = n_days <= 0;
				if (_v2) {
					return $elm$core$String$fromInt(mod_hours) + (' hours' + (' ' + ($elm$core$String$fromInt(mod_mins) + ' mins')));
				} else {
					return $elm$core$String$fromInt(mod_days) + (' days' + (' ' + ($elm$core$String$fromInt(mod_hours) + (' hours' + (' ' + ($elm$core$String$fromInt(mod_mins) + ' mins'))))));
				}
			}
		}
	});
var $elm$core$Maybe$map2 = F3(
	function (func, ma, mb) {
		if (ma.$ === 1) {
			return $elm$core$Maybe$Nothing;
		} else {
			var a = ma.a;
			if (mb.$ === 1) {
				return $elm$core$Maybe$Nothing;
			} else {
				var b = mb.a;
				return $elm$core$Maybe$Just(
					A2(func, a, b));
			}
		}
	});
var $author$project$BigO$TypesAsStrings$durationStrHumanMaybe = F2(
	function (start, end) {
		return A2(
			$elm$core$Maybe$withDefault,
			'',
			A3($elm$core$Maybe$map2, $author$project$BigO$TypesAsStrings$durationStrHuman, start, end));
	});
var $author$project$BigO$TimeUtils$isSameDayUtcMaybe = F2(
	function (t0M, t1M) {
		var _v0 = _Utils_Tuple2(t0M, t1M);
		if ((!_v0.a.$) && (!_v0.b.$)) {
			var t0 = _v0.a.a;
			var t1 = _v0.b.a;
			return _Utils_eq(
				A2($elm$time$Time$toDay, $elm$time$Time$utc, t0),
				A2($elm$time$Time$toDay, $elm$time$Time$utc, t1)) && (_Utils_eq(
				A2($elm$time$Time$toMonth, $elm$time$Time$utc, t0),
				A2($elm$time$Time$toMonth, $elm$time$Time$utc, t1)) && _Utils_eq(
				A2($elm$time$Time$toYear, $elm$time$Time$utc, t0),
				A2($elm$time$Time$toYear, $elm$time$Time$utc, t1)));
		} else {
			return false;
		}
	});
var $author$project$BigO$TimeUtils$toUtcStringDate = function (time) {
	return $author$project$BigO$TimeUtils$toWeekdayStr(
		A2($elm$time$Time$toWeekday, $elm$time$Time$utc, time)) + ('  ' + (A2(
		$ggb$numeral_elm$Numeral$format,
		'00',
		A2($elm$time$Time$toDay, $elm$time$Time$utc, time)) + (' ' + ($author$project$BigO$TimeUtils$monthToString(
		A2($elm$time$Time$toMonth, $elm$time$Time$utc, time)) + (' ' + $elm$core$String$fromInt(
		A2($elm$time$Time$toYear, $elm$time$Time$utc, time)))))));
};
var $author$project$BigO$TimeUtils$toUtcStringDateMaybe = F2(
	function (t, def) {
		return A2(
			$elm$core$Maybe$withDefault,
			def,
			A2($elm$core$Maybe$map, $author$project$BigO$TimeUtils$toUtcStringDate, t));
	});
var $author$project$BigO$TimeUtils$toUtcStringDateTimeMaybe = F2(
	function (def, t) {
		return A2(
			$elm$core$Maybe$withDefault,
			def,
			A2($elm$core$Maybe$map, $author$project$BigO$TimeUtils$toUtcStringDateTime, t));
	});
var $author$project$BigO$TimeUtils$toUtcStringTime = F2(
	function (timeFormatIncludeSeconds, time) {
		var secStr = function () {
			if (!timeFormatIncludeSeconds) {
				return ':' + A2(
					$ggb$numeral_elm$Numeral$format,
					'00',
					A2($elm$time$Time$toSecond, $elm$time$Time$utc, time));
			} else {
				return '';
			}
		}();
		var minStr = ':' + A2(
			$ggb$numeral_elm$Numeral$format,
			'00',
			A2($elm$time$Time$toMinute, $elm$time$Time$utc, time));
		var hrStr = A2(
			$ggb$numeral_elm$Numeral$format,
			'00',
			A2($elm$time$Time$toHour, $elm$time$Time$utc, time));
		return _Utils_ap(
			hrStr,
			_Utils_ap(minStr, secStr));
	});
var $author$project$BigO$TimeUtils$toUtcStringTimeMaybe = F3(
	function (timeFormatIncludeSeconds, t, def) {
		return A2(
			$elm$core$Maybe$withDefault,
			def,
			A2(
				$elm$core$Maybe$map,
				$author$project$BigO$TimeUtils$toUtcStringTime(timeFormatIncludeSeconds),
				t));
	});
var $author$project$BigO$Common$buildN96RecordingStatus = F6(
	function (options, status, summary, t0, tN, def) {
		var x = 3;
		return {
			ct: A2($author$project$BigO$TypesAsStrings$durationStrHumanMaybe, t0, tN),
			cu: A2($author$project$BigO$TimeUtils$isSameDayUtcMaybe, t0, tN) ? A3($author$project$BigO$TimeUtils$toUtcStringTimeMaybe, options.gl, tN, def) : A2($author$project$BigO$TimeUtils$toUtcStringDateTimeMaybe, def, tN),
			cv: A2($author$project$BigO$TimeUtils$toUtcStringDateMaybe, t0, def),
			cw: A3($author$project$BigO$TimeUtils$toUtcStringTimeMaybe, options.gl, t0, def),
			cx: summary
		};
	});
var $author$project$BigO$TypesAsStrings$bigIntKB = 1024;
var $author$project$BigO$TypesAsStrings$bigIntMB = $author$project$BigO$TypesAsStrings$bigIntKB * $author$project$BigO$TypesAsStrings$bigIntKB;
var $author$project$BigO$TypesAsStrings$showByteCount = function (b_) {
	var b = b_.gz;
	return (_Utils_cmp(b, $author$project$BigO$TypesAsStrings$bigIntMB) > 0) ? ($elm$core$String$fromInt((b / $author$project$BigO$TypesAsStrings$bigIntMB) | 0) + 'MB') : ((_Utils_cmp(b, $author$project$BigO$TypesAsStrings$bigIntKB) > 0) ? ($elm$core$String$fromInt((b / $author$project$BigO$TypesAsStrings$bigIntKB) | 0) + 'KB') : $elm$core$String$fromInt(b));
};
var $author$project$BigO$TypesAsStrings$showByteCountN96 = function (n96Bytes) {
	return $author$project$BigO$TypesAsStrings$showByteCount(n96Bytes.g$);
};
var $author$project$BigO$Common$getN96UploadSummaryStringClosed = function (closed) {
	var nbytes = $author$project$BigO$TypesAsStrings$showByteCountN96(closed.fb);
	var c32 = A3(
		$elm$core$Basics$composeR,
		function ($) {
			return $.gy;
		},
		A2(
			$elm$core$Basics$composeR,
			function ($) {
				return $.gx;
			},
			$elm$core$String$fromInt),
		closed.fd);
	return c32 + ('/' + nbytes);
};
var $author$project$BigO$Common$getN96UploadSummaryStringError = function (err) {
	var nbytes = $author$project$BigO$TypesAsStrings$showByteCountN96(err.fe);
	var c32 = A3(
		$elm$core$Basics$composeR,
		function ($) {
			return $.gy;
		},
		A2(
			$elm$core$Basics$composeR,
			function ($) {
				return $.gx;
			},
			$elm$core$String$fromInt),
		err.ff);
	return c32 + ('/' + nbytes);
};
var $author$project$BigO$Common$getN96UploadSummaryStringOpen = function (open) {
	var nbytes = $author$project$BigO$TypesAsStrings$showByteCountN96(open.fk);
	var c32 = A3(
		$elm$core$Basics$composeR,
		function ($) {
			return $.gy;
		},
		A2(
			$elm$core$Basics$composeR,
			function ($) {
				return $.gx;
			},
			$elm$core$String$fromInt),
		open.fj);
	return c32 + ('/' + nbytes);
};
var $author$project$BigO$TypesAsStrings$showN96DbErrorStatus = function (st) {
	return 'ERROR: ADD ME';
};
var $author$project$BigO$Common$getN96TableInfo = F2(
	function (options, details) {
		switch (details.$) {
			case 0:
				var open = details.a;
				return A6(
					$author$project$BigO$Common$buildN96RecordingStatus,
					options,
					'Running',
					$author$project$BigO$Common$getN96UploadSummaryStringOpen(open),
					open.fh,
					open.fg,
					'?');
			case 1:
				var closed = details.a;
				return A6(
					$author$project$BigO$Common$buildN96RecordingStatus,
					options,
					'Complete',
					$author$project$BigO$Common$getN96UploadSummaryStringClosed(closed),
					closed.fc,
					closed.fa,
					'UNEXPECTED');
			default:
				var err = details.a;
				return A6(
					$author$project$BigO$Common$buildN96RecordingStatus,
					options,
					'Error' + $author$project$BigO$TypesAsStrings$showN96DbErrorStatus(err.d1),
					$author$project$BigO$Common$getN96UploadSummaryStringError(err),
					$elm$core$Maybe$Nothing,
					$elm$core$Maybe$Nothing,
					'');
		}
	});
var $author$project$Pages$PageAllN80s$isBlackListed = F2(
	function (mdl, n80) {
		var all_blacklisted = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.c6;
				},
				mdl.fm.gd));
		var is_blacklisted = A2(
			$turboMaCk$any_dict$Dict$Any$get,
			n80,
			A2($turboMaCk$any_dict$Dict$Any$fromList, $author$project$BigO$TypesAsStrings$showN80, all_blacklisted));
		return is_blacklisted;
	});
var $lattyware$elm_fontawesome$FontAwesome$Solid$Definitions$lock = A4(
	$lattyware$elm_fontawesome$FontAwesome$IconDef,
	'fas',
	'lock',
	_Utils_Tuple2(448, 512),
	_Utils_Tuple2('M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z', $elm$core$Maybe$Nothing));
var $lattyware$elm_fontawesome$FontAwesome$Solid$lock = $lattyware$elm_fontawesome$FontAwesome$present($lattyware$elm_fontawesome$FontAwesome$Solid$Definitions$lock);
var $author$project$BigO$TypesAsStrings$showByteCountN80 = function (n80Bytes) {
	return $author$project$BigO$TypesAsStrings$showByteCount(n80Bytes.gZ);
};
var $author$project$BigO$TypesAsStrings$showN80ParcelUpCount = function (cnt) {
	return $elm$core$String$fromInt(cnt.g_);
};
var $author$project$BigO$TimeUtils$toHumanTimeAgo = F2(
	function (tNow, t) {
		var millis = $elm$time$Time$posixToMillis(tNow) - $elm$time$Time$posixToMillis(t);
		var seconds = (millis / 1000) | 0;
		var minutes = (seconds / 60) | 0;
		var hours = (minutes / 60) | 0;
		var days = (hours / 24) | 0;
		var result = (seconds < 60) ? ($elm$core$String$fromInt(seconds) + ' secs ago') : ((minutes < 60) ? ($elm$core$String$fromInt(minutes) + ' mins ago') : ((hours < 24) ? ($elm$core$String$fromInt(hours) + ' hours ago') : ($elm$core$String$fromInt(days) + ' days ago')));
		return result;
	});
var $author$project$BigO$TimeUtils$toHumanTimeAgoMaybe = F2(
	function (tNow, t) {
		if (t.$ === 1) {
			return '';
		} else {
			var t_ = t.a;
			return A2($author$project$BigO$TimeUtils$toHumanTimeAgo, tNow, t_);
		}
	});
var $lattyware$elm_fontawesome$FontAwesome$Solid$Definitions$unlock = A4(
	$lattyware$elm_fontawesome$FontAwesome$IconDef,
	'fas',
	'unlock',
	_Utils_Tuple2(448, 512),
	_Utils_Tuple2('M144 144c0-44.2 35.8-80 80-80c31.9 0 59.4 18.6 72.3 45.7c7.6 16 26.7 22.8 42.6 15.2s22.8-26.7 15.2-42.6C331 33.7 281.5 0 224 0C144.5 0 80 64.5 80 144v48H64c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V256c0-35.3-28.7-64-64-64H144V144z', $elm$core$Maybe$Nothing));
var $lattyware$elm_fontawesome$FontAwesome$Solid$unlock = $lattyware$elm_fontawesome$FontAwesome$present($lattyware$elm_fontawesome$FontAwesome$Solid$Definitions$unlock);
var $author$project$Pages$PageAllN80s$buildN80Cell_ = F3(
	function (mdl, _v0, col) {
		var n80Meta = _v0.a;
		var accessLevel = _v0.b;
		var vLook = function () {
			var _v4 = n80Meta.eQ;
			if (_v4.$ === 1) {
				return {
					cs: _List_fromArray(
						[
							$elm$html$Html$text('')
						]),
					e_: $elm$html$Html$text('')
				};
			} else {
				var n96AMeta = _v4.a;
				var s104Metas = n96AMeta.e8;
				var n96Link_ = A2($author$project$Components$CommonPageComponents$buildN96PageLink, n96AMeta.e2, n96AMeta);
				var last_val_badges_ = A2(
					$elm$core$List$map,
					function (s) {
						return A2(
							$rundis$elm_bootstrap$Bootstrap$Badge$badgeInfo,
							_List_Nil,
							$elm$core$List$singleton(
								$elm$html$Html$text(
									$author$project$Pages$PageAllN80s$buildCloudN96SensorMetadataTxt(s))));
					},
					s104Metas);
				var last_vals_ = _List_fromArray(
					[
						A2($elm$html$Html$div, _List_Nil, last_val_badges_)
					]);
				return {cs: last_vals_, e_: n96Link_};
			}
		}();
		var user_access_pill = $author$project$Components$CommonPageComponents$buildAccessLevel(accessLevel.gt);
		var n96_tbl_info = function () {
			var _v3 = n80Meta.eQ;
			if (_v3.$ === 1) {
				return $author$project$BigO$Common$emptyRecordingStatus;
			} else {
				var n96Meta = _v3.a;
				return A2($author$project$BigO$Common$getN96TableInfo, mdl.it, n96Meta.e9);
			}
		}();
		var n80_nparcels_str = $author$project$ElmCommon$HtmlCommon$addCommaToLargeNumberString(
			$author$project$BigO$TypesAsStrings$showN80ParcelUpCount(n80Meta.eX));
		var n80_nbytes_str = $author$project$BigO$TypesAsStrings$showByteCountN80(n80Meta.eD);
		var n80_stats = n80_nbytes_str + ('/' + n80_nparcels_str);
		var n80 = n80Meta.eC;
		var unblackListBtn = A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Events$onClick(
					$author$project$Msg$ServerUnblacklistN80(n80))
				]),
			_List_fromArray(
				[
					$lattyware$elm_fontawesome$FontAwesome$view(
					A2(
						$lattyware$elm_fontawesome$FontAwesome$styled,
						_List_fromArray(
							[$lattyware$elm_fontawesome$FontAwesome$Attributes$sm, $lattyware$elm_fontawesome$FontAwesome$Attributes$fw]),
						$lattyware$elm_fontawesome$FontAwesome$Solid$lock))
				]));
		var last_connection_time = A2($author$project$BigO$TimeUtils$toHumanTimeAgoMaybe, mdl.gm, n80Meta.eR);
		var is_blacklisted = A2($author$project$Pages$PageAllN80s$isBlackListed, mdl, n80);
		var blackListBtn = A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Events$onClick(
					$author$project$Msg$ServerBlacklistN80(n80))
				]),
			_List_fromArray(
				[
					$lattyware$elm_fontawesome$FontAwesome$view(
					A2(
						$lattyware$elm_fontawesome$FontAwesome$styled,
						_List_fromArray(
							[$lattyware$elm_fontawesome$FontAwesome$Attributes$sm, $lattyware$elm_fontawesome$FontAwesome$Attributes$fw]),
						$lattyware$elm_fontawesome$FontAwesome$Solid$unlock))
				]));
		var cell = function () {
			switch (col) {
				case 0:
					if (is_blacklisted.$ === 1) {
						return A2(
							$elm$html$Html$map,
							$author$project$Msg$UiPageUpdate,
							A2(
								$elm$html$Html$div,
								_List_Nil,
								_List_fromArray(
									[blackListBtn])));
					} else {
						var err = is_blacklisted.a;
						return A2(
							$elm$html$Html$map,
							$author$project$Msg$UiPageUpdate,
							A2(
								$elm$html$Html$div,
								_List_Nil,
								_List_fromArray(
									[unblackListBtn])));
					}
				case 1:
					return A2(
						$elm$html$Html$map,
						$author$project$Msg$RunGlobalAction,
						$author$project$Components$CommonPageComponents$build_tblcell_device_and_type(n80Meta));
				case 2:
					return user_access_pill;
				case 3:
					return $elm$html$Html$text(n80Meta.eW.gJ.gR);
				case 4:
					return A2($elm$html$Html$div, _List_Nil, vLook.cs);
				case 5:
					return $elm$html$Html$text(last_connection_time);
				case 6:
					return A2(
						$elm$core$Maybe$withDefault,
						$elm$html$Html$text(''),
						A2($elm$core$Maybe$map, $author$project$Components$CommonPageComponents$build_tblcell_n96_link, n80Meta.eQ));
				case 7:
					return A2(
						$elm$core$Maybe$withDefault,
						$elm$html$Html$text(''),
						A2($elm$core$Maybe$map, $author$project$Components$CommonPageComponents$build_tblcell_n96_status, n80Meta.eQ));
				case 8:
					return $elm$html$Html$text(n96_tbl_info.cv);
				case 9:
					return $elm$html$Html$text(n96_tbl_info.cw);
				case 10:
					return $elm$html$Html$text(n96_tbl_info.cu);
				case 11:
					return $elm$html$Html$text(n96_tbl_info.ct);
				case 12:
					return $elm$html$Html$text(n96_tbl_info.cx);
				default:
					return $elm$html$Html$text(n80_stats);
			}
		}();
		return cell;
	});
var $elm_community$maybe_extra$Maybe$Extra$isJust = function (m) {
	if (m.$ === 1) {
		return false;
	} else {
		return true;
	}
};
var $rundis$elm_bootstrap$Bootstrap$Table$RowAttr = function (a) {
	return {$: 2, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Table$rowAttr = function (attr_) {
	return $rundis$elm_bootstrap$Bootstrap$Table$RowAttr(attr_);
};
var $author$project$Pages$PageAllN80s$buildN80Row_ = F3(
	function (mdl, cols, _v0) {
		var n80Meta = _v0.a;
		var access = _v0.b;
		var rowAttrs = $elm_community$maybe_extra$Maybe$Extra$isJust(
			A2($author$project$Pages$PageAllN80s$isBlackListed, mdl, n80Meta.eC)) ? _List_fromArray(
			[
				$rundis$elm_bootstrap$Bootstrap$Table$rowAttr(
				A2($elm$html$Html$Attributes$style, 'background-color', '#FFECEF'))
			]) : _List_Nil;
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Table$tr,
			rowAttrs,
			A2(
				$elm$core$List$map,
				function (t) {
					return A2(
						$rundis$elm_bootstrap$Bootstrap$Table$td,
						_List_Nil,
						_List_fromArray(
							[t]));
				},
				A2(
					$elm$core$List$map,
					function (col) {
						return A3(
							$author$project$Pages$PageAllN80s$buildN80Cell_,
							mdl,
							_Utils_Tuple2(n80Meta, access),
							col);
					},
					cols)));
	});
var $author$project$Pages$PageAllN80s$buildTblColumnHeader = function (col) {
	switch (col) {
		case 0:
			return $elm$html$Html$text('BL');
		case 2:
			return $elm$html$Html$text('Access');
		case 3:
			return $elm$html$Html$text('Owner');
		case 1:
			return $elm$html$Html$text('Device');
		case 5:
			return $elm$html$Html$text('Last Seen');
		case 4:
			return $elm$html$Html$text('Values');
		case 6:
			return $elm$html$Html$text('Rec');
		case 7:
			return $elm$html$Html$text('');
		case 8:
			return $elm$html$Html$text('Date');
		case 9:
			return $elm$html$Html$text('Start');
		case 10:
			return $elm$html$Html$text('End');
		case 11:
			return $elm$html$Html$text('Duration');
		case 12:
			return $elm$html$Html$text('N96 Uploads');
		default:
			return $elm$html$Html$text('N80 Uploads');
	}
};
var $author$project$Pages$PageAllN80s$buildTblHeader = function (cols) {
	var hdrs = A2(
		$elm$core$List$map,
		function (col) {
			return A2(
				$rundis$elm_bootstrap$Bootstrap$Table$th,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$Pages$PageAllN80s$buildTblColumnHeader(col)
					]));
		},
		cols);
	return A2($rundis$elm_bootstrap$Bootstrap$Table$tr, _List_Nil, hdrs);
};
var $author$project$Pages$PageAllN80s$N80AllTblColumn00Device = 1;
var $author$project$Pages$PageAllN80s$N80AllTblColumn00IsBlackListed = 0;
var $author$project$Pages$PageAllN80s$N80AllTblColumn01Owner = 3;
var $author$project$Pages$PageAllN80s$N80AllTblColumn01UserAccess = 2;
var $author$project$Pages$PageAllN80s$N80AllTblColumn02LastConnectionTime = 5;
var $author$project$Pages$PageAllN80s$N80AllTblColumn03LastRecordingValues = 4;
var $author$project$Pages$PageAllN80s$N80AllTblColumn05RecordingLink = 6;
var $author$project$Pages$PageAllN80s$N80AllTblColumn05RecordingStatus = 7;
var $author$project$Pages$PageAllN80s$N80AllTblColumn06RecordingStatusLatestEndTime = 10;
var $author$project$Pages$PageAllN80s$N80AllTblColumn07RecordingStatusStartDate = 8;
var $author$project$Pages$PageAllN80s$N80AllTblColumn07RecordingStatusStartTime = 9;
var $author$project$Pages$PageAllN80s$N80AllTblColumn08RecordingDuration = 11;
var $author$project$Pages$PageAllN80s$N80AllTblColumn10UploadsN80 = 13;
var $author$project$Pages$PageAllN80s$getTblColumns = function (mdl) {
	var std_cols = _List_fromArray(
		[1, 5, 4, 6, 7, 8, 9, 10, 11, 13]);
	var is_dev = mdl.it.hz;
	var dev_cols = _List_fromArray(
		[3, 2, 0]);
	return _Utils_ap(
		std_cols,
		is_dev ? dev_cols : _List_Nil);
};
var $author$project$Pages$PageAllN80s$isN80Visible = function (access) {
	var _v0 = access.gt;
	switch (_v0) {
		case 0:
			return false;
		case 1:
			return true;
		case 2:
			return true;
		default:
			return true;
	}
};
var $author$project$NodeDataStore$shouldShowN80 = F2(
	function (options, n80Meta) {
		return (!n80Meta.eP.gI) || options.iF;
	});
var $author$project$NodeDataStore$mdlGetN80Metas = F2(
	function (options, nds) {
		return A2(
			$elm$core$List$filter,
			function (meta) {
				return A2($author$project$NodeDataStore$shouldShowN80, options, meta);
			},
			$turboMaCk$any_dict$Dict$Any$values(nds.eZ));
	});
var $author$project$Pages$PageAllN80s$pageAllN80s_ = F2(
	function (mdl, cmn) {
		var n80Metas = A2($author$project$NodeDataStore$mdlGetN80Metas, mdl.it, mdl.fm);
		var n80MetasAndAccess = A2(
			$elm$core$List$map,
			function (n80Meta) {
				return _Utils_Tuple2(
					n80Meta,
					cmn.ij(n80Meta.eC));
			},
			n80Metas);
		var cols = $author$project$Pages$PageAllN80s$getTblColumns(mdl);
		return $elm$core$List$singleton(
			$author$project$ElmCommon$HtmlCommon$dashboardElemDiv_(
				$rundis$elm_bootstrap$Bootstrap$Table$table(
					{
						it: _List_fromArray(
							[$rundis$elm_bootstrap$Bootstrap$Table$striped, $rundis$elm_bootstrap$Bootstrap$Table$hover, $rundis$elm_bootstrap$Bootstrap$Table$small]),
						iN: A2(
							$rundis$elm_bootstrap$Bootstrap$Table$tbody,
							_List_Nil,
							A2(
								$elm$core$List$map,
								A2($author$project$Pages$PageAllN80s$buildN80Row_, mdl, cols),
								A2(
									$elm$core$List$filter,
									function (_v0) {
										var auth = _v0.b;
										return $author$project$Pages$PageAllN80s$isN80Visible(auth);
									},
									n80MetasAndAccess))),
						iO: A2(
							$rundis$elm_bootstrap$Bootstrap$Table$thead,
							_List_Nil,
							_List_fromArray(
								[
									$author$project$Pages$PageAllN80s$buildTblHeader(cols)
								]))
					})));
	});
var $author$project$Pages$PageAllN80s$pageAllN80s = F2(
	function (mdl, cmn) {
		return A2($author$project$Pages$PageAllN80s$pageAllN80s_, mdl, cmn);
	});
var $elm$html$Html$pre = _VirtualDom_node('pre');
var $author$project$Pages$PageApiNotifications$buildNotification = function (_v0) {
	var notification = _v0;
	return A2(
		$elm$html$Html$li,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$pre,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(notification)
					]))
			]));
};
var $author$project$Pages$PageApiNotifications$buildNotifications = function (mdl) {
	var entries = A2($elm$core$List$map, $author$project$Pages$PageApiNotifications$buildNotification, mdl.fm.fn);
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2($elm$html$Html$ul, _List_Nil, entries)
			]));
};
var $author$project$Pages$PageApiNotifications$pageApiNotifications = F2(
	function (mdl, cmn) {
		return $elm$core$List$singleton(
			A2(
				$author$project$ElmCommon$HtmlCommon$dashboardElemTitledDiv_,
				'PageUserApiNotifications',
				_List_fromArray(
					[
						$author$project$Pages$PageApiNotifications$buildNotifications(mdl)
					])));
	});
var $author$project$Msg$UiRevertN80DescUser = {$: 1};
var $author$project$Msg$UiRevertN80GroupsRemove = {$: 7};
var $author$project$Msg$UiRevertN80Owner = {$: 3};
var $author$project$Msg$UiUpdateN80DescUser = function (a) {
	return {$: 0, a: a};
};
var $author$project$Msg$UiUpdateN80MetaGroupsAutocomplete = function (a) {
	return {$: 4, a: a};
};
var $author$project$Msg$UiUpdateN80Owner = function (a) {
	return {$: 2, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$Attrs = function (a) {
	return {$: 10, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$attrs = function (attrs_) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Input$Attrs(attrs_);
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$ColAttrs = function (a) {
	return {$: 6, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Col$attrs = function (attrs_) {
	return $rundis$elm_bootstrap$Bootstrap$Grid$Internal$ColAttrs(attrs_);
};
var $author$project$ElmCommon$Autocomplete$AutoCompleteMsgInputBlur = {$: 3};
var $author$project$ElmCommon$Autocomplete$AutoCompleteMsgInputChange = function (a) {
	return {$: 0, a: a};
};
var $author$project$ElmCommon$Autocomplete$AutoCompleteMsgInputFocus = {$: 2};
var $author$project$ElmCommon$Autocomplete$AutoCompleteMsgOptionSelect = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $rundis$elm_bootstrap$Bootstrap$Internal$ListGroup$Attrs = function (a) {
	return {$: 4, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$ListGroup$attrs = function (attrs_) {
	return $rundis$elm_bootstrap$Bootstrap$Internal$ListGroup$Attrs(attrs_);
};
var $elm$html$Html$b = _VirtualDom_node('b');
var $author$project$ElmCommon$Utils$bold = function (n) {
	return A2(
		$elm$html$Html$b,
		_List_Nil,
		_List_fromArray(
			[n]));
};
var $elm$core$String$indices = _String_indexes;
var $author$project$ElmCommon$Autocomplete$getAutoCompletehighlight = F2(
	function (inp, option) {
		var idx = $elm$core$List$head(
			A2(
				$elm$core$String$indices,
				$elm$core$String$toLower(inp),
				$elm$core$String$toLower(option)));
		var res = function () {
			var _v0 = $elm$core$String$length(inp);
			if (!_v0) {
				return $elm$core$Maybe$Just(
					_Utils_Tuple3(option, '', ''));
			} else {
				if (idx.$ === 1) {
					return $elm$core$Maybe$Nothing;
				} else {
					var idx0 = idx.a;
					var s0 = A3($elm$core$String$slice, 0, idx0, option);
					var idxN = idx0 + $elm$core$String$length(inp);
					var s1 = A3($elm$core$String$slice, idx0, idxN, option);
					var s2 = A3(
						$elm$core$String$slice,
						idxN,
						$elm$core$String$length(option),
						option);
					return $elm$core$Maybe$Just(
						_Utils_Tuple3(s0, s1, s2));
				}
			}
		}();
		return res;
	});
var $rundis$elm_bootstrap$Bootstrap$Internal$ListGroup$Item = $elm$core$Basics$identity;
var $rundis$elm_bootstrap$Bootstrap$ListGroup$li = F2(
	function (options, children) {
		return {c9: children, em: $elm$html$Html$li, it: options};
	});
var $elm$html$Html$Events$onMouseDown = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'mousedown',
		$elm$json$Json$Decode$succeed(msg));
};
var $author$project$ElmCommon$Autocomplete$build_autocomplete_option = F4(
	function (autcomplete, inp, userOnSelectMsgM, option) {
		var onselect_msg = function () {
			if (userOnSelectMsgM.$ === 1) {
				return function (opt) {
					return $elm$core$Maybe$Nothing;
				};
			} else {
				var func = userOnSelectMsgM.a;
				return function (opt) {
					return $elm$core$Maybe$Just(
						func(opt));
				};
			}
		}();
		var res = function () {
			var _v0 = A2(
				$author$project$ElmCommon$Autocomplete$getAutoCompletehighlight,
				inp,
				autcomplete.hn.hw(option));
			if (_v0.$ === 1) {
				return $elm$core$Maybe$Nothing;
			} else {
				var _v1 = _v0.a;
				var t0 = _v1.a;
				var t1 = _v1.b;
				var t2 = _v1.c;
				return $elm$core$Maybe$Just(
					A2(
						$rundis$elm_bootstrap$Bootstrap$ListGroup$li,
						_List_fromArray(
							[
								$rundis$elm_bootstrap$Bootstrap$ListGroup$attrs(
								_List_fromArray(
									[
										$elm$html$Html$Events$onMouseDown(
										A2(
											$author$project$ElmCommon$Autocomplete$AutoCompleteMsgOptionSelect,
											option,
											onselect_msg(option)))
									]))
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(t0),
								$author$project$ElmCommon$Utils$bold(
								$elm$html$Html$text(t1)),
								$elm$html$Html$text(t2)
							])));
			}
		}();
		return res;
	});
var $elm$html$Html$Events$onBlur = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'blur',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$html$Html$Events$onFocus = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'focus',
		$elm$json$Json$Decode$succeed(msg));
};
var $rundis$elm_bootstrap$Bootstrap$Internal$ListGroup$applyModifier = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 0:
				var role = modifier.a;
				return _Utils_update(
					options,
					{
						cK: $elm$core$Maybe$Just(role)
					});
			case 3:
				return _Utils_update(
					options,
					{aJ: true});
			case 2:
				return _Utils_update(
					options,
					{a7: true});
			case 1:
				return _Utils_update(
					options,
					{aK: true});
			default:
				var attrs = modifier.a;
				return _Utils_update(
					options,
					{
						aU: _Utils_ap(options.aU, attrs)
					});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Internal$ListGroup$defaultOptions = {aJ: false, aK: false, aU: _List_Nil, a7: false, cK: $elm$core$Maybe$Nothing};
var $rundis$elm_bootstrap$Bootstrap$Internal$ListGroup$itemAttributes = function (options) {
	return _Utils_ap(
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('list-group-item', true),
						_Utils_Tuple2('disabled', options.a7),
						_Utils_Tuple2('active', options.aK),
						_Utils_Tuple2('list-group-item-action', options.aJ)
					]))
			]),
		_Utils_ap(
			_List_fromArray(
				[
					$elm$html$Html$Attributes$disabled(options.a7)
				]),
			_Utils_ap(
				A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					A2(
						$elm$core$Maybe$map,
						function (r) {
							return _List_fromArray(
								[
									A2($rundis$elm_bootstrap$Bootstrap$Internal$Role$toClass, 'list-group-item', r)
								]);
						},
						options.cK)),
				options.aU)));
};
var $rundis$elm_bootstrap$Bootstrap$Internal$ListGroup$renderItem = function (_v0) {
	var itemFn = _v0.em;
	var options = _v0.it;
	var children = _v0.c9;
	return A2(
		itemFn,
		$rundis$elm_bootstrap$Bootstrap$Internal$ListGroup$itemAttributes(
			A3($elm$core$List$foldl, $rundis$elm_bootstrap$Bootstrap$Internal$ListGroup$applyModifier, $rundis$elm_bootstrap$Bootstrap$Internal$ListGroup$defaultOptions, options)),
		children);
};
var $rundis$elm_bootstrap$Bootstrap$ListGroup$ul = function (items) {
	return A2(
		$elm$html$Html$ul,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('list-group')
			]),
		A2($elm$core$List$map, $rundis$elm_bootstrap$Bootstrap$Internal$ListGroup$renderItem, items));
};
var $author$project$ElmCommon$Autocomplete$buildAutoCompleteWidget = function (state) {
	var options = $author$project$ElmCommon$Utils$catMaybes(
		A2(
			$elm$core$List$map,
			A3($author$project$ElmCommon$Autocomplete$build_autocomplete_option, state, state.iG, state.hn.h9),
			state.hn.it));
	var is_open = state.hS;
	var autocomplete_div_opts = A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$rundis$elm_bootstrap$Bootstrap$ListGroup$ul(options)
			]));
	var autocomplete_div_input = $rundis$elm_bootstrap$Bootstrap$Form$Input$text(
		_List_fromArray(
			[
				$rundis$elm_bootstrap$Bootstrap$Form$Input$value(state.iG),
				$rundis$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$ElmCommon$Autocomplete$AutoCompleteMsgInputChange),
				$rundis$elm_bootstrap$Bootstrap$Form$Input$attrs(
				_List_fromArray(
					[
						$elm$html$Html$Events$onFocus($author$project$ElmCommon$Autocomplete$AutoCompleteMsgInputFocus),
						$elm$html$Html$Events$onBlur($author$project$ElmCommon$Autocomplete$AutoCompleteMsgInputBlur)
					])),
				$rundis$elm_bootstrap$Bootstrap$Form$Input$attrs(
				_List_fromArray(
					[
						$elm$html$Html$Attributes$placeholder(state.hn.iw)
					]))
			]));
	var ws = A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('ui-autocomplete')
			]),
		_Utils_ap(
			_List_fromArray(
				[autocomplete_div_input]),
			is_open ? _List_fromArray(
				[autocomplete_div_opts]) : _List_Nil));
	return ws;
};
var $author$project$Msg$ServerN80UpdateMetadataSubmit = {$: 8};
var $author$project$Components$CommonPageComponents$buildButtonSubmit = F2(
	function (txt, msg) {
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Button$button,
			_List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Button$primary,
					$rundis$elm_bootstrap$Bootstrap$Button$attrs(
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('float-right')
						])),
					$rundis$elm_bootstrap$Bootstrap$Button$onClick(msg)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(txt)
				]));
	});
var $author$project$Pages$PageDetailsN80$buildButtonSaveMetadata = A2($author$project$Components$CommonPageComponents$buildButtonSubmit, 'Save', $author$project$Msg$ServerN80UpdateMetadataSubmit);
var $author$project$Msg$UiUpdateN80MetaGroupsRemove = function (a) {
	return {$: 6, a: a};
};
var $author$project$Components$CommonPageComponents$buildElemN80GroupRemoveable = F2(
	function (n80GroupInfo, on_click) {
		var contents_std = _List_fromArray(
			[
				$elm$html$Html$text(n80GroupInfo.di.gG)
			]);
		var contents_remove = _List_fromArray(
			[
				A2(
				$elm$html$Html$span,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(' × ')
					]))
			]);
		var contents = _Utils_ap(contents_std, contents_remove);
		var attrs_onclick = _List_fromArray(
			[
				$rundis$elm_bootstrap$Bootstrap$Button$onClick(on_click)
			]);
		var attrs = _Utils_ap(
			_List_fromArray(
				[$rundis$elm_bootstrap$Bootstrap$Button$small, $rundis$elm_bootstrap$Bootstrap$Button$outlineInfo]),
			attrs_onclick);
		return A2($rundis$elm_bootstrap$Bootstrap$Button$button, attrs, contents);
	});
var $author$project$Pages$PageDetailsN80$buildGroupBadge = F2(
	function (can_remove, group) {
		if (can_remove) {
			return A2(
				$author$project$Components$CommonPageComponents$buildElemN80GroupRemoveable,
				group,
				$author$project$Msg$UiPageUpdate(
					$author$project$Msg$UiUpdateN80MetaGroupsRemove(group)));
		} else {
			return A2(
				$elm$html$Html$map,
				$author$project$Msg$RunGlobalAction,
				$author$project$Components$CommonPageComponents$buildElemN80GroupStd(group));
		}
	});
var $elm$html$Html$label = _VirtualDom_node('label');
var $rundis$elm_bootstrap$Bootstrap$Form$colLabel = F2(
	function (options, children) {
		return {
			c9: children,
			dS: $elm$html$Html$label,
			it: A2(
				$elm$core$List$cons,
				$rundis$elm_bootstrap$Bootstrap$Grid$Col$attrs(
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('col-form-label')
						])),
				options)
		};
	});
var $elm$html$Html$form = _VirtualDom_node('form');
var $rundis$elm_bootstrap$Bootstrap$Form$form = F2(
	function (attributes, children) {
		return A2($elm$html$Html$form, attributes, children);
	});
var $author$project$BigO$EditState$getEditStateDisplayValue = function (state) {
	switch (state.$) {
		case 0:
			var a = state.a;
			return $elm$core$Maybe$Just(a);
		case 1:
			var _v1 = state.a;
			var a = _v1.b;
			return $elm$core$Maybe$Just(a);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $elm$html$Html$hr = _VirtualDom_node('hr');
var $lattyware$elm_fontawesome$FontAwesome$Solid$Definitions$arrowRotateLeft = A4(
	$lattyware$elm_fontawesome$FontAwesome$IconDef,
	'fas',
	'arrow-rotate-left',
	_Utils_Tuple2(512, 512),
	_Utils_Tuple2('M125.7 160H176c17.7 0 32 14.3 32 32s-14.3 32-32 32H48c-17.7 0-32-14.3-32-32V64c0-17.7 14.3-32 32-32s32 14.3 32 32v51.2L97.6 97.6c87.5-87.5 229.3-87.5 316.8 0s87.5 229.3 0 316.8s-229.3 87.5-316.8 0c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0c62.5 62.5 163.8 62.5 226.3 0s62.5-163.8 0-226.3s-163.8-62.5-226.3 0L125.7 160z', $elm$core$Maybe$Nothing));
var $lattyware$elm_fontawesome$FontAwesome$Solid$arrowRotateLeft = $lattyware$elm_fontawesome$FontAwesome$present($lattyware$elm_fontawesome$FontAwesome$Solid$Definitions$arrowRotateLeft);
var $lattyware$elm_fontawesome$FontAwesome$Solid$undo = $lattyware$elm_fontawesome$FontAwesome$Solid$arrowRotateLeft;
var $author$project$Components$CommonPageComponents$iconRevert = A2(
	$elm$html$Html$span,
	_List_Nil,
	_List_fromArray(
		[
			$lattyware$elm_fontawesome$FontAwesome$view(
			A2(
				$lattyware$elm_fontawesome$FontAwesome$styled,
				_List_fromArray(
					[$lattyware$elm_fontawesome$FontAwesome$Attributes$sm, $lattyware$elm_fontawesome$FontAwesome$Attributes$fw]),
				$lattyware$elm_fontawesome$FontAwesome$Solid$undo))
		]));
var $author$project$Components$CommonPageComponents$iconBtnRevert = function (onClick) {
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Button$button,
		_List_fromArray(
			[
				$rundis$elm_bootstrap$Bootstrap$Button$outlineWarning,
				$rundis$elm_bootstrap$Bootstrap$Button$onClick(onClick)
			]),
		_List_fromArray(
			[$author$project$Components$CommonPageComponents$iconRevert]));
};
var $author$project$Components$CommonPageComponents$ifIsDirty = F2(
	function (is_dirty, val) {
		if (is_dirty) {
			return val;
		} else {
			return $author$project$BigO$Utils$htmlEmpty;
		}
	});
var $author$project$Components$CommonPageComponents$ifIsSaving = F2(
	function (is_saving, val) {
		if (is_saving) {
			return $elm$html$Html$text('SAVING...');
		} else {
			return val;
		}
	});
var $author$project$BigO$EditState$isStateSavingToServer = function (state) {
	switch (state.$) {
		case 0:
			var x = state.a;
			return false;
		case 1:
			var _v1 = state.a;
			var x = _v1.a;
			var y = _v1.b;
			return false;
		default:
			return true;
	}
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$Readonly = function (a) {
	return {$: 8, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$readonly = function (readonly_) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Input$Readonly(readonly_);
};
var $author$project$ElmCommon$Autocomplete$updateAutoCompleteConfig = F2(
	function (func, st) {
		return _Utils_update(
			st,
			{
				hn: func(st.hn)
			});
	});
var $author$project$ElmCommon$Autocomplete$removeAutocompleteOptions = F2(
	function (opts, state) {
		return A2(
			$author$project$ElmCommon$Autocomplete$updateAutoCompleteConfig,
			function (cfg) {
				return _Utils_update(
					cfg,
					{
						it: A2(
							$elm$core$List$filter,
							function (o) {
								return !A2($elm$core$List$member, o, opts);
							},
							cfg.it)
					});
			},
			state);
	});
var $author$project$ElmCommon$HaskellJsonTypesToStrings$showNodeBuildType = function (buildType) {
	switch (buildType) {
		case 0:
			return 'NodeBuildTypeUnknown';
		case 1:
			return 'NodeBuildTypeUndefined';
		case 2:
			return 'NodeBuildTypeProd';
		case 3:
			return 'NodeBuildTypeDev';
		default:
			return 'NodeBuildTypeTest';
	}
};
var $author$project$ElmCommon$HaskellJsonTypesToStrings$showNodePlatform = function (platform) {
	switch (platform) {
		case 0:
			return 'NodePlatformUnknown';
		case 1:
			return 'NodePlatformUndefined ';
		case 2:
			return 'NodePlatformLinuxDesktop ';
		default:
			return 'NodePlatformEsp32V1';
	}
};
var $author$project$ElmCommon$HaskellJsonTypesToStrings$showSemanticVersion = function (version) {
	return $elm$core$String$fromInt(version.ey) + ('.' + ($elm$core$String$fromInt(version.ez) + ('.' + $elm$core$String$fromInt(version.fA))));
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$Col10 = 10;
var $rundis$elm_bootstrap$Bootstrap$Grid$Col$sm10 = A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$width, 1, 10);
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$Col7 = 7;
var $rundis$elm_bootstrap$Bootstrap$Grid$Col$sm7 = A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$width, 1, 7);
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$Col9 = 9;
var $rundis$elm_bootstrap$Bootstrap$Grid$Col$sm9 = A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$width, 1, 9);
var $author$project$Pages$PageDetailsN80$buildN80ConfigPane = F4(
	function (mdl, cmn, editStatePageDetailsN80, n80Meta) {
		var ui_edit_value_80_desc_user = function () {
			var _v5 = $author$project$BigO$EditState$getEditStateDisplayValue(editStatePageDetailsN80.ig);
			if (_v5.$ === 1) {
				return $author$project$BigO$TypesAsStrings$showN80DescUser(n80Meta.eF);
			} else {
				var desc = _v5.a;
				return $author$project$BigO$TypesAsStrings$showN80DescUser(desc);
			}
		}();
		var platform_build_info = function () {
			var _v4 = n80Meta.eI.eK;
			if (_v4.$ === 1) {
				return '<unknown>';
			} else {
				var hw_platform = _v4.a;
				return A2(
					$elm$core$Maybe$withDefault,
					'<unknown>',
					A2($elm$core$Maybe$map, $author$project$ElmCommon$HaskellJsonTypesToStrings$showNodePlatform, hw_platform.eU)) + (' - ' + (A2(
					$elm$core$Maybe$withDefault,
					'unknown',
					A2($elm$core$Maybe$map, $author$project$ElmCommon$HaskellJsonTypesToStrings$showNodeBuildType, hw_platform.eT)) + (' - ' + (A2(
					$elm$core$Maybe$withDefault,
					'unknown',
					A2($elm$core$Maybe$map, $author$project$ElmCommon$HaskellJsonTypesToStrings$showSemanticVersion, hw_platform.eV)) + ('  ' + ('(' + (A2($elm$core$Maybe$withDefault, 'unknown', hw_platform.eS) + ')')))))));
			}
		}();
		var n80_user_access_level = A2(
			$elm$core$Maybe$withDefault,
			$elm$html$Html$text('BUG: COULDN\'T FIND AUTH?'),
			A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.gt;
					},
					$author$project$Components$CommonPageComponents$buildAccessLevel),
				$elm$core$List$head(
					A2(
						$elm$core$List$map,
						function (_v3) {
							var n80Id = _v3.a;
							var perm = _v3.b;
							return perm;
						},
						A2(
							$elm$core$List$filter,
							function (_v2) {
								var n80Id = _v2.a;
								return _Utils_eq(n80Id, n80Meta.eC);
							},
							cmn.hR.dW.dV)))));
		var n80_desc_simple = $author$project$BigO$TypesAsStrings$showN80DescSimple(n80Meta.eE);
		var n80 = n80Meta.eC;
		var is_n80_admin = _Utils_eq(
			cmn.ij(n80),
			$author$project$Api$AccessGrantedForUser$AccessGrantedForUser(3));
		var isPendingServerUserDesc = $author$project$BigO$EditState$isStateSavingToServer(editStatePageDetailsN80.ig);
		var isPendingServerOwner = $author$project$BigO$EditState$isStateSavingToServer(editStatePageDetailsN80.ii);
		var isPendingServerGroups = $author$project$BigO$EditState$isStateSavingToServer(editStatePageDetailsN80.ih);
		var isPendingAny = isPendingServerUserDesc || (isPendingServerOwner || isPendingServerGroups);
		var isDirtyUserDesc = $elm_community$maybe_extra$Maybe$Extra$isJust(
			$author$project$BigO$EditState$getEditStateIsUpdated(editStatePageDetailsN80.ig));
		var isDirtyOwner = $elm_community$maybe_extra$Maybe$Extra$isJust(
			$author$project$BigO$EditState$getEditStateIsUpdated(editStatePageDetailsN80.ii));
		var isDirtyGroups = $elm_community$maybe_extra$Maybe$Extra$isJust(
			$author$project$BigO$EditState$getEditStateIsUpdated(editStatePageDetailsN80.ih));
		var isDirtyAny = isDirtyUserDesc || (isDirtyOwner || isDirtyGroups);
		var instr_owner = A2(
			$elm$core$Maybe$withDefault,
			'NotSET',
			A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.gJ;
					},
					function ($) {
						return $.gR;
					}),
				$author$project$BigO$EditState$getEditStateDisplayValue(editStatePageDetailsN80.ii)));
		var instr_desc = A2(
			$elm$core$Maybe$withDefault,
			'unknown',
			A2(
				$elm$core$Maybe$map,
				function (instr) {
					return _Utils_ap(
						A2($elm$core$Maybe$withDefault, '?', instr.eN),
						A2($elm$core$Maybe$withDefault, '?', instr.eM));
				},
				n80Meta.eI.eJ));
		var instr_build_info = function () {
			var _v1 = n80Meta.eI.eJ;
			if (_v1.$ === 1) {
				return '<unknown>';
			} else {
				var hwInstr = _v1.a;
				return A2(
					$elm$core$Maybe$withDefault,
					'unknown',
					A2($elm$core$Maybe$map, $author$project$ElmCommon$HaskellJsonTypesToStrings$showSemanticVersion, hwInstr.eO)) + (' - ' + ('(' + (A2($elm$core$Maybe$withDefault, 'unknown', hwInstr.eL) + ')')));
			}
		}();
		var groupBadges = function () {
			var _v0 = $author$project$BigO$EditState$getEditStateDisplayValue(editStatePageDetailsN80.ih);
			if (_v0.$ === 1) {
				return _List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('FIX ME')
							]))
					]);
			} else {
				var groupsEdit = _v0.a;
				return A2(
					$elm$core$List$map,
					$author$project$Pages$PageDetailsN80$buildGroupBadge(is_n80_admin),
					groupsEdit);
			}
		}();
		var current_n80_groups = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			$author$project$BigO$EditState$getEditStateIsUpdated(editStatePageDetailsN80.ih));
		var groupAutocomplete = A2(
			$elm$html$Html$map,
			A2($elm$core$Basics$composeR, $author$project$Msg$UiUpdateN80MetaGroupsAutocomplete, $author$project$Msg$UiPageUpdate),
			$author$project$ElmCommon$Autocomplete$buildAutoCompleteWidget(
				A2($author$project$ElmCommon$Autocomplete$removeAutocompleteOptions, current_n80_groups, editStatePageDetailsN80.ie)));
		var buildButtonSaveMetadata_ = A2($elm$html$Html$map, $author$project$Msg$UiPageUpdate, $author$project$Pages$PageDetailsN80$buildButtonSaveMetadata);
		var all_blacklisted = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.c6;
				},
				mdl.fm.gd));
		var is_blacklisted = A2(
			$turboMaCk$any_dict$Dict$Any$get,
			n80,
			A2($turboMaCk$any_dict$Dict$Any$fromList, $author$project$BigO$TypesAsStrings$showN80, all_blacklisted));
		var container = A2(
			$rundis$elm_bootstrap$Bootstrap$Grid$container,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Form$form,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('n80-form')
						]),
					_List_fromArray(
						[
							A2(
							$rundis$elm_bootstrap$Bootstrap$Form$row,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$rundis$elm_bootstrap$Bootstrap$Form$colLabel,
									_List_fromArray(
										[$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm2]),
									_List_fromArray(
										[
											$elm$html$Html$text('Simple Identifier')
										])),
									A2(
									$rundis$elm_bootstrap$Bootstrap$Form$col,
									_List_fromArray(
										[$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm10]),
									_List_fromArray(
										[
											$rundis$elm_bootstrap$Bootstrap$Form$Input$text(
											_List_fromArray(
												[
													$rundis$elm_bootstrap$Bootstrap$Form$Input$readonly(true),
													$rundis$elm_bootstrap$Bootstrap$Form$Input$attrs(
													_List_fromArray(
														[
															$elm$html$Html$Attributes$placeholder(n80_desc_simple)
														]))
												]))
										]))
								])),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Form$row,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$rundis$elm_bootstrap$Bootstrap$Form$colLabel,
									_List_fromArray(
										[$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm2]),
									_List_fromArray(
										[
											$elm$html$Html$text('User Access')
										])),
									A2(
									$rundis$elm_bootstrap$Bootstrap$Form$col,
									_List_fromArray(
										[$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm10]),
									_List_fromArray(
										[n80_user_access_level]))
								])),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Form$row,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$rundis$elm_bootstrap$Bootstrap$Form$colLabel,
									_List_fromArray(
										[$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm2]),
									_List_fromArray(
										[
											$elm$html$Html$text('User Desc')
										])),
									A2(
									$rundis$elm_bootstrap$Bootstrap$Form$col,
									_List_fromArray(
										[$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm9]),
									_List_fromArray(
										[
											A2(
											$author$project$Components$CommonPageComponents$ifIsSaving,
											isPendingServerUserDesc,
											$rundis$elm_bootstrap$Bootstrap$Form$Input$text(
												_List_fromArray(
													[
														$rundis$elm_bootstrap$Bootstrap$Form$Input$value(ui_edit_value_80_desc_user),
														$rundis$elm_bootstrap$Bootstrap$Form$Input$onInput(
														A2(
															$elm$core$Basics$composeL,
															A2($elm$core$Basics$composeL, $author$project$Msg$UiPageUpdate, $author$project$Msg$UiUpdateN80DescUser),
															$author$project$Api$N80DescUser$N80DescUser)),
														$rundis$elm_bootstrap$Bootstrap$Form$Input$readonly(!is_n80_admin)
													])))
										])),
									A2(
									$rundis$elm_bootstrap$Bootstrap$Form$col,
									_List_fromArray(
										[$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm1]),
									_List_fromArray(
										[
											A2(
											$author$project$Components$CommonPageComponents$ifIsDirty,
											isDirtyUserDesc,
											$author$project$Components$CommonPageComponents$iconBtnRevert(
												$author$project$Msg$UiPageUpdate($author$project$Msg$UiRevertN80DescUser)))
										]))
								])),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Form$row,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$rundis$elm_bootstrap$Bootstrap$Form$colLabel,
									_List_fromArray(
										[$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm2]),
									_List_fromArray(
										[
											$elm$html$Html$text('Owner')
										])),
									A2(
									$rundis$elm_bootstrap$Bootstrap$Form$col,
									_List_fromArray(
										[$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm9]),
									_List_fromArray(
										[
											A2(
											$author$project$Components$CommonPageComponents$ifIsSaving,
											isPendingServerOwner,
											$rundis$elm_bootstrap$Bootstrap$Form$Input$text(
												_List_fromArray(
													[
														$rundis$elm_bootstrap$Bootstrap$Form$Input$value(instr_owner),
														$rundis$elm_bootstrap$Bootstrap$Form$Input$onInput(
														function (val) {
															return $author$project$Msg$UiPageUpdate(
																$author$project$Msg$UiUpdateN80Owner(
																	$author$project$Api$N80OwnerEmail$N80OwnerEmail(
																		$author$project$Api$UserEmail$UserEmail(val))));
														}),
														$rundis$elm_bootstrap$Bootstrap$Form$Input$readonly(!is_n80_admin)
													])))
										])),
									A2(
									$rundis$elm_bootstrap$Bootstrap$Form$col,
									_List_fromArray(
										[$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm1]),
									_List_fromArray(
										[
											A2(
											$author$project$Components$CommonPageComponents$ifIsDirty,
											isDirtyOwner,
											$author$project$Components$CommonPageComponents$iconBtnRevert(
												$author$project$Msg$UiPageUpdate($author$project$Msg$UiRevertN80Owner)))
										]))
								])),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Form$row,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$rundis$elm_bootstrap$Bootstrap$Form$colLabel,
									_List_fromArray(
										[$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm2]),
									_List_fromArray(
										[
											$elm$html$Html$text('Groups')
										])),
									A2(
									$rundis$elm_bootstrap$Bootstrap$Form$col,
									_List_fromArray(
										[
											$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm7,
											$rundis$elm_bootstrap$Bootstrap$Grid$Col$attrs(_List_Nil)
										]),
									_List_fromArray(
										[
											A2(
											$author$project$Components$CommonPageComponents$ifIsSaving,
											isPendingServerGroups,
											A2($elm$html$Html$div, _List_Nil, groupBadges))
										])),
									A2(
									$rundis$elm_bootstrap$Bootstrap$Form$col,
									_List_fromArray(
										[$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm2]),
									is_n80_admin ? _List_fromArray(
										[groupAutocomplete]) : _List_Nil),
									A2(
									$rundis$elm_bootstrap$Bootstrap$Form$col,
									_List_fromArray(
										[$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm1]),
									_List_fromArray(
										[
											A2(
											$author$project$Components$CommonPageComponents$ifIsDirty,
											isDirtyGroups,
											$author$project$Components$CommonPageComponents$iconBtnRevert(
												$author$project$Msg$UiPageUpdate($author$project$Msg$UiRevertN80GroupsRemove)))
										]))
								])),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Form$row,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$rundis$elm_bootstrap$Bootstrap$Form$colLabel,
									_List_fromArray(
										[$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm2]),
									_List_Nil),
									A2(
									$rundis$elm_bootstrap$Bootstrap$Form$col,
									_List_fromArray(
										[$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm7]),
									_List_Nil),
									A2(
									$rundis$elm_bootstrap$Bootstrap$Form$col,
									_List_fromArray(
										[$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm2]),
									_List_Nil),
									A2(
									$rundis$elm_bootstrap$Bootstrap$Form$col,
									_List_fromArray(
										[$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm1]),
									_List_fromArray(
										[
											A2(
											$author$project$Components$CommonPageComponents$ifIsSaving,
											isPendingAny,
											A2($author$project$Components$CommonPageComponents$ifIsDirty, isDirtyAny, buildButtonSaveMetadata_))
										]))
								])),
							A2($elm$html$Html$hr, _List_Nil, _List_Nil),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Form$row,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$rundis$elm_bootstrap$Bootstrap$Form$colLabel,
									_List_fromArray(
										[$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm2]),
									_List_fromArray(
										[
											$elm$html$Html$text('Full Identifier')
										])),
									A2(
									$rundis$elm_bootstrap$Bootstrap$Form$col,
									_List_fromArray(
										[$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm10]),
									_List_fromArray(
										[
											$rundis$elm_bootstrap$Bootstrap$Form$Input$text(
											_List_fromArray(
												[
													$rundis$elm_bootstrap$Bootstrap$Form$Input$readonly(true),
													$rundis$elm_bootstrap$Bootstrap$Form$Input$attrs(
													_List_fromArray(
														[
															$elm$html$Html$Attributes$placeholder(
															$author$project$BigO$TypesAsStrings$toHexStrN80(n80))
														]))
												]))
										]))
								])),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Form$row,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$rundis$elm_bootstrap$Bootstrap$Form$colLabel,
									_List_fromArray(
										[$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm2]),
									_List_fromArray(
										[
											$elm$html$Html$text('Plaform Build')
										])),
									A2(
									$rundis$elm_bootstrap$Bootstrap$Form$col,
									_List_fromArray(
										[$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm10]),
									_List_fromArray(
										[
											$rundis$elm_bootstrap$Bootstrap$Form$Input$text(
											_List_fromArray(
												[
													$rundis$elm_bootstrap$Bootstrap$Form$Input$readonly(true),
													$rundis$elm_bootstrap$Bootstrap$Form$Input$attrs(
													_List_fromArray(
														[
															$elm$html$Html$Attributes$placeholder(platform_build_info)
														]))
												]))
										]))
								])),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Form$row,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$rundis$elm_bootstrap$Bootstrap$Form$colLabel,
									_List_fromArray(
										[$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm2]),
									_List_fromArray(
										[
											$elm$html$Html$text('Instr Desc')
										])),
									A2(
									$rundis$elm_bootstrap$Bootstrap$Form$col,
									_List_fromArray(
										[$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm10]),
									_List_fromArray(
										[
											$rundis$elm_bootstrap$Bootstrap$Form$Input$text(
											_List_fromArray(
												[
													$rundis$elm_bootstrap$Bootstrap$Form$Input$readonly(true),
													$rundis$elm_bootstrap$Bootstrap$Form$Input$attrs(
													_List_fromArray(
														[
															$elm$html$Html$Attributes$placeholder(instr_desc)
														]))
												]))
										]))
								])),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Form$row,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$rundis$elm_bootstrap$Bootstrap$Form$colLabel,
									_List_fromArray(
										[$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm2]),
									_List_fromArray(
										[
											$elm$html$Html$text('Instrument Build')
										])),
									A2(
									$rundis$elm_bootstrap$Bootstrap$Form$col,
									_List_fromArray(
										[$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm10]),
									_List_fromArray(
										[
											$rundis$elm_bootstrap$Bootstrap$Form$Input$text(
											_List_fromArray(
												[
													$rundis$elm_bootstrap$Bootstrap$Form$Input$readonly(true),
													$rundis$elm_bootstrap$Bootstrap$Form$Input$attrs(
													_List_fromArray(
														[
															$elm$html$Html$Attributes$placeholder(instr_build_info)
														]))
												]))
										]))
								])),
							A2($elm$html$Html$hr, _List_Nil, _List_Nil),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Form$row,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$rundis$elm_bootstrap$Bootstrap$Form$colLabel,
									_List_fromArray(
										[$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm2]),
									_List_fromArray(
										[
											$elm$html$Html$text('Errors')
										])),
									A2(
									$rundis$elm_bootstrap$Bootstrap$Form$col,
									_List_fromArray(
										[$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm10]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											A2($elm$core$Maybe$withDefault, 'OK', is_blacklisted))
										]))
								]))
						]))
				]));
		return container;
	});
var $author$project$Pages$PageDetailsN80$N80DetailsTblColumnRecording = 0;
var $author$project$Pages$PageDetailsN80$N80DetailsTblColumnRecordingActions = 9;
var $author$project$Pages$PageDetailsN80$N80DetailsTblColumnRecordingComment = 7;
var $author$project$Pages$PageDetailsN80$N80DetailsTblColumnRecordingDate = 2;
var $author$project$Pages$PageDetailsN80$N80DetailsTblColumnRecordingDescUser = 8;
var $author$project$Pages$PageDetailsN80$N80DetailsTblColumnRecordingDuration = 5;
var $author$project$Pages$PageDetailsN80$N80DetailsTblColumnRecordingEndTime = 4;
var $author$project$Pages$PageDetailsN80$N80DetailsTblColumnRecordingStartTime = 3;
var $author$project$Pages$PageDetailsN80$N80DetailsTblColumnRecordingStatus = 1;
var $author$project$Pages$PageDetailsN80$N80DetailsTblColumnRecordingUploadsN96 = 6;
var $author$project$NodeDataStore$mdlGetAllN96MetadatasForN80 = F3(
	function (options, ns, n80) {
		var _v0 = ns.da;
		if ((!_v0.$) && (!_v0.a.$)) {
			var _v1 = _v0.a;
			var n80_ = _v1.a;
			var n96MetasShort = _v1.b;
			return n96MetasShort;
		} else {
			return _List_Nil;
		}
	});
var $author$project$Msg$ServerN96UpdateMetadataSetHidden = F2(
	function (a, b) {
		return {$: 24, a: a, b: b};
	});
var $author$project$Pages$PageDetailsN80$showN96IsHiddenActionTxt = function (n96IsHidden) {
	var _v0 = n96IsHidden.gM;
	if (_v0) {
		return 'Unhide';
	} else {
		return 'Hide';
	}
};
var $author$project$Pages$PageDetailsN80$toggleN96IsHidden = function (n96IsHidden) {
	return $author$project$Api$N96IsHidden$N96IsHidden(!n96IsHidden.gM);
};
var $author$project$Pages$PageDetailsN80$buildButtonHideN96 = function (n96Meta) {
	return A2(
		$author$project$Components$CommonPageComponents$buildButtonSubmit,
		$author$project$Pages$PageDetailsN80$showN96IsHiddenActionTxt(n96Meta.e5),
		A2(
			$author$project$Msg$ServerN96UpdateMetadataSetHidden,
			n96Meta.e2,
			$author$project$Pages$PageDetailsN80$toggleN96IsHidden(n96Meta.e5)));
};
var $author$project$BigO$TypesAsStrings$showN96Comment = function (n96CommentM) {
	return n96CommentM.gK;
};
var $author$project$BigO$TypesAsStrings$showN96CommentM = function (n96DescUser) {
	return A2(
		$elm$core$Maybe$withDefault,
		'',
		A2($elm$core$Maybe$map, $author$project$BigO$TypesAsStrings$showN96Comment, n96DescUser));
};
var $author$project$BigO$TypesAsStrings$showN96DescUserM = function (n96DescUserM) {
	return A2(
		$elm$core$Maybe$withDefault,
		'',
		A2($elm$core$Maybe$map, $author$project$BigO$TypesAsStrings$showN96DescUser, n96DescUserM));
};
var $author$project$Pages$PageDetailsN80$n80RecordSessionDataRow = F3(
	function (mdl, cols, _v0) {
		var n80Meta = _v0.a;
		var n96Meta = _v0.b;
		var n96_tbl_info = A2($author$project$BigO$Common$getN96TableInfo, mdl.it, n96Meta.e9);
		var build_td = function (t) {
			return A2(
				$rundis$elm_bootstrap$Bootstrap$Table$td,
				_List_Nil,
				_List_fromArray(
					[t]));
		};
		var colVal = function (c) {
			switch (c) {
				case 0:
					return build_td(
						$author$project$Components$CommonPageComponents$build_tblcell_n96_link(n96Meta));
				case 1:
					return build_td(
						$author$project$Components$CommonPageComponents$build_tblcell_n96_status(n96Meta));
				case 2:
					return build_td(
						$elm$html$Html$text(n96_tbl_info.cv));
				case 3:
					return build_td(
						$elm$html$Html$text(n96_tbl_info.cw));
				case 4:
					return build_td(
						$elm$html$Html$text(n96_tbl_info.cu));
				case 5:
					return build_td(
						$elm$html$Html$text(n96_tbl_info.ct));
				case 6:
					return build_td(
						$elm$html$Html$text(n96_tbl_info.cx));
				case 7:
					return build_td(
						$elm$html$Html$text(
							$author$project$BigO$TypesAsStrings$showN96CommentM(n96Meta.e3)));
				case 8:
					return build_td(
						$elm$html$Html$text(
							$author$project$BigO$TypesAsStrings$showN96DescUserM(n96Meta.e4)));
				default:
					return build_td(
						$author$project$Pages$PageDetailsN80$buildButtonHideN96(n96Meta));
			}
		};
		var col_contents = A2($elm$core$List$map, colVal, cols);
		return A2($rundis$elm_bootstrap$Bootstrap$Table$tr, _List_Nil, col_contents);
	});
var $author$project$Pages$PageDetailsN80$columnName = function (col) {
	switch (col) {
		case 0:
			return 'Experiment';
		case 1:
			return 'Status';
		case 2:
			return 'Date';
		case 3:
			return 'StartTime';
		case 4:
			return 'EndTime';
		case 5:
			return 'Duration';
		case 6:
			return 'Uploads';
		case 7:
			return 'Comment';
		case 8:
			return 'DescUser';
		default:
			return 'Action';
	}
};
var $author$project$Pages$PageDetailsN80$n80RecordSessionHeaderRow = function (cols) {
	var build_th = function (t) {
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Table$th,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text(t)
				]));
	};
	var named_cols = A2(
		$elm$core$List$map,
		A2($elm$core$Basics$composeR, $author$project$Pages$PageDetailsN80$columnName, build_th),
		cols);
	return A2($rundis$elm_bootstrap$Bootstrap$Table$tr, _List_Nil, named_cols);
};
var $elm$core$List$sortBy = _List_sortBy;
var $author$project$BigO$TypesAsStrings$u16ToInt = function (_v0) {
	var u16 = _v0;
	return u16;
};
var $author$project$Pages$PageDetailsN80$buildN80N96Pane = F2(
	function (mdl, n80Meta) {
		var n80MetasSorted = A2(
			$elm$core$List$map,
			function (n96Meta) {
				return _Utils_Tuple2(n80Meta, n96Meta);
			},
			$elm$core$List$reverse(
				A2(
					$elm$core$List$sortBy,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.e2;
						},
						A2(
							$elm$core$Basics$composeR,
							$author$project$BigO$TypesAsStrings$splitN96,
							A2($elm$core$Basics$composeR, $elm$core$Tuple$second, $author$project$BigO$TypesAsStrings$u16ToInt))),
					A3($author$project$NodeDataStore$mdlGetAllN96MetadatasForN80, mdl.it, mdl.fm, n80Meta.eC))));
		var cols = _List_fromArray(
			[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);
		var tbl = $rundis$elm_bootstrap$Bootstrap$Table$table(
			{
				it: _List_fromArray(
					[$rundis$elm_bootstrap$Bootstrap$Table$striped, $rundis$elm_bootstrap$Bootstrap$Table$hover]),
				iN: A2(
					$rundis$elm_bootstrap$Bootstrap$Table$tbody,
					_List_Nil,
					A2(
						$elm$core$List$map,
						A2($author$project$Pages$PageDetailsN80$n80RecordSessionDataRow, mdl, cols),
						n80MetasSorted)),
				iO: A2(
					$rundis$elm_bootstrap$Bootstrap$Table$thead,
					_List_Nil,
					_List_fromArray(
						[
							$author$project$Pages$PageDetailsN80$n80RecordSessionHeaderRow(cols)
						]))
			});
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Grid$container,
			_List_Nil,
			_List_fromArray(
				[tbl]));
	});
var $author$project$Pages$PageDetailsN80$pageDetailsN80 = F3(
	function (mdl, cmn, n80) {
		var n80MetaM = A2($author$project$NodeDataStore$mdlGetN80Meta, mdl.fm, n80);
		var page = function () {
			var _v0 = _Utils_Tuple2(n80MetaM, mdl.dM);
			if ((!_v0.a.$) && (!_v0.b.$)) {
				var n80Meta = _v0.a.a;
				var editStatePageDetailsN80 = _v0.b.a;
				var n96Pane = A2(
					$author$project$ElmCommon$HtmlCommon$dashboardElemTitledDiv_,
					'Recorded data:',
					_List_fromArray(
						[
							A2($author$project$Pages$PageDetailsN80$buildN80N96Pane, mdl, n80Meta)
						]));
				var configPane = A2(
					$author$project$ElmCommon$HtmlCommon$dashboardElemTitledDiv_,
					'Node details:',
					_List_fromArray(
						[
							A4($author$project$Pages$PageDetailsN80$buildN80ConfigPane, mdl, cmn, editStatePageDetailsN80, n80Meta)
						]));
				return _List_fromArray(
					[configPane, n96Pane]);
			} else {
				return _List_fromArray(
					[
						A2(
						$author$project$ElmCommon$HtmlCommon$dashboardElemTitledDiv_,
						'Node details:',
						_List_fromArray(
							[
								$elm$html$Html$text('Missing metadata?')
							]))
					]);
			}
		}();
		return page;
	});
var $author$project$Components$N80GroupMembershipTableTypes$N80GroupMembershipTableColDevice = 0;
var $author$project$Components$N80GroupMembershipTableTypes$N80GroupMembershipTableColDeviceAction = 2;
var $author$project$Components$N80GroupMembershipTableTypes$N80GroupMembershipTableColDeviceType = 1;
var $author$project$Msg$UiUpdatePageDetailsN80Group_N80MembershipTable = function (a) {
	return {$: 19, a: a};
};
var $author$project$Components$N80GroupMembershipTableTypes$N80GroupTableMsgUpdateAddN80 = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Components$N80GroupMembershipTableTypes$N80GroupTableMsgUpdateAutocompleteMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$ElmCommon$Autocomplete$autoCompleteElementSelected = function (autocomplete) {
	return $elm$core$List$head(
		A2(
			$elm$core$List$filter,
			function (o) {
				return _Utils_eq(
					autocomplete.hn.hw(o),
					autocomplete.iG);
			},
			autocomplete.hn.it));
};
var $author$project$Components$N80GroupMembershipTable$buildN80GrouopMembershipTableHdr = function (col) {
	switch (col) {
		case 0:
			return $elm$html$Html$text('Device');
		case 1:
			return $elm$html$Html$text('Type');
		default:
			return $elm$html$Html$text('Action');
	}
};
var $author$project$Components$N80GroupMembershipTableTypes$N80GroupTableMsgUpdateDeleteN80 = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Components$N80GroupMembershipTableTypes$N80GroupTableMsgUpdateGlobalAction = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$CommonPageComponents$build_tblcell_deviceM = F2(
	function (n80, n80MetaLiveM) {
		if (n80MetaLiveM.$ === 1) {
			return $elm$html$Html$text('FIX ME ');
		} else {
			var n80MetaLive = n80MetaLiveM.a;
			return $author$project$Components$CommonPageComponents$build_tblcell_device(n80MetaLive);
		}
	});
var $author$project$Components$N80GroupMembershipTable$buildN80GroupMembershipTableCell = F5(
	function (cmn, n80Grp, n80_lookup, n80, col) {
		var n80Meta = n80_lookup(n80);
		switch (col) {
			case 0:
				return A2(
					$elm$html$Html$map,
					$author$project$Components$N80GroupMembershipTableTypes$N80GroupTableMsgUpdateGlobalAction,
					A2($author$project$Components$CommonPageComponents$build_tblcell_deviceM, n80, n80Meta));
			case 1:
				return A2(
					$elm$html$Html$map,
					$author$project$Components$N80GroupMembershipTableTypes$N80GroupTableMsgUpdateGlobalAction,
					A2($author$project$Components$CommonPageComponents$build_tblcell_typeM, n80, n80Meta));
			default:
				var n80group_admin = _Utils_eq(
					cmn.ik(n80Grp.dh),
					$author$project$Api$AccessLevelGrantedForN80Group$AccessLevelGrantedForN80Group(3));
				var n80_admin = cmn.ij(n80).gt === 3;
				var deleteBtn = $author$project$Components$CommonPageComponents$iconBtnDelete(
					A2($author$project$Components$N80GroupMembershipTableTypes$N80GroupTableMsgUpdateDeleteN80, n80Grp.dh, n80));
				var can_delete = n80_admin || n80group_admin;
				return can_delete ? deleteBtn : $author$project$BigO$Utils$htmlEmpty;
		}
	});
var $author$project$Utils$htmlsToHdrRow = function (cells) {
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Table$thead,
		_List_Nil,
		$elm$core$List$singleton(
			A2(
				$rundis$elm_bootstrap$Bootstrap$Table$tr,
				_List_Nil,
				A2(
					$elm$core$List$map,
					function (c) {
						return A2(
							$rundis$elm_bootstrap$Bootstrap$Table$th,
							_List_Nil,
							_List_fromArray(
								[c]));
					},
					cells))));
};
var $author$project$Components$N80GroupMembershipTable$pageDetailsN80GroupN80MembersTable = F5(
	function (cmn, cols, editState, data, n80Group) {
		var user_can_add = true;
		var hdr = A2($elm$core$List$map, $author$project$Components$N80GroupMembershipTable$buildN80GrouopMembershipTableHdr, cols);
		var thead_ = $author$project$Utils$htmlsToHdrRow(hdr);
		var buildN80GroupRow = function (n80) {
			return $author$project$Utils$htmlsToRow(
				A2(
					$elm$core$List$map,
					A4($author$project$Components$N80GroupMembershipTable$buildN80GroupMembershipTableCell, cmn, n80Group, data.id, n80),
					cols));
		};
		var userRows = A2($elm$core$List$map, buildN80GroupRow, n80Group.dk);
		var tbody = A2($rundis$elm_bootstrap$Bootstrap$Table$tbody, _List_Nil, userRows);
		var tbl = $elm$core$Maybe$Just(
			$rundis$elm_bootstrap$Bootstrap$Table$table(
				{
					it: _List_fromArray(
						[$rundis$elm_bootstrap$Bootstrap$Table$striped, $rundis$elm_bootstrap$Bootstrap$Table$hover, $rundis$elm_bootstrap$Bootstrap$Table$small]),
					iN: tbody,
					iO: thead_
				}));
		var add_row = function () {
			if (!user_can_add) {
				return $elm$core$Maybe$Nothing;
			} else {
				var widget_autocomplete = A2(
					$elm$html$Html$map,
					$author$project$Components$N80GroupMembershipTableTypes$N80GroupTableMsgUpdateAutocompleteMsg,
					$author$project$ElmCommon$Autocomplete$buildAutoCompleteWidget(editState.aM));
				var add_button = function () {
					var _v1 = $author$project$ElmCommon$Autocomplete$autoCompleteElementSelected(editState.aM);
					if (_v1.$ === 1) {
						return _List_Nil;
					} else {
						var selected = _v1.a;
						var add_action = A2($author$project$Components$N80GroupMembershipTableTypes$N80GroupTableMsgUpdateAddN80, n80Group.dh, selected.eC);
						return _List_fromArray(
							[
								$author$project$Components$CommonPageComponents$iconBtnAdd(add_action)
							]);
					}
				}();
				var add_row_ = A2(
					$rundis$elm_bootstrap$Bootstrap$Form$row,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rundis$elm_bootstrap$Bootstrap$Form$col,
							_List_fromArray(
								[$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm8]),
							_List_fromArray(
								[widget_autocomplete])),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Form$col,
							_List_fromArray(
								[$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm2]),
							_List_Nil),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Form$col,
							_List_fromArray(
								[$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm1]),
							_List_Nil),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Form$col,
							_List_fromArray(
								[$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm1]),
							add_button)
						]));
				return $elm$core$Maybe$Just(add_row_);
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			$author$project$ElmCommon$Utils$catMaybes(
				_List_fromArray(
					[tbl, add_row])));
	});
var $author$project$BigO$Common$buildPropertyTable = function (rows) {
	var thead_ = A2(
		$rundis$elm_bootstrap$Bootstrap$Table$thead,
		_List_Nil,
		_List_fromArray(
			[
				A2($rundis$elm_bootstrap$Bootstrap$Table$tr, _List_Nil, _List_Nil)
			]));
	var buildRow = function (_v0) {
		var k = _v0.a;
		var v = _v0.b;
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Table$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Table$td,
					_List_Nil,
					_List_fromArray(
						[
							$author$project$ElmCommon$Utils$bold(
							$elm$html$Html$text(k))
						])),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Table$td,
					_List_Nil,
					_List_fromArray(
						[v]))
				]));
	};
	var tbody = A2(
		$rundis$elm_bootstrap$Bootstrap$Table$tbody,
		_List_Nil,
		A2($elm$core$List$map, buildRow, rows));
	return $rundis$elm_bootstrap$Bootstrap$Table$table(
		{
			it: _List_fromArray(
				[$rundis$elm_bootstrap$Bootstrap$Table$striped, $rundis$elm_bootstrap$Bootstrap$Table$hover, $rundis$elm_bootstrap$Bootstrap$Table$small]),
			iN: tbody,
			iO: thead_
		});
};
var $author$project$Pages$PageDetailsN80Group$pageUserAccessUserGroupDetailsSummaryTable_ = F3(
	function (cmn, ug, n80Group) {
		var auth = cmn.ik(n80Group.dh);
		var authTxt = $author$project$Components$CommonPageComponents$buildAccessLevel(auth.gw);
		return $author$project$BigO$Common$buildPropertyTable(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'N80 GroupName',
					$elm$html$Html$text(n80Group.di.gG)),
					_Utils_Tuple2('User AuthLevel', authTxt),
					_Utils_Tuple2(
					'Public AuthLevel',
					$author$project$Components$CommonPageComponents$buildAccessLevel(n80Group.dg.gu))
				]));
	});
var $author$project$Pages$PageDetailsN80Group$pageUserAccessN80GroupDetails_ = F5(
	function (mdl, cmn, editState, ug, n80Group) {
		var summary_table = A3($author$project$Pages$PageDetailsN80Group$pageUserAccessUserGroupDetailsSummaryTable_, cmn, ug, n80Group);
		var n80MemberhsipTblCols = _List_fromArray(
			[0, 1, 2]);
		var dataN80GroupMembershipTable = {
			id: $author$project$NodeDataStore$mdlGetN80Meta(mdl.fm)
		};
		var n80_table = A5($author$project$Components$N80GroupMembershipTable$pageDetailsN80GroupN80MembersTable, cmn, n80MemberhsipTblCols, editState.hF, dataN80GroupMembershipTable, n80Group);
		return _List_fromArray(
			[
				A2(
				$author$project$ElmCommon$HtmlCommon$dashboardElemTitledDiv_,
				'N80 Group Overview',
				_List_fromArray(
					[summary_table])),
				A2(
				$author$project$ElmCommon$HtmlCommon$dashboardElemTitledDiv_,
				'Devices in Group',
				_List_fromArray(
					[
						A2(
						$elm$html$Html$map,
						A2($elm$core$Basics$composeL, $author$project$Msg$UiPageUpdate, $author$project$Msg$UiUpdatePageDetailsN80Group_N80MembershipTable),
						n80_table)
					]))
			]);
	});
var $author$project$Pages$PageDetailsN80Group$pageUserAccessN80Group_ = F4(
	function (mdl, cmn, ug, n80GroupId) {
		var editStateM = mdl.dN;
		var adminDataLookup = function () {
			var _v1 = mdl.c0;
			if (_v1.$ === 1) {
				return $elm$core$Maybe$Nothing;
			} else {
				var auth_admin_data = _v1.a;
				return $elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (g) {
							return _Utils_eq(g.dh, n80GroupId);
						},
						auth_admin_data.cX));
			}
		}();
		var cloudGroupM = adminDataLookup;
		var _v0 = _Utils_Tuple2(cloudGroupM, editStateM);
		if ((!_v0.a.$) && (!_v0.b.$)) {
			var n80Group = _v0.a.a;
			var editState = _v0.b.a;
			return A5($author$project$Pages$PageDetailsN80Group$pageUserAccessN80GroupDetails_, mdl, cmn, editState, ug, n80Group);
		} else {
			return _List_fromArray(
				[
					A2(
					$author$project$ElmCommon$HtmlCommon$dashboardElemTitledDiv_,
					'asdfrs',
					_List_fromArray(
						[
							$elm$html$Html$text('Access denied')
						]))
				]);
		}
	});
var $author$project$Pages$PageDetailsN80Group$pageDetailsN80Group = F3(
	function (mdl, cmn, n80GroudId) {
		var _v0 = mdl.c3;
		if (_v0.$ === 1) {
			return _List_fromArray(
				[
					$elm$html$Html$text('Wait, wot. Not Connection.')
				]);
		} else {
			var ug = _v0.a;
			return A4($author$project$Pages$PageDetailsN80Group$pageUserAccessN80Group_, mdl, cmn, ug, n80GroudId);
		}
	});
var $author$project$Msg$UiUpdateN96Comment = F2(
	function (a, b) {
		return {$: 22, a: a, b: b};
	});
var $author$project$Msg$UiUpdateN96DescUser = F2(
	function (a, b) {
		return {$: 21, a: a, b: b};
	});
var $author$project$TypesPage$PageDetailsN96DevDownloads = function (a) {
	return {$: 5, a: a};
};
var $author$project$Pages$PageDetailsN80$buildButtonShowDevDetails = function (n96Meta) {
	return A2(
		$author$project$Components$CommonPageComponents$buildButtonSubmit,
		'Show DevDetails',
		$author$project$Msg$RunGlobalAction(
			$author$project$TypesPage$PageDetailsN96DevDownloads(n96Meta.e2)));
};
var $author$project$TypesPage$PageDetailsN96PartialDownloads = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$PageDetailsN80$buildButtonShowPartials = function (n96Meta) {
	return A2(
		$author$project$Components$CommonPageComponents$buildButtonSubmit,
		'Show partial-files',
		$author$project$Msg$RunGlobalAction(
			$author$project$TypesPage$PageDetailsN96PartialDownloads(n96Meta.e2)));
};
var $author$project$Msg$ServerN96UpdateMetadataSubmit = function (a) {
	return {$: 23, a: a};
};
var $author$project$Pages$PageDetailsN80$buildButtonSubmitMetadataN96 = function (n96) {
	return A2(
		$author$project$Components$CommonPageComponents$buildButtonSubmit,
		'Submit',
		$author$project$Msg$ServerN96UpdateMetadataSubmit(n96));
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Column = function (a) {
	return {$: 0, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Grid$col = F2(
	function (options, children) {
		return $rundis$elm_bootstrap$Bootstrap$Grid$Column(
			{c9: children, it: options});
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$renderCol = function (column) {
	switch (column.$) {
		case 0:
			var options = column.a.it;
			var children = column.a.c9;
			return A2(
				$elm$html$Html$div,
				$rundis$elm_bootstrap$Bootstrap$Grid$Internal$colAttributes(options),
				children);
		case 1:
			var e = column.a;
			return e;
		default:
			var options = column.a.it;
			var children = column.a.c9;
			return A3(
				$elm$html$Html$Keyed$node,
				'div',
				$rundis$elm_bootstrap$Bootstrap$Grid$Internal$colAttributes(options),
				children);
	}
};
var $rundis$elm_bootstrap$Bootstrap$Grid$row = F2(
	function (options, cols) {
		return A2(
			$elm$html$Html$div,
			$rundis$elm_bootstrap$Bootstrap$Grid$Internal$rowAttributes(options),
			A2($elm$core$List$map, $rundis$elm_bootstrap$Bootstrap$Grid$renderCol, cols));
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$Top = 0;
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$RowVAlign = function (a) {
	return {$: 0, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$VAlign = F2(
	function (screenSize, align) {
		return {c_: align, f4: screenSize};
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$rowVAlign = F2(
	function (size, align) {
		return $rundis$elm_bootstrap$Bootstrap$Grid$Internal$RowVAlign(
			A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$VAlign, size, align));
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Row$topXs = A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$rowVAlign, 0, 0);
var $rundis$elm_bootstrap$Bootstrap$Grid$Col$xs2 = A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$width, 0, 2);
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$Col4 = 4;
var $rundis$elm_bootstrap$Bootstrap$Grid$Col$xs4 = A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$width, 0, 4);
var $author$project$Pages$PageDetailsN96$buildSummaryForm1Col = function (vals) {
	var buildRow = function (_v0) {
		var k = _v0.a;
		var v = _v0.b;
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Grid$row,
			_List_fromArray(
				[$rundis$elm_bootstrap$Bootstrap$Grid$Row$topXs]),
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_fromArray(
						[$rundis$elm_bootstrap$Bootstrap$Grid$Col$xs2]),
					_List_fromArray(
						[k])),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_fromArray(
						[$rundis$elm_bootstrap$Bootstrap$Grid$Col$xs4]),
					_List_fromArray(
						[v]))
				]));
	};
	var rows = A2($elm$core$List$map, buildRow, vals);
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Grid$container,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('n80-form')
			]),
		rows);
};
var $rundis$elm_bootstrap$Bootstrap$General$Internal$Right = 2;
var $rundis$elm_bootstrap$Bootstrap$General$Internal$HAlign = F2(
	function (screenSize, align) {
		return {c_: align, f4: screenSize};
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$RowHAlign = function (a) {
	return {$: 1, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$rowHAlign = F2(
	function (size, align) {
		return $rundis$elm_bootstrap$Bootstrap$Grid$Internal$RowHAlign(
			A2($rundis$elm_bootstrap$Bootstrap$General$Internal$HAlign, size, align));
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Row$rightSm = A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$rowHAlign, 1, 2);
var $author$project$BigO$TypesAsStrings$showC32 = function (c32) {
	return $elm$core$String$fromInt(c32.gx);
};
var $author$project$Pages$PageDetailsN96$buildN96SummaryPaneOverview = F3(
	function (mdl, pageState, _v0) {
		var n80Meta = _v0.a;
		var n96Meta = _v0.b;
		var ui_edit_value_96_desc_user = pageState.im.gL;
		var ui_edit_value_96_comment = pageState.il.gK;
		var status = $author$project$Components$CommonPageComponents$build_n96_status_pill(
			$author$project$Components$CommonPageComponents$cloudMetadataN96StatusDetailsToStatus(n96Meta.e9));
		var n96 = n96Meta.e2;
		var n80Link = $author$project$Components$CommonPageComponents$build_tblcell_device_and_type(n80Meta);
		var table = function () {
			var _v1 = n96Meta.e9;
			switch (_v1.$) {
				case 2:
					var errDetails = _v1.a;
					return $author$project$Pages$PageDetailsN96$buildSummaryForm1Col(
						_List_fromArray(
							[
								_Utils_Tuple2(
								$author$project$ElmCommon$Utils$bold(
									$elm$html$Html$text('Node')),
								A2($elm$html$Html$map, $author$project$Msg$RunGlobalAction, n80Link)),
								_Utils_Tuple2(
								$author$project$ElmCommon$Utils$bold(
									$elm$html$Html$text('Status')),
								status),
								_Utils_Tuple2(
								$author$project$ElmCommon$Utils$bold(
									$elm$html$Html$text('Duration')),
								$elm$html$Html$text('')),
								_Utils_Tuple2(
								$author$project$ElmCommon$Utils$bold(
									$elm$html$Html$text('Start-time')),
								$elm$html$Html$text('')),
								_Utils_Tuple2(
								$author$project$ElmCommon$Utils$bold(
									$elm$html$Html$text('End-time')),
								$elm$html$Html$text('')),
								_Utils_Tuple2(
								$author$project$ElmCommon$Utils$bold(
									$elm$html$Html$text('Parcels')),
								$elm$html$Html$text('')),
								_Utils_Tuple2(
								$author$project$ElmCommon$Utils$bold(
									$elm$html$Html$text('NBytes')),
								$elm$html$Html$text(''))
							]));
				case 0:
					var openDetails = _v1.a;
					return $author$project$Pages$PageDetailsN96$buildSummaryForm1Col(
						_List_fromArray(
							[
								_Utils_Tuple2(
								$author$project$ElmCommon$Utils$bold(
									$elm$html$Html$text('Node')),
								A2($elm$html$Html$map, $author$project$Msg$RunGlobalAction, n80Link)),
								_Utils_Tuple2(
								$author$project$ElmCommon$Utils$bold(
									$elm$html$Html$text('Status')),
								status),
								_Utils_Tuple2(
								$author$project$ElmCommon$Utils$bold(
									$elm$html$Html$text('Duration')),
								$elm$html$Html$text(
									A2($author$project$BigO$TypesAsStrings$durationStrHumanMaybe, openDetails.fh, openDetails.fg))),
								_Utils_Tuple2(
								$author$project$ElmCommon$Utils$bold(
									$elm$html$Html$text('Start-time')),
								$elm$html$Html$text(
									A2($author$project$BigO$TimeUtils$toUtcStringDateTimeMaybe, '??', openDetails.fh))),
								_Utils_Tuple2(
								$author$project$ElmCommon$Utils$bold(
									$elm$html$Html$text('End-time')),
								$elm$html$Html$text(
									A2($author$project$BigO$TimeUtils$toUtcStringDateTimeMaybe, '??', openDetails.fg))),
								_Utils_Tuple2(
								$author$project$ElmCommon$Utils$bold(
									$elm$html$Html$text('Parcels')),
								$elm$html$Html$text(
									$author$project$ElmCommon$HtmlCommon$addCommaToLargeNumberString(
										$author$project$BigO$TypesAsStrings$showC32(openDetails.fj.gy)))),
								_Utils_Tuple2(
								$author$project$ElmCommon$Utils$bold(
									$elm$html$Html$text('NBytes')),
								$elm$html$Html$text(
									$author$project$BigO$TypesAsStrings$showByteCountN96(openDetails.fk)))
							]));
				default:
					var closedDetails = _v1.a;
					return $author$project$Pages$PageDetailsN96$buildSummaryForm1Col(
						_List_fromArray(
							[
								_Utils_Tuple2(
								$author$project$ElmCommon$Utils$bold(
									$elm$html$Html$text('Node')),
								A2($elm$html$Html$map, $author$project$Msg$RunGlobalAction, n80Link)),
								_Utils_Tuple2(
								$author$project$ElmCommon$Utils$bold(
									$elm$html$Html$text('Status')),
								status),
								_Utils_Tuple2(
								$author$project$ElmCommon$Utils$bold(
									$elm$html$Html$text('Duration')),
								$elm$html$Html$text(
									A2($author$project$BigO$TypesAsStrings$durationStrHumanMaybe, closedDetails.fc, closedDetails.fa))),
								_Utils_Tuple2(
								$author$project$ElmCommon$Utils$bold(
									$elm$html$Html$text('Start-time')),
								$elm$html$Html$text(
									A2($author$project$BigO$TimeUtils$toUtcStringDateTimeMaybe, '??', closedDetails.fc))),
								_Utils_Tuple2(
								$author$project$ElmCommon$Utils$bold(
									$elm$html$Html$text('End-time')),
								$elm$html$Html$text(
									A2($author$project$BigO$TimeUtils$toUtcStringDateTimeMaybe, '??', closedDetails.fa))),
								_Utils_Tuple2(
								$author$project$ElmCommon$Utils$bold(
									$elm$html$Html$text('Parcels')),
								$elm$html$Html$text(
									A3(
										$elm$core$Basics$composeR,
										function ($) {
											return $.gy;
										},
										A2($elm$core$Basics$composeR, $author$project$BigO$TypesAsStrings$showC32, $author$project$ElmCommon$HtmlCommon$addCommaToLargeNumberString),
										closedDetails.fd))),
								_Utils_Tuple2(
								$author$project$ElmCommon$Utils$bold(
									$elm$html$Html$text('NBytes')),
								$elm$html$Html$text(
									$author$project$BigO$TypesAsStrings$showByteCountN96(closedDetails.fb)))
							]));
			}
		}();
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Grid$container,
			_List_Nil,
			_List_fromArray(
				[
					table,
					A2($elm$html$Html$hr, _List_Nil, _List_Nil),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Form$row,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rundis$elm_bootstrap$Bootstrap$Form$colLabel,
							_List_fromArray(
								[$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm2]),
							_List_fromArray(
								[
									$elm$html$Html$text('User Desc')
								])),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Form$col,
							_List_fromArray(
								[$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm9]),
							_List_fromArray(
								[
									$rundis$elm_bootstrap$Bootstrap$Form$Input$text(
									_List_fromArray(
										[
											$rundis$elm_bootstrap$Bootstrap$Form$Input$value(ui_edit_value_96_desc_user),
											$rundis$elm_bootstrap$Bootstrap$Form$Input$onInput(
											function (val) {
												return A2(
													$author$project$Msg$UiUpdateN96DescUser,
													n96,
													$author$project$Api$N96DescUser$N96DescUser(val));
											})
										]))
								]))
						])),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Form$row,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rundis$elm_bootstrap$Bootstrap$Form$colLabel,
							_List_fromArray(
								[$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm2]),
							_List_fromArray(
								[
									$elm$html$Html$text('Comment')
								])),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Form$col,
							_List_fromArray(
								[$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm9]),
							_List_fromArray(
								[
									$rundis$elm_bootstrap$Bootstrap$Form$Input$text(
									_List_fromArray(
										[
											$rundis$elm_bootstrap$Bootstrap$Form$Input$value(ui_edit_value_96_comment),
											$rundis$elm_bootstrap$Bootstrap$Form$Input$onInput(
											function (val) {
												return A2(
													$author$project$Msg$UiUpdateN96Comment,
													n96,
													$author$project$Api$N96Comment$N96Comment(val));
											})
										]))
								])),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Form$col,
							_List_fromArray(
								[$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm1]),
							_List_fromArray(
								[
									$author$project$Pages$PageDetailsN80$buildButtonSubmitMetadataN96(n96Meta.e2)
								]))
						])),
					A2($elm$html$Html$hr, _List_Nil, _List_Nil),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Form$row,
					_List_fromArray(
						[$rundis$elm_bootstrap$Bootstrap$Grid$Row$rightSm]),
					_List_fromArray(
						[
							A2(
							$rundis$elm_bootstrap$Bootstrap$Form$col,
							_List_fromArray(
								[$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm2]),
							_List_fromArray(
								[
									$author$project$Pages$PageDetailsN80$buildButtonHideN96(n96Meta)
								]))
						])),
					A2($elm$html$Html$hr, _List_Nil, _List_Nil),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Form$row,
					_List_fromArray(
						[$rundis$elm_bootstrap$Bootstrap$Grid$Row$rightSm]),
					_List_fromArray(
						[
							A2(
							$rundis$elm_bootstrap$Bootstrap$Form$col,
							_List_fromArray(
								[$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm2]),
							_List_fromArray(
								[
									$author$project$Pages$PageDetailsN80$buildButtonShowPartials(n96Meta)
								]))
						])),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Form$row,
					_List_fromArray(
						[$rundis$elm_bootstrap$Bootstrap$Grid$Row$rightSm]),
					_List_fromArray(
						[
							A2(
							$rundis$elm_bootstrap$Bootstrap$Form$col,
							_List_fromArray(
								[$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm2]),
							_List_fromArray(
								[
									$author$project$Pages$PageDetailsN80$buildButtonShowDevDetails(n96Meta)
								]))
						]))
				]));
	});
var $author$project$Msg$OnClickToggleDropDown = F2(
	function (a, b) {
		return {$: 26, a: a, b: b};
	});
var $author$project$Msg$OnClickCloudDownloadLink = function (a) {
	return {$: 27, a: a};
};
var $author$project$BigO$TypesAsStrings$showDownloadOutputType = function (ftype) {
	switch (ftype) {
		case 0:
			return 'PyBin';
		case 1:
			return 'CSV';
		case 2:
			return 'Excel (Clipped)';
		default:
			return 'HDF5';
	}
};
var $author$project$Pages$PageDetailsN96$buildDownloadDropDownItemComplete = function (linkInfo_) {
	var linkInfo = linkInfo_.gX;
	var fileType = $author$project$BigO$TypesAsStrings$showDownloadOutputType(linkInfo.eq);
	var btnText = function () {
		var _v1 = linkInfo.es;
		switch (_v1) {
			case 0:
				return fileType + ' [Request...]';
			case 1:
				return fileType + ' [Requested...]';
			case 2:
				return fileType + ' [Converting...]';
			case 3:
				return fileType;
			default:
				return fileType + ' [Error]';
		}
	}();
	var btnAttrInactive = _List_fromArray(
		[
			$elm$html$Html$Attributes$class('disabled')
		]);
	var btnAttrActive = _List_fromArray(
		[
			$elm$html$Html$Events$onClick(
			$author$project$Msg$OnClickCloudDownloadLink(linkInfo))
		]);
	var btnAttrs = function () {
		var _v0 = linkInfo.es;
		switch (_v0) {
			case 0:
				return btnAttrActive;
			case 1:
				return btnAttrInactive;
			case 2:
				return btnAttrInactive;
			case 3:
				return _List_fromArray(
					[
						$elm$html$Html$Events$onClick(
						$author$project$Msg$OnClickCloudDownloadLink(linkInfo))
					]);
			default:
				return btnAttrInactive;
		}
	}();
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Dropdown$buttonItem,
		btnAttrs,
		_List_fromArray(
			[
				$elm$html$Html$text(btnText)
			]));
};
var $author$project$Pages$PageDetailsN96$buildDownloadDropDownComplete = F3(
	function (mdl, n96Meta, n96Sensor) {
		var _v0 = n96Sensor.f9;
		switch (_v0.$) {
			case 2:
				var sensorDetailsError = _v0.a;
				return A2($elm$html$Html$div, _List_Nil, _List_Nil);
			case 0:
				var sensorDetailsOpen = _v0.a;
				return A2($elm$html$Html$div, _List_Nil, _List_Nil);
			default:
				var sensorDetailsClosed = _v0.a;
				var s104 = n96Sensor.f$;
				var state = function () {
					var _v1 = mdl.dP;
					if (_v1.$ === 1) {
						return $rundis$elm_bootstrap$Bootstrap$Dropdown$initialState;
					} else {
						var es = _v1.a;
						return _Utils_eq(s104, es.f$) ? es.hB : $rundis$elm_bootstrap$Bootstrap$Dropdown$initialState;
					}
				}();
				var downloads = sensorDetailsClosed.dK;
				return A2(
					$rundis$elm_bootstrap$Bootstrap$Dropdown$dropdown,
					state,
					{
						h0: A2($elm$core$List$map, $author$project$Pages$PageDetailsN96$buildDownloadDropDownItemComplete, downloads),
						it: _List_fromArray(
							[$rundis$elm_bootstrap$Bootstrap$Dropdown$alignMenuRight]),
						iT: A2(
							$rundis$elm_bootstrap$Bootstrap$Dropdown$toggle,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Download')
								])),
						iU: $author$project$Msg$OnClickToggleDropDown(s104)
					});
		}
	});
var $author$project$BigO$TypesAsStrings$showS104Desc = function (s) {
	return s.gO;
};
var $author$project$BigO$TypesAsStrings$showSampleCount = function (cnt) {
	return $author$project$ElmCommon$HtmlCommon$addCommaToLargeNumberString(
		$elm$core$String$fromInt(cnt.gB));
};
var $author$project$BigO$TypesAsStrings$showSamplePeriodUs32To = function (_v0) {
	var us = _v0;
	var freq_Hz = 1000000 / us;
	return $elm$core$String$fromInt(us) + ('us' + (' (' + (A2($ggb$numeral_elm$Numeral$format, '00', freq_Hz) + 'Hz)')));
};
var $author$project$BigO$TypesAsStrings$showSensorDataType = function (s) {
	return $elm$core$String$fromInt(s.g3);
};
var $author$project$BigO$TypesAsStrings$showSensorIdx = function (s) {
	return 'S-' + $elm$core$String$fromInt(s.gP);
};
var $author$project$Pages$PageDetailsN96$buildN96SensorSummary = F3(
	function (mdl, n96Meta, n96Sensor) {
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Grid$container,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('n80-form')
				]),
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$row,
					_List_fromArray(
						[$rundis$elm_bootstrap$Bootstrap$Grid$Row$topXs]),
					_List_fromArray(
						[
							A2(
							$rundis$elm_bootstrap$Bootstrap$Grid$col,
							_List_fromArray(
								[$rundis$elm_bootstrap$Bootstrap$Grid$Col$xs2]),
							_List_fromArray(
								[
									$author$project$ElmCommon$Utils$bold(
									$elm$html$Html$text(
										'Sensor-' + ($author$project$BigO$TypesAsStrings$showSensorIdx(n96Sensor.f1) + ':')))
								])),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Grid$col,
							_List_fromArray(
								[$rundis$elm_bootstrap$Bootstrap$Grid$Col$xs4]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$BigO$TypesAsStrings$showS104Desc(n96Sensor.f6) + ('[' + ($author$project$BigO$TypesAsStrings$showSensorDataType(n96Sensor.f5) + ']')))
								])),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Grid$col,
							_List_fromArray(
								[$rundis$elm_bootstrap$Bootstrap$Grid$Col$xs2]),
							_List_fromArray(
								[
									$author$project$ElmCommon$Utils$bold(
									$elm$html$Html$text('Period:'))
								])),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Grid$col,
							_List_fromArray(
								[$rundis$elm_bootstrap$Bootstrap$Grid$Col$xs4]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$BigO$TypesAsStrings$showSamplePeriodUs32To(n96Sensor.f8))
								]))
						])),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$row,
					_List_fromArray(
						[$rundis$elm_bootstrap$Bootstrap$Grid$Row$topXs]),
					_List_fromArray(
						[
							A2(
							$rundis$elm_bootstrap$Bootstrap$Grid$col,
							_List_fromArray(
								[$rundis$elm_bootstrap$Bootstrap$Grid$Col$xs2]),
							_List_fromArray(
								[
									$author$project$ElmCommon$Utils$bold(
									$elm$html$Html$text('Value:'))
								])),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Grid$col,
							_List_fromArray(
								[$rundis$elm_bootstrap$Bootstrap$Grid$Col$xs4]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$BigO$TypesAsStrings$getSampleValue(n96Sensor.f7) + (' ' + n96Sensor.gb))
								])),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Grid$col,
							_List_fromArray(
								[$rundis$elm_bootstrap$Bootstrap$Grid$Col$xs2]),
							_List_fromArray(
								[
									$author$project$ElmCommon$Utils$bold(
									$elm$html$Html$text('Samples:'))
								])),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Grid$col,
							_List_fromArray(
								[$rundis$elm_bootstrap$Bootstrap$Grid$Col$xs4]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$BigO$TypesAsStrings$showSampleCount(n96Sensor.ga))
								]))
						])),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$row,
					_List_fromArray(
						[$rundis$elm_bootstrap$Bootstrap$Grid$Row$topXs]),
					_List_fromArray(
						[
							A2(
							$rundis$elm_bootstrap$Bootstrap$Grid$col,
							_List_fromArray(
								[$rundis$elm_bootstrap$Bootstrap$Grid$Col$xs2]),
							_List_Nil),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Grid$col,
							_List_fromArray(
								[$rundis$elm_bootstrap$Bootstrap$Grid$Col$xs4]),
							_List_Nil),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Grid$col,
							_List_fromArray(
								[$rundis$elm_bootstrap$Bootstrap$Grid$Col$xs2]),
							_List_fromArray(
								[
									$author$project$ElmCommon$Utils$bold(
									$elm$html$Html$text('Download:'))
								])),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Grid$col,
							_List_fromArray(
								[$rundis$elm_bootstrap$Bootstrap$Grid$Col$xs4]),
							_List_fromArray(
								[
									A3($author$project$Pages$PageDetailsN96$buildDownloadDropDownComplete, mdl, n96Meta, n96Sensor)
								]))
						]))
				]));
	});
var $author$project$Pages$PageDetailsN96$buildN96SummaryPaneSensors = F2(
	function (mdl, n96Meta) {
		var sensor_metas = n96Meta.e8;
		var sensor_rows = A2(
			$elm$core$List$map,
			A2($author$project$Pages$PageDetailsN96$buildN96SensorSummary, mdl, n96Meta),
			sensor_metas);
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Grid$container,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$container,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('n80-form')
						]),
					sensor_rows)
				]));
	});
var $author$project$BigO$TypesAsStrings$n96MetadataHumanReadable = function (n) {
	var str_idx = 'Recording: ' + $author$project$BigO$TypesAsStrings$showU16(
		$author$project$BigO$TypesAsStrings$splitN96(n.e2).b);
	return str_idx;
};
var $author$project$Pages$PageDetailsN96$pageDetailsN96 = F3(
	function (mdl, cmn, n96) {
		var n96Metadata = $author$project$NodeDataStore$mdlGetN96MetaFull(mdl.fm);
		var n96EditStateM = mdl.dO;
		var _v0 = $author$project$BigO$TypesAsStrings$splitN96(n96);
		var n80 = _v0.a;
		var u16 = _v0.b;
		var n80MetaM = A2($author$project$NodeDataStore$mdlGetN80Meta, mdl.fm, n80);
		var _v1 = _Utils_Tuple3(n96EditStateM, n80MetaM, n96Metadata);
		if (((!_v1.a.$) && (!_v1.b.$)) && (!_v1.c.$)) {
			var editStatePageDetailsN96 = _v1.a.a;
			var n80Meta = _v1.b.a;
			var n96MetadataFull = _v1.c.a;
			return _List_fromArray(
				[
					A2(
					$author$project$ElmCommon$HtmlCommon$dashboardElemTitledDiv_,
					$author$project$BigO$TypesAsStrings$n96MetadataHumanReadable(n96MetadataFull.e1),
					_List_fromArray(
						[
							A3(
							$author$project$Pages$PageDetailsN96$buildN96SummaryPaneOverview,
							mdl,
							editStatePageDetailsN96,
							_Utils_Tuple2(n80Meta, n96MetadataFull.e1))
						])),
					A2(
					$author$project$ElmCommon$HtmlCommon$dashboardElemTitledDiv_,
					'Sensors',
					_List_fromArray(
						[
							A2($author$project$Pages$PageDetailsN96$buildN96SummaryPaneSensors, mdl, n96MetadataFull.e1)
						]))
				]);
		} else {
			return $elm$core$List$singleton(
				$elm$html$Html$text('Could not find N96 metadata'));
		}
	});
var $author$project$BigO$TypesAsStrings$showParcelUpDataPayloadType = function (typ) {
	switch (typ) {
		case 0:
			return 'ParcelUpDataTypeRecording';
		case 1:
			return 'ParcelUpDataTypeNodeEventLog';
		case 2:
			return 'ParcelUpDataTypeTimesyncData';
		default:
			return 'ParcelUpDataTypeUpCtxEnd';
	}
};
var $author$project$BigO$TypesAsStrings$showParcelUpProcessingState = function (state) {
	if (!state) {
		return 'ReadyToProcess';
	} else {
		return 'Complete';
	}
};
var $author$project$BigO$Utils$stringFromBool = function (value) {
	return value ? 'True' : 'False';
};
var $author$project$Pages$PageDetailsN96DevDownloads$buildParcelsRow = F2(
	function (mdl, parcelData) {
		var cols = _List_fromArray(
			[
				A2(
				$rundis$elm_bootstrap$Bootstrap$Table$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$elm$core$String$fromInt(parcelData.fs.gx))
					])),
				A2(
				$rundis$elm_bootstrap$Bootstrap$Table$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$BigO$TimeUtils$toUtcStringDateTime(parcelData.fx))
					])),
				A2(
				$rundis$elm_bootstrap$Bootstrap$Table$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$BigO$TypesAsStrings$showParcelUpProcessingState(parcelData.fv))
					])),
				A2(
				$rundis$elm_bootstrap$Bootstrap$Table$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$BigO$Utils$stringFromBool(parcelData.fw))
					])),
				A2(
				$rundis$elm_bootstrap$Bootstrap$Table$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$BigO$TypesAsStrings$showParcelUpDataPayloadType(parcelData.fu))
					]))
			]);
		return A2($rundis$elm_bootstrap$Bootstrap$Table$tr, _List_Nil, cols);
	});
var $author$project$Pages$PageDetailsN96DevDownloads$buildParcelsTable = F2(
	function (mdl, parcelData) {
		return $rundis$elm_bootstrap$Bootstrap$Table$table(
			{
				it: _List_fromArray(
					[$rundis$elm_bootstrap$Bootstrap$Table$striped, $rundis$elm_bootstrap$Bootstrap$Table$hover, $rundis$elm_bootstrap$Bootstrap$Table$small]),
				iN: A2(
					$rundis$elm_bootstrap$Bootstrap$Table$tbody,
					_List_Nil,
					A2(
						$elm$core$List$map,
						$author$project$Pages$PageDetailsN96DevDownloads$buildParcelsRow(mdl),
						parcelData)),
				iO: A2($rundis$elm_bootstrap$Bootstrap$Table$thead, _List_Nil, _List_Nil)
			});
	});
var $author$project$Pages$PageDetailsN96DevDownloads$buildDevDetails = F2(
	function (mdl, n96MetaFull) {
		return _List_fromArray(
			[
				A2($author$project$ElmCommon$HtmlCommon$dashboardElemTitledDiv_, 'DevDetails', _List_Nil),
				A2(
				$author$project$ElmCommon$HtmlCommon$dashboardElemTitledDiv_,
				'Parcels',
				_List_fromArray(
					[
						A2($author$project$Pages$PageDetailsN96DevDownloads$buildParcelsTable, mdl, n96MetaFull.e$.fy)
					]))
			]);
	});
var $author$project$Pages$PageDetailsN96DevDownloads$pageDetailsN96DevDownloads = F3(
	function (mdl, cmn, n96) {
		var _v0 = mdl.fm.da;
		if ((!_v0.$) && (_v0.a.$ === 1)) {
			if (_v0.a.a.$ === 1) {
				var _v1 = _v0.a.a;
				return $elm$core$List$singleton(
					$elm$html$Html$text('No data??'));
			} else {
				var n96MetaFull = _v0.a.a.a;
				return A2($author$project$Pages$PageDetailsN96DevDownloads$buildDevDetails, mdl, n96MetaFull);
			}
		} else {
			return $elm$core$List$singleton(
				$elm$html$Html$text('Loading....'));
		}
	});
var $author$project$Pages$PageDetailsN96PartialDownloads$flatten2 = F2(
	function (m, _v0) {
		var s16 = _v0.a;
		var files = _v0.b;
		return _Utils_Tuple2(
			_Utils_Tuple2(m, s16),
			files);
	});
var $author$project$Pages$PageDetailsN96PartialDownloads$flatten = function (_v0) {
	var m = _v0.a;
	var sensorPartials = _v0.b;
	return A2(
		$elm$core$List$map,
		$author$project$Pages$PageDetailsN96PartialDownloads$flatten2(m),
		sensorPartials);
};
var $author$project$Pages$PageDetailsN96PartialDownloads$allDataGroupedRM = function (cloudN96MetadataPartialDownloads) {
	return $elm$core$List$concat(
		A2($elm$core$List$map, $author$project$Pages$PageDetailsN96PartialDownloads$flatten, cloudN96MetadataPartialDownloads.fz));
};
var $author$project$Pages$PageDetailsN96PartialDownloads$allDataGroupedR = function (mdl) {
	var _v0 = mdl.fm.da;
	if (_v0.$ === 1) {
		return _List_Nil;
	} else {
		if (_v0.a.$ === 1) {
			if (_v0.a.a.$ === 1) {
				var _v1 = _v0.a.a;
				return _List_Nil;
			} else {
				var n96MetaFull = _v0.a.a.a;
				return $author$project$Pages$PageDetailsN96PartialDownloads$allDataGroupedRM(n96MetaFull.e0);
			}
		} else {
			var otherwise = _v0;
			return _List_Nil;
		}
	}
};
var $author$project$Msg$OnClickCloudDownloadRequest = function (a) {
	return {$: 28, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Button$secondary = $rundis$elm_bootstrap$Bootstrap$Internal$Button$Coloring(
	$rundis$elm_bootstrap$Bootstrap$Internal$Button$Roled(1));
var $rundis$elm_bootstrap$Bootstrap$Button$warning = $rundis$elm_bootstrap$Bootstrap$Internal$Button$Coloring(
	$rundis$elm_bootstrap$Bootstrap$Internal$Button$Roled(4));
var $author$project$Pages$PageDetailsN96PartialDownloads$buildButtonDownloadPartial = function (link) {
	var typeStr = $author$project$BigO$TypesAsStrings$showDownloadOutputType(link.eq);
	var btnText = function () {
		var _v1 = link.es;
		switch (_v1) {
			case 0:
				return 'Request: ' + typeStr;
			case 1:
				return '<Requested>';
			case 2:
				return '<InProgress>';
			case 3:
				return 'Download: ' + typeStr;
			default:
				return 'Error';
		}
	}();
	var btnAttrsRequestDownload = _List_fromArray(
		[
			$rundis$elm_bootstrap$Bootstrap$Button$secondary,
			$rundis$elm_bootstrap$Bootstrap$Button$attrs(
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('float-right')
				])),
			$rundis$elm_bootstrap$Bootstrap$Button$onClick(
			$author$project$Msg$OnClickCloudDownloadRequest(link))
		]);
	var btnAttrsDownload = _List_fromArray(
		[
			$rundis$elm_bootstrap$Bootstrap$Button$primary,
			$rundis$elm_bootstrap$Bootstrap$Button$attrs(
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('float-right')
				])),
			$rundis$elm_bootstrap$Bootstrap$Button$onClick(
			$author$project$Msg$OnClickCloudDownloadLink(link))
		]);
	var btnAttrsDisabled = _List_fromArray(
		[
			$rundis$elm_bootstrap$Bootstrap$Button$warning,
			$rundis$elm_bootstrap$Bootstrap$Button$attrs(
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('disabled')
				]))
		]);
	var btnAttrs = function () {
		var _v0 = link.es;
		switch (_v0) {
			case 0:
				return btnAttrsRequestDownload;
			case 1:
				return btnAttrsDisabled;
			case 2:
				return btnAttrsDisabled;
			case 3:
				return btnAttrsDownload;
			default:
				return btnAttrsDisabled;
		}
	}();
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Button$button,
		btnAttrs,
		_List_fromArray(
			[
				$elm$html$Html$text(btnText)
			]));
};
var $author$project$Pages$PageDetailsN96PartialDownloads$buildDownloadCell = function (downloadInfoM) {
	if (downloadInfoM.$ === 1) {
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Table$td,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('MISSING')
				]));
	} else {
		var downloadInfo = downloadInfoM.a;
		var download_info = downloadInfo.gY;
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Table$td,
			_List_Nil,
			_List_fromArray(
				[
					$author$project$Pages$PageDetailsN96PartialDownloads$buildButtonDownloadPartial(download_info)
				]));
	}
};
var $author$project$BigO$TypesAsStrings$fromHexStrN96 = function (n96HexStr) {
	return A2(
		$elm$core$Maybe$map,
		$elm$core$Basics$identity,
		$cmditch$elm_bigint$BigInt$fromHexString(n96HexStr));
};
var $author$project$BigO$TypesAsStrings$joinN96 = function (_v0) {
	var n80 = _v0.a;
	var u16 = _v0.b;
	var hU16 = A3(
		$elm$core$String$padLeft,
		4,
		'0',
		$rtfeldman$elm_hex$Hex$toString(u16));
	var hN80 = $author$project$BigO$TypesAsStrings$toHexStrN80(n80);
	var hN96 = _Utils_ap(hN80, hU16);
	return A2(
		$elm$core$Maybe$withDefault,
		$cmditch$elm_bigint$BigInt$fromInt(0),
		$author$project$BigO$TypesAsStrings$fromHexStrN96(hN96));
};
var $author$project$BigO$TypesAsStrings$showN96MsgUpIdx32Recording = function (nr32) {
	return 'NR-' + $elm$core$String$fromInt(nr32.g0);
};
var $author$project$Pages$PageDetailsN96PartialDownloads$buildDownloadRow = F2(
	function (outputOrder, _v0) {
		var _v1 = _v0.a;
		var recBlkMeta = _v1.a;
		var s16 = _v1.b;
		var links = _v0.b;
		var nr32 = recBlkMeta.fU;
		var n96 = $author$project$BigO$TypesAsStrings$joinN96(
			_Utils_Tuple2(recBlkMeta.fP, recBlkMeta.fZ));
		var linkDict = A2(
			$turboMaCk$any_dict$Dict$Any$fromList,
			$author$project$BigO$TypesAsStrings$showDownloadOutputType,
			A2(
				$elm$core$List$map,
				function (c) {
					return _Utils_Tuple2(c.gY.eq, c);
				},
				links));
		var cells_hdr = _List_fromArray(
			[
				A2(
				$rundis$elm_bootstrap$Bootstrap$Table$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$BigO$TypesAsStrings$showSensorIdx(s16))
					])),
				A2(
				$rundis$elm_bootstrap$Bootstrap$Table$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$BigO$TypesAsStrings$showN96MsgUpIdx32Recording(nr32))
					])),
				A2(
				$rundis$elm_bootstrap$Bootstrap$Table$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						A2($author$project$BigO$TimeUtils$toUtcStringDateTimeMaybe, '??', recBlkMeta.fX.a))
					])),
				A2(
				$rundis$elm_bootstrap$Bootstrap$Table$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						A2($author$project$BigO$TypesAsStrings$durationStrHumanMaybe, recBlkMeta.fX.a, recBlkMeta.fX.b))
					]))
			]);
		var cells_download = A2(
			$elm$core$List$map,
			$author$project$Pages$PageDetailsN96PartialDownloads$buildDownloadCell,
			A2(
				$elm$core$List$map,
				function (typ) {
					return A2($turboMaCk$any_dict$Dict$Any$get, typ, linkDict);
				},
				outputOrder));
		var row = A2(
			$rundis$elm_bootstrap$Bootstrap$Table$tr,
			_List_Nil,
			_Utils_ap(cells_hdr, cells_download));
		return row;
	});
var $author$project$Pages$PageDetailsN96PartialDownloads$buildDownloadTable = function (mdl) {
	var outputTypes = _List_fromArray(
		[0, 1, 2, 3]);
	var all_data_grouped = $author$project$Pages$PageDetailsN96PartialDownloads$allDataGroupedR(mdl);
	var rows = A2(
		$elm$core$List$map,
		$author$project$Pages$PageDetailsN96PartialDownloads$buildDownloadRow(outputTypes),
		all_data_grouped);
	var table_ = $rundis$elm_bootstrap$Bootstrap$Table$table(
		{
			it: _List_fromArray(
				[$rundis$elm_bootstrap$Bootstrap$Table$striped, $rundis$elm_bootstrap$Bootstrap$Table$hover, $rundis$elm_bootstrap$Bootstrap$Table$small]),
			iN: A2($rundis$elm_bootstrap$Bootstrap$Table$tbody, _List_Nil, rows),
			iO: A2($rundis$elm_bootstrap$Bootstrap$Table$thead, _List_Nil, _List_Nil)
		});
	return table_;
};
var $author$project$Pages$PageDetailsN96PartialDownloads$pageDetailsN96PartialDownloads = F3(
	function (mdl, cmn, n96) {
		var n96MetaFullM = $author$project$NodeDataStore$mdlGetN96MetaFull(mdl.fm);
		if (n96MetaFullM.$ === 1) {
			return $elm$core$List$singleton(
				$elm$html$Html$text('N96-Downloads - metadata not loaded??!'));
		} else {
			var n96MetaFull = n96MetaFullM.a;
			return _List_fromArray(
				[
					A2(
					$author$project$ElmCommon$HtmlCommon$dashboardElemTitledDiv_,
					'Partial files:' + ($author$project$BigO$TypesAsStrings$toHexStrN96(n96) + (' ' + $author$project$BigO$TypesAsStrings$n96MetadataHumanReadable(n96MetaFull.e1))),
					_List_fromArray(
						[
							$author$project$Pages$PageDetailsN96PartialDownloads$buildDownloadTable(mdl)
						]))
				]);
		}
	});
var $author$project$Msg$UiUpdatePageDetailsUserGroup_UserGroupMembershipTable = function (a) {
	return {$: 17, a: a};
};
var $author$project$Msg$UiUpdatePageDetailsUserGroup_UserGroupToN80AccessTable = function (a) {
	return {$: 18, a: a};
};
var $author$project$Components$UserGroupUserMembershipTable$UserGroupMembershipTableColUserActions = 2;
var $author$project$Components$UserGroupUserMembershipTable$UserGroupMembershipTableColUserAuthLevel = 1;
var $author$project$Components$UserGroupUserMembershipTable$UserGroupMembershipTableColUserEmail = 0;
var $author$project$Components$UserGroupToN80GroupAccessTableTypes$UserGroupToN80GroupAccessTableColAccess = 1;
var $author$project$Components$UserGroupToN80GroupAccessTableTypes$UserGroupToN80GroupAccessTableColActions = 2;
var $author$project$Components$UserGroupToN80GroupAccessTableTypes$UserGroupToN80GroupAccessTableColN80GroupName = 0;
var $author$project$Model$ModelExtractAdmin$getUserN80GroupById = F2(
	function (mdl, n80GroupId) {
		var _v0 = mdl.c3;
		if (_v0.$ === 1) {
			return $elm$core$Maybe$Nothing;
		} else {
			var auth_user_groups = _v0.a;
			return $elm$core$List$head(
				A2(
					$elm$core$List$map,
					function (_v2) {
						var _v3 = _v2.b;
						var grp = _v3.a;
						return grp;
					},
					A2(
						$elm$core$List$filter,
						function (_v1) {
							var o = _v1.a;
							return _Utils_eq(o, n80GroupId);
						},
						auth_user_groups.dW.dT)));
		}
	});
var $author$project$Components$UserGroupToN80GroupAccessTableTypes$UserGroupToN80GroupAccessTableMsgConnectN80Group = F3(
	function (a, b, c) {
		return {$: 4, a: a, b: b, c: c};
	});
var $author$project$Components$UserGroupToN80GroupAccessTableTypes$UserGroupToN80GroupAccessTableMsgUpdateAuth = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$UserGroupToN80GroupAccessTableTypes$UserGroupToN80GroupAccessTableMsgUpdateAutocomplete = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$UserGroupToN80GroupAccessTableTypes$UserGroupToN80GroupAccessTableMsgDisconnectN80Group = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Components$UserGroupToN80GroupAccessTableTypes$UserGroupToN80GroupAccessTableMsgGlobalAction = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$UserGroupToN80GroupAccessTable$buildN80GroupAccessTableCell = F5(
	function (cmn, tblData, userGroup, _v0, col) {
		var n80GrpId = _v0.a;
		var auth = _v0.b;
		switch (col) {
			case 0:
				var m = function () {
					var _v2 = tblData.h4(n80GrpId);
					if (_v2.$ === 1) {
						return $elm$html$Html$text('Missing n80 group?');
					} else {
						var n80Grp = _v2.a;
						return A2(
							$elm$html$Html$map,
							$author$project$Components$UserGroupToN80GroupAccessTableTypes$UserGroupToN80GroupAccessTableMsgGlobalAction,
							$author$project$Components$CommonPageComponents$buildElemN80GroupStd(n80Grp));
					}
				}();
				return m;
			case 1:
				return $author$project$Components$CommonPageComponents$buildAccessLevel(auth.gw);
			default:
				var userGroupId = tblData.aC.db;
				var userGroup_admin = _Utils_eq(
					cmn.i$(userGroupId),
					$elm$core$Maybe$Just(1));
				var n80Group_admin = _Utils_eq(
					cmn.ik(n80GrpId),
					$author$project$Api$AccessLevelGrantedForN80Group$AccessLevelGrantedForN80Group(3));
				var deleteBtn = $author$project$Components$CommonPageComponents$iconBtnDelete(
					A2($author$project$Components$UserGroupToN80GroupAccessTableTypes$UserGroupToN80GroupAccessTableMsgDisconnectN80Group, userGroup.db, n80GrpId));
				var can_delete = userGroup_admin && n80Group_admin;
				return can_delete ? deleteBtn : $author$project$BigO$Utils$htmlEmpty;
		}
	});
var $author$project$Components$UserGroupToN80GroupAccessTable$buildN80GroupAccessTableHdr = function (col) {
	switch (col) {
		case 0:
			return $elm$html$Html$text('N80-Groupname');
		case 1:
			return $elm$html$Html$text('Access');
		default:
			return $elm$html$Html$text('Action');
	}
};
var $author$project$Components$UserGroupToN80GroupAccessTable$pageUserAccessUserGroupDetailsN80GroupsTable_ = F4(
	function (cmn, cfg, pageState, tblData) {
		var userGroupId = tblData.aC.db;
		var n80group_auth_ = $author$project$ElmCommon$Dropdown$buildDropdownWidget(pageState.cf);
		var n80group_auth = A2($elm$html$Html$map, $author$project$Components$UserGroupToN80GroupAccessTableTypes$UserGroupToN80GroupAccessTableMsgUpdateAuth, n80group_auth_);
		var n80group_autcomplete = A2(
			$elm$html$Html$map,
			$author$project$Components$UserGroupToN80GroupAccessTableTypes$UserGroupToN80GroupAccessTableMsgUpdateAutocomplete,
			$author$project$ElmCommon$Autocomplete$buildAutoCompleteWidget(pageState.cg));
		var cols = cfg.hq;
		var thead_ = A2(
			$rundis$elm_bootstrap$Bootstrap$Table$thead,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Table$tr,
					_List_Nil,
					A2(
						$elm$core$List$map,
						function (h) {
							return A2(
								$rundis$elm_bootstrap$Bootstrap$Table$td,
								_List_Nil,
								_List_fromArray(
									[
										$author$project$Components$UserGroupToN80GroupAccessTable$buildN80GroupAccessTableHdr(h)
									]));
						},
						cols))
				]));
		var can_create = true;
		var buildN80GroupRow = function (_v2) {
			var n80GroupId = _v2.a;
			var auth = _v2.b;
			return $author$project$Utils$htmlsToRow(
				A2(
					$elm$core$List$map,
					A4(
						$author$project$Components$UserGroupToN80GroupAccessTable$buildN80GroupAccessTableCell,
						cmn,
						tblData,
						tblData.aC,
						_Utils_Tuple2(n80GroupId, auth)),
					cols));
		};
		var userRows = A2($elm$core$List$map, buildN80GroupRow, tblData.aC.df);
		var tbody = A2($rundis$elm_bootstrap$Bootstrap$Table$tbody, _List_Nil, userRows);
		var tbl = $elm$core$Maybe$Just(
			$rundis$elm_bootstrap$Bootstrap$Table$table(
				{
					it: _List_fromArray(
						[$rundis$elm_bootstrap$Bootstrap$Table$striped, $rundis$elm_bootstrap$Bootstrap$Table$hover, $rundis$elm_bootstrap$Bootstrap$Table$small]),
					iN: tbody,
					iO: thead_
				}));
		var add_row = function () {
			if (!can_create) {
				return $elm$core$Maybe$Nothing;
			} else {
				var add_button = function () {
					var _v1 = $author$project$ElmCommon$Autocomplete$autoCompleteElementSelected(pageState.cg);
					if (_v1.$ === 1) {
						return _List_Nil;
					} else {
						var n80Group = _v1.a;
						var add_action = A3($author$project$Components$UserGroupToN80GroupAccessTableTypes$UserGroupToN80GroupAccessTableMsgConnectN80Group, tblData.aC.db, n80Group.dh, pageState.cf.iC);
						return _List_fromArray(
							[
								$author$project$Components$CommonPageComponents$iconBtnAdd(add_action)
							]);
					}
				}();
				var add_row_ = A2(
					$rundis$elm_bootstrap$Bootstrap$Form$row,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rundis$elm_bootstrap$Bootstrap$Form$col,
							_List_fromArray(
								[$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm8]),
							_List_fromArray(
								[n80group_autcomplete])),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Form$col,
							_List_fromArray(
								[$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm2]),
							_List_fromArray(
								[n80group_auth])),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Form$col,
							_List_fromArray(
								[$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm1]),
							_List_Nil),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Form$col,
							_List_fromArray(
								[$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm1]),
							add_button)
						]));
				return $elm$core$Maybe$Just(add_row_);
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			$author$project$ElmCommon$Utils$catMaybes(
				_List_fromArray(
					[tbl, add_row])));
	});
var $author$project$Pages$PageDetailsUserGroup$pageUserAccessUserGroupDetailsSummaryTable_ = function (_v0) {
	var authLevel = _v0.a;
	var cloudUserGroup = _v0.b;
	return $author$project$BigO$Common$buildPropertyTable(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'Group-Name',
				$elm$html$Html$text(cloudUserGroup.dc.gT)),
				_Utils_Tuple2(
				'Access',
				$author$project$Components$CommonPageComponents$buildUserGroupMembershipType(authLevel))
			]));
};
var $author$project$Components$UserGroupUserMembershipTableTypes$UserGroupMembershipTableMsgActionAddUser = F3(
	function (a, b, c) {
		return {$: 3, a: a, b: b, c: c};
	});
var $author$project$Components$UserGroupUserMembershipTableTypes$UserGroupMembershipTableMsgUpdateEmail = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$UserGroupUserMembershipTableTypes$UserGroupMembershipTableMsgActionRemoveUser = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$Components$UserGroupUserMembershipTable$buildUserGroupMembershipCell = F3(
	function (userGroup, _v0, col) {
		var email = _v0.a;
		var auth = _v0.b;
		switch (col) {
			case 0:
				return $elm$html$Html$text(email.gR);
			case 1:
				return $author$project$Components$CommonPageComponents$buildUserGroupMembershipType(auth);
			default:
				return $author$project$Components$CommonPageComponents$iconBtnDelete(
					A2($author$project$Components$UserGroupUserMembershipTableTypes$UserGroupMembershipTableMsgActionRemoveUser, userGroup.db, email));
		}
	});
var $author$project$Components$UserGroupUserMembershipTable$buildUserGroupMembershipHdr = function (col) {
	switch (col) {
		case 0:
			return $elm$html$Html$text('Email');
		case 1:
			return $elm$html$Html$text('Auth');
		default:
			return $elm$html$Html$text('Actions');
	}
};
var $author$project$Components$UserGroupUserMembershipTable$pageUserAccessUserGroupDetailsUsersTable = F4(
	function (cmn, cfg, pageState, cloudUserGroup) {
		var thead_ = A2(
			$rundis$elm_bootstrap$Bootstrap$Table$thead,
			_List_Nil,
			$elm$core$List$singleton(
				A2(
					$rundis$elm_bootstrap$Bootstrap$Table$tr,
					_List_Nil,
					A2(
						$elm$core$List$map,
						function (c) {
							return A2(
								$rundis$elm_bootstrap$Bootstrap$Table$td,
								_List_Nil,
								_List_fromArray(
									[c]));
						},
						A2($elm$core$List$map, $author$project$Components$UserGroupUserMembershipTable$buildUserGroupMembershipHdr, cfg.hq)))));
		var groupAddUserAutocomplete = A2(
			$elm$html$Html$map,
			$author$project$Components$UserGroupUserMembershipTableTypes$UserGroupMembershipTableMsgUpdateEmail,
			$author$project$ElmCommon$Autocomplete$buildAutoCompleteWidget(pageState.cc));
		var groupAddUserAccessDropDown_ = $author$project$ElmCommon$Dropdown$buildDropdownWidget(pageState.cV);
		var groupAddUserAccessDropDown = A2($elm$html$Html$map, $author$project$Components$UserGroupUserMembershipTableTypes$UserGroupMembershipTableMsgSetAuth, groupAddUserAccessDropDown_);
		var can_add_user = _Utils_eq(
			cmn.i$(cloudUserGroup.db),
			$elm$core$Maybe$Just(1));
		var buildUserRow = function (_v2) {
			var email = _v2.a;
			var auth = _v2.b;
			return $author$project$Utils$htmlsToRow(
				A2(
					$elm$core$List$map,
					A2(
						$author$project$Components$UserGroupUserMembershipTable$buildUserGroupMembershipCell,
						cloudUserGroup,
						_Utils_Tuple2(email, auth)),
					cfg.hq));
		};
		var userRows = A2($elm$core$List$map, buildUserRow, cloudUserGroup.de);
		var tbody = A2($rundis$elm_bootstrap$Bootstrap$Table$tbody, _List_Nil, userRows);
		var tbl = $elm$core$Maybe$Just(
			$rundis$elm_bootstrap$Bootstrap$Table$table(
				{
					it: _List_fromArray(
						[$rundis$elm_bootstrap$Bootstrap$Table$striped, $rundis$elm_bootstrap$Bootstrap$Table$hover, $rundis$elm_bootstrap$Bootstrap$Table$small]),
					iN: tbody,
					iO: thead_
				}));
		var add_row = function () {
			if (!can_add_user) {
				return $elm$core$Maybe$Nothing;
			} else {
				var add_button_enabled = pageState.cc.iG !== '';
				var add_action = A3(
					$author$project$Components$UserGroupUserMembershipTableTypes$UserGroupMembershipTableMsgActionAddUser,
					cloudUserGroup.db,
					$author$project$Api$UserEmail$UserEmail(pageState.cc.iG),
					pageState.cV.iC);
				var add_button = function () {
					if (!add_button_enabled) {
						return _List_Nil;
					} else {
						return _List_fromArray(
							[
								$author$project$Components$CommonPageComponents$iconBtnAdd(add_action)
							]);
					}
				}();
				var add_row_ = A2(
					$rundis$elm_bootstrap$Bootstrap$Form$row,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rundis$elm_bootstrap$Bootstrap$Form$col,
							_List_fromArray(
								[$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm8]),
							_List_fromArray(
								[groupAddUserAutocomplete])),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Form$col,
							_List_fromArray(
								[$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm2]),
							_List_fromArray(
								[groupAddUserAccessDropDown])),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Form$col,
							_List_fromArray(
								[$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm1]),
							_List_Nil),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Form$col,
							_List_fromArray(
								[$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm1]),
							add_button)
						]));
				return $elm$core$Maybe$Just(add_row_);
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			$author$project$ElmCommon$Utils$catMaybes(
				_List_fromArray(
					[tbl, add_row])));
	});
var $author$project$Pages$PageDetailsUserGroup$pageUserAccessUserGroupDetails_ = F4(
	function (mdl, cmn, pageState, _v0) {
		var authLevel = _v0.a;
		var cloudUserGroup = _v0.b;
		var usergroup_summary_table = $author$project$Pages$PageDetailsUserGroup$pageUserAccessUserGroupDetailsSummaryTable_(
			_Utils_Tuple2(authLevel, cloudUserGroup));
		var usergroup_n80group_access_table_data = {
			h4: $author$project$Model$ModelExtractAdmin$getUserN80GroupById(mdl),
			aC: cloudUserGroup
		};
		var usergroup_n80group_access_table_cfg = {
			hq: _List_fromArray(
				[0, 1, 2])
		};
		var usergroup_n80group_access_table = A4($author$project$Components$UserGroupToN80GroupAccessTable$pageUserAccessUserGroupDetailsN80GroupsTable_, cmn, usergroup_n80group_access_table_cfg, pageState.dR, usergroup_n80group_access_table_data);
		var usergroup_membership_table_cfg = {
			hq: _List_fromArray(
				[0, 1, 2])
		};
		var usergroup_membership_table = A4($author$project$Components$UserGroupUserMembershipTable$pageUserAccessUserGroupDetailsUsersTable, cmn, usergroup_membership_table_cfg, pageState.hI, cloudUserGroup);
		return _List_fromArray(
			[
				A2(
				$author$project$ElmCommon$HtmlCommon$dashboardElemTitledDiv_,
				'UserGroup Overview',
				_List_fromArray(
					[usergroup_summary_table])),
				A2(
				$author$project$ElmCommon$HtmlCommon$dashboardElemTitledDiv_,
				'UserGroup Members',
				_List_fromArray(
					[
						A2(
						$elm$html$Html$map,
						A2($elm$core$Basics$composeL, $author$project$Msg$UiPageUpdate, $author$project$Msg$UiUpdatePageDetailsUserGroup_UserGroupMembershipTable),
						usergroup_membership_table)
					])),
				A2(
				$author$project$ElmCommon$HtmlCommon$dashboardElemTitledDiv_,
				'Accessible N80 Groups',
				_List_fromArray(
					[
						A2(
						$elm$html$Html$map,
						A2($elm$core$Basics$composeL, $author$project$Msg$UiPageUpdate, $author$project$Msg$UiUpdatePageDetailsUserGroup_UserGroupToN80AccessTable),
						usergroup_n80group_access_table)
					]))
			]);
	});
var $author$project$Pages$PageDetailsUserGroup$pageUserAccessUserGroup_ = F4(
	function (mdl, cmn, editState, cloudGroupUserId) {
		var adminDataLookup = function () {
			var _v1 = mdl.c0;
			if (_v1.$ === 1) {
				return $elm$core$Maybe$Nothing;
			} else {
				var auth_admin_data = _v1.a;
				return $elm$core$List$head(
					A2(
						$elm$core$List$map,
						function (g) {
							return _Utils_Tuple2(1, g);
						},
						A2(
							$elm$core$List$filter,
							function (g) {
								return _Utils_eq(g.db, cloudGroupUserId);
							},
							auth_admin_data.cY)));
			}
		}();
		var cloudGroupM = adminDataLookup;
		if (cloudGroupM.$ === 1) {
			return _List_fromArray(
				[
					A2(
					$author$project$ElmCommon$HtmlCommon$dashboardElemTitledDiv_,
					'FIX ME',
					_List_fromArray(
						[
							$elm$html$Html$text('Access denied')
						]))
				]);
		} else {
			var cloudGroup = cloudGroupM.a;
			return A4($author$project$Pages$PageDetailsUserGroup$pageUserAccessUserGroupDetails_, mdl, cmn, editState, cloudGroup);
		}
	});
var $author$project$Pages$PageDetailsUserGroup$pageDetailsUserGroup = F3(
	function (mdl, cmn, cloudGroupUserId) {
		var _v0 = mdl.dQ;
		if (_v0.$ === 1) {
			return _List_fromArray(
				[
					$elm$html$Html$text('Wait, wot. Not Connection.')
				]);
		} else {
			var es = _v0.a;
			return A4($author$project$Pages$PageDetailsUserGroup$pageUserAccessUserGroup_, mdl, cmn, es, cloudGroupUserId);
		}
	});
var $author$project$Pages$PageBase$pageError = function (mdl) {
	return A2(
		$elm$core$List$map,
		function (err) {
			return $elm$html$Html$text('An error occured: ' + err.iX);
		},
		mdl.d2);
};
var $author$project$Pages$PageHome$pageHome = function (mdl) {
	return $elm$core$List$singleton(
		A2($author$project$ElmCommon$HtmlCommon$dashboardElemTitledDiv_, 'HOMEPAGE', _List_Nil));
};
var $elm$html$Html$h1 = _VirtualDom_node('h1');
var $elm$url$Url$addPort = F2(
	function (maybePort, starter) {
		if (maybePort.$ === 1) {
			return starter;
		} else {
			var port_ = maybePort.a;
			return starter + (':' + $elm$core$String$fromInt(port_));
		}
	});
var $elm$url$Url$addPrefixed = F3(
	function (prefix, maybeSegment, starter) {
		if (maybeSegment.$ === 1) {
			return starter;
		} else {
			var segment = maybeSegment.a;
			return _Utils_ap(
				starter,
				_Utils_ap(prefix, segment));
		}
	});
var $elm$url$Url$toString = function (url) {
	var http = function () {
		var _v0 = url.fI;
		if (!_v0) {
			return 'http://';
		} else {
			return 'https://';
		}
	}();
	return A3(
		$elm$url$Url$addPrefixed,
		'#',
		url.d8,
		A3(
			$elm$url$Url$addPrefixed,
			'?',
			url.fJ,
			_Utils_ap(
				A2(
					$elm$url$Url$addPort,
					url.fD,
					_Utils_ap(http, url.ea)),
				url.fB)));
};
var $author$project$Pages$PageBase$pageNotFound = function (url) {
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$h1,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('Not found')
				])),
			$elm$html$Html$text(
			'Sorry couldn\'t find page' + $elm$url$Url$toString(url))
		]);
};
var $author$project$Msg$UiUpdatePageUserAccessUserGroupTable = function (a) {
	return {$: 11, a: a};
};
var $author$project$Components$UserGroupTable$allUserGroupTblCols = _List_fromArray(
	[0, 1, 2, 3, 4, 5]);
var $author$project$Msg$UiUpdatePageUserAccessN80GroupTable = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$PageUserAccess$buildN80GroupsDiv = F4(
	function (mdl, pageState, cmn, cloudGroups) {
		var data = {
			ib: A2($elm$core$List$map, $elm$core$Tuple$second, cloudGroups),
			ic: $author$project$NodeDataStore$mdlGetN80Meta(mdl.fm)
		};
		var cfg = {
			hh: cmn.hR.dY,
			hj: $author$project$Api$N80GroupOwnerEmail$N80GroupOwnerEmail(cmn.g5.dE),
			eY: _List_fromArray(
				[0, 3, 1, 2, 4, 5])
		};
		var tbl = A4($author$project$Components$N80GroupTable$buildN80GroupsTable, cmn, cfg, data, pageState.hG);
		var res = A2($elm$html$Html$map, $author$project$Msg$UiUpdatePageUserAccessN80GroupTable, tbl);
		return _List_fromArray(
			[
				A2($elm$html$Html$map, $author$project$Msg$UiPageUpdate, res)
			]);
	});
var $author$project$BigO$TypesAsStrings$showUserType_ = function (typ) {
	switch (typ) {
		case 0:
			return 'UserTypePublic';
		case 1:
			return 'UserTypeUser';
		default:
			return 'UserTypeAdmin';
	}
};
var $author$project$Components$CommonPageComponents$buildUserType = function (typ_) {
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Badge$pillSecondary,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text(
				$author$project$BigO$TypesAsStrings$showUserType_(typ_))
			]));
};
var $author$project$Pages$PageUserAccess$buildUserGroupsAuthSummary = function (mdl) {
	var contents = function () {
		var _v0 = mdl.c2;
		if (_v0.$ === 1) {
			return A2($elm$html$Html$div, _List_Nil, _List_Nil);
		} else {
			var dets = _v0.a;
			return $author$project$BigO$Common$buildPropertyTable(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'User name',
						$elm$html$Html$text(dets.dF.gV)),
						_Utils_Tuple2(
						'User email',
						$elm$html$Html$text(dets.dE.gR)),
						_Utils_Tuple2(
						'User AuthLevel',
						$author$project$Components$CommonPageComponents$buildUserType(dets.dG))
					]));
		}
	}();
	return _List_fromArray(
		[contents]);
};
var $author$project$Pages$PageUserAccess$buildUserGroupsN80ResolvedN80AuthDivTableHeader = function () {
	var build_th = function (t) {
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Table$th,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text(t)
				]));
	};
	var named_cols = _List_fromArray(
		[
			build_th('N80'),
			build_th('N80 Resolved Access')
		]);
	return A2($rundis$elm_bootstrap$Bootstrap$Table$tr, _List_Nil, named_cols);
}();
var $author$project$Pages$PageUserAccess$buildUserGroupsN80ResolvedN80AuthDivTableRow = F2(
	function (mdl, _v0) {
		var n80 = _v0.a;
		var authLevel = _v0.b;
		var n80Meta = A2($author$project$NodeDataStore$mdlGetN80Meta, mdl.fm, n80);
		var row = A2(
			$rundis$elm_bootstrap$Bootstrap$Table$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Table$td,
					_List_Nil,
					_List_fromArray(
						[
							A2($author$project$Components$CommonPageComponents$build_tblcell_deviceM, n80, n80Meta)
						])),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Table$td,
					_List_Nil,
					_List_fromArray(
						[
							$author$project$Components$CommonPageComponents$buildAccessLevel(authLevel.gt)
						]))
				]));
		return row;
	});
var $author$project$Pages$PageUserAccess$buildUserGroupsN80ResolvedN80AuthDiv = F2(
	function (mdl, cloudGroups) {
		var row_data = A2(
			$elm$core$List$map,
			$author$project$Pages$PageUserAccess$buildUserGroupsN80ResolvedN80AuthDivTableRow(mdl),
			cloudGroups);
		var tbl = $rundis$elm_bootstrap$Bootstrap$Table$table(
			{
				it: _List_fromArray(
					[$rundis$elm_bootstrap$Bootstrap$Table$striped, $rundis$elm_bootstrap$Bootstrap$Table$hover, $rundis$elm_bootstrap$Bootstrap$Table$small]),
				iN: A2($rundis$elm_bootstrap$Bootstrap$Table$tbody, _List_Nil, row_data),
				iO: A2(
					$rundis$elm_bootstrap$Bootstrap$Table$thead,
					_List_Nil,
					_List_fromArray(
						[$author$project$Pages$PageUserAccess$buildUserGroupsN80ResolvedN80AuthDivTableHeader]))
			});
		return _List_fromArray(
			[
				A2($elm$html$Html$map, $author$project$Msg$RunGlobalAction, tbl)
			]);
	});
var $author$project$Pages$PageUserAccess$pageUserAccess_ = F3(
	function (mdl, cmn, pageState) {
		var usergroups_visible = A2(
			$elm$core$List$map,
			function (o) {
				return o.b;
			},
			cmn.hR.dW.dU);
		var usergroups_table_state = pageState.hH;
		var usergroups_table_cfg = {hi: cmn.hR.d_, i0: $author$project$Components$UserGroupTable$allUserGroupTblCols};
		var user_n80group_access = A4($author$project$Pages$PageUserAccess$buildN80GroupsDiv, mdl, pageState, cmn, cmn.hR.dW.dT);
		var user_n80_access = A2($author$project$Pages$PageUserAccess$buildUserGroupsN80ResolvedN80AuthDiv, mdl, cmn.hR.dW.dV);
		var user_details = $author$project$Pages$PageUserAccess$buildUserGroupsAuthSummary(mdl);
		var n80_groups_visible = A2(
			$elm$core$List$map,
			function (o) {
				return o.b.a;
			},
			cmn.hR.dW.dT);
		var usergroups_table_data = {i2: n80_groups_visible, i3: usergroups_visible};
		var user_groups_table = A4($author$project$Components$UserGroupTable$buildUserGroupsTable, cmn, usergroups_table_cfg, usergroups_table_state, usergroups_table_data);
		var user_group_memberships = A2(
			$elm$html$Html$map,
			A2($elm$core$Basics$composeL, $author$project$Msg$UiPageUpdate, $author$project$Msg$UiUpdatePageUserAccessUserGroupTable),
			user_groups_table);
		var sections = _List_fromArray(
			[
				A2($author$project$ElmCommon$HtmlCommon$dashboardElemTitledDiv_, 'User\'s Details', user_details),
				A2(
				$author$project$ElmCommon$HtmlCommon$dashboardElemTitledDiv_,
				'User\'s User-Groups Memberships',
				_List_fromArray(
					[user_group_memberships])),
				A2($author$project$ElmCommon$HtmlCommon$dashboardElemTitledDiv_, 'User\'s N80-Group Access', user_n80group_access),
				A2($author$project$ElmCommon$HtmlCommon$dashboardElemTitledDiv_, 'Resolved N80 Access Levels', user_n80_access)
			]);
		return sections;
	});
var $author$project$Pages$PageUserAccess$pageUserAccess = F2(
	function (mdl, cmn) {
		var _v0 = mdl.hC;
		if (!_v0.$) {
			var pageState = _v0.a;
			return A3($author$project$Pages$PageUserAccess$pageUserAccess_, mdl, cmn, pageState);
		} else {
			return _List_fromArray(
				[
					$elm$html$Html$text('Wait, wot. No data.')
				]);
		}
	});
var $author$project$Msg$UiSetOptionDevMode = function (a) {
	return {$: 20, a: a};
};
var $author$project$Msg$UiSetOptionShowHidden = function (a) {
	return {$: 19, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$Checkbox = $elm$core$Basics$identity;
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$create = F2(
	function (options, label_) {
		return {en: label_, it: options};
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$Label = $elm$core$Basics$identity;
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$label = F2(
	function (attributes, children) {
		return {aU: attributes, c9: children};
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$applyModifier = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 0:
				var val = modifier.a;
				return _Utils_update(
					options,
					{
						ef: $elm$core$Maybe$Just(val)
					});
			case 1:
				var val = modifier.a;
				return _Utils_update(
					options,
					{h: val});
			case 2:
				return _Utils_update(
					options,
					{an: true});
			case 3:
				var toMsg = modifier.a;
				return _Utils_update(
					options,
					{
						bx: $elm$core$Maybe$Just(toMsg)
					});
			case 4:
				return _Utils_update(
					options,
					{q: true});
			case 5:
				var val = modifier.a;
				return _Utils_update(
					options,
					{a7: val});
			case 6:
				var validation = modifier.a;
				return _Utils_update(
					options,
					{
						ca: $elm$core$Maybe$Just(validation)
					});
			default:
				var attrs_ = modifier.a;
				return _Utils_update(
					options,
					{
						aU: _Utils_ap(options.aU, attrs_)
					});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$Off = 1;
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$defaultOptions = {aU: _List_Nil, q: false, a7: false, ef: $elm$core$Maybe$Nothing, an: false, bx: $elm$core$Maybe$Nothing, h: 1, ca: $elm$core$Maybe$Nothing};
var $elm$html$Html$Attributes$for = $elm$html$Html$Attributes$stringProperty('htmlFor');
var $elm$html$Html$Events$targetChecked = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'checked']),
	$elm$json$Json$Decode$bool);
var $elm$html$Html$Events$onCheck = function (tagger) {
	return A2(
		$elm$html$Html$Events$on,
		'change',
		A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetChecked));
};
var $elm$html$Html$Attributes$checked = $elm$html$Html$Attributes$boolProperty('checked');
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$stateAttribute = function (state) {
	switch (state) {
		case 0:
			return $elm$html$Html$Attributes$checked(true);
		case 1:
			return $elm$html$Html$Attributes$checked(false);
		default:
			return A2($elm$html$Html$Attributes$attribute, 'indeterminate', 'true');
	}
};
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$toAttributes = function (options) {
	return _Utils_ap(
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('form-check-input', !options.q),
						_Utils_Tuple2('custom-control-input', options.q)
					])),
				$elm$html$Html$Attributes$type_('checkbox'),
				$elm$html$Html$Attributes$disabled(options.a7),
				$rundis$elm_bootstrap$Bootstrap$Form$Checkbox$stateAttribute(options.h)
			]),
		_Utils_ap(
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				_List_fromArray(
					[
						A2($elm$core$Maybe$map, $elm$html$Html$Events$onCheck, options.bx),
						A2($elm$core$Maybe$map, $elm$html$Html$Attributes$id, options.ef)
					])),
			_Utils_ap(
				function () {
					var _v0 = options.ca;
					if (!_v0.$) {
						var v = _v0.a;
						return _List_fromArray(
							[
								$elm$html$Html$Attributes$class(
								$rundis$elm_bootstrap$Bootstrap$Form$FormInternal$validationToString(v))
							]);
					} else {
						return _List_Nil;
					}
				}(),
				options.aU)));
};
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$view = function (_v0) {
	var chk = _v0;
	var opts = A3($elm$core$List$foldl, $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$applyModifier, $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$defaultOptions, chk.it);
	var _v1 = chk.en;
	var label_ = _v1;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('form-check', !opts.q),
						_Utils_Tuple2('form-check-inline', (!opts.q) && opts.an),
						_Utils_Tuple2('custom-control', opts.q),
						_Utils_Tuple2('custom-checkbox', opts.q),
						_Utils_Tuple2('custom-control-inline', opts.an && opts.q)
					]))
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$input,
				$rundis$elm_bootstrap$Bootstrap$Form$Checkbox$toAttributes(opts),
				_List_Nil),
				A2(
				$elm$html$Html$label,
				_Utils_ap(
					label_.aU,
					_Utils_ap(
						_List_fromArray(
							[
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2('form-check-label', !opts.q),
										_Utils_Tuple2('custom-control-label', opts.q)
									]))
							]),
						function () {
							var _v2 = opts.ef;
							if (!_v2.$) {
								var v = _v2.a;
								return _List_fromArray(
									[
										$elm$html$Html$Attributes$for(v)
									]);
							} else {
								return _List_Nil;
							}
						}())),
				label_.c9)
			]));
};
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox = F2(
	function (options, labelText) {
		return $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$view(
			A2(
				$rundis$elm_bootstrap$Bootstrap$Form$Checkbox$create,
				options,
				A2(
					$rundis$elm_bootstrap$Bootstrap$Form$Checkbox$label,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(labelText)
						]))));
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$On = 0;
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$Value = function (a) {
	return {$: 1, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$checked = function (isCheck) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$Value(
		isCheck ? 0 : 1);
};
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$OnChecked = function (a) {
	return {$: 3, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$onCheck = function (toMsg) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$OnChecked(toMsg);
};
var $author$project$Pages$PageBase$pageUserSettings = F2(
	function (mdl, cmn) {
		return _List_fromArray(
			[
				A2(
				$author$project$ElmCommon$HtmlCommon$dashboardElemTitledDiv_,
				'Options',
				_List_fromArray(
					[
						A2(
						$rundis$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
						_List_fromArray(
							[
								$rundis$elm_bootstrap$Bootstrap$Form$Checkbox$checked(mdl.it.iF),
								$rundis$elm_bootstrap$Bootstrap$Form$Checkbox$onCheck($author$project$Msg$UiSetOptionShowHidden)
							]),
						'Show hidden?'),
						A2(
						$rundis$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
						_List_fromArray(
							[
								$rundis$elm_bootstrap$Bootstrap$Form$Checkbox$checked(mdl.it.hz),
								$rundis$elm_bootstrap$Bootstrap$Form$Checkbox$onCheck($author$project$Msg$UiSetOptionDevMode)
							]),
						'Dev-Mode?')
					])),
				A2(
				$author$project$ElmCommon$HtmlCommon$dashboardElemTitledDiv_,
				'Debug info',
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Some H.text')
							]))
					]))
			]);
	});
var $author$project$Pages$PageBase$mainContent = function (mdl) {
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Grid$container,
		_List_Nil,
		function () {
			var _v0 = $author$project$PageCommonInfo$buildPageCommonInfo(mdl);
			if (_v0.$ === 1) {
				return $author$project$Pages$PageHome$pageHome(mdl);
			} else {
				var pageCmnInfo = _v0.a;
				var _v1 = mdl.fq;
				switch (_v1.$) {
					case 0:
						return $author$project$Pages$PageHome$pageHome(mdl);
					case 1:
						return A2($author$project$Pages$PageAllN80s$pageAllN80s, mdl, pageCmnInfo);
					case 2:
						var n80 = _v1.a;
						return A3($author$project$Pages$PageDetailsN80$pageDetailsN80, mdl, pageCmnInfo, n80);
					case 3:
						var n96 = _v1.a;
						return A3($author$project$Pages$PageDetailsN96$pageDetailsN96, mdl, pageCmnInfo, n96);
					case 4:
						var n96 = _v1.a;
						return A3($author$project$Pages$PageDetailsN96PartialDownloads$pageDetailsN96PartialDownloads, mdl, pageCmnInfo, n96);
					case 5:
						var n96 = _v1.a;
						return A3($author$project$Pages$PageDetailsN96DevDownloads$pageDetailsN96DevDownloads, mdl, pageCmnInfo, n96);
					case 6:
						var n80GroupId = _v1.a;
						return A3($author$project$Pages$PageDetailsN80Group$pageDetailsN80Group, mdl, pageCmnInfo, n80GroupId);
					case 7:
						var cloudGroupId = _v1.a;
						return A3($author$project$Pages$PageDetailsUserGroup$pageDetailsUserGroup, mdl, pageCmnInfo, cloudGroupId);
					case 8:
						return A2($author$project$Pages$PageUserAccess$pageUserAccess, mdl, pageCmnInfo);
					case 9:
						return A2($author$project$Pages$PageBase$pageUserSettings, mdl, pageCmnInfo);
					case 10:
						return A2($author$project$Pages$PageApiNotifications$pageApiNotifications, mdl, pageCmnInfo);
					case 11:
						return A2($author$project$Pages$PageAdminUsersAndGroups$pageAdminAccess, mdl, pageCmnInfo);
					case 12:
						return $author$project$Pages$PageAdminServerProcessingStatus$pageAdminServerStatus(mdl);
					case 14:
						return $author$project$Pages$PageBase$pageError(mdl);
					case 13:
						var url = _v1.a;
						return $author$project$Pages$PageBase$pageNotFound(url);
					default:
						return $author$project$Pages$PageHome$pageHome(mdl);
				}
			}
		}());
};
var $author$project$Model$ModelUpdate$authIsAdmin = function (mdl) {
	var _v0 = mdl.c2;
	if (_v0.$ === 1) {
		return false;
	} else {
		var auth_user_details_ = _v0.a;
		var _v1 = auth_user_details_.dG;
		switch (_v1) {
			case 0:
				return false;
			case 1:
				return false;
			default:
				return true;
		}
	}
};
var $rundis$elm_bootstrap$Bootstrap$Navbar$Brand = $elm$core$Basics$identity;
var $elm$html$Html$a = _VirtualDom_node('a');
var $rundis$elm_bootstrap$Bootstrap$Navbar$Config = $elm$core$Basics$identity;
var $rundis$elm_bootstrap$Bootstrap$Navbar$updateConfig = F2(
	function (mapper, _v0) {
		var conf = _v0;
		return mapper(conf);
	});
var $rundis$elm_bootstrap$Bootstrap$Navbar$brand = F3(
	function (attributes, children, config_) {
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Navbar$updateConfig,
			function (conf) {
				return _Utils_update(
					conf,
					{
						ai: $elm$core$Maybe$Just(
							A2(
								$elm$html$Html$a,
								_Utils_ap(
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('navbar-brand')
										]),
									attributes),
								children))
					});
			},
			config_);
	});
var $elm$html$Html$Attributes$href = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'href',
		_VirtualDom_noJavaScriptUri(url));
};
var $author$project$Routing$buildInternalHRef = function (pg) {
	switch (pg) {
		case 0:
			return $elm$html$Html$Attributes$href('#');
		case 1:
			return $elm$html$Html$Attributes$href('/home');
		case 2:
			return $elm$html$Html$Attributes$href('/n80s');
		case 3:
			return $elm$html$Html$Attributes$href('/user-access');
		case 4:
			return $elm$html$Html$Attributes$href('/user-settings');
		case 5:
			return $elm$html$Html$Attributes$href('/user-api-notifications');
		case 6:
			return $elm$html$Html$Attributes$href('/admin-access');
		case 7:
			return $elm$html$Html$Attributes$href('/admin-serverstatus');
		default:
			return $elm$html$Html$Attributes$href('/logout');
	}
};
var $rundis$elm_bootstrap$Bootstrap$Navbar$Dropdown = $elm$core$Basics$identity;
var $rundis$elm_bootstrap$Bootstrap$Navbar$NavDropdown = function (a) {
	return {$: 1, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Navbar$dropdown = function (conf) {
	return $rundis$elm_bootstrap$Bootstrap$Navbar$NavDropdown(conf);
};
var $rundis$elm_bootstrap$Bootstrap$Navbar$DropdownItem = $elm$core$Basics$identity;
var $rundis$elm_bootstrap$Bootstrap$Navbar$dropdownDivider = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('dropdown-divider')
		]),
	_List_Nil);
var $rundis$elm_bootstrap$Bootstrap$Navbar$dropdownItem = F2(
	function (attributes, children) {
		return A2(
			$elm$html$Html$a,
			_Utils_ap(
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('dropdown-item')
					]),
				attributes),
			children);
	});
var $rundis$elm_bootstrap$Bootstrap$Navbar$DropdownToggle = $elm$core$Basics$identity;
var $rundis$elm_bootstrap$Bootstrap$Navbar$dropdownToggle = F2(
	function (attributes, children) {
		return {aU: attributes, c9: children};
	});
var $author$project$Pages$PageBase$buildDropdownAdmin = function (mdl) {
	return $rundis$elm_bootstrap$Bootstrap$Navbar$dropdown(
		{
			ef: 'my-link',
			h0: _List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Navbar$dropdownItem,
					_List_fromArray(
						[
							$author$project$Routing$buildInternalHRef(7)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Server Status')
						])),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Navbar$dropdownItem,
					_List_fromArray(
						[
							$author$project$Routing$buildInternalHRef(6)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Users & Groups')
						])),
					$rundis$elm_bootstrap$Bootstrap$Navbar$dropdownDivider,
					A2(
					$rundis$elm_bootstrap$Bootstrap$Navbar$dropdownItem,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$href('#GlobalAccess')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Global Access')
						]))
				]),
			gn: A2(
				$rundis$elm_bootstrap$Bootstrap$Navbar$dropdownToggle,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Admin')
					]))
		});
};
var $author$project$Pages$PageBase$buildDropdownUser = function (mdl) {
	return $rundis$elm_bootstrap$Bootstrap$Navbar$dropdown(
		{
			ef: 'my-link2',
			h0: _List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Navbar$dropdownItem,
					_List_fromArray(
						[
							$author$project$Routing$buildInternalHRef(4)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Settings')
						])),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Navbar$dropdownItem,
					_List_fromArray(
						[
							$author$project$Routing$buildInternalHRef(3)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Access')
						])),
					$rundis$elm_bootstrap$Bootstrap$Navbar$dropdownDivider,
					A2(
					$rundis$elm_bootstrap$Bootstrap$Navbar$dropdownItem,
					_List_fromArray(
						[
							$author$project$Routing$buildInternalHRef(5)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('PageUserApiNotifications')
						])),
					$rundis$elm_bootstrap$Bootstrap$Navbar$dropdownDivider,
					A2(
					$rundis$elm_bootstrap$Bootstrap$Navbar$dropdownItem,
					_List_fromArray(
						[
							$author$project$Routing$buildInternalHRef(8)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Logout')
						]))
				]),
			gn: A2(
				$rundis$elm_bootstrap$Bootstrap$Navbar$dropdownToggle,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('User')
					]))
		});
};
var $rundis$elm_bootstrap$Bootstrap$Internal$Role$Light = 6;
var $rundis$elm_bootstrap$Bootstrap$Navbar$Light = 1;
var $rundis$elm_bootstrap$Bootstrap$Navbar$Roled = function (a) {
	return {$: 0, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Navbar$config = function (toMsg) {
	return {
		ai: $elm$core$Maybe$Nothing,
		a3: _List_Nil,
		h0: _List_Nil,
		it: {
			aU: _List_Nil,
			Z: $elm$core$Maybe$Nothing,
			bi: false,
			bW: $elm$core$Maybe$Just(
				{
					aW: $rundis$elm_bootstrap$Bootstrap$Navbar$Roled(6),
					bp: 1
				}),
			az: 0
		},
		cR: toMsg,
		U: false
	};
};
var $rundis$elm_bootstrap$Bootstrap$Navbar$updateOptions = F2(
	function (mapper, _v0) {
		var conf = _v0;
		return _Utils_update(
			conf,
			{
				it: mapper(conf.it)
			});
	});
var $rundis$elm_bootstrap$Bootstrap$Navbar$container = function (conf) {
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Navbar$updateOptions,
		function (opts) {
			return _Utils_update(
				opts,
				{bi: true});
		},
		conf);
};
var $elm$html$Html$Attributes$height = function (n) {
	return A2(
		_VirtualDom_attribute,
		'height',
		$elm$core$String$fromInt(n));
};
var $elm$html$Html$img = _VirtualDom_node('img');
var $rundis$elm_bootstrap$Bootstrap$Navbar$Item = function (a) {
	return {$: 0, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Navbar$itemLink = F2(
	function (attributes, children) {
		return $rundis$elm_bootstrap$Bootstrap$Navbar$Item(
			{aU: attributes, c9: children});
	});
var $rundis$elm_bootstrap$Bootstrap$Navbar$items = F2(
	function (items_, config_) {
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Navbar$updateConfig,
			function (conf) {
				return _Utils_update(
					conf,
					{h0: items_});
			},
			config_);
	});
var $elm$core$Dict$sizeHelp = F2(
	function (n, dict) {
		sizeHelp:
		while (true) {
			if (dict.$ === -2) {
				return n;
			} else {
				var left = dict.d;
				var right = dict.e;
				var $temp$n = A2($elm$core$Dict$sizeHelp, n + 1, right),
					$temp$dict = left;
				n = $temp$n;
				dict = $temp$dict;
				continue sizeHelp;
			}
		}
	});
var $elm$core$Dict$size = function (dict) {
	return A2($elm$core$Dict$sizeHelp, 0, dict);
};
var $turboMaCk$any_dict$Dict$Any$size = function (_v0) {
	var dict = _v0.c;
	return $elm$core$Dict$size(dict);
};
var $elm$html$Html$Attributes$src = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'src',
		_VirtualDom_noJavaScriptOrHtmlUri(url));
};
var $rundis$elm_bootstrap$Bootstrap$Navbar$maybeBrand = function (brand_) {
	if (!brand_.$) {
		var b = brand_.a;
		return _List_fromArray(
			[b]);
	} else {
		return _List_Nil;
	}
};
var $rundis$elm_bootstrap$Bootstrap$Navbar$sizeToComparable = function (size) {
	switch (size) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		default:
			return 5;
	}
};
var $rundis$elm_bootstrap$Bootstrap$General$Internal$LG = 3;
var $rundis$elm_bootstrap$Bootstrap$General$Internal$MD = 2;
var $rundis$elm_bootstrap$Bootstrap$General$Internal$XL = 4;
var $rundis$elm_bootstrap$Bootstrap$Navbar$toScreenSize = function (windowWidth) {
	return (windowWidth <= 576) ? 0 : ((windowWidth <= 768) ? 1 : ((windowWidth <= 992) ? 2 : ((windowWidth <= 1200) ? 3 : 4)));
};
var $rundis$elm_bootstrap$Bootstrap$Navbar$shouldHideMenu = F2(
	function (_v0, _v1) {
		var windowWidth = _v0.aI;
		var options = _v1.it;
		var winMedia = function () {
			if (!windowWidth.$) {
				var s = windowWidth.a;
				return $rundis$elm_bootstrap$Bootstrap$Navbar$toScreenSize(s);
			} else {
				return 0;
			}
		}();
		return _Utils_cmp(
			$rundis$elm_bootstrap$Bootstrap$Navbar$sizeToComparable(winMedia),
			$rundis$elm_bootstrap$Bootstrap$Navbar$sizeToComparable(options.az)) > 0;
	});
var $rundis$elm_bootstrap$Bootstrap$Navbar$Shown = 5;
var $rundis$elm_bootstrap$Bootstrap$Navbar$StartDown = 1;
var $rundis$elm_bootstrap$Bootstrap$Navbar$StartUp = 3;
var $rundis$elm_bootstrap$Bootstrap$Navbar$visibilityTransition = F2(
	function (withAnimation_, visibility) {
		var _v0 = _Utils_Tuple2(withAnimation_, visibility);
		if (_v0.a) {
			switch (_v0.b) {
				case 0:
					var _v1 = _v0.b;
					return 1;
				case 1:
					var _v2 = _v0.b;
					return 2;
				case 2:
					var _v3 = _v0.b;
					return 5;
				case 5:
					var _v4 = _v0.b;
					return 3;
				case 3:
					var _v5 = _v0.b;
					return 4;
				default:
					var _v6 = _v0.b;
					return 0;
			}
		} else {
			switch (_v0.b) {
				case 0:
					var _v7 = _v0.b;
					return 5;
				case 5:
					var _v8 = _v0.b;
					return 0;
				default:
					return 0;
			}
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Navbar$transitionHandler = F2(
	function (state, configRec) {
		return $elm$json$Json$Decode$succeed(
			configRec.cR(
				A2(
					$rundis$elm_bootstrap$Bootstrap$Navbar$mapState,
					function (s) {
						return _Utils_update(
							s,
							{
								m: A2($rundis$elm_bootstrap$Bootstrap$Navbar$visibilityTransition, configRec.U, s.m)
							});
					},
					state)));
	});
var $rundis$elm_bootstrap$Bootstrap$Navbar$transitionStyle = function (maybeHeight) {
	var pixelHeight = A2(
		$elm$core$Maybe$withDefault,
		'0',
		A2(
			$elm$core$Maybe$map,
			function (v) {
				return $elm$core$String$fromFloat(v) + 'px';
			},
			maybeHeight));
	return _List_fromArray(
		[
			A2($elm$html$Html$Attributes$style, 'position', 'relative'),
			A2($elm$html$Html$Attributes$style, 'height', pixelHeight),
			A2($elm$html$Html$Attributes$style, 'width', '100%'),
			A2($elm$html$Html$Attributes$style, 'overflow', 'hidden'),
			A2($elm$html$Html$Attributes$style, '-webkit-transition-timing-function', 'ease'),
			A2($elm$html$Html$Attributes$style, '-o-transition-timing-function', 'ease'),
			A2($elm$html$Html$Attributes$style, 'transition-timing-function', 'ease'),
			A2($elm$html$Html$Attributes$style, '-webkit-transition-duration', '0.35s'),
			A2($elm$html$Html$Attributes$style, '-o-transition-duration', '0.35s'),
			A2($elm$html$Html$Attributes$style, 'transition-duration', '0.35s'),
			A2($elm$html$Html$Attributes$style, '-webkit-transition-property', 'height'),
			A2($elm$html$Html$Attributes$style, '-o-transition-property', 'height'),
			A2($elm$html$Html$Attributes$style, 'transition-property', 'height')
		]);
};
var $rundis$elm_bootstrap$Bootstrap$Navbar$menuAttributes = F2(
	function (state, configRec) {
		var visibility = state.m;
		var height = state.d9;
		var defaults = _List_fromArray(
			[
				$elm$html$Html$Attributes$class('collapse navbar-collapse')
			]);
		switch (visibility) {
			case 0:
				if (height.$ === 1) {
					return ((!configRec.U) || A2($rundis$elm_bootstrap$Bootstrap$Navbar$shouldHideMenu, state, configRec)) ? defaults : _List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'display', 'block'),
							A2($elm$html$Html$Attributes$style, 'height', '0'),
							A2($elm$html$Html$Attributes$style, 'overflow', 'hidden'),
							A2($elm$html$Html$Attributes$style, 'width', '100%')
						]);
				} else {
					return defaults;
				}
			case 1:
				return $rundis$elm_bootstrap$Bootstrap$Navbar$transitionStyle($elm$core$Maybe$Nothing);
			case 2:
				return _Utils_ap(
					$rundis$elm_bootstrap$Bootstrap$Navbar$transitionStyle(height),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$Events$on,
							'transitionend',
							A2($rundis$elm_bootstrap$Bootstrap$Navbar$transitionHandler, state, configRec))
						]));
			case 4:
				return _Utils_ap(
					$rundis$elm_bootstrap$Bootstrap$Navbar$transitionStyle($elm$core$Maybe$Nothing),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$Events$on,
							'transitionend',
							A2($rundis$elm_bootstrap$Bootstrap$Navbar$transitionHandler, state, configRec))
						]));
			case 3:
				return $rundis$elm_bootstrap$Bootstrap$Navbar$transitionStyle(height);
			default:
				return _Utils_ap(
					defaults,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('show')
						]));
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Navbar$menuWrapperAttributes = F2(
	function (state, confRec) {
		var visibility = state.m;
		var height = state.d9;
		var styleBlock = _List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'display', 'block'),
				A2($elm$html$Html$Attributes$style, 'width', '100%')
			]);
		var display = function () {
			if (height.$ === 1) {
				return ((!confRec.U) || A2($rundis$elm_bootstrap$Bootstrap$Navbar$shouldHideMenu, state, confRec)) ? 'flex' : 'block';
			} else {
				return 'flex';
			}
		}();
		switch (visibility) {
			case 0:
				return _List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'display', display),
						A2($elm$html$Html$Attributes$style, 'width', '100%')
					]);
			case 1:
				return styleBlock;
			case 2:
				return styleBlock;
			case 4:
				return styleBlock;
			case 3:
				return styleBlock;
			default:
				return ((!confRec.U) || A2($rundis$elm_bootstrap$Bootstrap$Navbar$shouldHideMenu, state, confRec)) ? _List_fromArray(
					[
						$elm$html$Html$Attributes$class('collapse navbar-collapse show')
					]) : _List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'display', 'block')
					]);
		}
	});
var $elm$html$Html$nav = _VirtualDom_node('nav');
var $rundis$elm_bootstrap$Bootstrap$Navbar$expandOption = function (size) {
	var toClass = function (sz) {
		return $elm$html$Html$Attributes$class(
			'navbar-expand' + A2(
				$elm$core$Maybe$withDefault,
				'',
				A2(
					$elm$core$Maybe$map,
					function (s) {
						return '-' + s;
					},
					$rundis$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption(sz))));
	};
	switch (size) {
		case 0:
			return _List_fromArray(
				[
					toClass(1)
				]);
		case 1:
			return _List_fromArray(
				[
					toClass(2)
				]);
		case 2:
			return _List_fromArray(
				[
					toClass(3)
				]);
		case 3:
			return _List_fromArray(
				[
					toClass(4)
				]);
		default:
			return _List_Nil;
	}
};
var $rundis$elm_bootstrap$Bootstrap$Navbar$fixOption = function (fix) {
	if (!fix) {
		return 'fixed-top';
	} else {
		return 'fixed-bottom';
	}
};
var $avh4$elm_color$Color$toCssString = function (_v0) {
	var r = _v0.a;
	var g = _v0.b;
	var b = _v0.c;
	var a = _v0.d;
	var roundTo = function (x) {
		return $elm$core$Basics$round(x * 1000) / 1000;
	};
	var pct = function (x) {
		return $elm$core$Basics$round(x * 10000) / 100;
	};
	return $elm$core$String$concat(
		_List_fromArray(
			[
				'rgba(',
				$elm$core$String$fromFloat(
				pct(r)),
				'%,',
				$elm$core$String$fromFloat(
				pct(g)),
				'%,',
				$elm$core$String$fromFloat(
				pct(b)),
				'%,',
				$elm$core$String$fromFloat(
				roundTo(a)),
				')'
			]));
};
var $rundis$elm_bootstrap$Bootstrap$Navbar$backgroundColorOption = function (bgClass) {
	switch (bgClass.$) {
		case 0:
			var role = bgClass.a;
			return A2($rundis$elm_bootstrap$Bootstrap$Internal$Role$toClass, 'bg', role);
		case 1:
			var color = bgClass.a;
			return A2(
				$elm$html$Html$Attributes$style,
				'background-color',
				$avh4$elm_color$Color$toCssString(color));
		default:
			var classString = bgClass.a;
			return $elm$html$Html$Attributes$class(classString);
	}
};
var $rundis$elm_bootstrap$Bootstrap$Navbar$linkModifierClass = function (modifier) {
	return $elm$html$Html$Attributes$class(
		function () {
			if (!modifier) {
				return 'navbar-dark';
			} else {
				return 'navbar-light';
			}
		}());
};
var $rundis$elm_bootstrap$Bootstrap$Navbar$schemeAttributes = function (_v0) {
	var modifier = _v0.bp;
	var bgColor = _v0.aW;
	return _List_fromArray(
		[
			$rundis$elm_bootstrap$Bootstrap$Navbar$linkModifierClass(modifier),
			$rundis$elm_bootstrap$Bootstrap$Navbar$backgroundColorOption(bgColor)
		]);
};
var $rundis$elm_bootstrap$Bootstrap$Navbar$navbarAttributes = function (options) {
	return _Utils_ap(
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('navbar', true),
						_Utils_Tuple2('container', options.bi)
					]))
			]),
		_Utils_ap(
			$rundis$elm_bootstrap$Bootstrap$Navbar$expandOption(options.az),
			_Utils_ap(
				function () {
					var _v0 = options.bW;
					if (!_v0.$) {
						var scheme_ = _v0.a;
						return $rundis$elm_bootstrap$Bootstrap$Navbar$schemeAttributes(scheme_);
					} else {
						return _List_Nil;
					}
				}(),
				_Utils_ap(
					function () {
						var _v1 = options.Z;
						if (!_v1.$) {
							var fix = _v1.a;
							return _List_fromArray(
								[
									$elm$html$Html$Attributes$class(
									$rundis$elm_bootstrap$Bootstrap$Navbar$fixOption(fix))
								]);
						} else {
							return _List_Nil;
						}
					}(),
					options.aU))));
};
var $rundis$elm_bootstrap$Bootstrap$Navbar$renderCustom = function (items_) {
	return A2(
		$elm$core$List$map,
		function (_v0) {
			var item = _v0;
			return item;
		},
		items_);
};
var $rundis$elm_bootstrap$Bootstrap$Navbar$getOrInitDropdownStatus = F2(
	function (id, _v0) {
		var dropdowns = _v0.M;
		return A2(
			$elm$core$Maybe$withDefault,
			2,
			A2($elm$core$Dict$get, id, dropdowns));
	});
var $elm$virtual_dom$VirtualDom$Custom = function (a) {
	return {$: 3, a: a};
};
var $elm$html$Html$Events$custom = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Custom(decoder));
	});
var $rundis$elm_bootstrap$Bootstrap$Navbar$toggleOpen = F3(
	function (state, id, _v0) {
		var toMsg = _v0.cR;
		var currStatus = A2($rundis$elm_bootstrap$Bootstrap$Navbar$getOrInitDropdownStatus, id, state);
		var newStatus = function () {
			switch (currStatus) {
				case 0:
					return 2;
				case 1:
					return 2;
				default:
					return 0;
			}
		}();
		return toMsg(
			A2(
				$rundis$elm_bootstrap$Bootstrap$Navbar$mapState,
				function (s) {
					return _Utils_update(
						s,
						{
							M: A3($elm$core$Dict$insert, id, newStatus, s.M)
						});
				},
				state));
	});
var $rundis$elm_bootstrap$Bootstrap$Navbar$renderDropdownToggle = F4(
	function (state, id, configRec, _v0) {
		var attributes = _v0.aU;
		var children = _v0.c9;
		return A2(
			$elm$html$Html$a,
			_Utils_ap(
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('nav-link dropdown-toggle'),
						$elm$html$Html$Attributes$href('#'),
						A2(
						$elm$html$Html$Events$custom,
						'click',
						$elm$json$Json$Decode$succeed(
							{
								G: A3($rundis$elm_bootstrap$Bootstrap$Navbar$toggleOpen, state, id, configRec),
								cG: true,
								cO: false
							}))
					]),
				attributes),
			children);
	});
var $rundis$elm_bootstrap$Bootstrap$Navbar$renderDropdown = F3(
	function (state, configRec, _v0) {
		var ddRec = _v0;
		var needsDropup = A2(
			$elm$core$Maybe$withDefault,
			false,
			A2(
				$elm$core$Maybe$map,
				function (fix) {
					if (fix === 1) {
						return true;
					} else {
						return false;
					}
				},
				configRec.it.Z));
		var isShown = A2($rundis$elm_bootstrap$Bootstrap$Navbar$getOrInitDropdownStatus, ddRec.ef, state) !== 2;
		return A2(
			$elm$html$Html$li,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('nav-item', true),
							_Utils_Tuple2('dropdown', true),
							_Utils_Tuple2('shown', isShown),
							_Utils_Tuple2('dropup', needsDropup)
						]))
				]),
			_List_fromArray(
				[
					A4($rundis$elm_bootstrap$Bootstrap$Navbar$renderDropdownToggle, state, ddRec.ef, configRec, ddRec.gn),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('dropdown-menu', true),
									_Utils_Tuple2('show', isShown)
								]))
						]),
					A2(
						$elm$core$List$map,
						function (_v1) {
							var item = _v1;
							return item;
						},
						ddRec.h0))
				]));
	});
var $rundis$elm_bootstrap$Bootstrap$Navbar$renderItemLink = function (_v0) {
	var attributes = _v0.aU;
	var children = _v0.c9;
	return A2(
		$elm$html$Html$li,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('nav-item')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$a,
				_Utils_ap(
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('nav-link')
						]),
					attributes),
				children)
			]));
};
var $rundis$elm_bootstrap$Bootstrap$Navbar$renderNav = F3(
	function (state, configRec, navItems) {
		return A2(
			$elm$html$Html$ul,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('navbar-nav mr-auto')
				]),
			A2(
				$elm$core$List$map,
				function (item) {
					if (!item.$) {
						var item_ = item.a;
						return $rundis$elm_bootstrap$Bootstrap$Navbar$renderItemLink(item_);
					} else {
						var dropdown_ = item.a;
						return A3($rundis$elm_bootstrap$Bootstrap$Navbar$renderDropdown, state, configRec, dropdown_);
					}
				},
				navItems));
	});
var $elm$json$Json$Decode$decodeValue = _Json_run;
var $rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$parentElement = function (decoder) {
	return A2($elm$json$Json$Decode$field, 'parentElement', decoder);
};
var $rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$target = function (decoder) {
	return A2($elm$json$Json$Decode$field, 'target', decoder);
};
var $elm$json$Json$Decode$value = _Json_decodeValue;
var $rundis$elm_bootstrap$Bootstrap$Navbar$heightDecoder = function () {
	var tagDecoder = A3(
		$elm$json$Json$Decode$map2,
		F2(
			function (tag, val) {
				return _Utils_Tuple2(tag, val);
			}),
		A2($elm$json$Json$Decode$field, 'tagName', $elm$json$Json$Decode$string),
		$elm$json$Json$Decode$value);
	var resToDec = function (res) {
		if (!res.$) {
			var v = res.a;
			return $elm$json$Json$Decode$succeed(v);
		} else {
			var err = res.a;
			return $elm$json$Json$Decode$fail(
				$elm$json$Json$Decode$errorToString(err));
		}
	};
	var fromNavDec = $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$json$Json$Decode$at,
				_List_fromArray(
					['childNodes', '2', 'childNodes', '0', 'offsetHeight']),
				$elm$json$Json$Decode$float),
				A2(
				$elm$json$Json$Decode$at,
				_List_fromArray(
					['childNodes', '1', 'childNodes', '0', 'offsetHeight']),
				$elm$json$Json$Decode$float)
			]));
	var fromButtonDec = $rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$parentElement(fromNavDec);
	return A2(
		$elm$json$Json$Decode$andThen,
		function (_v0) {
			var tag = _v0.a;
			var val = _v0.b;
			switch (tag) {
				case 'NAV':
					return resToDec(
						A2($elm$json$Json$Decode$decodeValue, fromNavDec, val));
				case 'BUTTON':
					return resToDec(
						A2($elm$json$Json$Decode$decodeValue, fromButtonDec, val));
				default:
					return $elm$json$Json$Decode$succeed(0);
			}
		},
		$rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$target(
			$rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$parentElement(tagDecoder)));
}();
var $rundis$elm_bootstrap$Bootstrap$Navbar$toggleHandler = F2(
	function (state, configRec) {
		var height = state.d9;
		var updState = function (h) {
			return A2(
				$rundis$elm_bootstrap$Bootstrap$Navbar$mapState,
				function (s) {
					return _Utils_update(
						s,
						{
							d9: $elm$core$Maybe$Just(h),
							m: A2($rundis$elm_bootstrap$Bootstrap$Navbar$visibilityTransition, configRec.U, s.m)
						});
				},
				state);
		};
		return A2(
			$elm$html$Html$Events$on,
			'click',
			A2(
				$elm$json$Json$Decode$andThen,
				function (v) {
					return $elm$json$Json$Decode$succeed(
						configRec.cR(
							(v > 0) ? updState(v) : updState(
								A2($elm$core$Maybe$withDefault, 0, height))));
				},
				$rundis$elm_bootstrap$Bootstrap$Navbar$heightDecoder));
	});
var $rundis$elm_bootstrap$Bootstrap$Navbar$view = F2(
	function (state, conf) {
		var configRec = conf;
		return A2(
			$elm$html$Html$nav,
			$rundis$elm_bootstrap$Bootstrap$Navbar$navbarAttributes(configRec.it),
			_Utils_ap(
				$rundis$elm_bootstrap$Bootstrap$Navbar$maybeBrand(configRec.ai),
				_Utils_ap(
					_List_fromArray(
						[
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class(
									'navbar-toggler' + A2(
										$elm$core$Maybe$withDefault,
										'',
										A2(
											$elm$core$Maybe$map,
											function (_v0) {
												return ' navbar-toggler-right';
											},
											configRec.ai))),
									$elm$html$Html$Attributes$type_('button'),
									A2($rundis$elm_bootstrap$Bootstrap$Navbar$toggleHandler, state, configRec)
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('navbar-toggler-icon')
										]),
									_List_Nil)
								]))
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							A2($rundis$elm_bootstrap$Bootstrap$Navbar$menuAttributes, state, configRec),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									A2($rundis$elm_bootstrap$Bootstrap$Navbar$menuWrapperAttributes, state, configRec),
									_Utils_ap(
										_List_fromArray(
											[
												A3($rundis$elm_bootstrap$Bootstrap$Navbar$renderNav, state, configRec, configRec.h0)
											]),
										$rundis$elm_bootstrap$Bootstrap$Navbar$renderCustom(configRec.a3)))
								]))
						]))));
	});
var $rundis$elm_bootstrap$Bootstrap$Navbar$withAnimation = function (config_) {
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Navbar$updateConfig,
		function (conf) {
			return _Utils_update(
				conf,
				{U: true});
		},
		config_);
};
var $author$project$Pages$PageBase$menu = function (mdl) {
	var n_nodes = $turboMaCk$any_dict$Dict$Any$size(mdl.fm.eZ);
	var n_nodes_txt = ' [' + ($elm$core$String$fromInt(n_nodes) + ']');
	var menuitem_node_txt = 'Sensors' + n_nodes_txt;
	var dropdownUser = _List_fromArray(
		[
			$author$project$Pages$PageBase$buildDropdownUser(mdl)
		]);
	var dropdownAdmin = $author$project$Model$ModelUpdate$authIsAdmin(mdl) ? _List_fromArray(
		[
			$author$project$Pages$PageBase$buildDropdownAdmin(mdl)
		]) : _List_Nil;
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Navbar$view,
		mdl.io,
		A2(
			$rundis$elm_bootstrap$Bootstrap$Navbar$items,
			_Utils_ap(
				_List_fromArray(
					[
						A2(
						$rundis$elm_bootstrap$Bootstrap$Navbar$itemLink,
						_List_fromArray(
							[
								$author$project$Routing$buildInternalHRef(2)
							]),
						_List_fromArray(
							[
								(_Utils_eq(mdl.fq, $author$project$TypesPage$PageAllN80s) ? $author$project$ElmCommon$Utils$bold : $elm$core$Basics$identity)(
								$elm$html$Html$text(menuitem_node_txt))
							]))
					]),
				_Utils_ap(dropdownUser, dropdownAdmin)),
			A3(
				$rundis$elm_bootstrap$Bootstrap$Navbar$brand,
				_List_fromArray(
					[
						$author$project$Routing$buildInternalHRef(1)
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$img,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$src('favicon_src.png'),
								$elm$html$Html$Attributes$height(40)
							]),
						_List_Nil)
					]),
				$rundis$elm_bootstrap$Bootstrap$Navbar$container(
					$rundis$elm_bootstrap$Bootstrap$Navbar$withAnimation(
						$rundis$elm_bootstrap$Bootstrap$Navbar$config($author$project$Msg$NavMsg))))));
};
var $author$project$Msg$CloseModal = {$: 3};
var $rundis$elm_bootstrap$Bootstrap$Modal$Body = $elm$core$Basics$identity;
var $rundis$elm_bootstrap$Bootstrap$Modal$Config = $elm$core$Basics$identity;
var $rundis$elm_bootstrap$Bootstrap$Modal$body = F3(
	function (attributes, children, _v0) {
		var conf = _v0;
		return _Utils_update(
			conf,
			{
				hl: $elm$core$Maybe$Just(
					{aU: attributes, c9: children})
			});
	});
var $rundis$elm_bootstrap$Bootstrap$Modal$config = function (closeMsg) {
	return {
		hl: $elm$core$Maybe$Nothing,
		aj: closeMsg,
		ck: $elm$core$Maybe$Nothing,
		bh: $elm$core$Maybe$Nothing,
		it: {aV: _List_Nil, a_: true, al: true, at: $elm$core$Maybe$Nothing, bX: false},
		U: $elm$core$Maybe$Nothing
	};
};
var $rundis$elm_bootstrap$Bootstrap$Grid$containerFluid = F2(
	function (attributes, children) {
		return A2(
			$elm$html$Html$div,
			_Utils_ap(
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('container-fluid')
					]),
				attributes),
			children);
	});
var $elm$html$Html$h4 = _VirtualDom_node('h4');
var $rundis$elm_bootstrap$Bootstrap$Modal$Header = $elm$core$Basics$identity;
var $rundis$elm_bootstrap$Bootstrap$Modal$header = F3(
	function (attributes, children, _v0) {
		var conf = _v0;
		return _Utils_update(
			conf,
			{
				bh: $elm$core$Maybe$Just(
					{aU: attributes, c9: children})
			});
	});
var $rundis$elm_bootstrap$Bootstrap$Modal$titledHeader = F3(
	function (itemFn, attributes, children) {
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Modal$header,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					itemFn,
					A2(
						$elm$core$List$cons,
						$elm$html$Html$Attributes$class('modal-title'),
						attributes),
					children)
				]));
	});
var $rundis$elm_bootstrap$Bootstrap$Modal$h4 = $rundis$elm_bootstrap$Bootstrap$Modal$titledHeader($elm$html$Html$h4);
var $rundis$elm_bootstrap$Bootstrap$Modal$small = function (_v0) {
	var conf = _v0;
	var options = conf.it;
	return _Utils_update(
		conf,
		{
			it: _Utils_update(
				options,
				{
					at: $elm$core$Maybe$Just(1)
				})
		});
};
var $rundis$elm_bootstrap$Bootstrap$Modal$StartClose = 1;
var $rundis$elm_bootstrap$Bootstrap$Modal$getCloseMsg = function (config_) {
	var _v0 = config_.U;
	if (!_v0.$) {
		var animationMsg = _v0.a;
		return animationMsg(1);
	} else {
		return config_.aj;
	}
};
var $rundis$elm_bootstrap$Bootstrap$Modal$isFade = function (conf) {
	return A2(
		$elm$core$Maybe$withDefault,
		false,
		A2(
			$elm$core$Maybe$map,
			function (_v0) {
				return true;
			},
			conf.U));
};
var $rundis$elm_bootstrap$Bootstrap$Modal$backdrop = F2(
	function (visibility, conf) {
		var attributes = function () {
			switch (visibility) {
				case 0:
					return _Utils_ap(
						_List_fromArray(
							[
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2('modal-backdrop', true),
										_Utils_Tuple2(
										'fade',
										$rundis$elm_bootstrap$Bootstrap$Modal$isFade(conf)),
										_Utils_Tuple2('show', true)
									]))
							]),
						conf.it.al ? _List_fromArray(
							[
								$elm$html$Html$Events$onClick(
								$rundis$elm_bootstrap$Bootstrap$Modal$getCloseMsg(conf))
							]) : _List_Nil);
				case 1:
					return _List_fromArray(
						[
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('modal-backdrop', true),
									_Utils_Tuple2('fade', true),
									_Utils_Tuple2('show', true)
								]))
						]);
				case 2:
					return _List_fromArray(
						[
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('modal-backdrop', true),
									_Utils_Tuple2('fade', true),
									_Utils_Tuple2('show', false)
								]))
						]);
				default:
					return _List_fromArray(
						[
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('modal-backdrop', false),
									_Utils_Tuple2(
									'fade',
									$rundis$elm_bootstrap$Bootstrap$Modal$isFade(conf)),
									_Utils_Tuple2('show', false)
								]))
						]);
			}
		}();
		return _List_fromArray(
			[
				A2($elm$html$Html$div, attributes, _List_Nil)
			]);
	});
var $rundis$elm_bootstrap$Bootstrap$Modal$containerClickDecoder = function (closeMsg) {
	return A2(
		$elm$json$Json$Decode$andThen,
		function (c) {
			return A2($elm$core$String$contains, 'elm-bootstrap-modal', c) ? $elm$json$Json$Decode$succeed(closeMsg) : $elm$json$Json$Decode$fail('ignoring');
		},
		$rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$target($rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$className));
};
var $rundis$elm_bootstrap$Bootstrap$Modal$display = F2(
	function (visibility, conf) {
		switch (visibility) {
			case 0:
				return _List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'pointer-events', 'none'),
						A2($elm$html$Html$Attributes$style, 'display', 'block'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('modal', true),
								_Utils_Tuple2(
								'fade',
								$rundis$elm_bootstrap$Bootstrap$Modal$isFade(conf)),
								_Utils_Tuple2('show', true)
							]))
					]);
			case 1:
				return _List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'pointer-events', 'none'),
						A2($elm$html$Html$Attributes$style, 'display', 'block'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('modal', true),
								_Utils_Tuple2('fade', true),
								_Utils_Tuple2('show', true)
							]))
					]);
			case 2:
				return _List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'pointer-events', 'none'),
						A2($elm$html$Html$Attributes$style, 'display', 'block'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('modal', true),
								_Utils_Tuple2('fade', true),
								_Utils_Tuple2('show', false)
							])),
						A2(
						$elm$html$Html$Events$on,
						'transitionend',
						$elm$json$Json$Decode$succeed(conf.aj))
					]);
			default:
				return _List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'height', '0px'),
						A2($elm$html$Html$Attributes$style, 'display', 'block'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('modal', true),
								_Utils_Tuple2(
								'fade',
								$rundis$elm_bootstrap$Bootstrap$Modal$isFade(conf)),
								_Utils_Tuple2('show', false)
							]))
					]);
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Modal$modalClass = function (size) {
	var _v0 = $rundis$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption(size);
	if (!_v0.$) {
		var s = _v0.a;
		return _List_fromArray(
			[
				$elm$html$Html$Attributes$class('modal-' + s)
			]);
	} else {
		return _List_Nil;
	}
};
var $rundis$elm_bootstrap$Bootstrap$Modal$modalAttributes = function (options) {
	return _Utils_ap(
		options.aV,
		_Utils_ap(
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('modal-dialog', true),
							_Utils_Tuple2('modal-dialog-centered', options.a_),
							_Utils_Tuple2('modal-dialog-scrollable', options.bX)
						])),
					A2($elm$html$Html$Attributes$style, 'pointer-events', 'auto')
				]),
			A2(
				$elm$core$Maybe$withDefault,
				_List_Nil,
				A2($elm$core$Maybe$map, $rundis$elm_bootstrap$Bootstrap$Modal$modalClass, options.at))));
};
var $rundis$elm_bootstrap$Bootstrap$Modal$renderBody = function (maybeBody) {
	if (!maybeBody.$) {
		var cfg = maybeBody.a;
		return $elm$core$Maybe$Just(
			A2(
				$elm$html$Html$div,
				A2(
					$elm$core$List$cons,
					$elm$html$Html$Attributes$class('modal-body'),
					cfg.aU),
				cfg.c9));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $rundis$elm_bootstrap$Bootstrap$Modal$renderFooter = function (maybeFooter) {
	if (!maybeFooter.$) {
		var cfg = maybeFooter.a;
		return $elm$core$Maybe$Just(
			A2(
				$elm$html$Html$div,
				A2(
					$elm$core$List$cons,
					$elm$html$Html$Attributes$class('modal-footer'),
					cfg.aU),
				cfg.c9));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $rundis$elm_bootstrap$Bootstrap$Modal$closeButton = function (closeMsg) {
	return A2(
		$elm$html$Html$button,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('close'),
				$elm$html$Html$Events$onClick(closeMsg)
			]),
		_List_fromArray(
			[
				$elm$html$Html$text('×')
			]));
};
var $rundis$elm_bootstrap$Bootstrap$Modal$renderHeader = function (conf_) {
	var _v0 = conf_.bh;
	if (!_v0.$) {
		var cfg = _v0.a;
		return $elm$core$Maybe$Just(
			A2(
				$elm$html$Html$div,
				A2(
					$elm$core$List$cons,
					$elm$html$Html$Attributes$class('modal-header'),
					cfg.aU),
				_Utils_ap(
					cfg.c9,
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Modal$closeButton(
							$rundis$elm_bootstrap$Bootstrap$Modal$getCloseMsg(conf_))
						]))));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$html$Html$Attributes$tabindex = function (n) {
	return A2(
		_VirtualDom_attribute,
		'tabIndex',
		$elm$core$String$fromInt(n));
};
var $rundis$elm_bootstrap$Bootstrap$Modal$view = F2(
	function (visibility, _v0) {
		var conf = _v0;
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_Utils_ap(
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_Utils_ap(
							_List_fromArray(
								[
									$elm$html$Html$Attributes$tabindex(-1)
								]),
							A2($rundis$elm_bootstrap$Bootstrap$Modal$display, visibility, conf)),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_Utils_ap(
									_List_fromArray(
										[
											A2($elm$html$Html$Attributes$attribute, 'role', 'document'),
											$elm$html$Html$Attributes$class('elm-bootstrap-modal')
										]),
									_Utils_ap(
										$rundis$elm_bootstrap$Bootstrap$Modal$modalAttributes(conf.it),
										conf.it.al ? _List_fromArray(
											[
												A2(
												$elm$html$Html$Events$on,
												'click',
												$rundis$elm_bootstrap$Bootstrap$Modal$containerClickDecoder(conf.aj))
											]) : _List_Nil)),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('modal-content')
											]),
										A2(
											$elm$core$List$filterMap,
											$elm$core$Basics$identity,
											_List_fromArray(
												[
													$rundis$elm_bootstrap$Bootstrap$Modal$renderHeader(conf),
													$rundis$elm_bootstrap$Bootstrap$Modal$renderBody(conf.hl),
													$rundis$elm_bootstrap$Bootstrap$Modal$renderFooter(conf.ck)
												])))
									]))
							]))
					]),
				A2($rundis$elm_bootstrap$Bootstrap$Modal$backdrop, visibility, conf)));
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$Col6 = 6;
var $rundis$elm_bootstrap$Bootstrap$Grid$Col$xs6 = A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$width, 0, 6);
var $author$project$Pages$PageBase$modal = function (mdl) {
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Modal$view,
		function () {
			var _v0 = mdl.ek;
			if (_v0.$ === 1) {
				return $rundis$elm_bootstrap$Bootstrap$Modal$hidden;
			} else {
				return $rundis$elm_bootstrap$Bootstrap$Modal$shown;
			}
		}(),
		A3(
			$rundis$elm_bootstrap$Bootstrap$Modal$body,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$containerFluid,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rundis$elm_bootstrap$Bootstrap$Grid$row,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$rundis$elm_bootstrap$Bootstrap$Grid$col,
									_List_fromArray(
										[$rundis$elm_bootstrap$Bootstrap$Grid$Col$xs6]),
									_List_fromArray(
										[
											$elm$html$Html$text('Col 1')
										])),
									A2(
									$rundis$elm_bootstrap$Bootstrap$Grid$col,
									_List_fromArray(
										[$rundis$elm_bootstrap$Bootstrap$Grid$Col$xs6]),
									_List_fromArray(
										[
											$elm$html$Html$text('Col 2')
										]))
								]))
						]))
				]),
			A3(
				$rundis$elm_bootstrap$Bootstrap$Modal$h4,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Getting started ?')
					]),
				$rundis$elm_bootstrap$Bootstrap$Modal$small(
					$rundis$elm_bootstrap$Bootstrap$Modal$config($author$project$Msg$CloseModal)))));
};
var $author$project$Msg$LoginUpdateEmail = function (a) {
	return {$: 13, a: a};
};
var $author$project$Msg$LoginUpdatePassword = function (a) {
	return {$: 14, a: a};
};
var $author$project$Msg$OnClickServerAuthLoginRequest = F2(
	function (a, b) {
		return {$: 15, a: a, b: b};
	});
var $author$project$Msg$OnClickServerAuthRegisterRequest = F2(
	function (a, b) {
		return {$: 17, a: a, b: b};
	});
var $author$project$Msg$OnClickServerAuthResetPasswordRequest = function (a) {
	return {$: 16, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Tab$Center = 0;
var $rundis$elm_bootstrap$Bootstrap$Tab$Config = $elm$core$Basics$identity;
var $rundis$elm_bootstrap$Bootstrap$Tab$layout = F2(
	function (layout_, _v0) {
		var configRec = _v0;
		return _Utils_update(
			configRec,
			{
				l: $elm$core$Maybe$Just(layout_)
			});
	});
var $rundis$elm_bootstrap$Bootstrap$Tab$center = $rundis$elm_bootstrap$Bootstrap$Tab$layout(0);
var $rundis$elm_bootstrap$Bootstrap$Tab$config = function (toMsg) {
	return {aU: _List_Nil, ab: false, h0: _List_Nil, l: $elm$core$Maybe$Nothing, cR: toMsg, b4: false, U: false};
};
var $rundis$elm_bootstrap$Bootstrap$Internal$Button$Disabled = function (a) {
	return {$: 3, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Button$disabled = function (disabled_) {
	return $rundis$elm_bootstrap$Bootstrap$Internal$Button$Disabled(disabled_);
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$Email = 8;
var $rundis$elm_bootstrap$Bootstrap$Form$Input$email = $rundis$elm_bootstrap$Bootstrap$Form$Input$input(8);
var $author$project$ElmCommon$HtmlCommon$empty = $author$project$BigO$Utils$htmlEmpty;
var $author$project$Pages$PageLogin$errPage = function (p) {
	if (p.$ === 14) {
		return true;
	} else {
		return false;
	}
};
var $rundis$elm_bootstrap$Bootstrap$Tab$Fill = 2;
var $rundis$elm_bootstrap$Bootstrap$Tab$fill = $rundis$elm_bootstrap$Bootstrap$Tab$layout(2);
var $rundis$elm_bootstrap$Bootstrap$Form$applyModifier = F2(
	function (modifier, options) {
		var value = modifier;
		return _Utils_update(
			options,
			{
				aU: _Utils_ap(options.aU, value)
			});
	});
var $rundis$elm_bootstrap$Bootstrap$Form$defaultOptions = {aU: _List_Nil};
var $rundis$elm_bootstrap$Bootstrap$Form$toAttributes = function (modifiers) {
	var options = A3($elm$core$List$foldl, $rundis$elm_bootstrap$Bootstrap$Form$applyModifier, $rundis$elm_bootstrap$Bootstrap$Form$defaultOptions, modifiers);
	return _Utils_ap(
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('form-group')
			]),
		options.aU);
};
var $rundis$elm_bootstrap$Bootstrap$Form$group = F2(
	function (options, children) {
		return A2(
			$elm$html$Html$div,
			$rundis$elm_bootstrap$Bootstrap$Form$toAttributes(options),
			children);
	});
var $elm_community$maybe_extra$Maybe$Extra$isNothing = function (m) {
	if (m.$ === 1) {
		return true;
	} else {
		return false;
	}
};
var $rundis$elm_bootstrap$Bootstrap$Tab$Item = $elm$core$Basics$identity;
var $rundis$elm_bootstrap$Bootstrap$Tab$item = function (rec) {
	return {ef: rec.ef, ep: rec.ep, fr: rec.fr};
};
var $rundis$elm_bootstrap$Bootstrap$Tab$items = F2(
	function (items_, _v0) {
		var configRec = _v0;
		return _Utils_update(
			configRec,
			{h0: items_});
	});
var $rundis$elm_bootstrap$Bootstrap$Tab$Link = $elm$core$Basics$identity;
var $rundis$elm_bootstrap$Bootstrap$Tab$link = F2(
	function (attributes, children) {
		return {aU: attributes, c9: children};
	});
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$p3 = $elm$html$Html$Attributes$class('p-3');
var $rundis$elm_bootstrap$Bootstrap$Tab$Pane = $elm$core$Basics$identity;
var $rundis$elm_bootstrap$Bootstrap$Tab$pane = F2(
	function (attributes, children) {
		return {aU: attributes, c9: children};
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Input$Password = 1;
var $rundis$elm_bootstrap$Bootstrap$Form$Input$password = $rundis$elm_bootstrap$Bootstrap$Form$Input$input(1);
var $rundis$elm_bootstrap$Bootstrap$Spinner$applyModifier = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 0:
				var spinnerKind = modifier.a;
				return _Utils_update(
					options,
					{aq: spinnerKind});
			case 1:
				var spinnerSize = modifier.a;
				return _Utils_update(
					options,
					{ge: spinnerSize});
			case 2:
				var color_ = modifier.a;
				return _Utils_update(
					options,
					{
						a2: $elm$core$Maybe$Just(color_)
					});
			default:
				var list = modifier.a;
				return _Utils_update(
					options,
					{aU: list});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Spinner$Border = 0;
var $rundis$elm_bootstrap$Bootstrap$Spinner$Normal = 0;
var $rundis$elm_bootstrap$Bootstrap$Spinner$defaultOptions = {aU: _List_Nil, a2: $elm$core$Maybe$Nothing, aq: 0, ge: 0};
var $rundis$elm_bootstrap$Bootstrap$Spinner$kindClassName = function (kind_) {
	if (!kind_) {
		return 'spinner-border';
	} else {
		return 'spinner-grow';
	}
};
var $rundis$elm_bootstrap$Bootstrap$Spinner$kindClass = A2($elm$core$Basics$composeL, $elm$html$Html$Attributes$class, $rundis$elm_bootstrap$Bootstrap$Spinner$kindClassName);
var $rundis$elm_bootstrap$Bootstrap$Spinner$sizeAttributes = F2(
	function (size_, kind_) {
		switch (size_) {
			case 0:
				return $elm$core$Maybe$Nothing;
			case 1:
				return $elm$core$Maybe$Just(
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(
							$rundis$elm_bootstrap$Bootstrap$Spinner$kindClassName(kind_) + '-sm')
						]));
			default:
				return $elm$core$Maybe$Just(
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'width', '3rem'),
							A2($elm$html$Html$Attributes$style, 'height', '3rem')
						]));
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Internal$Text$textColorClass = function (color) {
	if (color.$ === 1) {
		return $elm$html$Html$Attributes$class('text-white');
	} else {
		var role = color.a;
		return A2($rundis$elm_bootstrap$Bootstrap$Internal$Role$toClass, 'text', role);
	}
};
var $rundis$elm_bootstrap$Bootstrap$Spinner$toAttributes = function (options) {
	return _Utils_ap(
		A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					$elm$core$Maybe$Just(
					$rundis$elm_bootstrap$Bootstrap$Spinner$kindClass(options.aq)),
					A2($elm$core$Maybe$map, $rundis$elm_bootstrap$Bootstrap$Internal$Text$textColorClass, options.a2)
				])),
		_Utils_ap(
			A2(
				$elm$core$Maybe$withDefault,
				_List_Nil,
				A2($rundis$elm_bootstrap$Bootstrap$Spinner$sizeAttributes, options.ge, options.aq)),
			_Utils_ap(
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$attribute, 'role', 'status')
					]),
				options.aU)));
};
var $rundis$elm_bootstrap$Bootstrap$Spinner$spinner = F2(
	function (options, children) {
		var opts = A3($elm$core$List$foldl, $rundis$elm_bootstrap$Bootstrap$Spinner$applyModifier, $rundis$elm_bootstrap$Bootstrap$Spinner$defaultOptions, options);
		return A2(
			$elm$html$Html$div,
			$rundis$elm_bootstrap$Bootstrap$Spinner$toAttributes(opts),
			children);
	});
var $rundis$elm_bootstrap$Bootstrap$Tab$getActiveItem = F2(
	function (_v0, configRec) {
		var activeTab = _v0.aL;
		if (activeTab.$ === 1) {
			return $elm$core$List$head(configRec.h0);
		} else {
			var id = activeTab.a;
			return function (found) {
				if (!found.$) {
					var f = found.a;
					return $elm$core$Maybe$Just(f);
				} else {
					return $elm$core$List$head(configRec.h0);
				}
			}(
				$elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (_v2) {
							var item_ = _v2;
							return _Utils_eq(item_.ef, id);
						},
						configRec.h0)));
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Tab$Hidden = 0;
var $rundis$elm_bootstrap$Bootstrap$Tab$Start = 1;
var $rundis$elm_bootstrap$Bootstrap$Tab$visibilityTransition = F2(
	function (withAnimation_, visibility) {
		var _v0 = _Utils_Tuple2(withAnimation_, visibility);
		_v0$2:
		while (true) {
			if (_v0.a) {
				switch (_v0.b) {
					case 0:
						var _v1 = _v0.b;
						return 1;
					case 1:
						var _v2 = _v0.b;
						return 2;
					default:
						break _v0$2;
				}
			} else {
				break _v0$2;
			}
		}
		return 2;
	});
var $rundis$elm_bootstrap$Bootstrap$Tab$renderLink = F4(
	function (id, active, _v0, configRec) {
		var attributes = _v0.aU;
		var children = _v0.c9;
		var commonClasses = _List_fromArray(
			[
				_Utils_Tuple2('nav-link', true),
				_Utils_Tuple2('active', active)
			]);
		var clickHandler = $elm$html$Html$Events$onClick(
			configRec.cR(
				{
					aL: $elm$core$Maybe$Just(id),
					m: A2($rundis$elm_bootstrap$Bootstrap$Tab$visibilityTransition, configRec.U && (!active), 0)
				}));
		var linkItem = configRec.b4 ? A2(
			$elm$html$Html$a,
			_Utils_ap(
				_List_fromArray(
					[
						$elm$html$Html$Attributes$classList(commonClasses),
						clickHandler,
						$elm$html$Html$Attributes$href('#' + id)
					]),
				attributes),
			children) : A2(
			$elm$html$Html$button,
			_Utils_ap(
				_List_fromArray(
					[
						$elm$html$Html$Attributes$classList(
						_Utils_ap(
							commonClasses,
							_List_fromArray(
								[
									_Utils_Tuple2('btn', true),
									_Utils_Tuple2('btn-link', true)
								]))),
						clickHandler
					]),
				attributes),
			children);
		return A2(
			$elm$html$Html$li,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('nav-item')
				]),
			_List_fromArray(
				[linkItem]));
	});
var $rundis$elm_bootstrap$Bootstrap$Tab$transitionStyles = function (opacity) {
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$Attributes$style,
			'opacity',
			$elm$core$String$fromInt(opacity)),
			A2($elm$html$Html$Attributes$style, '-webkit-transition', 'opacity 0.15s linear'),
			A2($elm$html$Html$Attributes$style, '-o-transition', 'opacity 0.15s linear'),
			A2($elm$html$Html$Attributes$style, 'transition', 'opacity 0.15s linear')
		]);
};
var $rundis$elm_bootstrap$Bootstrap$Tab$activeTabAttributes = F2(
	function (_v0, configRec) {
		var visibility = _v0.m;
		switch (visibility) {
			case 0:
				return _List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'display', 'none')
					]);
			case 1:
				return _List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'display', 'block'),
						A2($elm$html$Html$Attributes$style, 'opacity', '0')
					]);
			default:
				return _Utils_ap(
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'display', 'block')
						]),
					$rundis$elm_bootstrap$Bootstrap$Tab$transitionStyles(1));
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Tab$renderTabPane = F5(
	function (id, active, _v0, state, configRec) {
		var attributes = _v0.aU;
		var children = _v0.c9;
		var displayAttrs = active ? A2($rundis$elm_bootstrap$Bootstrap$Tab$activeTabAttributes, state, configRec) : _List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'display', 'none')
			]);
		return A2(
			$elm$html$Html$div,
			_Utils_ap(
				_List_fromArray(
					[
						$elm$html$Html$Attributes$id(id),
						$elm$html$Html$Attributes$class('tab-pane')
					]),
				_Utils_ap(displayAttrs, attributes)),
			children);
	});
var $rundis$elm_bootstrap$Bootstrap$Tab$tabAttributes = function (configRec) {
	return _Utils_ap(
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('nav', true),
						_Utils_Tuple2('nav-tabs', !configRec.ab),
						_Utils_Tuple2('nav-pills', configRec.ab)
					]))
			]),
		_Utils_ap(
			function () {
				var _v0 = configRec.l;
				if (!_v0.$) {
					switch (_v0.a) {
						case 3:
							var _v1 = _v0.a;
							return _List_fromArray(
								[
									$elm$html$Html$Attributes$class('nav-justified')
								]);
						case 2:
							var _v2 = _v0.a;
							return _List_fromArray(
								[
									$elm$html$Html$Attributes$class('nav-fill')
								]);
						case 0:
							var _v3 = _v0.a;
							return _List_fromArray(
								[
									$elm$html$Html$Attributes$class('justify-content-center')
								]);
						default:
							var _v4 = _v0.a;
							return _List_fromArray(
								[
									$elm$html$Html$Attributes$class('justify-content-end')
								]);
					}
				} else {
					return _List_Nil;
				}
			}(),
			configRec.aU));
};
var $rundis$elm_bootstrap$Bootstrap$Tab$view = F2(
	function (state, _v0) {
		var configRec = _v0;
		var _v1 = A2($rundis$elm_bootstrap$Bootstrap$Tab$getActiveItem, state, configRec);
		if (_v1.$ === 1) {
			return A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$ul,
						$rundis$elm_bootstrap$Bootstrap$Tab$tabAttributes(configRec),
						_List_Nil),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('tab-content')
							]),
						_List_Nil)
					]));
		} else {
			var currentItem = _v1.a;
			return A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$ul,
						$rundis$elm_bootstrap$Bootstrap$Tab$tabAttributes(configRec),
						A2(
							$elm$core$List$map,
							function (_v2) {
								var item_ = _v2;
								return A4(
									$rundis$elm_bootstrap$Bootstrap$Tab$renderLink,
									item_.ef,
									_Utils_eq(item_.ef, currentItem.ef),
									item_.ep,
									configRec);
							},
							configRec.h0)),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('tab-content')
							]),
						A2(
							$elm$core$List$map,
							function (_v3) {
								var item_ = _v3;
								return A5(
									$rundis$elm_bootstrap$Bootstrap$Tab$renderTabPane,
									item_.ef,
									_Utils_eq(item_.ef, currentItem.ef),
									item_.fr,
									state,
									configRec);
							},
							configRec.h0))
					]));
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Tab$withAnimation = function (_v0) {
	var configRec = _v0;
	return _Utils_update(
		configRec,
		{U: true});
};
var $author$project$Pages$PageLogin$modalLogin = function (mdl) {
	var loginPage = function () {
		var _v1 = mdl.h3.c$;
		switch (_v1.$) {
			case 2:
				return $author$project$ElmCommon$HtmlCommon$empty;
			case 1:
				return A2(
					$rundis$elm_bootstrap$Bootstrap$Modal$view,
					$rundis$elm_bootstrap$Bootstrap$Modal$shown,
					A3(
						$rundis$elm_bootstrap$Bootstrap$Modal$body,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rundis$elm_bootstrap$Bootstrap$Grid$containerFluid,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Authenticating...'),
										A2($rundis$elm_bootstrap$Bootstrap$Spinner$spinner, _List_Nil, _List_Nil)
									]))
							]),
						$rundis$elm_bootstrap$Bootstrap$Modal$small(
							$rundis$elm_bootstrap$Bootstrap$Modal$config($author$project$Msg$CloseModal))));
			default:
				var email = _v1.a;
				var password = _v1.b;
				var lastError = _v1.c;
				var passwordInput = $rundis$elm_bootstrap$Bootstrap$Form$Input$password(
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Form$Input$value(password.gW),
							$rundis$elm_bootstrap$Bootstrap$Form$Input$onInput(
							A2($elm$core$Basics$composeL, $author$project$Msg$LoginUpdatePassword, $author$project$Api$UserPassword$UserPassword)),
							$rundis$elm_bootstrap$Bootstrap$Form$Input$attrs(
							_List_fromArray(
								[
									$elm$html$Html$Attributes$placeholder('password')
								]))
						]));
				var emailInput = $rundis$elm_bootstrap$Bootstrap$Form$Input$email(
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Form$Input$value(email.gR),
							$rundis$elm_bootstrap$Bootstrap$Form$Input$onInput(
							A2($elm$core$Basics$composeL, $author$project$Msg$LoginUpdateEmail, $author$project$Api$UserEmail$UserEmail)),
							$rundis$elm_bootstrap$Bootstrap$Form$Input$attrs(
							_List_fromArray(
								[
									$elm$html$Html$Attributes$placeholder('Email Address')
								]))
						]));
				var btnResetPassowrd = A2(
					$rundis$elm_bootstrap$Bootstrap$Button$button,
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Button$primary,
							$rundis$elm_bootstrap$Bootstrap$Button$disabled(
							$elm_community$maybe_extra$Maybe$Extra$isNothing(mdl.eb)),
							$rundis$elm_bootstrap$Bootstrap$Button$onClick(
							$author$project$Msg$OnClickServerAuthResetPasswordRequest(email))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Reset Password')
						]));
				var btnRegister = A2(
					$rundis$elm_bootstrap$Bootstrap$Button$button,
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Button$primary,
							$rundis$elm_bootstrap$Bootstrap$Button$disabled(
							$elm_community$maybe_extra$Maybe$Extra$isNothing(mdl.eb)),
							$rundis$elm_bootstrap$Bootstrap$Button$onClick(
							A2($author$project$Msg$OnClickServerAuthRegisterRequest, email, password))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Register')
						]));
				var registerForm = A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$containerFluid,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rundis$elm_bootstrap$Bootstrap$Form$form,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$rundis$elm_bootstrap$Bootstrap$Form$group,
									_List_Nil,
									_List_fromArray(
										[emailInput])),
									A2(
									$rundis$elm_bootstrap$Bootstrap$Form$group,
									_List_Nil,
									_List_fromArray(
										[passwordInput])),
									btnRegister
								]))
						]));
				var btnLogin = A2(
					$rundis$elm_bootstrap$Bootstrap$Button$button,
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Button$primary,
							$rundis$elm_bootstrap$Bootstrap$Button$disabled(
							$elm_community$maybe_extra$Maybe$Extra$isNothing(mdl.eb)),
							$rundis$elm_bootstrap$Bootstrap$Button$onClick(
							A2($author$project$Msg$OnClickServerAuthLoginRequest, email, password))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Login')
						]));
				var logInForm = A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$containerFluid,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rundis$elm_bootstrap$Bootstrap$Form$form,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$rundis$elm_bootstrap$Bootstrap$Form$group,
									_List_Nil,
									_List_fromArray(
										[emailInput])),
									A2(
									$rundis$elm_bootstrap$Bootstrap$Form$group,
									_List_Nil,
									_List_fromArray(
										[passwordInput])),
									btnLogin,
									btnResetPassowrd
								])),
							$elm$html$Html$text(
							A2(
								$elm$core$Maybe$withDefault,
								'',
								A2(
									$elm$core$Maybe$map,
									function ($) {
										return $.iY;
									},
									lastError)))
						]));
				var body = function () {
					var _v2 = mdl.h3.gs;
					switch (_v2) {
						case 0:
							return A2(
								$rundis$elm_bootstrap$Bootstrap$Tab$view,
								mdl.h3.iM,
								A2(
									$rundis$elm_bootstrap$Bootstrap$Tab$items,
									_List_fromArray(
										[
											$rundis$elm_bootstrap$Bootstrap$Tab$item(
											{
												ef: 'tabItem1',
												ep: A2(
													$rundis$elm_bootstrap$Bootstrap$Tab$link,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Login')
														])),
												fr: A2(
													$rundis$elm_bootstrap$Bootstrap$Tab$pane,
													_List_fromArray(
														[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$p3]),
													_List_fromArray(
														[logInForm]))
											}),
											$rundis$elm_bootstrap$Bootstrap$Tab$item(
											{
												ef: 'tabItem2',
												ep: A2(
													$rundis$elm_bootstrap$Bootstrap$Tab$link,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Register')
														])),
												fr: A2(
													$rundis$elm_bootstrap$Bootstrap$Tab$pane,
													_List_fromArray(
														[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$p3]),
													_List_fromArray(
														[registerForm]))
											})
										]),
									$rundis$elm_bootstrap$Bootstrap$Tab$center(
										$rundis$elm_bootstrap$Bootstrap$Tab$fill(
											$rundis$elm_bootstrap$Bootstrap$Tab$withAnimation(
												$rundis$elm_bootstrap$Bootstrap$Tab$config($author$project$Msg$UiLoginTabMsg))))));
						case 1:
							return $elm$html$Html$text('Email sent - please check your inbox');
						default:
							return $elm$html$Html$text('Email sent - please check your inbox');
					}
				}();
				return A2(
					$rundis$elm_bootstrap$Bootstrap$Modal$view,
					$rundis$elm_bootstrap$Bootstrap$Modal$shown,
					A3(
						$rundis$elm_bootstrap$Bootstrap$Modal$body,
						_List_Nil,
						_List_fromArray(
							[body]),
						$rundis$elm_bootstrap$Bootstrap$Modal$small(
							$rundis$elm_bootstrap$Bootstrap$Modal$config($author$project$Msg$CloseModal))));
		}
	}();
	var _v0 = $author$project$Pages$PageLogin$errPage(mdl.fq);
	if (_v0) {
		return $author$project$ElmCommon$HtmlCommon$empty;
	} else {
		return loginPage;
	}
};
var $author$project$Pages$PageBase$view = function (mdl) {
	return {
		hl: _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$Pages$PageBase$menu(mdl),
						$author$project$Pages$PageBase$mainContent(mdl),
						$author$project$Pages$PageBase$modal(mdl),
						$author$project$Pages$PageLogin$modalLogin(mdl)
					]))
			]),
		iS: 'super easy sensors'
	};
};
var $author$project$Main$main = $elm$browser$Browser$application(
	{hZ: $author$project$Main$init, iq: $author$project$Msg$UrlChange, ir: $author$project$Msg$ClickedLink, iK: $author$project$Main$subscriptions, iZ: $author$project$Update$update, i1: $author$project$Pages$PageBase$view});
_Platform_export({'Main':{'init':$author$project$Main$main(
	$elm$json$Json$Decode$succeed(
		{}))(0)}});}(this));